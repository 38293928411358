import React, { useContext, useEffect, useState } from "react";
import useApi from "hooks/useApi";
import { getSearchDetails } from "services/product.service";
import { Link, useSearchParams } from "react-router-dom";
import SearchProductCard from "@components/SearchDetail/SearchProductCard";
import { IWareHousesResult } from "types/wareHouseTypes";
import StoreItem from "@components/Stores/StoreItem";
import BlogCard from "@components/Blog/BlogCard";
import { IBlogPosts } from "views/Blog/BlogCategories";
import SearchInstantProductCard from "@components/SearchDetail/SearchInstantProductCard";
import SearchOrangeProductCard from "@components/SearchDetail/SearchOrangeProductCard";
import Skeleton from "react-loading-skeleton";
import ProductHomeSkeleton from "@components/General/ProductHomeSkeleton";
import StoreSkeletonItem from "@components/Stores/StoreSkeletonItem";
import BlogCardSkeleton from "@components/Blog/BlogCardSkeleton";
import ProductsCardSkeleton from "@components/General/ProductsCardSkeleton";
import { PublicContext } from "contexts/PublicContext/PublicContext";

export interface ISearchProduct {
  displayBrandName: string;
  displayProductForm: string;
  price: {
    unit: string;
    value: 37.41;
  };
  categoryCode: string;
  brand: string;
  brandCode: string;
  form: string;
  formCode: string;
  label: string[];
  hasOffer: boolean;
  hasPlan: boolean;
  thumbnail: {
    fileContents: string;
    contentType: string;
    fileDownloadName: string;
    lastModified: string;
    entityTag: string;
    enableRangeProcessing: boolean;
  };
  isFavorite: boolean;
  id: string;
}

interface ISearchDetails {
  productCount: number;
  products: ISearchProduct[];
  warehouseCount: number;
  warehouses: IWareHousesResult[];
  blogCount: number;
  blogs: IBlogPosts[];
  instantCampaignsCount: number;
  instantCampaigns: ISearchProduct[];
  orangeTermCampaignsCount: number;
  orangeTermCampaigns: ISearchProduct[];
}
/* eslint-disable react-hooks/exhaustive-deps */
const SearchDetails = () => {
  const { data, handleApi, loading } = useApi();
  let [searchParams] = useSearchParams();
  const [query, setQuery] = useState(searchParams.get("q"));
  const [catQuery, setCatQuery] = useState(searchParams.get("kategori"));
  const { setSiteTitle } = useContext(PublicContext);

  useEffect(() => {
    setQuery(searchParams.get("q"));
    setCatQuery(searchParams.get("kategori"));
  }, [searchParams]);

  useEffect(() => {
    handleApi(getSearchDetails, {
      query: query?.toUpperCase(),
    });
    const title = `${query} kelimesi Arama Sonuçları`
    setSiteTitle(title);
    return () => {};
  }, [query]);

  const result: ISearchDetails = data && data;
  return (
    <>
      <section className="bg:white shadow:9">
        <div className="container">
          <section className="singleMenu singleMenu--search flex-nowrap">
            <nav>
              <ul className="fs:16 c:dark:2 ff:bold">
                <li>
                  <Link
                    className={!catQuery ? "singleMenu__active" : ""}
                    to={`/ara?q=${query}`}
                  >
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1 me-4">Ürünler</div>
                      <div className="flex-shrink-0">
                        <p
                          className="singleMenu__badge c:white paddingElem fs:14 ff:bold radius:6 lh:1"
                          style={
                            {
                              "--paddingElem": "0.188rem 0.600rem",
                            } as React.CSSProperties
                          }
                        >
                          {result?.productCount}
                        </p>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link
                    className={
                      catQuery === "depolar" ? "singleMenu__active" : ""
                    }
                    to={`/ara?q=${query}&kategori=depolar`}
                  >
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1 me-4">Depolar</div>
                      <div className="flex-shrink-0">
                        <p
                          className="singleMenu__badge c:white paddingElem fs:14 ff:bold radius:6 lh:1"
                          style={
                            {
                              "--paddingElem": "0.188rem 0.600rem",
                            } as React.CSSProperties
                          }
                        >
                          {result?.warehouseCount}
                        </p>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link
                    className={catQuery === "blog" ? "singleMenu__active" : ""}
                    to={`/ara?q=${query}&kategori=blog`}
                  >
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1 me-4">Blog</div>
                      <div className="flex-shrink-0">
                        <p
                          className="singleMenu__badge c:white paddingElem fs:14 ff:bold radius:6 lh:1"
                          style={
                            {
                              "--paddingElem": "0.188rem 0.600rem",
                            } as React.CSSProperties
                          }
                        >
                          {result?.blogCount}
                        </p>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link
                    className={catQuery === "anlik" ? "singleMenu__active" : ""}
                    to={`/ara?q=${query}&kategori=anlik`}
                  >
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1 me-4">Anlık Kampanyalar</div>
                      <div className="flex-shrink-0">
                        <p
                          className="singleMenu__badge c:white paddingElem fs:14 ff:bold radius:6 lh:1"
                          style={
                            {
                              "--paddingElem": "0.188rem 0.600rem",
                            } as React.CSSProperties
                          }
                        >
                          {result?.instantCampaignsCount}
                        </p>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link
                    className={
                      catQuery === "turuncu" ? "singleMenu__active" : ""
                    }
                    to={`/ara?q=${query}&kategori=turuncu`}
                  >
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1 me-4">
                        Turuncu Dönemsel Kampanyalar
                      </div>
                      <div className="flex-shrink-0">
                        <p
                          className="singleMenu__badge c:white paddingElem fs:14 ff:bold radius:6 lh:1"
                          style={
                            {
                              "--paddingElem": "0.188rem 0.600rem",
                            } as React.CSSProperties
                          }
                        >
                          {result?.orangeTermCampaignsCount}
                        </p>
                      </div>
                    </div>
                  </Link>
                </li>
              </ul>
            </nav>
          </section>
        </div>
      </section>
      <section className="mt-4 mt-sm-5 mb-4 mb-sm-5">
        <div className="container">
          {loading ? (
            <Skeleton height={17} width={60} />
          ) : (
            <p className="c:dark fs:16 ff:regular text-center text-md-start">
              <b>"{query}"</b> ile ilgili{" "}
              <b>
                {catQuery === null
                  ? result?.productCount !== 0
                    ? result?.productCount
                    : null
                  : catQuery === "depolar"
                  ? result?.warehouseCount !== 0
                    ? result?.warehouseCount
                    : null
                  : catQuery === "blog"
                  ? result?.blogCount !== 0
                    ? result?.blogCount
                    : null
                  : catQuery === "anlik"
                  ? result?.instantCampaignsCount !== 0
                    ? result?.instantCampaignsCount
                    : null
                  : catQuery === "turuncu" &&
                    result?.orangeTermCampaignsCount !== 0
                  ? result?.orangeTermCampaignsCount
                  : null}
              </b>{" "}
              {catQuery === null
                ? result?.productCount !== 0
                  ? "sonuç bulundu."
                  : "sonuç bulunamadı."
                : catQuery === "depolar"
                ? result?.warehouseCount !== 0
                  ? "sonuç bulundu."
                  : "sonuç bulunamadı."
                : catQuery === "blog"
                ? result?.blogCount !== 0
                  ? "sonuç bulundu."
                  : "sonuç bulunamadı."
                : catQuery === "anlik"
                ? result?.instantCampaignsCount !== 0
                  ? "sonuç bulundu."
                  : "sonuç bulunamadı."
                : catQuery === "turuncu" &&
                  result?.orangeTermCampaignsCount !== 0
                ? "sonuç bulundu."
                : "sonuç bulunamadı."}
            </p>
          )}
        </div>
      </section>
      <section className="mb-5">
        <div className="container">
          {loading && catQuery === null ? (
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-5 g-3">
              <ProductHomeSkeleton />
              <ProductHomeSkeleton />
              <ProductHomeSkeleton />
              <ProductHomeSkeleton />
              <ProductHomeSkeleton />
            </div>
          ) : (
            result &&
            catQuery === null && (
              <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-5 g-3">
                {result.products.map((product) => (
                  <SearchProductCard key={product.id} product={product} />
                ))}
              </div>
            )
          )}
          {catQuery === "depolar" && (
            <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-3 g-3">
              {loading ? (
                <>
                  <StoreSkeletonItem />
                  <StoreSkeletonItem />
                  <StoreSkeletonItem />
                </>
              ) : (
                result &&
                result.warehouses.map((warehouse) => (
                  <StoreItem item={warehouse} key={warehouse.id} />
                ))
              )}
            </div>
          )}
          {catQuery === "blog" && (
            <div className="row row-cols-1 row-cols-lg-3 gx-0 gx-lg-3 gy-3">
              {loading ? (
                <>
                  <BlogCardSkeleton />
                  <BlogCardSkeleton />
                  <BlogCardSkeleton />
                </>
              ) : (
                result &&
                result.blogs.map((item) => (
                  <BlogCard post={item} key={item.id} color={item.blogColor} />
                ))
              )}
            </div>
          )}
          {catQuery === "anlik" && (
            <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-3 row-cols-xxl-4 g-3">
              {loading ? (
                <>
                  <Skeleton
                    height={234.05}
                    width={421.33}
                    count={1}
                    borderRadius={"1rem"}
                  />
                  <Skeleton
                    height={234.05}
                    width={421.33}
                    count={1}
                    borderRadius={"1rem"}
                  />
                  <Skeleton
                    height={234.05}
                    width={421.33}
                    count={1}
                    borderRadius={"1rem"}
                  />
                </>
              ) : (
                result &&
                result.instantCampaigns.map((product) => (
                  <SearchInstantProductCard
                    product={product}
                    key={product.id}
                  />
                ))
              )}
            </div>
          )}
          {catQuery === "turuncu" && (
            <section className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-5 g-3">
              {loading ? (
                <>
                  <ProductsCardSkeleton />
                  <ProductsCardSkeleton />
                  <ProductsCardSkeleton />
                  <ProductsCardSkeleton />
                  <ProductsCardSkeleton />
                </>
              ) : (
                result &&
                result.orangeTermCampaigns.map((product, index) => (
                  <SearchOrangeProductCard
                    data={data}
                    product={product}
                    key={product.id + index}
                    // handleActivePopup={handleActivePopup}
                    // selectedWareHouse={selectedWareHouse}
                    // setSelectedPlan={setSelectedPlan}
                  />
                ))
              )}
            </section>
          )}
        </div>
      </section>
    </>
  );
};

export default SearchDetails;
