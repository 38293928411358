import Skeleton from "react-loading-skeleton";

const StoreSkeletonItem = () => {
  return (
    <div className="col">
      <div className="h-100">
        <div className="bg:white radius:12 p-3 shadow:4 border:1 w-100 h-100">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <div className="ms-auto">
              <button
                aria-label="star button"
                className="d-block"
                type="button"
              >
                <div className={`starButton`}>
                  <Skeleton height={20} width={20} borderRadius={"50%"} />
                </div>
              </button>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <Skeleton
                height={"2.875rem"}
                width={"2.875rem"}
                borderRadius={"50%"}
              />
            </div>
            <div className="flex-grow-1 ms-3">
              <p className="c:dark ff:regular fs:16 text-capitalize">
                <Skeleton height={24} width={190} />
              </p>
            </div>
          </div>
          <div className="d-flex justify-content-end mt-3">
            <Skeleton
              style={{
                padding: "0.5rem 1.8rem",
                boxShadow: "0 0.125rem 0.3125rem #eee",
                borderRadius: "0.75rem",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoreSkeletonItem;
