import React from "react";
import { IPlans } from "types/productTypes";
import { convertDate } from "utility/formatDate";
interface IProps {
  plan: IPlans;
  handleShowDetails: (plan: IPlans) => void;
  campaignEndDate: string;
}
const TdkPlanCard = ({ plan, handleShowDetails, campaignEndDate }: IProps) => {
  return (
    <div className="col">
      <div className="bg:white radius:12 p-3 shadow:4 h-100">
        <div className="d-flex flex-column h-100">
          <div>
            <div className="row align-items-center gx-3">
              <div className="col-7 col-lg-7 col-xl-8">
                <p className="fs:14 ff:regular c:dark text-uppercase">
                  {plan.productName}
                </p>
              </div>
              <div className="col-5 col-lg-5 col-xl-4">
                <div
                  className="bg:green:3 shadow:4 c:dark ff:regular fs:13 d-block w-100 borderStyle radius:12 text-center paddingElem"
                  style={
                    {
                      "--gsk-border-width": "0.063rem",
                      "--gsk-border-color": "#39BD8D",
                      "--paddingElem": "0.563rem 0rem",
                    } as React.CSSProperties
                  }
                >
                  {plan.status}
                </div>
              </div>
            </div>
            <p className="c:orange ff:bold fs:14 text-uppercase my-3">
              {plan.planName}
            </p>
          </div>
          <div className="mt-auto">
            <div className="row align-items-center gx-2 gx-xl-3 gy-lg-0 gy-3">
              <div className="col-12 col-lg-7 col-xl-8">
                <p className="c:dark:2 fs:14 ff:bold">
                  Kampanya bitiş tarihi: {convertDate(campaignEndDate)}
                </p>
              </div>
              <div className="col-12 col-lg-5 col-xl-4">
                <button
                  onClick={() => handleShowDetails(plan)}
                  aria-label="kampanya detayı"
                  type="button"
                  className="buttonStyle buttonStyle--light buttonStyle--paddingElem c:dark ff:bold fs:14 bg:white shadow:4 d-block w-100 text-center radius:12"
                  style={
                    {
                      "--btnPaddingElem": "0.8rem 0rem",
                    } as React.CSSProperties
                  }
                >
                  Detaylı Bilgi
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TdkPlanCard;
