import { NavLink } from "react-router-dom";

const HeaderTop = () => {
  return (
    <section className="header__top w-100 bg:white d-none d-lg-flex align-items-center">
      <div className="container-fluid">
        <div className="row justify-content-center g-0">
          <div className="col-12 col-md-11">
            <nav className="header__top-nav">
              <ul className="row row-cols-auto gy-0 align-items-center justify-content-start justify-content-md-end ff:bold fs:14 c:dark">
                <li className="col">
                  <NavLink
                    end={true}
                    className={({ isActive, isPending }) =>
                      isPending ? "pending" : isActive ? "c:orange" : ""
                    }
                    to="/hesabim/favorilerim"
                  >
                    Favorilerim
                  </NavLink>
                </li>
                <li className="col">
                  <NavLink
                    className={({ isActive, isPending }) =>
                      isPending ? "pending" : isActive ? "c:orange" : ""
                    }
                    to="/hesabim/kampanyalarim"
                  >
                    Kampanyalarım
                  </NavLink>
                </li>
                <li className="col">
                  <NavLink
                    className={({ isActive, isPending }) =>
                      isPending ? "pending" : isActive ? "c:orange" : ""
                    }
                    to="/hesabim/soru-ve-taleplerim"
                  >
                    Soru ve Taleplerim
                  </NavLink>
                </li>
                <li className="col">
                  <NavLink
                    end={true}
                    className={({ isActive, isPending }) =>
                      isPending ? "pending" : isActive ? "c:orange" : ""
                    }
                    to="/hesabim/siparislerim"
                  >
                    Siparişlerim
                  </NavLink>
                </li>
                <li className="col">
                  <NavLink
                    end={true}
                    className={({ isActive, isPending }) =>
                      isPending ? "pending" : isActive ? "c:orange" : "c:purple"
                    }
                    to="/hesabim/hizli-siparislerim"
                  >
                    Hızlı Sipariş
                  </NavLink>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeaderTop;
