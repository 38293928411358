import React, { useState, useRef, useContext, useEffect } from "react";
import useOnClickOutside from "hooks/useOnClickOutside";
import { IOffer, IOfferItem, IOffers, ResultDetail } from "types/productTypes";
import { CartContext } from "contexts/CartContext/CartContext";
import { ICartItem } from "types/shoppingCartTypes";
import iconIsolation from "@images/icon-isolation.svg";
import { sortInstantCompaings } from "utility/sortBy";
/* eslint-disable react-hooks/exhaustive-deps */
interface IProps {
  product: ResultDetail;
  setSelectedOffer: (offer: IOfferItem | undefined) => void;
  selectedOffer?: IOfferItem | undefined;
  isPreOrder: boolean;
  whichOffer: IOffers | undefined;
  cartItem: ICartItem | undefined;
}

const Select = ({
  product,
  selectedOffer,
  setSelectedOffer,
  isPreOrder,
  whichOffer,
  cartItem,
}: IProps) => {
  const [dropDownIsOpen, setDropDownIsOpen] = useState(false);
  const { updateCartItem } = useContext(CartContext);
  const [offer, setOffer] = useState<IOfferItem | undefined>();

  const handleDropDown = () => {
    setDropDownIsOpen((prev) => !prev);
  };

  useEffect(() => {
    if (cartItem && whichOffer) {
      setOffer(
        whichOffer?.items.find(
          (prodOffer) => prodOffer.id === cartItem?.selectedOffer
        )
      );
    }
  }, [product, cartItem, whichOffer]);

  useEffect(() => {
    if (offer) {
      setSelectedOffer({ ...offer!, productId: product.id });
    }
  }, [offer, product]);

  const handleOffer = (offr: IOffer | undefined) => {
    if (offr) {
      setSelectedOffer({ ...offr, productId: product.id });
      if (cartItem) {
        updateCartItem!(cartItem, offr.quantity, offr.id, isPreOrder);
      }
    } else {
      setSelectedOffer(undefined);
      if (cartItem) {
        updateCartItem!(cartItem, 1, "", isPreOrder);
      }
    }
    setDropDownIsOpen(false);
  };
  const dropdownRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(
    dropdownRef,
    () => dropDownIsOpen && setDropDownIsOpen(false)
  );

  return (
    <>
      <div ref={dropdownRef} className="dropdownBtn">
        <div className="formElementItem formElementItem--customSelect">
          {/* <!-- selectDropdown'a tıklandığında formElementItem__input--select__active classı eklenecek. --> */}
          <div
            onClick={handleDropDown}
            className={`selectDropdown formElementItem__input formElementItem__input--padding formElementItem__input--select ${
              dropDownIsOpen ? "formElementItem__input--select__active" : ""
            } bg:white radius:12 w-100 fs:15 ff:regular c:dark shadow:4 d-flex align-items-center`}
            style={
              {
                "--inputPadding": "0 3rem 0 2.6rem",
                "--form-borderColor": "#D8D8D8",
                "--form-height": "3rem",
              } as React.CSSProperties
            }
          >
            {!selectedOffer ? (
              <p>Anlık kampanya seç</p>
            ) : (
              <p className="c:dark ff:bold fs:16">
                {selectedOffer?.quantity}{" "}
                <span className="d-inline-block c:yellow:4">
                  + {selectedOffer?.free_amount}
                </span>
              </p>
            )}
          </div>
        </div>
        {/* <!-- selectDropdown'a tıklandığında d-none d-block --> */}
        <div
          ref={dropdownRef}
          className={`dropdownBtn__box bg:white radius:12 shadow:8 border:1 ${
            dropDownIsOpen ? "d-block" : "d-none "
          }`}
        >
          <ul className="py-3">
            <li>
              <button
                type="button"
                aria-label="select campaigns"
                onClick={() => handleOffer(undefined)}
                className={`px-3 w-100 ${
                  !selectedOffer?.id ? "dropdownBtn__box-active" : ""
                }`}
              >
                <p className="c:dark ff:bold fs:16">Anlık kampanya seç</p>
              </button>
            </li>
            {whichOffer?.items?.sort(sortInstantCompaings).map((offer) => (
              <li key={offer.id}>
                <button
                  type="button"
                  aria-label="select campaigns"
                  onClick={() => handleOffer(offer)}
                  className={`px-3 w-100 ${
                    selectedOffer?.id === offer.id
                      ? "dropdownBtn__box-active"
                      : ""
                  }`}
                >
                  <div className="d-flex align-items-center justify-content-between w-100">
                    <div>
                      <p className="c:dark ff:bold fs:16">
                        {offer.quantity}{" "}
                        <span className="d-inline-block c:yellow:4">
                          + {offer.free_amount}
                        </span>
                      </p>
                    </div>
                    <div>
                      {offer.rate ? (
                        <div className="row row-cols-auto gy-0 gx-2 align-items-center">
                          {offer.loyalityScore ? (
                            <div className="col">
                              <div className="iconSize:28">
                                <img src={iconIsolation} alt="" />
                              </div>
                            </div>
                          ) : null}

                          <div className="col">
                            <div
                              className="bg:green:6 ff:bold fs:15 paddingElem c:green radius:6 lh:1 borderStyle heightElem d-flex align-items-center justify-content-center"
                              style={
                                {
                                  "--heightElem": "29px",
                                  "--paddingElem": "0rem 0.438rem",
                                  "--gsk-border-width": "0.125rem",
                                  "--gsk-border-color": "white",
                                } as React.CSSProperties
                              }
                            >
                              &#37;{offer.rate.toFixed(2)}
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Select;
