import React, { useState } from "react";
import iconEye from "@images/icon-eye.svg";
import iconEyeClose from "@images/icon-eye-close.svg";
import FieldText from "./FieldText";
interface IProps {
  onChange: (e: any) => void;
  name: string;
  value: string;
  maxLength?: number;
  minLength?: number;
  invalid: boolean;
}

function FieldPassword({
  name,
  value,
  onChange,
  minLength,
  maxLength,
  invalid,
}: IProps) {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className="formElementItem__password">
      <button
        onClick={() => setShowPassword((prev) => !prev)}
        className="formElementItem__password-icon"
        tabIndex={-1}
        type="button"
        aria-label="show hide password"
      >
        <img src={showPassword ? iconEye : iconEyeClose} alt="eyeicon" />
      </button>
      <FieldText
        type={showPassword ? "text" : "password"}
        name={name}
        value={value}
        invalid={invalid}
        minLength={minLength}
        maxLength={maxLength}
        onChange={onChange}
      />
    </div>
  );
}

export default FieldPassword;
