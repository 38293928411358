import React, { useContext } from "react";
import iconArrowBadge from "@images/icon-arrow-badge.svg";
import iconPdf from "@images/icon-pdf.svg";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { ResultDetail } from "types/productTypes";
import remarkGfm from "remark-gfm";
import { BodyContext } from "contexts/BodyContext/BodyContext";

const ProductContent = ({ product }: { product: ResultDetail }) => {
  const { handlePopup, handleBody } = useContext(BodyContext);
  const handleModal = (file: { contentType: string; fileContents: string }) => {
    handlePopup("file", file);
    handleBody("overflowHidden");
  };
  return (
    <>
      {product && (
        <>
          {product?.about?.length ? (
            <>
              <div className="mt-4 mb-4">
                <p className="ff:bold fs:18 c:dark">Ürün hakkında</p>
              </div>
              <section className="contentArea ff:light fs:16 c:dark">
                <article style={{whiteSpace: "pre-wrap"}}>
                  <ReactMarkdown
                    children={product.about}
                    remarkPlugins={[remarkGfm]}
                  />
                </article>
              </section>
            </>
          ) : null}

          {product.files.length ? (
            <>
              <div className="mt-4 mt-xl-5 mb-3">
                <p className="ff:bold fs:18 c:dark">Ek Bilgiler</p>
              </div>
              <div className="row  gx-3 align-items-center">
                {product?.files?.map((file) => (
                  <div key={file.id} className="col-5 col-sm-4 col-md-3">
                    <a
                      onClick={() =>
                        handleModal({
                          contentType: file.fileData.contentType,
                          fileContents: file.fileData.fileContents,
                        })
                      }
                      className="bg:white borderStyle radius:100 paddingElem shadow:4 c:dark fs:13 ff:bold d-block hover:opacity:7"
                      style={
                        {
                          "--paddingElem": "0.688rem 1.5rem 0.688rem 1.5rem",
                          "--gsk-border-width": "0.063rem",
                          "--gsk-border-color": "#D7D7D7",
                        } as React.CSSProperties
                      }
                    >
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          <img src={iconPdf} alt="" />
                        </div>
                        <div className="flex-grow-1 ms-2">
                          <div className="d-flex align-items-center">
                            <p>{file.fileTitle}</p>
                            <div className="ms-auto">
                              <img src={iconArrowBadge} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                ))}
              </div>
            </>
          ) : null}
        </>
      )}
    </>
  );
};

export default ProductContent;
