import classNames from "classnames";
import useOnClickOutside from "hooks/useOnClickOutside";
import React, { useRef, useState } from "react";
interface IProps {
  onChange: (e: any) => void;
  name: string;
  value: string;
  invalid: boolean;
  searchable: boolean;
  options: any[];
}
function FieldSelect({
  name,
  value,
  options,
  onChange,
  invalid,
  searchable,
}: IProps) {
  const [dropDownIsOpen, setDropDownIsOpen] = useState(false);
  const [searchText, setSearchText] = useState("");

  const handleDropDown = () => {
    setDropDownIsOpen((prev) => !prev);
  };
  const dropdownRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(
    dropdownRef,
    () => dropDownIsOpen && setDropDownIsOpen(false)
  );

  const handleSelectOptions = (optVal: string) => {
    const event = {
      target: {
        name: name,
        value: optVal,
      },
    };
    onChange(event);
    setDropDownIsOpen(false);
  };

  const handleSearch = (e: {
    preventDefault: () => void;
    target: { value: React.SetStateAction<string> };
  }) => {
    e.preventDefault();
    setSearchText(e.target.value);
  };
  const filteredOptions = options?.filter((option) => {
    return option.value
      .toLocaleUpperCase("tr-TR")
      .includes(searchText.toLocaleUpperCase("tr-TR"));
  });

  return (
    <div ref={dropdownRef} className="dropdownBtn">
      <div className="formElementItem">
        {/* <!-- selectDropdown'a tıklandığında formElementItem__input--select__active classı eklenecek. --> */}
        <div
          tabIndex={0}
          ref={dropdownRef}
          onClick={handleDropDown}
          className={classNames(
            "selectDropdown formElementItem__input formElementItem__input--padding formElementItem__input--select  bg:white radius:12 w-100 fs:15 ff:regular c:dark d-flex align-items-center",
            {
              "formElementItem__input--select__active": dropDownIsOpen,
              errorValidation: invalid,
            }
          )}
          style={
            {
              "--inputPadding": "0 3rem 0 1rem",
              "--form-borderColor": "#8F8F92",
              "--form-height": "3rem",
            } as React.CSSProperties
          }
        >
          {value?.length ? (
            <p lang="tr" className="c:dark ff:bold fs:16 text-capitalize">
              {value}
            </p>
          ) : (
            "Bir seçenek seçiniz."
          )}
        </div>
      </div>
      {/* <!-- selectDropdown'a tıklandığında d-none d-block --> */}
      <div
        ref={dropdownRef}
        className={classNames(
          "dropdownBtn__box dropdownBtn__box--height bg:white radius:12 shadow:8 border:1",
          {
            "d-block": dropDownIsOpen,
            "d-none": !dropDownIsOpen,
          }
        )}
      >
        {searchable ? (
          <div className="dropdownBtn__search">
            <div className="formElementItem">
              <input
                className="formElementItem__input formElementItem__input--padding formElementItem__input--search bg:white radius:12 w-100 fs:16 pe ff:regular c:dark shadow:4"
                type="text"
                name="search"
                id="search"
                placeholder="Ara"
                value={searchText}
                onChange={handleSearch}
                style={
                  {
                    "--inputPadding": "0 1rem 0 2.25rem",
                    "--form-borderColor": "#D8D8D8",
                    "--form-height": "2.5rem",
                  } as React.CSSProperties
                }
              />
            </div>
          </div>
        ) : null}

        {filteredOptions?.length ? (
          <ul className="py-3">
            {filteredOptions?.map((option) => (
              <li key={option.value}>
                <button
                  type="button"
                  aria-label="select campaigns"
                  className={`px-3 w-100 ${
                    option.value === value ? "dropdownBtn__box-active" : null
                  }`}
                  onClick={() => handleSelectOptions(option.value)}
                >
                  <p lang="tr" className="c:dark ff:bold fs:16">
                    {option.value}
                  </p>
                </button>
              </li>
            ))}
          </ul>
        ) : (
          <p className="px-3 py-4 text-center c:dark ff:regular fs:15">
            Sonuç bulunamadı.
          </p>
        )}
      </div>
    </div>
  );
}

export default FieldSelect;
