import React, { useEffect, useState } from "react";
import { changePasswordValidations } from "validations/AuthValidations";
import { z } from "zod";
import { changePassword } from "services/user.service";
import useApi from "hooks/useApi";
import { useAuth } from "hooks/useAuth";
import Field from "@components/Form/Field";

interface ErrorFields {
  currentPassword?: string;
  password?: string;
  rePassword?: string;
}

const fieldMap: Record<string, keyof ErrorFields> = {
  currentPassword: "currentPassword",
  password: "password",
  rePassword: "rePassword",
};

const initialErrorState = {
  currentPassword: "",
  password: "",
  rePassword: "",
};

const Password = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const { success, error: apiError, handleApi } = useApi();

  const [errors, setErrors] = useState(initialErrorState);

  const handlePasswordValidation = async (e: any) => {
    e.preventDefault();
    setErrors({ password: "", rePassword: "", currentPassword: "" });

    try {
      const parsedValidations = changePasswordValidations.parse({
        currentPassword: currentPassword,
        password: newPassword,
        rePassword: rePassword,
      });

      if (parsedValidations.password === parsedValidations.rePassword) {
        if (parsedValidations.password === currentPassword) {
          setErrors({
            password: "Yeni şifreniz eskisiyle aynı olmamalı.",
            rePassword: "Yeni şifreniz eskisiyle aynı olmamalı.",
            currentPassword: "",
          });
        } else {
          await handleApi(changePassword, {
            currentPassword: currentPassword,
            newPassword: newPassword,
          });
        }
      } else {
        setErrors({
          password: "Parolalar eşleşmiyor",
          rePassword: "Parolalar eşleşmiyor",
          currentPassword: "",
        });
      }
    } catch (err) {
      if (err instanceof z.ZodError) {
        var errors: any = {};
        err.issues.forEach((elem) => {
          if (fieldMap.hasOwnProperty(elem.path[0])) {
            const fieldName = fieldMap[elem.path[0]];
            errors[fieldName] = elem.message;
          }
        });
        setErrors({...initialErrorState, ...errors});
      }
    }
  };

  const { logout } = useAuth();

  useEffect(() => {
    if (success) {
      logout();
    }
  }, [success, logout]);

  return (
    <div className="row row-cols-1 gx-0 gy-3">
      <div className="col">
        <section className="bg:white radius:12 shadow:4 p-4 p-md-5">
          <div className="row g-0 justify-content-center">
            <div className="col-12 col-md-9 col-lg-8">
              <div className="formElement ">
              <form onSubmit={(e) => e.preventDefault()}>
                  {success ? (
                    <section>
                      <div className="row row-cols-1 text-center gx-0 gy-3">
                        <div className="col">
                          <h1 className="c:dark ff:bold fs:24 lh:1 lt:2">
                            Tebrikler!
                          </h1>
                        </div>
                        <div className="col">
                          <p className="ff:light c:dark fs:16">
                            Şifreniz başarıyla değiştirildi. Tekrar giriş yapmak
                            için yönlendirileceksiniz.
                          </p>
                        </div>
                      </div>
                    </section>
                  ) : (
                    <section>
                      <div className="row row-cols-1 gx-0 gy-3 gy-sm-4">
                        <div className="col">
                          <Field
                            type="password"
                            name="currentPassword"
                            label="Şu anki şifrenizi girin"
                            value={currentPassword}
                            errors={errors.currentPassword}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                          />
                        </div>
                        <div className="col">
                          <Field
                            type="password"
                            name="password"
                            label="yeni şifre oluştur"
                            value={newPassword}
                            errors={errors.password}
                            onChange={(e) => setNewPassword(e.target.value)}
                          />
                        </div>
                        <div className="col">
                          <Field
                            type="password"
                            name="rePassword"
                            label="yeni şifreyi onayla"
                            value={rePassword}
                            errors={errors.rePassword}
                            onChange={(e) => setRePassword(e.target.value)}
                          />
                        </div>
                        <div className="col">
                          <ul className="bulletList ff:regular fs:12 c:dark">
                            <li>En az 6 karakter.</li>
                            <li>
                              En az bir büyük harf, bir küçük harf, bir rakam ve
                              bir özel karakter olmalı.
                            </li>
                          </ul>
                        </div>
                        <div className="col">
                          {apiError.length ? (
                            <div
                              className="alert bg:red:2 p-3 radius:12 mb-3 mb-sm-4"
                              style={
                                {
                                  "--alert-borderColor":
                                    "rgba(243, 52, 52, 0.22)",
                                } as React.CSSProperties
                              }
                            >
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                  <img src="images/dist/icon-info.svg" alt="" />
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <p className="c:red lt:2 ff:regular fs:14">
                                    {apiError}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <button
                            onClick={handlePasswordValidation}
                            type="button"
                            aria-label="Değişiklikleri Kaydet"
                            className="buttonStyle buttonStyle--icon buttonStyle--submit c:white ff:bold fs:16 bg:dark:5 d-block w-100 lh:1 text-center radius:12"
                            style={
                              { "--form-height": "3rem" } as React.CSSProperties
                            }
                          >
                            Değişiklikleri Kaydet
                          </button>
                        </div>
                      </div>
                    </section>
                  )}
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Password;
