import React from "react";
import { useParams } from "react-router-dom";

const Notification = () => {
  let { id } = useParams();
  console.log(id);
  return <div className="container mt-2">Notification id: {id}</div>;
};

export default Notification;
