import React, { useContext, useState } from "react";
import iconClose from "@images/icon-close.svg";
import iconInfo from "@images/icon-info.svg";
import iconRatingFill from "@images/icon-rating-fill.svg";
import { BodyContext } from "contexts/BodyContext/BodyContext";
import useApi from "hooks/useApi";
import { rateOrder } from "services/account.service";
import Field from "@components/Form/Field";

const RatingPopup = () => {
  const { handlePopup, data } = useContext(BodyContext);
  const { handleApi, loading, success, error, setError } = useApi();
  const [description, setDescription] = useState("");
  const [rating, setRating] = useState(0);
  const [ratingError, setRatingError] = useState("");

  const handleClose = () => {
    if (success) {
      handlePopup("reFetchOrders", null);
      setError("");
    } else {
      handlePopup("", null);
      setError("");
    }
  };

  const handleRate = () => {
    setRatingError("");
    setError("");
    try {
      if (rating > 0) {
        handleApi(rateOrder, {
          id: data.orderId,
          rating: rating,
          description: description,
        });
      } else {
        setRatingError("Değerlendirme yapmadınız.");
      }
    } catch (error) {}
  };

  return (
    <section className="popupWrapper animate__animated animate__fadeIn animate__faster">
      <div className="popupWrapper__content bg:white radius:20">
        <div className="popupWrapper__content-head d-flex align-items-center justify-content-between px-4 radius:20 bg:white">
          <div>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0 iconSize:24">
                <img src={iconRatingFill} alt="..." />
              </div>
              <div className="flex-grow-1 ms-3">
                <h1 className="ff:bold fs:18 c:dark lh:2">
                  Siparişinizi değerlendirin!
                </h1>
              </div>
            </div>
          </div>
          <div className="ps-4">
            <button
              onClick={handleClose}
              aria-label="close popup"
              className="popupWrapper__close buttonStyle buttonStyle--light buttonStyle--paddingElem radius:full bg:white shadow:4"
              type="button"
              style={{ "--btnPaddingElem": "0" } as React.CSSProperties}
            >
              <img src={iconClose} alt="" />
            </button>
          </div>
        </div>
        <div className="px-4 pb-4">
          {success && (
            <div className="pt-4 pb-5">
              <div className="iconSize:32">
                <img className="mx-auto mb-3" src={iconRatingFill} alt="" />
              </div>
              <p className="text-center ff:regular c:dark fs:16">
                Değerlendirdiğiniz için çok teşekkür ederiz.
              </p>
            </div>
          )}

          <div className="row row-cols-1 gx-0 gy-4">
            <div className="col">
              {error.length ? (
                <div
                  className="alert bg:red:2 p-3 radius:12 mb-4"
                  style={
                    {
                      "--alert-borderColor": "rgba(243, 52, 52, 0.22)",
                    } as React.CSSProperties
                  }
                >
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <img src={iconInfo} alt="" />
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <p className="c:red lt:2 ff:regular fs:14">{error}</p>
                    </div>
                  </div>
                </div>
              ) : null}
              {ratingError.length ? (
                <div
                  className="alert bg:red:2 p-3 radius:12 mb-4"
                  style={
                    {
                      "--alert-borderColor": "rgba(243, 52, 52, 0.22)",
                    } as React.CSSProperties
                  }
                >
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <img src={iconInfo} alt="" />
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <p className="c:red lt:2 ff:regular fs:14">
                        {ratingError}
                      </p>
                    </div>
                  </div>
                </div>
              ) : null}

              <div
                className="bg:light:14 p-3 borderStyle shadow:4 radius:5"
                style={
                  {
                    "--gsk-border-width": "0.5px",
                    "--gsk-border-color": "#F0DCB4",
                  } as React.CSSProperties
                }
              >
                <form action="">
                  <div
                    className={`rating ${
                      success || data.currentRating > 0
                        ? "rating__disabled"
                        : ""
                    }`}
                  >
                    <input
                      type="radio"
                      name="rating"
                      id="rating-55"
                      value={5}
                      checked={rating === 5}
                      onChange={(e) => setRating(Number(e.target.value))}
                    />
                    <label htmlFor="rating-55"></label>
                    <input
                      type="radio"
                      name="rating"
                      id="rating-44"
                      value={4}
                      checked={rating === 4}
                      onChange={(e) => setRating(Number(e.target.value))}
                    />
                    <label htmlFor="rating-44"></label>
                    <input
                      type="radio"
                      name="rating"
                      id="rating-33"
                      value={3}
                      checked={rating === 3}
                      onChange={(e) => setRating(Number(e.target.value))}
                    />
                    <label htmlFor="rating-33"></label>
                    <input
                      type="radio"
                      name="rating"
                      id="rating-22"
                      value={2}
                      checked={rating === 2}
                      onChange={(e) => setRating(Number(e.target.value))}
                    />
                    <label htmlFor="rating-22"></label>
                    <input
                      type="radio"
                      name="rating"
                      id="rating-11"
                      value={1}
                      checked={rating === 1}
                      onChange={(e) => setRating(Number(e.target.value))}
                    />
                    <label htmlFor="rating-11"></label>
                  </div>
                </form>
              </div>
            </div>
            {!success && (
              <div className="col">
                <Field
                  type="textarea"
                  name="textarea"
                  label="Mesajınız"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  maxLength={250}
                />
              </div>
            )}

            <div className="col">
              <button
                onClick={handleRate}
                disabled={loading || success || data.currentRating > 0}
                aria-label="sepete ekle"
                className={`buttonStyle buttonStyle--icon buttonStyle--submit c:white ff:bold fs:16 bg:orange py-3 px-5 text-center w-100 radius:12 d-flex align-items-center justify-content-center ${
                  success ? "disabledBtn" : ""
                } ${loading ? "loadingBtn" : ""}`}
                type="submit"
              >
                Değerlendir
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RatingPopup;
