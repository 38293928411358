import ProductsSlider from "@components/General/ProductsSlider";
import Navigation from "@components/Orders/Navigation";
import { ProductFilters } from "contexts/BodyContext/BodyContext";
import { Link, Outlet, useLocation } from "react-router-dom";

const items = [
    {
        name: "/hesabim/siparislerim",
        title: "Devam Eden Siparişlerim"
    },
    {
        name: "/hesabim/on-siparislerim",
        title: "Ön Siparişlerim"
    },
    {
        name: "/hesabim/gecmis-siparislerim",
        title: "Geçmiş Siparişlerim"
    },
    {
        name: "/hesabim/hizli-siparislerim",
        title: "Hızlı Siparişlerim"
    }
]

function OrdersLayout() {

  let location = useLocation();
  const pageItem = items.find( item => item.name === location.pathname);
  return (
    <>
      <section className="bg:white shadow:9 pt-4">
        <div className="container">
          <section className="breadcrumb pb-4">
            <ul className="c:dark fs:14 lt:3">
              <li>
                <Link to="/anasayfa">Anasayfa</Link>
              </li>
              <li>
                <Link to="/hesabim">Hesabım</Link>
              </li>
              <li>
                <Link to="/hesabim/siparislerim">Siparislerim</Link>
              </li>
            </ul>
          </section>
          <Navigation />
        </div>
      </section>
      <section className="mt-4 mt-sm-5">
        <Outlet context={[pageItem?.title]}/>
      </section>
      <section className="my-5">
        <ProductsSlider
          filter={ProductFilters.Popular}
          title={"Popüler Ürünler"}
        />
      </section>
      <section className="my-5">
        <ProductsSlider
          filter={ProductFilters.Turuncu}
          title={"Turuncu Ürünler"}
        />
      </section>
    </>
  );
}

export default OrdersLayout;
