import React, { useState, useEffect, useContext } from "react";
import { z } from "zod";
import infoicon from "@images/icon-info.svg";
import infoiconGreen from "@images/icon-info-green.svg";
import iconEnvlopeOrange from "@images/icon-envlope-orange.svg";
import { resetPasswordValidations } from "validations/AuthValidations";
import useApi from "hooks/useApi";
import { emailConfirm, resetPassword } from "services/user.service";
import { useNavigate } from "react-router-dom";
import { notificationTexts } from "utility/notificationTexts";
import Field from "@components/Form/Field";
import Timer from "@components/Form/Timer";
import { formatInput } from "utility/FormatInput";
import { PublicContext } from "contexts/PublicContext/PublicContext";
import { ErrorHandler } from "@components/General/ErrorHandler";
import { useTealium } from "hooks/useTealium";

export const initialErrorState = {
  password: "",
  passwordVerify: "",
  token: "",
};
const ResetPassword = ({
  email,
  successSend,
  setRetryCount,
  retryCount,
}: {
  email: string;
  successSend: boolean;
  retryCount: number;
  setRetryCount: (val: number) => void;
}) => {
  const [rePassword, setRePassword] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [tokenCode, setTokenCode] = useState("");
  const [expired, setExpired] = useState(false);
  const { error: apiError, handleApi, success, loading } = useApi();
  const [step, setStep] = useState(1);
  const tealium = useTealium();
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (successSend && email) {
      tealium.trackEvent("password_change_demand", { user_email: email });
    }
  }, [successSend, email]);

  const {
    settings,
    error: settingsError,
    success: successSettings,
  } = useContext(PublicContext);

  const handlePasswordValidation = async (e: any) => {
    e.preventDefault();
    setError("");

    try {
      if (!disabled && password === rePassword) {
        handleApi(resetPassword, {
          email: email,
          password: password,
          code: tokenCode,
        });
      } else {
        setError("Parolalar eşleşmiyor");
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    window.addEventListener("resize", () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
  }, []);

  const navigate = useNavigate();

  const handleNavigateToLogin = () => {
    navigate("/giris");
  };

  const handleExpiredAgain = (e: any) => {
    setRetryCount(retryCount + 1);
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  const [touched, setTouched] = useState(false); // Track whether the inputs have changed
  const validations = z.object(resetPasswordValidations);
  const [disabled, setDisabled] = useState(true);
  const [errors, setErrors] = useState(initialErrorState);

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    if (touched === false) setTouched(true);
    if (name === "password") {
      setPassword(value);
    } else if (name === "passwordVerify") {
      setRePassword(value);
    }
    const validation = resetPasswordValidations[name];

    const result = validation.safeParse(value);

    if (result.success) {
      setErrors((prev) => {
        return { ...prev, [name]: "" };
      });
    } else {
      setErrors((prev) => {
        return { ...prev, [name]: result.error.issues[0].message };
      });
    }
  };

  useEffect(() => {
    if (touched) {
      const result = validations.safeParse({
        password: password,
        passwordVerify: rePassword,
      });

      if (result.success) {
        if (password !== rePassword) {
          setErrors((prev) => {
            return {
              ...prev,
              password: "Parolalar eşleşmiyor.",
              passwordVerify: "Parolalar eşleşmiyor.",
            };
          });
          setDisabled(true);
        } else {
          setErrors((prev) => {
            return { ...prev, password: "", passwordVerify: "" };
          });
          setDisabled(false);
        }
      } else {
        setDisabled(true);
      }
    }
  }, [touched, password, rePassword]);

  useEffect(() => {
    if (tokenCode.length === 6) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [tokenCode]);

  const handleTokenCode = async () => {
    try {
      await emailConfirm({ email: email, code: tokenCode });
      setStep(2);
      setDisabled(true);
      setError("");
    } catch (error) {
      const { errorMessage } = ErrorHandler(error);
      setError(errorMessage);
    }
  };

  useEffect(() => {
    if (success) {
      tealium.trackEvent("password_change_success", { user_email: email });
      handleNavigateToLogin();
    }
  }, [success]);

  return (
    <section className="w-100">
      <div className="container-fluid">
        <div className="row g-0 row-cols-1 row-cols-lg-2 row-cols-xl-3 justify-content-center">
          <div className="col">
            <div className="formElement ">
              <div className="mb-4">
                <div className="row align-items-center row-cols-1 row-cols-sm-auto flex-column-reverse flex-sm-row justify-content-between g-0">
                  <div className="col">
                    <h1 className="ff:light c:dark fs:28 d-flex align-items-center justify-content-center">
                      Şifremi Unuttum
                    </h1>
                  </div>
                </div>
              </div>
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="formElement__block bg:white radius:12 shadow:3 p-3 p-sm-4">
                  <div className="p-2">
                    <div className="row row-cols-1 g-0 gy-3 gy-sm-4">
                      {step === 1 && (
                        <>
                          <div className="col">
                            <div className="d-flex align-items-center">
                              <div className="flex-shrink-0">
                                <img src={iconEnvlopeOrange} alt="" />
                              </div>
                              <div className="flex-grow-1 ms-3">
                                <p className="fs:16 ff:light">
                                  Mail adresinize gelen kodu girerek,
                                  doğrulamayı tamamlayın.
                                </p>
                              </div>
                            </div>
                          </div>
                          {!expired && (
                            <div className="col">
                              <Field
                                type="text"
                                name="token"
                                label="email doğrulama kodu"
                                value={tokenCode}
                                errors={errors.token}
                                maxLength={6}
                                minLength={6}
                                onChange={(e) => setTokenCode(e.target.value)}
                                pattern={formatInput}
                              />
                            </div>
                          )}
                        </>
                      )}
                      {step === 2 && (
                        <>
                          <div className="col">
                            <Field
                              type="password"
                              name="password"
                              errors={errors.password}
                              label="yeni şifre"
                              onChange={handleChange}
                              value={password}
                            />
                          </div>
                          <div className="col">
                            <Field
                              type="password"
                              name="passwordVerify"
                              label="yeni şifreyi onayla"
                              errors={errors.passwordVerify}
                              value={rePassword}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col">
                            <div
                              className="alert bg:green:2 p-3 radius:12"
                              style={
                                {
                                  "--alert-borderColor":
                                    "rgba(57, 189, 141, 0.22)",
                                } as React.CSSProperties
                              }
                            >
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                  <img src={infoiconGreen} alt="" />
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <p className="c:green lt:2 ff:regular fs:12">
                                    Şifreniz değiştiğinde otomatik olarak giriş
                                    ekranına yönlendirileceksiniz.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <ul className="bulletList ff:regular fs:12 c:dark">
                              <li>{notificationTexts.passwordLength}</li>
                              <li>{notificationTexts.passwordRules}</li>
                            </ul>
                          </div>
                        </>
                      )}
                      <div className="col">
                        {error.length ? (
                          <div
                            className="alert bg:red:2 p-3 radius:12 mb-3 mb-sm-4"
                            style={
                              {
                                "--alert-borderColor":
                                  "rgba(243, 52, 52, 0.22)",
                              } as React.CSSProperties
                            }
                          >
                            <div className="d-flex align-items-center">
                              <div className="flex-shrink-0">
                                <img src={infoicon} alt="" />
                              </div>
                              <div className="flex-grow-1 ms-3">
                                <p className="c:red lt:2 ff:regular fs:12">
                                  {error}
                                </p>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {!error && apiError.length ? (
                          <div className="col">
                            <div
                              className="alert  bg:red:2 p-3 radius:12 mb-3 mb-sm-4"
                              style={
                                {
                                  "--alert-borderColor":
                                    "rgba(57, 189, 141, 0.22)",
                                } as React.CSSProperties
                              }
                            >
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                  <img src={infoicon} alt="" />
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <p className="c:red lt:2 ff:regular fs:12">
                                    {apiError}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {step === 1 && (
                          <Timer
                            onExpired={() => setExpired(true)}
                            success={success}
                            timeSeconds={
                              !settingsError.length && successSettings
                                ? Number(
                                    settings?.find(
                                      (item) =>
                                        item.name === "FORGOT_PASSWORD_TIMER"
                                    )?.value
                                  )
                                : notificationTexts.FORGOT_PASSWORD_TIMER
                            }
                          />
                        )}

                        {!success && !expired && step === 2 && (
                          <button
                            onClick={handlePasswordValidation}
                            aria-label="continue"
                            disabled={expired || loading || disabled}
                            className={`buttonStyle buttonStyle--icon buttonStyle--submit c:white ff:bold fs:16 bg:gradient:orange d-block w-100 text-center radius:12 ${
                              expired || loading || disabled
                                ? "disabledBtn"
                                : null
                            }`}
                            type="submit"
                            style={
                              {
                                "--form-height": "3rem",
                              } as React.CSSProperties
                            }
                          >
                            Devam Et
                          </button>
                        )}
                        {step === 1 && !expired && (
                          <button
                            onClick={handleTokenCode}
                            aria-label="continue"
                            disabled={expired || loading || disabled}
                            className={`buttonStyle buttonStyle--icon buttonStyle--submit c:white ff:bold fs:16 bg:gradient:orange d-block w-100 text-center radius:12 ${
                              expired || loading || disabled
                                ? "disabledBtn"
                                : null
                            }`}
                            type="submit"
                            style={
                              {
                                "--form-height": "3rem",
                              } as React.CSSProperties
                            }
                          >
                            Devam Et
                          </button>
                        )}
                        {expired && (
                          <button
                            onClick={handleExpiredAgain}
                            aria-label="continue"
                            disabled={loading}
                            className={`buttonStyle buttonStyle--icon buttonStyle--submit c:white ff:bold fs:16 bg:gradient:orange d-block w-100 text-center radius:12 ${
                              loading ? "disabledBtn" : null
                            }`}
                            type="submit"
                            style={
                              {
                                "--form-height": "3rem",
                              } as React.CSSProperties
                            }
                          >
                            Tekrar Kod Gönder
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResetPassword;
