import { useContext, useEffect } from "react";
import BlogSlider from "@components/Blog/BlogSlider";
import BlogMenu from "@components/Blog/BlogMenu";
import BlogSection from "@components/Blog/Sections/BlogSection";
import Populer from "@components/Blog/Sections/Populer";
import EnBegenilen from "@components/Blog/Sections/EnBegenilen";
import { IBlogPosts } from "./BlogCategories";

import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { PublicContext } from "contexts/PublicContext/PublicContext";
import { BlogContext } from "contexts/BlogContext/BlogContext";
import NoData from "@components/General/NoData";

export interface IBlog {
  slug: string;
  name: string;
  color: string;
  posts: IBlogPosts[];
  id: string;
}

export interface ISlider {
  slideRoute: string;
  postId: string;
  slide: {
    fileContents: string;
    contentType: string;
    fileDownloadName: string;
    lastModified: string;
    entityTag: string;
    enableRangeProcessing: boolean;
  };
}

export interface IHomeBlogs {
  blogs: IBlog[];
  slider: ISlider[];
}
/* eslint-disable react-hooks/exhaustive-deps */
const Blog = () => {
  const { blogs, loading, error } = useContext(BlogContext);
  const { setSiteTitle } = useContext(PublicContext);

  const popularPosts =
    blogs &&
    blogs.blogs.map((blog) =>
      blog.posts?.filter((post) => post.label.includes("Popular"))
    );

  const topLikedPosts =
    blogs &&
    blogs.blogs.map((blog) =>
      blog.posts?.filter((post) => post.label.includes("TopLiked"))
    );

  useEffect(() => {
    setSiteTitle("Blog");
  }, []);

  return (
    <>
      <section className="bg:white shadow:9 pt-4">
        <div className="container">
          <section className="breadcrumb pb-4">
            <ul className="c:dark fs:14 lt:3">
              <li>
                <Link to="/anasayfa">Anasayfa</Link>
              </li>
              <li>
                <Link to="/blog">Turuncu Blog</Link>
              </li>
            </ul>
          </section>
          <BlogMenu />
        </div>
      </section>
      <section className="mt-3 mt-sm-5 mb-5">
        <div className="container">
          <BlogSlider slider={blogs.slider} loading={loading} />
        </div>
      </section>
      {error ? <NoData text={error}/> : loading ? (
        <section className="mb-5 py-0 py-sm-4">
          <div className="container">
            <div className="row row-cols-1 row-cols-lg-3 gx-0 gx-lg-3 gy-3">
              <div className="col">
                <Skeleton
                  height={384}
                  width={"100%"}
                  count={1}
                  borderRadius={"1rem"}
                />
              </div>
              <div className="col">
                <Skeleton
                  height={384}
                  width={"100%"}
                  count={1}
                  borderRadius={"1rem"}
                />
              </div>
              <div className="col">
                <Skeleton
                  height={384}
                  width={"100%"}
                  count={1}
                  borderRadius={"1rem"}
                />
              </div>
              <div className="col">
                <Skeleton
                  height={384}
                  width={"100%"}
                  count={1}
                  borderRadius={"1rem"}
                />
              </div>
              <div className="col">
                <Skeleton
                  height={384}
                  width={"100%"}
                  count={1}
                  borderRadius={"1rem"}
                />
              </div>
              <div className="col">
                <Skeleton
                  height={384}
                  width={"100%"}
                  count={1}
                  borderRadius={"1rem"}
                />
              </div>
            </div>
          </div>
          <p>NP-TR-NA-WCNT-240011</p>
        </section>
      ) : (
        blogs &&
        blogs.blogs &&
        blogs.blogs.map((blog) => <BlogSection key={blog.id} blog={blog} />)
      )}

      {popularPosts.length ? (
        <Populer posts={popularPosts.flat().slice(0, 4)} />
      ) : null}

      {topLikedPosts.length ? (
        <EnBegenilen posts={topLikedPosts.flat().slice(0, 4)} />
      ) : null}
    </>
  );
};

export default Blog;
