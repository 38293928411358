import { Link } from "react-router-dom";
import FavoritesItem from "./FavoritesItem";
import FavoritesSkeletonItem from "./FavoritesSkeletonItem";

interface IProps {
  products: any;
  popupOpen: boolean;
  handlePopup: (productName: string, productId: string) => void;
  loading: boolean;
}

const Favorites = ({ products, popupOpen, handlePopup, loading }: IProps) => {
  return (
    <>
      {loading ? (
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-5 g-3">
          <FavoritesSkeletonItem />
          <FavoritesSkeletonItem />
          <FavoritesSkeletonItem />
          <FavoritesSkeletonItem />
          <FavoritesSkeletonItem />
        </div>
      ) : products && products.length ? (
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-5 g-3">
          {products &&
            products.map((product: any) => (
              <FavoritesItem
                product={product}
                key={product.id}
                popupOpen={popupOpen}
                handlePopup={handlePopup}
              />
            ))}
        </div>
      ) : (
        <section className="bg:white radius:12 py-5 px-3 text-center shadow:4 border:1">
          <div className="row g-0 justify-content-center w-100">
            <div className="col-12 col-lg-8">
              <div className="c:dark fs:16 ff:light">
                <p>Favorilere eklenmiş bir ürün bulunamadı.</p>
              </div>
              <div className="d-flex justify-content-center mt-4">
                <Link
                  to="/urunler"
                  aria-label="anasayfa"
                  className="buttonStyle buttonStyle--paddingElem hover:opacity:8 c:white ff:bold fs:16 bg:orange text-center radius:12 d-block"
                  style={
                    {
                      "--btnPaddingElem": "0.8rem 2.8rem",
                    } as React.CSSProperties
                  }
                >
                  Ürünler
                </Link>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Favorites;
