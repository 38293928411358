import React, { useContext, useEffect, useRef, useState } from "react";
import logo from "@images/turuncuhat-logo.svg";
import logoMobile from "@images/turuncuhat-logo-mobile.svg";
// import iconDiamond from "@images/icon-diamond.svg";
import iconBellGrey from "@images/icon-bell-grey.svg";
import iconUser from "@images/icon-user.svg";
import iconUserSecondary from "@images/icon-user-secondary.svg";
import iconBtnDown from "@images/icon-btn-down.svg";
// import iconCog from "@images/icon-cog.svg";
import iconComment from "@images/icon-comment.svg";
import iconBag from "@images/icon-bag.svg";
import iconShop from "@images/icon-shop.svg";
import iconHeart from "@images/icon-heart.svg";
import iconTag from "@images/icon-tag.svg";
import Search from "./Search";
import { useAuth } from "hooks/useAuth";
import useOnClickOutside from "hooks/useOnClickOutside";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { HeaderPropsTypes } from ".";
import { navigationData } from "data/NavigationData";
import { CartContext } from "contexts/CartContext/CartContext";
import { NotificationContext } from "contexts/NotificationContext/NotificationContext";
import classNames from "classnames";

const HeaderMid = ({
                     notificationDrawerIsOpen,
                     setNotificationDrawerIsOpen,
                   }: HeaderPropsTypes) => {
  const [isOpenMyAccountPopup, setIsOpenMyAccountPopup] = useState(false);
  const [customClass, setCustomClass] = useState("d-none");
  const { cart } = useContext(CartContext);

  const { logout } = useAuth();

  const handleMyAccountButton = () => {
    setIsOpenMyAccountPopup((prev) => !prev);
  };

  useEffect(() => {
    setTimeout(() => {
      if (isOpenMyAccountPopup) {
        setCustomClass("");
      } else {
        setCustomClass("d-none");
      }
    }, 100);
    return () => {};
  }, [isOpenMyAccountPopup]);

  const myAccountPopupRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(
    myAccountPopupRef,
    () => isOpenMyAccountPopup && setIsOpenMyAccountPopup(false)
  );

  const { unreadedNotifications, preferences } =
    useContext(NotificationContext);

  const pushNotificationIsEnabled = preferences?.find(
    (item) => item.name === "Push Notification"
  )?.enabled;

  const navigate = useNavigate();

  const handleNotificationButton = () => {
    if (pushNotificationIsEnabled) {
      setNotificationDrawerIsOpen(true);
    } else {
      navigate("/hesabim/iletisim-tercihleri");
    }
  };
  return (
    <section className="header__mid bg:gradient:orange:3">
      <div className="container-fluid">
        <div className="row justify-content-center g-0">
          <div className="col-12 col-md-11">
            <div className="row justify-content-between align-items-center gx-0">
              <div className="col-auto">
                <div className="logo filter:white">
                  <Link to="/anasayfa" aria-label="turuncuhat logo">
                    <picture>
                      <source media="(min-width:1024px)" srcSet={logo} />
                      <img src={logoMobile} alt="Flowers" />
                    </picture>
                  </Link>
                </div>
              </div>
              <div className="col-4 col-xl-5 col-xxl-6 d-none d-lg-block">
                <Search />
              </div>
              <div className="col-auto">
                <div className="row row-cols-auto align-items-center gx-3">
                  {/* <div className="col">
                    <a
                      aria-label="diamond"
                      className="header__btn bg:white radius:full"
                      href="#"
                    >
                      <img src={iconDiamond} alt="" />
                    </a>
                  </div> */}
                  <div className="col">
                    {notificationDrawerIsOpen ? (
                      <div
                        aria-label="notification"
                        className="header__btn  bg:white radius:full"
                      >
                        <img src={iconBellGrey} alt="" />
                        {/* <!-- bildirim varsa aşağıdaki span gösterilir. --> */}
                        {unreadedNotifications > 0 ? (
                          <span className="header__btn-bullet bg:red radius:full d-block"></span>
                        ) : null}
                      </div>
                    ) : (
                      <button
                        onClick={handleNotificationButton}
                        aria-label="notification"
                        className={classNames(
                          "header__btn  bg:white radius:full",
                          {
                            notifyNotAllow: !pushNotificationIsEnabled,
                          }
                        )}
                      >
                        <img src={iconBellGrey} alt="" />
                        {/* <!-- bildirim varsa aşağıdaki span gösterilir. --> */}
                        {unreadedNotifications > 0 ? (
                          <span className="header__btn-bullet bg:red radius:full d-block"></span>
                        ) : null}
                      </button>
                    )}
                  </div>
                  <div
                    ref={myAccountPopupRef}
                    className="col d-none d-lg-block"
                  >
                    <button
                      onClick={handleMyAccountButton}
                      aria-label="account"
                      className={`buttonStyle buttonStyle--custom ${
                        isOpenMyAccountPopup
                          ? "buttonStyle--custom__active"
                          : ""
                      } c:white ff:bold fs:16 bg:white:2 d-block w-100 text-center radius:16`}
                      type="button"
                      style={
                        {
                          "--special-height": "2.875rem",
                        } as React.CSSProperties
                      }
                    >
                      <div className="d-flex align-items-center justify-content-center">
                        <div className="buttonStyle--custom__icon pe-1 pe-sm-2">
                          <img src={iconUser} alt="" />
                        </div>
                        <div className="buttonStyle--custom__text">
                          <p>Hesabım</p>
                        </div>
                        <div
                          className={`buttonStyle--custom__arrow ms-2  ${
                            isOpenMyAccountPopup
                              ? "buttonStyle--custom__arrow--rotate"
                              : ""
                          }`}
                        >
                          <img src={iconBtnDown} alt="" />
                        </div>
                      </div>
                    </button>
                    {/* <!-- kapandığında aşağıdaki class'tan "animate__fadeIn" silinir "animate__fadeOut" eklenir. --> */}
                    <div
                      className={`btnDropdown bg:white p-4 radius:12 shadow:5 animate__animated  ${customClass} ${
                        isOpenMyAccountPopup
                          ? "animate__fadeIn"
                          : "animate__fadeOut"
                      }`}
                      style={
                        { "--animate-duration": "0.2s" } as React.CSSProperties
                      }
                    >
                      <ul className="btnDropdownList row row-cols-1 gx-0 gy-4 ff:regular c:dark fs:16">
                        <li className="col">
                          <NavLink
                            onClick={handleMyAccountButton}
                            to={navigationData.hesabim}
                            end={true}
                            className={({ isActive, isPending }) =>
                              isPending
                                ? "pending"
                                : isActive
                                  ? "btnDropdownList__active"
                                  : ""
                            }
                          >
                            <div className="d-flex align-items-center">
                              <div className="flex-shrink-0">
                                <img src={iconUserSecondary} alt="" />
                              </div>
                              <div className="flex-grow-1 ms-3">
                                <p>Hesabım</p>
                              </div>
                            </div>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            onClick={handleMyAccountButton}
                            to={navigationData.siparislerim}
                            className={({ isActive, isPending }) =>
                              isPending
                                ? "pending"
                                : isActive
                                  ? "btnDropdownList__active"
                                  : ""
                            }
                          >
                            <div className="d-flex align-items-center">
                              <div className="flex-shrink-0">
                                <img src={iconShop} alt="" />
                              </div>
                              <div className="flex-grow-1 ms-3">
                                <p>Siparişlerim</p>
                              </div>
                            </div>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            onClick={handleMyAccountButton}
                            to={navigationData.favorilerim}
                            className={({ isActive, isPending }) =>
                              isPending
                                ? "pending"
                                : isActive
                                  ? "btnDropdownList__active"
                                  : ""
                            }
                          >
                            <div className="d-flex align-items-center">
                              <div className="flex-shrink-0">
                                <img src={iconHeart} alt="" />
                              </div>
                              <div className="flex-grow-1 ms-3">
                                <p>Favorilerim</p>
                              </div>
                            </div>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            onClick={handleMyAccountButton}
                            to={navigationData.kampanyalarim}
                            className={({ isActive, isPending }) =>
                              isPending
                                ? "pending"
                                : isActive
                                  ? "btnDropdownList__active"
                                  : ""
                            }
                          >
                            <div className="d-flex align-items-center">
                              <div className="flex-shrink-0">
                                <img src={iconTag} alt="" />
                              </div>
                              <div className="flex-grow-1 ms-3">
                                <p>Kampanyalarım</p>
                              </div>
                            </div>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            onClick={handleMyAccountButton}
                            to={navigationData.soruVeTaleplerim}
                            className={({ isActive, isPending }) =>
                              isPending
                                ? "pending"
                                : isActive
                                  ? "btnDropdownList__active"
                                  : ""
                            }
                          >
                            <div className="d-flex align-items-center">
                              <div className="flex-shrink-0">
                                <img src={iconComment} alt="" />
                              </div>
                              <div className="flex-grow-1 ms-3">
                                <p>Soru ve Taleplerim</p>
                              </div>
                            </div>
                          </NavLink>
                        </li>

                        <li className="col">
                          <button
                            onClick={logout}
                            aria-label="çıkış yap"
                            className="buttonStyle buttonStyle--light c:dark ff:bold fs:14 bg:white shadow:4 d-block w-100 text-center radius:12"
                            type="submit"
                          >
                            Çıkış Yap
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col">
                    <Link
                      to={"/sepet"}
                      aria-label="account"
                      className="buttonStyle buttonStyle--custom buttonStyle--custom--white c:orange ff:bold fs:16 bg:white d-block w-100 text-center radius:16 d-flex align-items-center"
                      type="button"
                      style={
                        {
                          "--special-height": "2.875rem",
                        } as React.CSSProperties
                      }
                    >
                      <div className="d-flex align-items-center justify-content-center">
                        <div className="buttonStyle--custom__icon pe-1 pe-sm-2">
                          <img src={iconBag} alt="" />
                        </div>
                        <div className="buttonStyle--custom__text">
                          <p>Sepetim</p>
                        </div>
                        <div className="buttonStyle--custom__value bg:orange c:white fs:13 lh:1 ms-2">
                          <p>{cart?.quantity}</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeaderMid;
