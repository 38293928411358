import Skeleton from "react-loading-skeleton";

const TdkSkeletonCard = () => {
  return (
    <div className="col">
      <div className="bg:white radius:12 p-3 shadow:4 h-100">
        <div className="d-flex flex-column h-100">
          <div>
            <div className="row gx-3">
              <div className="col-7 col-lg-7 col-xl-8">
                <Skeleton height={20} width={50} />
              </div>
              <div className="col-5 col-lg-5 col-xl-4">
                <Skeleton
                  height={39.5}
                  style={{ borderRadius: ".75rem" }}
                  className="buttonStyle buttonStyle--light buttonStyle--paddingElem c:dark ff:bold fs:14 bg:white shadow:4 d-block w-100 text-center radius:12"
                />
              </div>
            </div>
            <Skeleton height={20} width={50} />
          </div>
          <div className="mt-auto">
            <div className="row gx-2 gx-xl-3 gy-lg-0 gy-1">
              <div className="col-12 col-lg-7 col-xl-8">
                <Skeleton height={20} width={150} />
              </div>
              <div className="col-12 col-lg-5 col-xl-4">
                <Skeleton
                  height={39.5}
                  style={{ borderRadius: ".75rem" }}
                  className="buttonStyle buttonStyle--light buttonStyle--paddingElem c:dark ff:bold fs:14 bg:white shadow:4 d-block w-100 text-center radius:12"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const TdkSkeletonCards = () => {
  return (
    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3 gx-0 gx-lg-3">
      <TdkSkeletonCard />
      <TdkSkeletonCard />
      <TdkSkeletonCard />
    </div>
  );
};

export default TdkSkeletonCards;
