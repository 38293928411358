import React, { useState } from "react";
import { AuthContextSite } from "./AuthContext";
import { useLocalStorage } from "hooks/useLocalStorage";
import { IUser } from "hooks/useUser";

const AuthProvider = ({ children }: { children: React.ReactElement }) => {
  const [user, setUser] = useState<IUser | null>(null);
  const { setItem } = useLocalStorage();
  const handleLogin = (user: IUser) => {
    setUser(user);
    setItem("user", JSON.stringify(user));
  };

  const [notificationType, setNotificationType] = useState("");

  const handleNotification = (type: string) => {
    setNotificationType(type);
  };

  return (
    <AuthContextSite.Provider
      value={{
        user,
        handleLogin,
        handleNotification,
        notificationType,
      }}
    >
      {children}
    </AuthContextSite.Provider>
  );
};

export default AuthProvider;
