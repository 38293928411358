import { useEffect } from "react";
import { useTimer } from "hooks/useTimer";

interface IProps {
  success: boolean;
  onExpired: () => void;
  timeSeconds: number;
}

function Timer({ success, onExpired, timeSeconds }: IProps) {
  const { time, setStatus } = useTimer(timeSeconds);

  useEffect(() => {
    if (time.minutes === -1) onExpired && onExpired();
  }, [onExpired, time]);

  useEffect(() => {
    if (success) {
      setStatus(false);
    }
  }, [setStatus, success]);

  return (
    <div className="timerText text-center mb-3 mb-sm-4">
      {time ? (
        time.minutes !== -1 ? (
          <p className="ff:bold c:dark:2 fs:15">
            Süre:{" "}
            <span className="d-inline-block" id="timerElem">
              {time.minutes.toString().padStart(2, "0")}:
              {time.seconds.toString().padStart(2, "0")}
            </span>
          </p>
        ) : (
          <p className="ff:bold c:orange fs:15">
            Süre doldu:{" "}
            <span className="d-inline-block" id="timerElem">
              00:00
            </span>
          </p>
        )
      ) : null}
    </div>
  );
}

export default Timer;
