import logo from '@images/turuncuhat-logo.svg';
import useOnClickOutside from 'hooks/useOnClickOutside';
import useWindowWide from 'hooks/useWindowWide';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import LoginButton from './LoginButton';

const HeadMenu = () => {
  const [sideMenuIsOpen, setSideMenuIsOpen] = useState(false);
  const [customClass, setCustomClass] = useState('d-none d-xl-block');

  const navRef = useRef<HTMLDivElement | any>(null);

  useOnClickOutside(navRef, () => setSideMenuIsOpen(false));

  const isBigScreen = useWindowWide(1200);

  useEffect(() => {
    if (!sideMenuIsOpen) {
      setTimeout(() => {
        setCustomClass('d-none d-xl-block');
      }, 450);
    } else {
      setCustomClass('');
    }
    return () => {
    };
  }, [sideMenuIsOpen]);

  useEffect(() => {
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener('click', function(e) {
        e.preventDefault();

        document.querySelector(this.getAttribute('href')).scrollIntoView({
          behavior: 'smooth',
        });
      });
    });
  }, []);

  const handleNav = () => {
    const currentPath = window.location.pathname;

    if (currentPath !== '/') {
      window.location.href = '/';
    }
    setSideMenuIsOpen(false);
  };

  return (
    <section className='landingHeaderBar d-flex align-items-center shadowHeader shadow:8'>
      <div className='container-fluid'>
        <div className='px-0 px-xl-4'>
          <div className='row row-cols-auto align-items-center justify-content-between g-0'>
            <div className='col'>
              <div className='logo'>
                <a onClick={() => handleNav()} href='#home' aria-label='turuncuhat logo'>
                  <img src={logo} alt='TuruncuHat Logo' />
                </a>
              </div>
            </div>
            <div className='col'>
              <div
                className={`landingHeaderBar__overlay ${
                  sideMenuIsOpen ? 'overlayItem' : ''
                }`}
              >
                <nav
                  ref={navRef}
                  className={`landingHeaderBar__menu ${
                    sideMenuIsOpen
                      ? 'animate__animated animate__slideInRight animate__faster'
                      : isBigScreen
                        ? ''
                        : `animate__animated animate__slideOutRight animate__faster ${customClass}`
                  }`}
                >
                  <ul
                    className='spyNav spyNavLink row row-cols-1 row-cols-xl-auto text-center align-items-center gx-0 gx-xl-4 gy-0 c:dark fs:16 ff:regular'>
                    <li
                      className='col'
                      onClick={() => handleNav()}
                    >
                      <a href='#section-1'>TuruncuHat Nedir?</a>
                    </li>
                    <li
                      className='col'
                      onClick={() => handleNav()}
                    >
                      <a href='#section-2'>TuruncuHat’a Özel</a>
                    </li>
                    {/*<li*/}
                    {/*  className="col"*/}
                    {/*  onClick={() => setSideMenuIsOpen(false)}*/}
                    {/*>*/}
                    {/*  <a href="#section-3">Turuncu Blog</a>*/}
                    {/*</li>*/}
                    <li
                      className='col'
                      onClick={() => handleNav()}
                    >
                      <a href='#section-4'>Uygulama</a>
                    </li>
                    <li
                      className='col'
                      onClick={() => handleNav()}
                    >
                      <a href='#section-5'>S.S.S</a>
                    </li>
                    <li className='col'>
                      <div className='landingHeaderBar__buttons'>
                        <div
                          className='row row-cols-1 row-cols-xl-auto justify-content-center gx-0 gx-xl-3 gy-2 gy-xl-0'>
                          <div className='col'>
                            <LoginButton />
                          </div>
                          <div className='col'>
                            <Link
                              className='buttonStyle buttonStyle--sm buttonStyle--icon bg:orange radius:12 c:white fs:13 ff:bold d-block d-xl-inline-block'
                              to='/kayit-ol'
                              data-testid='header-register'
                              aria-label='üye ol'
                            >
                              Üye Ol
                            </Link>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className='d-flex align-items-center d-xl-none'>
                {sideMenuIsOpen ? (
                  <button
                    onClick={() => setSideMenuIsOpen(false)}
                    className={`landingHeaderBar__hamburger opened`}
                    aria-label='Main Menu'
                  >
                    <svg width='24' height='24' viewBox='0 0 100 100'>
                      <path
                        className='landingHeaderBar__hamburger-line landingHeaderBar__hamburger-line1'
                        d='M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058'
                      />
                      <path
                        className='landingHeaderBar__hamburger-line landingHeaderBar__hamburger-line2'
                        d='M 20,50 H 80'
                      />
                      <path
                        className='landingHeaderBar__hamburger-line landingHeaderBar__hamburger-line3'
                        d='M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942'
                      />
                    </svg>
                  </button>
                ) : (
                  <button
                    ref={navRef}
                    onClick={() => setSideMenuIsOpen(true)}
                    className={`landingHeaderBar__hamburger `}
                    aria-label='Main Menu'
                  >
                    <svg width='24' height='24' viewBox='0 0 100 100'>
                      <path
                        className='landingHeaderBar__hamburger-line landingHeaderBar__hamburger-line1'
                        d='M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058'
                      />
                      <path
                        className='landingHeaderBar__hamburger-line landingHeaderBar__hamburger-line2'
                        d='M 20,50 H 80'
                      />
                      <path
                        className='landingHeaderBar__hamburger-line landingHeaderBar__hamburger-line3'
                        d='M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942'
                      />
                    </svg>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeadMenu;
