import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import OneSignal from "react-onesignal";
import { decryptData } from "utility/cryptoLocal";

const RouteGuard: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const val = localStorage.getItem("user");

  const user = val ? decryptData(val) : null;
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!user) {
      navigate("/giris", {
        state: {
          from: location.pathname,
        },
        replace: true,
      });
    } else {
      var userObject = JSON.parse(user);
      console.log("userObject", userObject);
      const logEnabled = async () => {
        await OneSignal.isPushNotificationsEnabled();
        // console.log("enabled", enabled);
        return true;
      };
      logEnabled();
      OneSignal.showNativePrompt();
      OneSignal.setExternalUserId(userObject.id);
      // OneSignal.setEmail(userObject.email);
      // OneSignal.setSMSNumber(`+90${userObject.mobile}`);
    }
  }, [user, navigate, location]);

  return children;
};

export default RouteGuard;
