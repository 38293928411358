import { endpoints } from "constants/endpoints";
import api from "../utility/request";

export const getBrands = async (): Promise<any> => {
  const response = (await api.get(`${endpoints.baseUrl}/brand`)).data;
  return response;
};
export const getForms = async (): Promise<any> => {
  const response = (await api.get(`${endpoints.baseUrl}/product-form`)).data;
  return response;
};

export const getSingleBrand = async (id: string): Promise<any> => {
  const response = (await api.get(`${endpoints.baseUrl}/brand/${id}`)).data;
  return response;
};
