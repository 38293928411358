import { useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import TagHead from "@components/Blog/tag/TagHead";
import BlogList from "@components/Blog/tag/BlogList";
import BlogMenu from "@components/Blog/BlogMenu";
import { getBlogPostsByHashTag } from "services/blog.service";
import useApi from "hooks/useApi";
import { IBlogPosts } from "./BlogCategories";
import BookMarkSkeletonItem from "./BookMarkSkeletonItem";
/* eslint-disable react-hooks/exhaustive-deps */
const BlogSearch = () => {
  let [searchParams] = useSearchParams();
  const slug = searchParams.get("slug");

  const { handleApi, data, success, loading, error } = useApi();

  useEffect(() => {
    handleApi(getBlogPostsByHashTag, slug);
  }, [slug]);

  const posts: IBlogPosts[] = data;
  return (
    <>
      <section className="bg:white shadow:9 pt-4">
        <div className="container">
          <section className="breadcrumb pb-4">
            <ul className="c:dark fs:14 lt:3">
              <li>
                <Link to="/anasayfa">Anasayfa</Link>
              </li>
              <li>
                <Link to="/blog">Turuncu Blog</Link>
              </li>
            </ul>
          </section>
          <BlogMenu />
        </div>
      </section>
      <section className="mt-4 mt-sm-5 pt-2 pt-sm-0">
        <div className="container">
          <TagHead
            tag={slug ? "#" + slug : ""}
            bookmarksLength={posts ? posts.length : 0}
            loading={loading}
          />
        </div>
      </section>
      <section className="mt-4 mt-sm-5 mb-5 pt-2 pt-sm-0">
        <div className="container">
          {loading ? (
            <BookMarkSkeletonItem />
          ) : error.length ? (
            error
          ) : (
            success && <BlogList posts={posts} />
          )}
          <div className="pt-4 pt-sm-0">{/* <Pagination /> */}</div>
        </div>
      </section>
    </>
  );
};

export default BlogSearch;
