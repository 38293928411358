import { useContext, useEffect } from "react";
import MyAccountTabNav from "./MyAccountTabNav";
import { Link } from "react-router-dom";
import { navigationData } from "data/NavigationData";
import Contact from "@components/Hesabim/MyAccount/Contact";
import ContactSkeleton from "@components/Hesabim/MyAccount/ContactSkeleton";
import { NotificationContext } from "contexts/NotificationContext/NotificationContext";
import { PublicContext } from "contexts/PublicContext/PublicContext";

export interface IPreference {
  name: string;
  enabled: boolean;
  notificationId: string;
}
/* eslint-disable react-hooks/exhaustive-deps */
const ContactPreferences = () => {
  const { setSiteTitle } = useContext(PublicContext);
  const { preferences, loading } = useContext(NotificationContext);

  useEffect(() => {
    setSiteTitle("İletişim Tercihleri");
  }, []);

  return (
    <>
      <section className="bg:white shadow:9 pt-4">
        <div className="container">
          <section className="breadcrumb pb-4">
            <ul className="c:dark fs:14 lt:3">
              <li>
                <Link to="/anasayfa">Anasayfa</Link>
              </li>
              <li>
                <Link to={navigationData.hesabim}>Hesabım</Link>
              </li>
              <li>
                <Link to={navigationData.iletisimTercihleri}>
                  İletişim Tercihleri
                </Link>
              </li>
            </ul>
          </section>

          <MyAccountTabNav />
        </div>
      </section>
      <section className="mt-4 mt-sm-5 mb-5">
        <div className="container">
          <div className="row g-0 justify-content-center">
            <div className="col-12 col-lg-9 col-xl-8 col-xxl-7">
              <h1 className="c:dark ff:bold fs:24 mb-4 text-center text-md-start">
                İletişim Tercihleri
              </h1>
              {loading ? (
                <ContactSkeleton />
              ) : preferences ? (
                <Contact preferences={preferences} />
              ) : null}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactPreferences;
