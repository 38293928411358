import React from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

const FavoritesSkeletonItem = () => {
  return (
    <div className="col">
      <div className="productListCard h-100">
        <div className="border:1 bg:white radius:12 p-3 shadow:4 w-100 h-100">
          <div
            className="productListCard__box d-flex flex-column heightElem"
            style={{ "--heightElem": "15.5rem" } as React.CSSProperties}
          >
            <div>
              <div
                className="d-flex justify-content-between align-items-center mb-3 heightElem"
                style={{ "--heightElem": "2rem" } as React.CSSProperties}
              >
                <div>
                  <div className="row row-cols-auto gy-0 gx-1"></div>
                </div>
                <div className="ms-auto">
                  <div className="d-flex">
                    <button
                      className="trashButton ms-3"
                      aria-label="sil butonu"
                      type="button"
                    >
                      <Skeleton height={18} width={18} />
                    </button>
                  </div>
                </div>
              </div>
              <Link className="productHover" aria-label="link card" to={`#`}>
                <div className="productListCard__box-img" style={{display: "flex", justifyContent:"center"}}>
                  <Skeleton height={90} width={90} />
                </div>
                <p className="c:dark ff:bold fs:16 text-center mt-3 mb-2 lh:1">
                  <Skeleton height={16} width={140} />
                </p>
                <p className="c:dark ff:regular fs:14 text-center">
                  <Skeleton height={16} width={100} />
                </p>
              </Link>
            </div>
            <div className="mt-auto">
              <p className="c:dark ff:bold fs:18 text-center c:orange lh:1">
                &#8378;&nbsp;
                <Skeleton height={18} width={60} />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FavoritesSkeletonItem;
