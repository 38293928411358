import { IUser } from "hooks/useUser";
import { createContext } from "react";

export interface IRegisterState {
  step: number;
  user: IUser | undefined;
  token: string;
  setUser: (data: IUser) => void;
  setToken: React.SetStateAction<any>;
}

export const RegisterContext = createContext<IRegisterState | null>(null);
