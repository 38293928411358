import { useEffect, useState } from "react";

export interface ICountdown {
  hours: number;
  minutes: number;
  seconds: number;
}

export const useTimer = (timer: number) => {
  const storedStartTime = localStorage.getItem("countdownStartTime");
  const [status, setStatus] = useState(true);
  const [startTime, setStartTime] = useState(
    storedStartTime ? parseInt(storedStartTime) : timer
  );
  const [time, setTime] = useState<ICountdown>({
    hours: 1,
    minutes: 0,
    seconds: 0,
  });

  const convertTime = (time: number) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor(time / 60);
    const seconds = time - minutes * 60;
    setTime({
      hours: hours,
      minutes: minutes,
      seconds: seconds,
    });
  };

  const reStartTimer = (timeSecond: number) => {
    setStatus(true);
    setStartTime(timeSecond);
  };

  useEffect(() => {
    localStorage.setItem("countdownStartTime", startTime.toString());
  }, [startTime]);

  useEffect(() => {
    if (!status || startTime <= -2) {
      localStorage.removeItem("countdownStartTime");
      return;
    }
    const timerId = setInterval(() => {
      setStartTime((prev) => prev - 1);
      convertTime(startTime);
    }, 1000);
    return () => {
      clearInterval(timerId);
      localStorage.removeItem("countdownStartTime");
    };
  }, [startTime, status]);

  return { time, setStatus, reStartTimer, setTime };
};
