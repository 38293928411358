import { useContext } from "react";
import { BodyContext } from "contexts/BodyContext/BodyContext";
import { HelmetData, Helmet } from "react-helmet-async";
import { PublicContext } from "contexts/PublicContext/PublicContext";

const HelmetComponent = () => {
  const helmetData = new HelmetData({});
  const { customClass } = useContext(BodyContext);
  const { siteTitle } = useContext(PublicContext);

  const str = siteTitle?.replace("-", " ");
  const arr = str ? str.split(" ") : [""];
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  const title = arr.join(" ");

  return (
    <Helmet helmetData={helmetData}>
      <title>{title} - TuruncuHat</title>
      <body className={`bg:bg ${customClass}`} />
    </Helmet>
  );
};

export default HelmetComponent;
