export const genderOptions = [
    {
      value: "Kadın",
      label: "Kadın",
    },
    {
      value: "Erkek",
      label: "Erkek",
    },
    {
      value: "Belirtmek istemiyorum",
      label: "Belirtmek istemiyorum",
    },
  ];