import { useEffect } from "react";
import { IUser, useUser } from "./useUser";
import { useLocalStorage } from "./useLocalStorage";
import { useAuth as useOAuth2 } from "react-oidc-context";
/* eslint-disable react-hooks/exhaustive-deps */
export const useAuth = () => {
  const auth = useOAuth2();
  const { user, addUser, removeUser } = useUser();
  const { getItem } = useLocalStorage();

  useEffect(() => {
    const user = getItem("user");

    if (user) {
      addUser(JSON.parse(user));
    }
  }, []);

  const login = (user: IUser) => {
    addUser(user);
  };

  const logout = () => {
    if (auth.isAuthenticated){
      auth.removeUser();
    }
    removeUser();
  };

  return { user, login, logout };
};
