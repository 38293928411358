import { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import AnlikKampanyalarSlider from "@components/AnlikKampanyalar/AnlikKampanyalarSlider";
import AnlikKampanyalarProducts from "@components/AnlikKampanyalar/AnlikKampanyalarProducts";
import { IProductAll } from "types/productTypes";
import { BodyContext } from "contexts/BodyContext/BodyContext";
import { PublicContext } from "contexts/PublicContext/PublicContext";
import { CartContext } from "contexts/CartContext/CartContext";
/* eslint-disable react-hooks/exhaustive-deps */
const AnlikKampanyalar = () => {
  const [limit, setLimit] = useState(12);
  const { setError } = useContext(CartContext);
  const { products } = useContext(BodyContext);
  const { setSiteTitle } = useContext(PublicContext);
  const [activeItem, setActiveItem] = useState<string>("");

  const totalProductsLength: number = products.filter(
    (product) => product.hasOffer
  ).length;

  const hasOfferProducts: IProductAll[] = products
    ?.filter(
      (product) =>
        product.hasOffer &&
        product.displayBrandName &&
        product.displayProductForm
    )
    .sort(function (
      a: { displayProductForm: string; displayBrandName: string },
      b: { displayProductForm: string; displayBrandName: string }
    ) {
      return (
        a?.displayProductForm
          .toLocaleLowerCase("tr-TR")
          .localeCompare(b.displayProductForm, "tr", { sensitivity: "base" }) &&
        a?.displayBrandName
          .toLocaleLowerCase("tr-TR")
          .localeCompare(b.displayBrandName, "tr", { sensitivity: "base" })
      );
    })
    .slice(0, limit);

  useEffect(() => {
    setSiteTitle("Anlık Kampanyalar");
  }, []);

  useEffect(() => {
    return () => {
      setError("");
    };
  }, []);

  return (
    <>
      <section className="breadcrumb mt-4 mt-sm-5">
        <div className="container">
          <ul className="c:dark fs:14 lt:3">
            <li>
              <Link to="/anasayfa">Anasayfa</Link>
            </li>
            <li>
              <Link to="/anlik-kampanyalar">Anlık Kampanyalar</Link>
            </li>
          </ul>
        </div>
      </section>
      <AnlikKampanyalarSlider />

      <section className="mb-5">
        <div className="container">
          <AnlikKampanyalarProducts
            setActiveItem={setActiveItem}
            activeItem={activeItem}
            limit={limit}
            setLimit={setLimit}
            totalProductsLength={totalProductsLength}
            hasOfferProducts={hasOfferProducts}
          />
          {/* {products && (
        <ListPagination
          path="/anlik-kampanyalar"
          searchParams={searchParams}
          items={products}
        />
      )} */}
        </div>
        <p>NP-TR-NA-WCNT-240010</p>
      </section>
    </>
  );
};

export default AnlikKampanyalar;
