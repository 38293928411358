import React from "react";
import Skeleton from "react-loading-skeleton";

const CartWareHouseSkeletonItem = () => {
  return (
    <div className="col">
      <div
        className={`w-100 bg:white radius:12 shadow:4 p-3 p-sm-4 storeCheck`}
        style={
          {
            "--gsk-border-width": "0.031rem",
            "--gsk-border-color": "#d7d7d7",
          } as React.CSSProperties
        }
      >
        <div className="d-flex align-items-center">
          <div className="flex-shrink-0"></div>
          <div className="flex-grow-1">
            <div className="kz-check">
              <div>
                <label className="d-flex">
                  <Skeleton width={24} height={24} borderRadius={"100%"}/>
                  <span className="d-block ff:regular fs:15 c:dark text-capitalize ms-3">
                    <Skeleton width={250} height={22.5} />
                  </span>
                
                  <div className="kz-check__input"></div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartWareHouseSkeletonItem;
