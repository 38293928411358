import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import iconDiamondSm from "@images/icon-diamond-sm.svg";
import iconClose from "@images/icon-close.svg";
import iconUserSecondary from "@images/icon-user-secondary.svg";
import iconShop from "@images/icon-shop.svg";
import iconHeart from "@images/icon-heart.svg";
import iconTag from "@images/icon-tag.svg";
import iconComment from "@images/icon-comment.svg";
import { HeaderPropsTypes } from ".";
import { MenuDatum } from "data/menuData";
import { navigationData } from "data/NavigationData";
import { useAuth } from "hooks/useAuth";

const MobileMenu = ({
  mobileMenuIsOpen,
  customClass,
  handleMobileMenu,
  mobileMenuRef,
  menuData,
}: HeaderPropsTypes) => {
  const [activeItem, setActiveItem] = useState<number>();
  const [activechildItem, setActivechildItem] = useState<number>();
  const { logout } = useAuth();

  const handleTreeMenu = (item: MenuDatum) => {
    if (activeItem === item.id) {
      setActiveItem(0);
    } else {
      if (!item.child.length) {
        handleMobileMenu!();
      }
      setActiveItem(item.id);
    }
  };

  const handlechildItem = (childId: number) => {
    if (activechildItem === childId) {
      setActivechildItem(0);
    } else {
      setActivechildItem(childId);
    }
  };

  return (
    // <!-- bu panel açıldığında body -- > overflowHidden classı eklenir. -->
    <section className={`mobileMenuBar ${customClass}`}>
      {/* <!-- kapandığında aşağıdaki class'tan "animate__slideInLeft" silinir "animate__slideOutLeft" eklenir. --> */}
      <div
        ref={mobileMenuRef}
        className={`mobileMenuBar__wrapper bg:white shadow:5  ${
          mobileMenuIsOpen
            ? "animate__animated animate__slideInLeft"
            : "animate__animated animate__slideOutLeft"
        } animate__faster delay:3 px-4 py-5`}
      >
        <div className="mb-4">
          <div className="row row-cols-auto align-items-center justify-content-between g-0">
            <div className="col">
              <button
                aria-label="plana git"
                className="buttonStyle buttonStyle--light buttonStyle--paddingElem c:dark ff:bold fs:14 bg:white shadow:4 d-inline-block radius:12"
                type="button"
                style={
                  {
                    "--btnPaddingElem": "0.625rem 1.25rem",
                  } as React.CSSProperties
                }
              >
                <div className="d-flex align-items-center ">
                  <div className="flex-shrink-0 iconSize:16">
                    <img src={iconDiamondSm} alt="" />
                  </div>
                  <div className="flex-grow-1 ms-2 text-start">
                    Standart Üye
                  </div>
                </div>
              </button>
            </div>
            <div onClick={handleMobileMenu} className="col">
              <button
                aria-label="close panel"
                className="d-block"
                type="button"
              >
                <img src={iconClose} alt="" />
              </button>
            </div>
          </div>
        </div>
        <section className="mobileMenuBar__list">
          <ul className="btnDropdownList row row-cols-1 gx-0 gy-4 ff:regular c:dark fs:16">
            <li className="col">
              <NavLink
                onClick={handleMobileMenu}
                to={navigationData.hesabim}
                end={true}
                className={({ isActive, isPending }) =>
                  isPending
                    ? "pending"
                    : isActive
                    ? "btnDropdownList__active"
                    : ""
                }
              >
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <img src={iconUserSecondary} alt="" />
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <p>Hesabım</p>
                  </div>
                </div>
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={handleMobileMenu}
                to={navigationData.siparislerim}
                className={({ isActive, isPending }) =>
                  isPending
                    ? "pending"
                    : isActive
                    ? "btnDropdownList__active"
                    : ""
                }
              >
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <img src={iconShop} alt="" />
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <p>Siparişlerim</p>
                  </div>
                </div>
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={handleMobileMenu}
                to={navigationData.favorilerim}
                className={({ isActive, isPending }) =>
                  isPending
                    ? "pending"
                    : isActive
                    ? "btnDropdownList__active"
                    : ""
                }
              >
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <img src={iconHeart} alt="" />
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <p>Favorilerim</p>
                  </div>
                </div>
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={handleMobileMenu}
                to={navigationData.kampanyalarim}
                className={({ isActive, isPending }) =>
                  isPending
                    ? "pending"
                    : isActive
                    ? "btnDropdownList__active"
                    : ""
                }
              >
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <img src={iconTag} alt="" />
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <p>Kampanyalarım</p>
                  </div>
                </div>
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={handleMobileMenu}
                to={navigationData.soruVeTaleplerim}
                className={({ isActive, isPending }) =>
                  isPending
                    ? "pending"
                    : isActive
                    ? "btnDropdownList__active"
                    : ""
                }
              >
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <img src={iconComment} alt="" />
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <p>Soru ve Taleplerim</p>
                  </div>
                </div>
              </NavLink>
            </li>
          </ul>
        </section>
        <section className="mobileMenuBar__nav my-4 py-4">
          <ul className="mobileMenuBar__nav-list row row-cols-1 gx-0 gy-4 ff:regular c:dark fs:16">
            {menuData &&
              menuData.map((item: MenuDatum) => (
                <li key={item.id} className="col">
                  {/* <!-- tıklandığında listArrow classının yanına listArrow--active eklenmeli --> */}
                  <Link
                    to={item.url}
                    onClick={() => handleTreeMenu(item)}
                    className={`${
                      item.child && item.child.length ? "listArrow" : ""
                    } ${item.id === activeItem ? "listArrow--active" : ""} `}
                  >
                    {item.title}
                  </Link>
                  {/* <!-- alt kırılımları göstermek için d-none toggle olmalı --> */}
                  <div
                    className={`ps-3 mt-3 ${
                      item.id === activeItem ? "" : "d-none"
                    } `}
                  >
                    <ul
                      className={`mobileMenuBar__nav-general row row-cols-1 gx-0 gy-3`}
                    >
                      {item.child &&
                        item.child.map((childItem) => (
                          <li
                            key={childItem.id}
                            className={`col ${
                              childItem.children ? "" : "treeMenuItem"
                            }`}
                          >
                            {/* <!-- tıklandığında listArrow classının yanına listArrow--active eklenmeli --> */}
                            <Link
                              onClick={() => handlechildItem(childItem.id)}
                              className={`${
                                childItem.children ? "listArrow" : ""
                              }  ${
                                activechildItem === childItem.id
                                  ? "listArrow--active"
                                  : ""
                              }`}
                              to={
                                childItem.children ? "#" : childItem.url ?? ""
                              }
                            >
                              {childItem.title}
                            </Link>
                            {/* <!-- alt kırılımları göstermek için d-none toggle olmalı --> */}

                            {childItem.children && (
                              <div
                                className={`ps-3 mt-3 ${
                                  activechildItem === childItem.id
                                    ? ""
                                    : "d-none"
                                } `}
                              >
                                <ul className="mobileMenuBar__nav-sub row row-cols-1 gx-0 gy-3">
                                  {childItem.children.map((children: any) => (
                                    <li key={children.id} className="col">
                                      <Link
                                        onClick={handleMobileMenu}
                                        to={children.url ?? ""}
                                      >
                                        {children.title}
                                      </Link>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </li>
                        ))}
                    </ul>
                  </div>
                </li>
              ))}
          </ul>
        </section>
        <button
          onClick={logout}
          aria-label="plana git"
          className="buttonStyle buttonStyle--light c:dark ff:bold fs:14 bg:white shadow:4 d-block w-100 text-center radius:12"
          type="submit"
        >
          Çıkış Yap
        </button>
      </div>
    </section>
  );
};

export default MobileMenu;
