import { decryptData } from "utility/cryptoLocal";

const AutoGuard: React.FC<{ children: (props: { isAuth: boolean }) => JSX.Element }> = ({ children }) => {
  const val = localStorage.getItem("user");

  const user = val ? decryptData(val) : null;
  const isAuth = !!user;

  return children({ isAuth });
};

export default AutoGuard;
