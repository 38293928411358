import { useContext, useEffect, useState } from "react";
import { AuthContextSite } from "contexts/AuthContext/AuthContext";
import { BodyContext } from "contexts/BodyContext/BodyContext";
import {
  deleteFavoriteProducts,
  setFavoriteProducts,
} from "services/user.service";
import { IProductAll } from "types/productTypes";

const HeartButtonAllProducts = ({ product }: { product: IProductAll }) => {
  const [favorite, setFavorite] = useState(product.isFavorite);
  const [customClass, setCustomClass] = useState("");
  const { handleNotification } = useContext(AuthContextSite);
  const [loading, setLoading] = useState(false);
  const { editProducts } = useContext(BodyContext);

  // useEffect(() => {
  //   setFavorite(product.isFavorite);

  //   return () => {};
  // }, [product]);

  const handleFavorite = async () => {
    if (favorite) {
      try {
        setLoading(true);
        await deleteFavoriteProducts({ product_id: product.id });
        setFavorite(false);
        handleNotification!("removeFromFavorites");
        editProducts(product.id, false);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    } else {
      try {
        setLoading(true);
        await setFavoriteProducts({ product_id: product.id });
        setFavorite(true);
        setCustomClass("heartButtonFade");
        handleNotification!("addToFavorites");
        editProducts(product.id, true);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setCustomClass("");
    }, 400);

    return () => {};
  }, [favorite]);

  return (
    <button
      onClick={handleFavorite}
      disabled={loading}
      aria-label="like button"
      className="d-block"
      type="button"
    >
      <div
        className={`heartButton ${favorite ? "heartButtonStrokeColor" : ""}`}
      >
        <svg
          className={`heartButton__main ${customClass} ${
            favorite ? "heartButtonStroke" : ""
          }`}
          viewBox="0 0 512 512"
          width="100"
        >
          <path d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z" />
        </svg>
        <svg
          className="heartButton__background"
          viewBox="0 0 512 512"
          width="100"
        >
          <path d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z" />
        </svg>
      </div>
    </button>
  );
};

export default HeartButtonAllProducts;
