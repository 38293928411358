import React from "react";
import { regexLettersAndSymbols } from "utility/FormatInput";
interface IProps {
  onChange: (e: any) => void;
  type: string;
  name: string;
  value: string;
  placeholder?: string;
  maxLength?: number;
  minLength?: number;
  invalid: boolean;
  pattern?: RegExp;
}

function FieldText({
  type,
  name,
  value,
  placeholder,
  onChange,
  minLength,
  maxLength,
  invalid,
  pattern,
}: IProps) {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (pattern) {
      if (pattern?.test(e.target.value)) {
        onChange(e);
      } else if (!regexLettersAndSymbols.test(e.target.value)) {
        const event = {
          target: {
            name: e.target.name,
            value: "",
          },
        };
        onChange(event);
      }
    } else onChange(e);
  };

  // const [isReadOnly, setIsReadOnly] = useState(true);
  return (
    <input
      autoComplete="off"
      className={`${
        invalid ? "errorValidation" : ""
      } formElementItem__input bg:white radius:12 w-100 px-3 fs:16 ff:regular c:dark`}
      type={type}
      name={name}
      value={value}
      // readOnly={isReadOnly}
      onChange={handleChange}
      // onFocus={() => setIsReadOnly(false)}
      id={name}
      placeholder={placeholder}
      minLength={minLength}
      maxLength={maxLength}
      style={
        {
          "--form-borderColor": "#8F8F92",
          "--form-height": "3rem",
        } as React.CSSProperties
      }
    />
  );
}

export default FieldText;
