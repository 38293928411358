import Skeleton from "react-loading-skeleton";

const StoresBg = ({ loading }: { loading: boolean }) => {
  return loading ? (
    <Skeleton height={350} width={"100%"} borderRadius={"1.125rem"} />
  ) : (
    <section className="bgContent">
      <img src="/warehouse-full.jpg" alt="" />
    </section>
  );
};

export default StoresBg;
