import logo from "@images/turuncuhat-logo.svg";
import appStoreBlur from "@images/app-store-blur.svg";
import playStoreBlur from "@images/play-store-blur.svg";
import mockupHand from "@images/mockup-hand.png";
import React from "react";

const AppBanner = () => {
  return (
    <section className="appBanner">
      <div className="container">
        <div className="bg:gradient:orange:2 radius:22 px-3 px-xl-5 py-5 py-lg-0">
          <div className="row row-cols-1 row-cols-lg-auto justify-content-between text-center text-lg-start align-items-center gx-0 gy-4 gy-lg-0">
            <div className="col">
              <div className="logo filter:white">
                <a href="#top" aria-label="turuncuhat logo">
                  <img className="mx-auto" src={logo} alt="" />
                </a>
              </div>
            </div>
            <div className="col">
              <p className="c:white ff:bold fs:12 lt:1 text-uppercase">
                ŞİMDİ APP STORE ve GOOGLE PLAY’DE!
              </p>
              <h1 className="c:white ff:bold fs:24 lh:2 mt-3">
                TuruncuHat Mobil <br />
                Artık Her Zaman Yanında
              </h1>
            </div>
            <div className="col d-none d-lg-block">
              <div className="pt-4">
                <img className="appBanner__img" src={mockupHand} alt="" />
              </div>
            </div>
            <div className="col">
              <div className="appBanner__badge">
                <p className="c:white ff:bold fs:15 mb-3">
                  Şimdi Yükle!
                </p>
                <div className="row align-items-center justify-content-center gx-3">
                  <div className="col-auto">
                    <a
                      aria-label="app store"
                      target="_blank"
                      className="hover:opacity:8"
                      rel="noreferrer"
                      href="https://apps.apple.com/tr/app/turuncuhat-gsk/id1456364298"
                    >
                      <img src={appStoreBlur} alt="" />
                    </a>
                  </div>
                  <div className="col-auto">
                    <a
                      aria-label="google play store"
                      target="_blank"
                      className="hover:opacity:8"
                      rel="noreferrer"
                      href="https://play.google.com/store/apps/details?id=com.gsk.turuncuhat&hl=tr&gl=TR"
                    >
                      <img src={playStoreBlur} alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default React.memo(AppBanner);
