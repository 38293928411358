import {
  IRegisterState,
  RegisterContext,
} from "contexts/RegisterContext/RegisterContext";
import { useContext } from "react";
import ProgressBar from "@components/ProgressBar/ProgressBar";

const steps = [
  {
    step: 1,
    title: "kişisel bilgiler",
    titleTop: false,
  },
  {
    step: 2,
    title: "eczane bilgileri",
    titleTop: false,
  },
  {
    step: 3,
    title: "email doğrula",
    titleTop: false,
  },
  {
    step: 4,
    title: "telefon doğrula",
    titleTop: false,
  },
];

function RegisterProgress() {
  const { step } = useContext(RegisterContext) as IRegisterState;
  return step < 5 ? <ProgressBar steps={steps} currentStep={step} /> : null;
}

export default RegisterProgress;
