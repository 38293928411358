import ProgressBar from "@components/ProgressBar/ProgressBar";

interface IProps {
  step: string;
}

const steps = [
  {
    step: 1,
    name: "cart",
    title: "sepetim",
    titleTop: true,
  },
  {
    step: 2,
    name: "depo",
    title: "depo seçimi",
    titleTop: true,
  },
  {
    step: 3,
    name: "ozet",
    title: "özet",
    titleTop: true,
  },
];

const CartStepper = ({ step }: IProps) => {
  const found = steps.find((p) => p.name === step);

  return (
    <>
      <section className="bg:white shadow:9 py-4">
        <div className="py-1"></div>
      </section>
      <div className="container">
        <ProgressBar
          currentStep={found?.step || 1}
          steps={steps}
          className="registerProgress--carts"
        />
      </div>
    </>
  );
};

export default CartStepper;
