import React from "react";
import classNames from "classnames";

interface IProps {
  disabled: boolean;
  loading: boolean;
  onClick: (e: { preventDefault: () => void }) => void;
  text: string;
  ariaLabel?: string;
}

const ButtonContinue = ({
  disabled,
  loading,
  onClick,
  text,
  ariaLabel,
}: IProps) => {

  return (
    <button
      disabled={disabled || loading}
      aria-label={ariaLabel ?? "continue"}
      onClick={onClick}
      className={classNames(
        "buttonStyle buttonStyle--icon buttonStyle--submit c:white ff:bold fs:16 bg:gradient:orange d-block w-100 text-center radius:12",
        {
          "spinnerElem": loading,
          disabledBtn: disabled,
        }
      )}
      type="submit"
      style={
        {
          "--form-height": "3rem",
        } as React.CSSProperties
      }
    >
      {text}
    </button>
  );
};

export default ButtonContinue;
