import { useContext } from "react";
import { AuthContextSite } from "../contexts/AuthContext/AuthContext";
import { useLocalStorage } from "./useLocalStorage";

interface IClaim {
  id: number;
  type: string;
  value: string;
}

interface IUserRoles {
  claims: IClaim[];
  description: string;
  id: string;
  name: string;
}

export interface IUser {
  accessFailedCount: number;
  address: string;
  age: number;
  avatar: null;
  confirmationMessage: string | null;
  blocked: false;
  city: string;
  company: string;
  concurrencyStamp: string;
  email: string;
  oneKeyCode: string;
  emailConfirmed: boolean;
  mobilePhoneConfirmed: boolean;
  externalCompanyId: string | null;
  externalUserId: string | null;
  firstName: string;
  gender: string;
  gln: string;
  id: string;
  interests: string[];
  lastName: string;
  lockoutEnabled: boolean;
  lockoutEnd: null | string;
  normalizedEmail: string;
  normalizedUserName: string;
  phone: string;
  phoneNumber: string;
  mobile?: string;
  phoneNumberConfirmed: boolean;
  provider: null | string;
  registeredDate: null | string;
  securityStamp: string;
  town: string;
  twoFactorEnabled: boolean;
  userName: string;
  roles: IUserRoles[];
  userStatus: number;
  accessToken?: string;
  accessTokenExpireDate: string;
  refreshToken: string;
  refreshTokenExpireDate: string;
  forceTermOfUse: boolean;
  forcePrivacyPolicy: boolean;
  token?: string;
}

export const useUser = () => {
  const { user, handleLogin } = useContext(AuthContextSite);
  const { setItem } = useLocalStorage();

  const addUser = (user: IUser) => {
    handleLogin(user);
    setItem("user", JSON.stringify(user));
  };

  const checkUserRole = (type: string) => {
    let roleData = null;
    if (user) {
      user.roles?.map((role) => {
        // console.log(role)
        let data = role.claims?.find((claim) => claim.value === type);
        if (data) {
          roleData = data;
          return roleData;
        }
        return data;
      });
      return roleData ? true : false;
    } else return false;
  };

  const removeUser = () => {
    handleLogin(null);
    localStorage.clear();
    window.location.replace("/giris");
  };

  return { user, addUser, removeUser, checkUserRole };
};
