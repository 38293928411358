import { useContext, useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { forgotPassword } from "services/user.service";
import useApi from "hooks/useApi";
import ResetPassword from "@components/Auth/ForgotPassword/ResetPassword";
import GeneralLoading from "@components/General/GeneralLoading";
import { PublicContext } from "contexts/PublicContext/PublicContext";
/* eslint-disable react-hooks/exhaustive-deps */
const ForgotPassword = () => {
  const { error: apiError, loading, handleApi, success } = useApi();
  let { state } = useLocation();
  const [retryCount, setRetryCount] = useState(1);
  const storedStartTime = localStorage.getItem("countdownStartTime");
  const { setSiteTitle } = useContext(PublicContext);
  useEffect(() => {
    setSiteTitle("Şifremi Unuttum")
    if (state && state.email && !storedStartTime) {
      handleApi(forgotPassword, state.email);
    }
  }, [state, retryCount]);

  const condition = storedStartTime ? true : success;

  return (
    <div className="layoutBody">
      <main className="layoutMain layoutMain--login">
        {state?.email && condition ? (
          <ResetPassword
            email={state.email}
            successSend={success}
            setRetryCount={setRetryCount}
            retryCount={retryCount}
          />
        ) : (
          <>
            {loading ? (
              <GeneralLoading />
            ) : apiError.length ? (
              apiError
            ) : (
              !state?.email && <Navigate to={"/giris"} />
            )}
          </>
        )}
      </main>
    </div>
  );
};

export default ForgotPassword;
