import React from 'react';
import image2 from "@images/neler-yapabiliriz-2.png";
import image3 from "@images/neler-yapabiliriz-3.png";
import image4 from "@images/neler-yapabiliriz-4.png";

const Section1 = () => {
  return (
    <section>
      <div className='py-5'>
        <div className='py-0 py-sm-5'>
          <div className='container'>
            <div className='row justify-content-center g-0'>
              <div className='col-12 col-lg-10'>
                <p className='c:orange ff:bold fs:13 lt:1 text-uppercase text-center'>
                  TuruncuHat Web Platformu
                </p>
                <h1 className='c:dark ff:bold fs:44 text-center lh:3 mt-2'>
                  TuruncuHat Web’te Neler var?
                </h1>
                <div className='seperatorLine mx-auto mt-3 mb-4'></div>
                <p className='c:dark ff:light fs:18 text-center mb-5'>
                  TuruncuHat, GSK tarafından eczacıların profesyonel yaşamlarını desteklemek amacıyla kurulmuş bir
                  platformdur.
                  <br />
                  <br />
                  GSK ürünlerine ait detaylı bilgilere kolayca ulaşabileceğiniz ve sipariş süreçlerinizi hızlıca
                  yönetebileceğiniz TuruncuHat sayesinde hep bir adım öndesiniz.
                </p>
                <div className='row row-cols-1 row-cols-md-3 gx-0 gx-md-4 gy-4 gy-md-0'>
                  <div className='col d-flex'>
                    <div className='landingInfo__item bg:white radius:18 p-4 text-center shadow:1 '><img
                      className='landingInfo__item-icon' src={image2} alt='' />
                      <div className='my-3 pt-2'><p className='ff:bold c:dark fs:18'> Bir Dolu Fırsat</p></div>
                      <p className='ff:light c:dark fs:16'>Size özel hazırlanan benzersiz avantajlar ile işinize değer
                        katabilirsiniz.</p></div>
                  </div>
                  <div className='col d-flex'>
                    <div className='landingInfo__item bg:white radius:18 p-4 text-center shadow:1 '><img
                      className='landingInfo__item-icon' src={image4} alt='' />
                      <div className='my-3 pt-2'><p className='ff:bold c:dark fs:18'> Kesintisiz İletişim</p></div>
                      <p className='ff:light c:dark fs:16'> GSK’ya dair gelişmelerden anında haberdar olabilir ve soru
                        ve taleplerinizle ilgili bize 7/24 ulaşabilir. </p></div>
                  </div>
                  <div className='col d-flex'>
                    <div className='landingInfo__item bg:white radius:18 p-4 text-center shadow:1 '><img
                      className='landingInfo__item-icon' src={image3} alt='' />
                      <div className='my-3 pt-2'><p className='ff:bold c:dark fs:18'>Renkli İçerikler</p></div>
                      <p className='ff:light c:dark fs:16'>
                        Sektörel haberleri takip edebilir ve eğitici materyaller ile mesleki gelişiminizi
                        destekleyebilirsiniz.
                      </p></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section1;
