import React, { useState, useReducer, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import TalepForm from "@components/Questions/Form";
import Result from "@components/Questions/Result";
import useApi from "hooks/useApi";
import { setDemand } from "services/user.service";
import { PublicContext } from "contexts/PublicContext/PublicContext";
import { useTealium } from "hooks/useTealium";

export interface ITalepForm {
  subject: string;
  message: string;
}
/* eslint-disable react-hooks/exhaustive-deps */
const YeniTalep = () => {
  const tealium = useTealium();
  const initialState = {
    subject: "",
    message: "",
  };
  const { success, handleApi, data } = useApi();
  const [formSuccess, setFormSuccess] = useState(false);
  const [state, dispatch] = useReducer(
    (state: ITalepForm, updates: any) => ({ ...state, ...updates }),
    initialState
  );
  const [formError, setFormError] = useState({ subject: "", message: "" });

  const handleSubmit = () => {
    if (!state.subject) {
      setFormError((prevState) => ({
        ...prevState,
        subject: "Lütfen bir talep konusu seçin",
      }));
    }
    if (!state.message) {
      setFormError((prevState) => ({
        ...prevState,
        message: "Lütfen bir talep içeriği yazınız.",
      }));
    }
    if (state.subject && state.message) {
      handleApi(setDemand, { subject: state.subject, message: state.message });
    }
  };

  useEffect(() => {
    if (success) {
      setFormSuccess(true);
      tealium.trackEvent("newDemand", {
        subject: state.subject,
        message: state.message,
        demandCode: data.code,
      });
      setFormError({ message: "", subject: "" });
      dispatch({ subject: "", message: "" });
    }
  }, [success]);
  /* eslint-disable no-useless-computed-key */
  useEffect(() => {
    if (state.subject) {
      setFormError((prevState) => ({
        ...prevState,
        ["subject"]: "",
      }));
    }
    if (state.message) {
      setFormError((prevState) => ({
        ...prevState,
        ["message"]: "",
      }));
    }
  }, [state]);

  const { setSiteTitle } = useContext(PublicContext);

  useEffect(() => {
    setSiteTitle("Yeni Talep");
  }, []);

  return (
    <>
      <section className="breadcrumb my-4 my-md-5">
        <div className="container">
          <ul className="c:dark fs:14 lt:3">
            <li>
              <Link to="/anasayfa">Anasayfa</Link>
            </li>
            <li>
              <Link to="/hesabim">Hesabım</Link>
            </li>
            <li>
              <Link to="/hesabim/soru-ve-taleplerim/yeni-talep">
                Yeni Talep
              </Link>
            </li>
          </ul>
        </div>
      </section>
      <section className="my-4 my-md-5">
        <div className="container">
          <div className="row row-cols-1 row-cols-sm-auto align-items-center justify-content-between gx-0 gy-3 gy-sm-0">
            <div className="col">
              <h1 className="c:dark ff:bold fs:24 text-center">
                Soru ve Taleplerim
              </h1>
            </div>
            <div className="col">
              <div className="d-flex justify-content-center">
                <Link
                  to="/hesabim/soru-ve-taleplerim"
                  aria-label="Yeni Talep Oluştur"
                  className="buttonStyle buttonStyle--paddingElem buttonStyle--border c:orange ff:bold fs:16 bg:white d-inline-block text-center radius:12 shadow:10"
                  style={
                    {
                      "--btnPaddingElem": "0.7rem 2.8rem",
                      "--btn-border": "#FE9744",
                      "--btn-hover": "#FE9744",
                    } as React.CSSProperties
                  }
                >
                  Tüm Taleplerim
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mb-5">
        <div className="container">
          {formSuccess ? (
            <Result setFormSucces={setFormSuccess} data={data} />
          ) : (
            <TalepForm
              handleSubmit={handleSubmit}
              state={state}
              dispatch={dispatch}
              error={formError}
            />
          )}
        </div>
      </section>
    </>
  );
};

export default YeniTalep;
