export const uniqueBy = <T, K extends keyof any>(
    list: T[] = [],
    getKey: (item: T) => K
  ) => {
    return list.reduce(
      (previous, currentItem) => {
        const keyValue = getKey(currentItem);
        const { uniqueMap, result } = previous;
        const alreadyHas = uniqueMap[keyValue];
        if (alreadyHas) return previous;
        return {
          result: [...result, currentItem],
          uniqueMap: { ...uniqueMap, [keyValue]: true },
        };
      },
      { uniqueMap: {} as Record<K, any>, result: [] as T[] }
    ).result;
  };