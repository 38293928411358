import React from "react";
import { ISelectedWareHouse } from "types/wareHouseTypes";

const CartWareHouseSelectedItem = ({ item }: { item: ISelectedWareHouse }) => {
  return (
    <div className="col">
      <div
        className={`w-100 bg:white radius:12 shadow:4 p-3 p-sm-4 storeCheck storeCheck__active`}
        style={
          {
            "--gsk-border-width": "0.031rem",
            "--gsk-border-color": "#d7d7d7",
          } as React.CSSProperties
        }
      >
        <div className="d-flex align-items-center">
          <div className="flex-shrink-0 me-2 pe-1"></div>
          <div className="flex-grow-1">
            <div className="kz-check">
              <div>
                <label className="kz-check__label">
                  <span className="d-block ff:regular fs:15 c:dark text-capitalize">
                    <p>{item.wareHouseName.toLowerCase()}</p>
                  </span>
                  <input
                    type="radio"
                    id="radio-1"
                    name="radio-1"
                    defaultChecked
                    aria-label="blankInput"
                    required
                  />
                  <div className="kz-check__input"></div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartWareHouseSelectedItem;
