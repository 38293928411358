import { useContext } from "react";
import iconHp from "@images/icon-hp.svg";
import { QuickOrderContext } from "contexts/QuickOrderContext/QuickOrderContext";
import { ISelectedWareHouse } from "types/wareHouseTypes";

interface IProps {
  productId: string;
  warehouse: ISelectedWareHouse;
  quantity: number;
}
function AddQuickOrder({ productId, warehouse, quantity }: IProps) {
  const { handleAddQuickOrder, loading, quickOrders, handleRemoveQuickOrder } =
    useContext(QuickOrderContext);

  const handleCreate = async () => {
    const warehouseId = warehouse.wareHouseId;
    handleAddQuickOrder({ productId, warehouseId, quantity });
  };

  const productInQuickOrders = quickOrders?.find(
    (qo) => qo.productId === productId
  );

  const handleRemove = async () => {
    handleRemoveQuickOrder(productInQuickOrders!.id);
  };

  return (
    <button
      className="iconLinkBtn iconLinkBtn--orange"
      type="button"
      aria-label="hızlı sipariş"
      disabled={loading}
    >
      <div className="d-flex align-items-center">
        <div className="flex-shrink-0 iconSize:16">
          <img src={iconHp} alt="" />
        </div>
        <div className="flex-grow-1 ms-2">
          {productInQuickOrders ? (
            <p onClick={handleRemove} className="c:purple ff:bold fs:16">
              Hızlı siparişlerimden kaldır
            </p>
          ) : (
            <p onClick={handleCreate} className="c:purple ff:bold fs:16">
              Hızlı siparişlerime ekle
            </p>
          )}
        </div>
      </div>
    </button>
  );
}

export default AddQuickOrder;
