import { endpoints } from "constants/endpoints";
import api from "../utility/request";

export const getWareHouseById = async (warehouseId: {
  id: string;
}): Promise<any> => {
  const { id } = warehouseId;
  const response = (await api.get(`${endpoints.baseUrl}/warehouse/${id}`)).data;
  return response;
};
export const getAllWareHouse = async (data: any): Promise<any> => {
  const { query, sort, offset, limit } = data;

  const response = (
    await api.get(
      `${endpoints.baseUrl}/warehouse?q=${encodeURIComponent(query) ?? ""}&sort=${
        sort ?? ""
      }&offset=${offset ?? ""}&limit=${limit ?? ""}`
    )
  ).data;
  return response;
};
