import { Actions } from "./AppActions";

// Define the reducer
export const appReducer = (state: any, action: any) => {
  const { pendingRequests } = state;
  switch (action.type) {
    case Actions.SEND_REQUEST:
      pendingRequests.push(action.id);
      return {
        pendingRequests: pendingRequests,
        loading: pendingRequests.length > 0,
      };
    case Actions.COMPLETED:
      const index = pendingRequests.indexOf(action.id);
      if (index > -1) {
        // only splice array when item is found
        pendingRequests.splice(index, 1); // 2nd parameter means remove one item only
      }
      return {
        pendingRequests: pendingRequests,
        loading: pendingRequests.length > 0,
      };
    default:
      return state;
  }
};
