import Skeleton from "react-loading-skeleton";

const LisItem = () => (
  <li className="col">
    <div className="listInfo__item">
      <div className="row row-cols-auto g-0 justify-content-between align-items-center">
        <div className="col">
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0 iconSize:16">
              <Skeleton width={16} height={16} />
            </div>
            <div className="flex-grow-1 ms-3">
              <Skeleton width={50} height={12} />
            </div>
          </div>
        </div>
        <div className="col">
          <Skeleton
            width={32}
            height={16}
            style={{ borderRadius: "2.125rem" }}
          />
        </div>
      </div>
    </div>
  </li>
);
const ContactSkeleton = () => {
  return (
    <div className="row row-cols-1 gx-0 gy-3">
      <div className="col">
        <section className="bg:white radius:12 shadow:4 p-4 p-md-5">
          <form action="">
            <ul className="row row-cols-1 gx-0 gy-4 listInfo listInfo--border">
              <LisItem />
              <LisItem />
              <LisItem />
            </ul>
          </form>
        </section>
      </div>
    </div>
  );
};

export default ContactSkeleton;
