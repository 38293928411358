import React, { useEffect, useState } from "react";
import HeartButton from "@components/General/HeartButton";
import iconStarFill from "@images/icon-star-fill.svg";
import iconHour from "@images/icon-hour.svg";
import { ResultDetail } from "types/productTypes";
/* eslint-disable react-hooks/exhaustive-deps */
interface IProductDetails {
  key: string;
  value: string;
}
const ProductDetail = ({ product }: { product: ResultDetail }) => {
  const productInformation = JSON.parse(product.Information);
  const [productDetails, setProductDetails] = useState<IProductDetails[]>();

  useEffect(() => {
    if (product && productInformation !== null && productInformation !== "{}") {
      const data = Object.keys(productInformation).map((key) => {
        return {
          key: key,
          value: productInformation[key],
        };
      });
      setProductDetails(data);
    }
  }, [product]);

  return (
    <>
      {product && (
        <div className="productDetails">
          <div className="row gx-0 gx-md-4 gy-4 gy-md-0">
            <div className="col-12 col-md-6">
              <div className="productDetails__img p-4">
                <div className="productDetails__img-content">
                  <img
                    src={
                      product.image
                        ? `data:${product.image.contentType};base64,${product.image.fileContents}`
                        : "/brokenImage.svg"
                    }
                    alt={
                      product.image?.contentType
                        ? product.image?.contentType
                        : "alttext"
                    }
                    loading="lazy"
                    onError={(e) => {
                      e.currentTarget.src = "/brokenImage.svg";
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 me-3">
                  <h1 className="c:dark ff:bold fs:22">
                    {product.displayBrandName}
                  </h1>
                  <h1 className="c:dark ff:light fs:22">
                    {product.displayProductForm}
                  </h1>
                </div>
                <div className="flex-shrink-0">
                  <HeartButton product={product} />
                </div>
              </div>
              <div className="mt-2">
                <div className="row row-cols-auto gx-2 gy-0">
                  {product?.label?.includes("PreOrder") && (
                    <div className="col">
                      <div
                        className="d-flex align-items-center bg:orange:5 c:white fs:12 ff:bold radius:5 paddingElem"
                        style={
                          {
                            "--paddingElem": "0.5rem 0.813rem",
                          } as React.CSSProperties
                        }
                      >
                        <div className="flex-shrink-0">
                          <img src={iconHour} alt="" />
                        </div>
                        <div className="flex-grow-1 ms-2">
                          <p>Ön Sipariş</p>
                        </div>
                      </div>
                    </div>
                  )}
                  {product?.label?.includes("Turuncu") && (
                    <div className="col">
                      <div
                        className="d-flex align-items-center bg:gradient:orange:4 c:white fs:12 ff:bold radius:5 paddingElem"
                        style={
                          {
                            "--paddingElem": "0.5rem 0.813rem",
                          } as React.CSSProperties
                        }
                      >
                        <div className="flex-shrink-0">
                          <img src={iconStarFill} alt="" />
                        </div>
                        <div className="flex-grow-1 ms-2">
                          <p>Turuncu Ürün</p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {productDetails?.length ? (
                <div className="mt-4 mt-md-5 mb-3">
                  <p className="c:dark fs:16">
                    <strong>Ürün Detayı</strong>
                  </p>
                </div>
              ) : null}
              {productInformation && (
                <div className="responsiveTable responsiveTable--noScroll">
                  <table>
                    <tbody>
                      {productDetails?.length
                        ? productDetails.map((item) => (
                            <tr key={item.key}>
                              <td>{item.key}</td>
                              <td>{item.value.length ? item.value : "-"}</td>
                            </tr>
                          ))
                        : null}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProductDetail;
