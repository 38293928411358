import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import iconSuccess from '@images/icon-success.svg';
import infoiconGreen from '@images/icon-info-green.svg';
import { PublicContext } from 'contexts/PublicContext/PublicContext';
import useApi from '../../../hooks/useApi';
import { createOwaUser } from '../../../services/user.service';
/* eslint-disable react-hooks/exhaustive-deps */
const Step5 = () => {
  const navigate = useNavigate();
  const [error, setError] = useState('');

  const {handleApi, error: secondError} = useApi();

  console.log(secondError);
  useEffect(() => {
    handleApi(createOwaUser, {});
    setError(secondError);
  }, []);

  const handleLoginButton = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    localStorage.removeItem('accessToken');
    navigate('/giris');
  };

  useEffect(() => {
    localStorage.removeItem('registeruser');
    localStorage.removeItem('registertoken');
    return () => {
    };
  }, []);

  // if (!user) return <NotUser />;

  const { setSiteTitle } = useContext(PublicContext);
  useEffect(() => {
    setSiteTitle('TuruncuHat hesabını başarıyla oluşturdunuz!');
  }, []);

  return (
    <section className='w-100'>
      <div className='container-fluid'>
        <div className='row g-0 row-cols-1 row-cols-lg-2 row-cols-xl-3 justify-content-center'>
          <div className='col'>
            <div className='formElement__block bg:white radius:12 shadow:3 p-3 p-sm-4'>
              <div className='p-2'>
                <div className='row row-cols-1 g-0 gy-3 gy-sm-4'>
                  <div className='col'>
                    <div className='formElement__success text-center'>
                      {!error && <div className='formElement__success-icon'>
                        <img className='mx-auto' src={iconSuccess} alt='' />
                      </div>}
                      <p className='ff:light fs:16 c:dark my-3 my-sm-4'>
                        {error && 'Hesabınız oluşturulurken bir hata ile karşılaşıldı.'}
                        {!error && 'TuruncuHat hesabını başarıyla oluşturdunuz!'}
                      </p>
                      <button
                        onClick={handleLoginButton}
                        aria-label='continue'
                        className='buttonStyle buttonStyle--icon buttonStyle--submit c:white ff:bold fs:16 bg:gradient:orange d-block w-100 text-center radius:12'
                        type='button'
                        style={
                          {
                            '--form-height': '3rem',
                          } as React.CSSProperties
                        }
                      >
                        Giriş Yap
                      </button>
                    </div>
                  </div>
                  <div className='col'>
                    <div
                      className={`alert bg:${error ? 'red' : 'green'}:2 p-3 radius:12`}
                      style={
                        {
                          '--alert-borderColor': `${error ? 'rgb(182,93,93)' : 'rgba(57, 189, 141, 0.22)'}`,
                        } as React.CSSProperties
                      }
                    >
                      <div className='d-flex align-items-center'>
                        {!error && (
                          <div className='flex-shrink-0'>
                            <img src={infoiconGreen} alt='' />
                          </div>
                        )}
                        <div className='flex-grow-1 ms-3'>
                          <p
                            id='successtext'
                            className={`c:${error ? 'red' : 'green'} lt:2 ff:regular fs:12`}
                          >
                            {error && error || ''}
                            {!error && 'Üyelik talebiniz alındı. Gün içerisinde üyeliğiniz ile ilgili sizinle iletişime geçilecektir.'}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Step5;
