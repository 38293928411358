import Skeleton from "react-loading-skeleton";

const OrderSkeletonDetail = () => {
  return (
    <div className="bg:white radius:12 shadow:4 p-4 layoutCollapse">
      <div>
        <section className="scrollingMobile flex-nowrap overflow-hidden">
          <div className="layoutTables">
            <ul className="layoutTables__content">
              <li className="layoutTables__content-item bg:blues">
                <div className="d-flex align-items-start">
                  <div className="flex-shrink-0">
                    <Skeleton
                      width={"2.125rem"}
                      height={"2.125rem"}
                      borderRadius={"100%"}
                    />
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <Skeleton width={197} height={24} />

                    <Skeleton width={157} height={21} className="mt-2" />
                  </div>
                </div>
              </li>
              <li className="layoutTables__content-item bg:reds">
                <Skeleton width={107} height={24} />
              </li>
              <li className="layoutTables__content-item bg:yellows">
                <div className="d-flex align-items-center justify-content-center">
                  <Skeleton width={107} height={24} />
                </div>
              </li>
              <li className="layoutTables__content-item bg:darks">
                <div className="text-end">
                  <Skeleton width={214} height={19} />

                  <Skeleton width={70} height={21} className="mt-2" />
                </div>
              </li>
            </ul>
          </div>
        </section>
      </div>
    </div>
  );
};

export default OrderSkeletonDetail;
