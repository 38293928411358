import { ReactNode } from "react";
import FieldText from "./FieldText";
import FieldPassword from "./FieldPassword";
import FieldSelect from "./FieldSelect";
import FieldTextArea from "./FieldTextArea";

interface IProps {
  children?: ReactNode;
  type: string;
  onChange: (e: any) => void;
  errors?: any;
  label?: string;
  name: string;
  value: string | undefined;
  placeholder?: string;
  loading?: boolean;
  maxLength?: number;
  minLength?: number;
  required?: boolean;
  searchable?: boolean;
  options?: any[];
  pattern?: RegExp;
}

function Field({
  children,
  type,
  label,
  name,
  errors,
  value,
  placeholder,
  minLength,
  maxLength,
  loading,
  onChange,
  required,
  options,
  pattern,
  searchable,
}: IProps) {
  function renderFieldInput({ type, ...props }: any) {
    switch (type) {
      case "select":
        return <FieldSelect type={type} {...props} />;
      case "password":
        return <FieldPassword type={type} {...props} />;
      case "textarea":
        return <FieldTextArea type={type} {...props} />;
      default:
        return <FieldText type={type} {...props} />;
    }
  }

  return (
    <div className="formElementItem">
      {label && (
        <label
          className="c:dark ff:regular fs:11 text-uppercase lt:1 d-block mb-2"
          htmlFor={name}
        >
          {label}
          {required && <sup>*</sup>}
        </label>
      )}
      {renderFieldInput({
        type,
        name,
        value,
        placeholder,
        minLength,
        maxLength,
        invalid: errors !== "" || errors?.length > 0,
        onChange,
        options,
        pattern,
        searchable,
      })}
      {children}
      <p
        className={`${
          errors?.length ? "d-block" : "d-none"
        } c:red ff:regular fs:11 mt-2 lt:1`}
      >
        {!loading && errors}
      </p>
    </div>
  );
}

export default Field;
