import { useContext, useEffect, useState } from "react";
import iconBookmark from "@images/icon-bookmark.svg";
import iconLike from "@images/icon-like.svg";
import iconRating from "@images/icon-rating.svg";
import iconDislike from "@images/icon-dislike.svg";
import BlogCard from "./BlogCard";
import { convertDate } from "utility/formatDate";
import { Link } from "react-router-dom";
import useApi from "hooks/useApi";
import { getPostCards } from "services/post.service";
import { IBlogPosts } from "views/Blog/BlogCategories";
import {
  toggleBookMarkPost,
  toggleDislikePost,
  toggleLikePost,
} from "services/account.service";
import Brosur from "./BlogDetailComponents/Brosur";
import BlogImage from "./BlogDetailComponents/BlogImage";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import remarkGfm from "remark-gfm";
import Video from "./BlogDetailComponents/Video";
import Skeleton from "react-loading-skeleton";
import BlogCardSkeleton from "./BlogCardSkeleton";
import { PublicContext } from "contexts/PublicContext/PublicContext";
import { useTealium } from "hooks/useTealium";

export interface IPostItem {
  postItemType: string;
  content: string;
  image: {
    fileContents: string;
    contentType: string;
    fileDownloadName: string;
    lastModified: string;
    entityTag: string;
    enableRangeProcessing: boolean;
  };
  id: string;
}

interface IPostData {
  title: string;
  description: string;
  hashtags: string[];
  label: string;
  publishDate: string;
  banner: {
    fileContents: string;
    contentType: string;
    fileDownloadName: string;
    lastModified: string;
    entityTag: string;
    enableRangeProcessing: boolean;
  };
  bookmark: boolean;
  like: boolean;
  dislike: boolean;
  blogName: string;
  blogColor: string;
  items: IPostItem[];
  id: string;
}
export interface IPost {
  post: IPostData;
}
interface IProps {
  post: IPostData;
  setActiveCategory: (cat: string) => void;
  blogLoading: boolean;
}
/* eslint-disable react-hooks/exhaustive-deps */
const BlogDetail = ({ post, setActiveCategory, blogLoading }: IProps) => {
  const tealium = useTealium();
  const { loading, handleApi, data } = useApi({
    loading: true,
  });
  const [likeState, setLikeState] = useState(post?.like);
  const [bookmarkState, setBookmarkState] = useState(post?.bookmark);
  const [dislikeState, setDislikeState] = useState(post?.dislike);

  const [loadings, setLoadings] = useState(false);

  const { setSiteTitle } = useContext(PublicContext);

  useEffect(() => {
    if (post) {
      setLikeState(post.like);
      setDislikeState(post.dislike);
      setBookmarkState(post.bookmark);
      setSiteTitle(post.title);
    }
  }, [post]);

  const handleLike = async () => {
    setLoadings((prev) => !prev);
    try {
      await toggleLikePost(post.id);
      tealium.trackEvent("postLiked", { postId: post.id });
      setLikeState((prev) => !prev);
      if (dislikeState) {
        setDislikeState((prev) => !prev);
      }
    } catch (error) {
    } finally {
      setLoadings((prev) => !prev);
    }
  };

  const handleDislike = async () => {
    setLoadings((prev) => !prev);
    try {
      await toggleDislikePost(post.id);
      tealium.trackEvent("postDisliked", { postId: post.id });
      setDislikeState((prev) => !prev);
      if (likeState) {
        setLikeState((prev) => !prev);
      }
    } catch (error) {
    } finally {
      setLoadings((prev) => !prev);
    }
  };

  const handleBookmark = async () => {
    setLoadings((prev) => !prev);
    try {
      await toggleBookMarkPost(post.id);
      tealium.trackEvent("postBookmarked", { postId: post.id });
      setBookmarkState((prev) => !prev);
    } catch (error) {
    } finally {
      setLoadings((prev) => !prev);
    }
  };

  useEffect(() => {
    if (post) {
      handleApi(getPostCards, {});
      setActiveCategory(post.blogName);
    }
  }, [post]);

  const allPosts: IBlogPosts[] = !loading && data;
  const popularPosts =
    !loading &&
    allPosts &&
    post &&
    allPosts
      ?.filter((elem) => elem.label.includes("Popular") && elem.id !== post.id)
      .slice(0, 3);

  return (
    <div className="blogDetail row gx-0 gy-5 gy-lg-0">
      <div className="col-12 col-lg-8 col-xl-9">
        <div className="pe-0 pe-lg-5">
          <div className="pe-0 pe-xl-5">
            <div className="d-flex align-items-center">
              <div className="flex-grow-1 me-3 me-sm-5">
                <h1 className="blogDetail__title c:dark fs:28 lt:3 ff:bold">
                  {blogLoading ? (
                    <Skeleton height={30} width={"50%"} />
                  ) : (
                    post.title
                  )}
                </h1>
              </div>
              <div className="flex-shrink-0">
                <div className="actionButton">
                  {blogLoading ? (
                    <Skeleton
                      width={46}
                      height={46}
                      borderRadius={50}
                      style={{ marginTop: 0 }}
                    />
                  ) : (
                    <button
                      onClick={handleBookmark}
                      className={`radius:full bg:light:18 d-flex align-items-center justify-content-center ${
                        bookmarkState ? "actionButton__active" : ""
                      }`}
                      aria-label="bookmark"
                      disabled={loadings}
                      type="button"
                    >
                      <img src={iconBookmark} alt="" />
                    </button>
                  )}

                  <div className="mt-2">
                    {blogLoading ? (
                      <Skeleton width={46} height={46} borderRadius={50} />
                    ) : (
                      <button
                        onClick={handleLike}
                        className={`radius:full bg:light:18 d-flex align-items-center justify-content-center ${
                          likeState ? "actionButton__active" : ""
                        }`}
                        disabled={loadings}
                        aria-label="like"
                        type="button"
                      >
                        <img src={iconLike} alt="" />
                      </button>
                    )}
                  </div>
                  <div className="mt-2">
                    {blogLoading ? (
                      <Skeleton width={46} height={46} borderRadius={50} />
                    ) : (
                      <button
                        onClick={handleDislike}
                        className={`radius:full bg:light:18 d-flex align-items-center justify-content-center ${
                          dislikeState ? "actionButton__active" : ""
                        }`}
                        disabled={loadings}
                        aria-label="like"
                        type="button"
                      >
                        <img src={iconDislike} alt="" />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="my-4 my-sm-5">
              <div className="row row-cols-auto g-0 justify-content-between">
                <div className="col">
                  {blogLoading ? (
                    <Skeleton height={20} width={150} />
                  ) : (
                    <p
                      className="text-uppercase fs:12 lt:4 ff:bold"
                      style={{ color: post.blogColor ?? "" }}
                    >
                      {post.blogName}
                    </p>
                  )}
                </div>
                <div className="col">
                  {blogLoading ? (
                    <Skeleton height={20} width={100} />
                  ) : (
                    <p className="c:dark ff:light fs:14 lt:2">
                      {convertDate(post.publishDate)}
                    </p>
                  )}
                </div>
              </div>
            </div>
            {!blogLoading && post.banner ? (
              <section className="blogDetail__img my-4 my-sm-5">
                <img
                  src={
                    post.banner
                      ? `data:${post?.banner?.contentType};base64,${post?.banner?.fileContents}`
                      : "/brokenImage.svg"
                  }
                  alt={
                    post.banner?.contentType
                      ? post.banner?.contentType
                      : "alttext"
                  }
                  loading="lazy"
                  onError={(e) => {
                    e.currentTarget.src = "/brokenImage.svg";
                  }}
                />
              </section>
            ) : (
              <Skeleton height={400} />
            )}
            <section className="contentArea ff:light fs:16 c:dark my-4 my-sm-5">
              <article>
                {blogLoading ? (
                  <Skeleton height={600} width={"100%"} />
                ) : (
                  <ReactMarkdown
                    children={post.description}
                    remarkPlugins={[remarkGfm]}
                  />
                )}
              </article>
            </section>
            {blogLoading ? (
              <Skeleton height={50} />
            ) : post.items.length ? (
              <>
                {post.items.map((item) => {
                  if (item.postItemType === "Text" && item.content) {
                    return (
                      <section
                        key={item.id}
                        className="contentArea ff:light fs:16 c:dark my-4 my-sm-5"
                      >
                        <article style={{ whiteSpace: "pre-wrap" }}>
                          <ReactMarkdown
                            children={item.content}
                            remarkPlugins={[remarkGfm]}
                            // rehypePlugins={[rehypeRaw]}
                          />
                        </article>
                      </section>
                    );
                  }
                  if (item.postItemType === "Video" && item.content) {
                    return <Video key={item.id} item={item} />;
                  }
                  if (item.postItemType === "Image") {
                    return <BlogImage key={item.id} item={item} />;
                  }
                  if (item.postItemType === "Brochure") {
                    return <Brosur key={item.id} item={item} />;
                  }
                  return null;
                })}
              </>
            ) : null}

            <section className="contentSeperator pt-4 pt-sm-5 mt-4 mt-sm-5">
              <div className="tagListItem">
                <ul className="c:dark ff:bold fs:14">
                  {blogLoading ? (
                    <Skeleton height={10} />
                  ) : post.hashtags.length ? (
                    post.hashtags.map((hashTag) =>
                      hashTag?.length ? (
                        <li key={hashTag}>
                          <Link to={`/tag?slug=${hashTag}`}>{hashTag}</Link>
                        </li>
                      ) : null
                    )
                  ) : null}
                </ul>
              </div>
            </section>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-4 col-xl-3">
        <div className="d-flex justify-content-center justify-content-lg-start mb-4">
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <img src={iconRating} alt="" />
            </div>
            <div className="flex-grow-1 ms-3">
              <h1 className="c:dark ff:bold fs:24 text-center text-lg-start lt:3">
                Popüler İçerikler
              </h1>
            </div>
          </div>
        </div>
        <div className="row row-cols-1 gx-0 gy-3">
          {loading ? (
            <>
              <div className="col">
                <BlogCardSkeleton />
              </div>
              <div className="col">
                <BlogCardSkeleton />
              </div>
              <div className="col">
                <BlogCardSkeleton />
              </div>
            </>
          ) : popularPosts ? (
            popularPosts.map((post) => (
              <div className="col" key={post.id}>
                <BlogCard color={post.blogColor ?? ""} post={post} />
              </div>
            ))
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default BlogDetail;
