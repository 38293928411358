import React, { useContext } from "react";
import AddQuickOrder from "@components/Orders/AddQuickOrder";
import { CartContext } from "contexts/CartContext/CartContext";
import { formatCurrency } from "utility/formatCurrency";
import Skeleton from "react-loading-skeleton";
import iconError from "@images/icon-errors.svg";

const AccordionSkeletonItem = () => (
  <div className="col">
    <div className="bg:white radius:12 p-4 shadow:4">
      <div className="row row-cols-1 row-cols-sm-2 justify-content-between align-items-center gx-0 gy-4 gy-sm-0">
        <div className="col">
          <div className="text-center text-sm-start">
            <div className="row row-cols-1 gx-0 gy-2 text-center text-sm-start">
              <div className="col d-block d-sm-none">
                <p className="text-uppercase ff:regular lt:3 c:dark fs:12">
                  ürün
                </p>
              </div>
              <div className="col">
                <Skeleton height={21} width={80} />
              </div>
              <div className="col">
                <Skeleton height={21} width={50} />
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="row row-cols-1 gx-0 gy-2 text-center text-sm-end">
            <div className="col">
              <div className="d-flex align-items-center justify-content-center justify-content-sm-end">
                <Skeleton height={19} width={50} />
                <div>
                  <Skeleton
                    width={56.52}
                    height={40.5}
                    className="radius:12 ms-2"
                  />
                </div>
              </div>
            </div>
            <div className="col">
              <Skeleton height={21} width={190} />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const AccordionSummary = () => {
  const { getProductInCart, loading, cart, error } = useContext(CartContext);
  return (
    <section className="mt-0 mt-sm-4">
      {error?.length ? (
        <section className="bg:white radius:12 py-4 py-sm-5 px-3 text-center shadow:4 mb-5">
          <div className="d-flex align-items-center justify-content-center">
            <div className="iconSize:20 me-3">
              <img src={iconError} alt="" />
            </div>
            <div className="c:red fs:16 ff:regular">
              <p>{error}</p>
            </div>
          </div>
          {/* <div className="d-flex justify-content-center mt-4">
              <Link
                to="/anasayfa"
                aria-label="anasayfa"
                className="buttonStyle buttonStyle--paddingElem hover:opacity:8 c:white ff:bold fs:16 bg:orange text-center radius:12 d-block"
                style={
                  { "--btnPaddingElem": "0.8rem 2.8rem" } as React.CSSProperties
                }
              >
                Ana Sayfa
              </Link>
            </div> */}
        </section>
      ) : null}

      <div className="row row-cols-1 gx-0 gy-4">
        <div className="col">
          <div className="d-none d-sm-flex justify-content-between align-items-center mb-2 pb-1">
            <p className="text-uppercase ff:regular lt:3 c:dark fs:12">ürün</p>
          </div>

          <div className="row row-cols-1 gx-0 gy-4">
            {loading ? (
              <>
                <AccordionSkeletonItem />
                <AccordionSkeletonItem />
              </>
            ) : (
              cart?.items.map((cartItem) => (
                <div key={cartItem.id} className="col">
                  <div className="bg:white radius:12 p-4 shadow:4">
                    <div className="row row-cols-1 row-cols-sm-2 justify-content-between align-items-center gx-0 gy-4 gy-sm-0">
                      <div className="col">
                        <div className="text-center text-sm-start">
                          <div className="row row-cols-1 gx-0 gy-2 text-center text-sm-start">
                            <div className="col d-block d-sm-none">
                              <p className="text-uppercase ff:regular lt:3 c:dark fs:12">
                                ürün
                              </p>
                            </div>
                            <div className="col">
                              <p className="c:dark ff:bold fs:16">
                                {!loading &&
                                  getProductInCart!(cartItem.productId)
                                    ?.displayBrandName}
                              </p>
                            </div>
                            <div className="col">
                              <p className="c:dark ff:light fs:16">
                                {!loading &&
                                  getProductInCart!(cartItem.productId)
                                    ?.displayProductForm}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="row row-cols-1 gx-0 gy-2 text-center text-sm-end">
                          <div className="col">
                            <div className="d-flex align-items-center justify-content-center justify-content-sm-end">
                              <p className="c:dark ff:bold fs:16 text-center text-sm-end">
                                Adet:&nbsp;{cartItem.quantity.amount}
                              </p>
                              <div>
                                <p
                                  className="bg:orange:2 radius:12 paddingElem fs:15 ff:bold c:orange ms-3 text-center d-inline-block"
                                  style={
                                    {
                                      "--paddingElem": "0.625rem 1rem",
                                    } as React.CSSProperties
                                  }
                                >
                                  MF {cartItem.quantity.freeAmount}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <AddQuickOrder
                              productId={cartItem.productId}
                              warehouse={{
                                wareHouseId: cart.warehouseId,
                                wareHouseName: cart.warehouseName,
                              }}
                              quantity={cartItem.quantity.amount}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
        <div className="col">
          <div className="d-flex justify-content-center justify-content-sm-between align-items-center mb-2 pb-1">
            <p className="text-uppercase ff:regular lt:3 c:dark fs:12">
              FİYAT & ORAN
            </p>
          </div>
          <div className="bg:white radius:12 p-4 shadow:4">
            <div className="row row-cols-1 g-0 c:dark fs:16 ff:regular flexTable">
              <div className="col flexTable__item">
                <div className="row row-cols-auto justify-content-between g-0">
                  <div className="col">
                    <p>Perakende Satış Fiyatı</p>
                  </div>
                  <div className="col">
                    <p>
                      <strong>
                        &#8378;&nbsp;
                        {cart && formatCurrency(cart.totalRetailPrice.value)}
                      </strong>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col flexTable__item">
                <div className="row row-cols-auto justify-content-between g-0">
                  <div className="col">
                    <p>Depo Satış Fiyatı</p>
                  </div>
                  <div className="col">
                    <p>
                      <strong>
                        &#8378;&nbsp;
                        {cart && formatCurrency(cart.totalSalePrice.value)}
                      </strong>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col flexTable__item">
                <div className="row row-cols-auto justify-content-between g-0">
                  <div className="col">
                    <p>Olası Kar Oranı</p>
                  </div>
                  <div className="col">
                    <p>
                      <strong>
                        &#37;&nbsp;
                        {cart && cart.totalGainPercentage.toFixed(2)}
                      </strong>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col flexTable__item">
                <div className="row row-cols-auto justify-content-between g-0">
                  <div className="col">
                    <p>Olası Kar Tutarı</p>
                  </div>
                  <div className="col">
                    <p>
                      <strong>
                        &#8378;&nbsp;
                        {cart && formatCurrency(cart.totalGainPrice.value)}
                      </strong>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col flexTable__item">
                <div className="row row-cols-auto justify-content-between g-0">
                  <div className="col">
                    <p>Mal Fazlası Kazanım</p>
                  </div>
                  <div className="col">
                    <p>
                      <strong> {cart && cart.totalMf}</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="d-flex justify-content-center justify-content-sm-between align-items-center mb-2 pb-1">
            <p className="ff:regular lt:3 c:dark fs:12">
              SEÇİLEN DEPO
            </p>
          </div>
          <div className="bg:white radius:12 p-4 shadow:4">
            <p className="c:dark fs:16 ff:regular">{cart?.warehouseName}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AccordionSummary;
