import React, { useState, useContext } from "react";
import iconMap from "@images/icon-map.svg";
import { Link } from "react-router-dom";
import { IWareHousesResult } from "types/wareHouseTypes";
import { AuthContextSite } from "contexts/AuthContext/AuthContext";
import { deleteWarehouses, setWarehouses } from "services/user.service";

interface IProps {
  item: IWareHousesResult;
}
const StoreItem = ({ item }: IProps) => {
  const [favorite, setFavorite] = useState(item.isFavorite);
  const { handleNotification } = useContext(AuthContextSite);
  const [loading, setLoading] = useState(false);

  const handleFavorite = async () => {
    if (favorite) {
      try {
        setLoading(true);
        await deleteWarehouses(item.id);
        setFavorite(false);
        handleNotification!("");
      } catch (error) {
      } finally {
        setLoading(false);
      }
    } else {
      try {
        setLoading(true);
        await setWarehouses(item.id);
        setFavorite(true);
        handleNotification!("addStoreToFavorites");
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="col">
      <div className="h-100">
        <div className="bg:white radius:12 p-3 shadow:4 border:1 w-100 h-100">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <div className="ms-auto">
              <button
                onClick={handleFavorite}
                disabled={loading}
                aria-label="star button"
                className="d-block"
                type="button"
              >
                <div
                  className={`starButton ${
                    favorite ? "starButtonStrokeColor" : ""
                  }`}
                >
                  <svg
                    className={`starButton__main ${
                      favorite ? "starButtonStroke" : ""
                    } `}
                    viewBox="0 0 512 512"
                    width="100%"
                  >
                    <path d="M274.17 43.2767L331.545 158.902C332.85 161.837 334.902 164.379 337.498 166.275C340.094 168.171 343.143 169.355 346.34 169.708L472.998 188.439C476.666 188.909 480.123 190.412 482.967 192.77C485.811 195.129 487.924 198.247 489.059 201.759C490.194 205.272 490.305 209.035 489.378 212.608C488.451 216.181 486.524 219.417 483.824 221.938L392.529 312.349C390.199 314.522 388.45 317.242 387.441 320.261C386.433 323.281 386.197 326.505 386.755 329.638L408.767 456.79C409.404 460.444 409.001 464.203 407.604 467.639C406.207 471.075 403.872 474.051 400.864 476.228C397.856 478.405 394.296 479.695 390.59 479.952C386.884 480.209 383.179 479.423 379.899 477.682L265.87 417.528C262.95 416.097 259.741 415.353 256.488 415.353C253.235 415.353 250.026 416.097 247.106 417.528L133.077 477.682C129.796 479.423 126.092 480.209 122.386 479.952C118.68 479.695 115.12 478.405 112.112 476.228C109.104 474.051 106.769 471.075 105.372 467.639C103.975 464.203 103.572 460.444 104.209 456.79L126.221 328.198C126.779 325.064 126.543 321.84 125.534 318.821C124.526 315.801 122.777 313.081 120.447 310.908L28.0696 221.938C25.3366 219.348 23.4146 216.022 22.5364 212.364C21.6583 208.706 21.8616 204.872 23.1219 201.327C24.3821 197.782 26.6451 194.677 29.6368 192.39C32.6285 190.102 36.2205 188.73 39.9776 188.439L166.636 169.708C169.833 169.355 172.882 168.171 175.477 166.275C178.073 164.379 180.126 161.837 181.431 158.902L238.806 43.2767C240.369 39.9091 242.864 37.058 245.997 35.0598C249.13 33.0617 252.77 32 256.488 32C260.206 32 263.846 33.0617 266.979 35.0598C270.112 37.058 272.607 39.9091 274.17 43.2767Z" />
                  </svg>
                  <svg
                    className={`starButton__background ${
                      favorite ? "starButtonFade" : ""
                    }`}
                    viewBox="0 0 512 512"
                    width="100"
                  >
                    <path d="M274.17 43.2767L331.545 158.902C332.85 161.837 334.902 164.379 337.498 166.275C340.094 168.171 343.143 169.355 346.34 169.708L472.998 188.439C476.666 188.909 480.123 190.412 482.967 192.77C485.811 195.129 487.924 198.247 489.059 201.759C490.194 205.272 490.305 209.035 489.378 212.608C488.451 216.181 486.524 219.417 483.824 221.938L392.529 312.349C390.199 314.522 388.45 317.242 387.441 320.261C386.433 323.281 386.197 326.505 386.755 329.638L408.767 456.79C409.404 460.444 409.001 464.203 407.604 467.639C406.207 471.075 403.872 474.051 400.864 476.228C397.856 478.405 394.296 479.695 390.59 479.952C386.884 480.209 383.179 479.423 379.899 477.682L265.87 417.528C262.95 416.097 259.741 415.353 256.488 415.353C253.235 415.353 250.026 416.097 247.106 417.528L133.077 477.682C129.796 479.423 126.092 480.209 122.386 479.952C118.68 479.695 115.12 478.405 112.112 476.228C109.104 474.051 106.769 471.075 105.372 467.639C103.975 464.203 103.572 460.444 104.209 456.79L126.221 328.198C126.779 325.064 126.543 321.84 125.534 318.821C124.526 315.801 122.777 313.081 120.447 310.908L28.0696 221.938C25.3366 219.348 23.4146 216.022 22.5364 212.364C21.6583 208.706 21.8616 204.872 23.1219 201.327C24.3821 197.782 26.6451 194.677 29.6368 192.39C32.6285 190.102 36.2205 188.73 39.9776 188.439L166.636 169.708C169.833 169.355 172.882 168.171 175.477 166.275C178.073 164.379 180.126 161.837 181.431 158.902L238.806 43.2767C240.369 39.9091 242.864 37.058 245.997 35.0598C249.13 33.0617 252.77 32 256.488 32C260.206 32 263.846 33.0617 266.979 35.0598C270.112 37.058 272.607 39.9091 274.17 43.2767Z" />
                  </svg>
                </div>
              </button>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <div
                className="widthElem heightElem iconSize:20 bg:orange:2 radius:full d-flex align-items-center justify-content-center"
                style={
                  {
                    "--widthElem": "2.875rem",
                    "--heightElem": "2.875rem",
                  } as React.CSSProperties
                }
              >
                <img src={iconMap} alt="" />
              </div>
            </div>
            <div className="flex-grow-1 ms-3">
              <p className="c:dark ff:regular fs:14">
                {item.name}
              </p>
            </div>
          </div>
          <div className="d-flex justify-content-end mt-3">
            <Link
              to={`/depolar/${item.id}`}
              aria-label="detaylı bilgi"
              className="buttonStyle buttonStyle--light buttonStyle--paddingElem c:dark ff:bold fs:14 bg:white shadow:4 d-inline-block text-center radius:12"
              style={
                { "--btnPaddingElem": "0.5rem 1.8rem" } as React.CSSProperties
              }
            >
              Detaylı Bilgi
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoreItem;

