import { useContext } from "react";
import iconTrashList from "@images/icon-trash-list-orange.svg";
import { CartContext } from "contexts/CartContext/CartContext";
import { BodyContext } from "contexts/BodyContext/BodyContext";

const CartHead = () => {
  const { cart } = useContext(CartContext);
  const { handlePopup } = useContext(BodyContext);

  return (
    <section className="mb-3 pb-1">
      <div className="row row-cols-auto justify-content-between align-items-center g-0">
        <div className="col">
          <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
            <p className="c:dark ff:bold fs:24">Sepetim</p>
            <p className="ms-2 ff:regular c:dark fs:16">
              ({cart?.quantity} ürün)
            </p>
          </div>
        </div>
        <div className="col">
          <button
            onClick={() => handlePopup("clear", {})}
            className="iconLinkBtn"
            aria-label="sil butonu"
            type="button"
          >
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0 iconSize:16">
                <img src={iconTrashList} alt="" />
              </div>
              <div className="flex-grow-1 ms-2">
                <p className="c:orange ff:bold fs:16">Sepeti Boşalt</p>
              </div>
            </div>
          </button>
        </div>
      </div>
    </section>
  );
};

export default CartHead;
