import { Outlet } from "react-router-dom";
import PublicProvider from "contexts/PublicContext/PublicProvider";
import HelmetComponent from "@components/General/Helmet";

const PublicLayout = () => {
  return (
    <>
      <PublicProvider>
        <>
          <HelmetComponent />
          <Outlet />
        </>
      </PublicProvider>
    </>
  );
};

export default PublicLayout;
