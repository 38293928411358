import { useContext, useEffect, useState } from "react";
import StoresHeadBreadCrumb from "@components/Stores/StoresHeadBreadCrumb";
import { Link } from "react-router-dom";
import useApi from "hooks/useApi";
import { IWareHouses, IWareHousesResult } from "types/wareHouseTypes";
import FavoriteStoreList from "@components/Stores/FavoriteStoreList";
import { getWarehouses } from "services/user.service";
import { PublicContext } from "contexts/PublicContext/PublicContext";
/* eslint-disable react-hooks/exhaustive-deps */
const FavoriteStores = () => {
  const { loading, handleApi, data, success } = useApi({ loading: true });
  const [limit, setLimit] = useState(12);

  const { setSiteTitle } = useContext(PublicContext);
  const [wareHouses, setWareHouses] = useState<IWareHouses>();
  const [removedWareHouseId, setRemovedWareHouseId] = useState<string | null>();
  const [filteredWareHouses, setFilteredWareHouses] =
    useState<IWareHousesResult[]>();

  useEffect(() => {
    setSiteTitle("Favori Depolarım");
  }, []);

  useEffect(() => {
    handleApi(getWarehouses, {
      limit: limit,
      offset: 0,
      sort: "name",
    });
    return () => {
      setRemovedWareHouseId(null);
    };
  }, [limit, removedWareHouseId]);

  useEffect(() => {
    if (success) {
      setWareHouses(data);
      setFilteredWareHouses(data?.result);
    }
  }, [success]);

  return (
    <>
      <section className="bg:white shadow:9 pt-4">
        <div className="container">
          <section className="breadcrumb pb-4">
            <ul className="c:dark fs:14 lt:3">
              <li>
                <Link to="/anasayfa">Anasayfa</Link>
              </li>
              <li>
                <Link to="/depolar/favori-depolarim">Favori Depolarim</Link>
              </li>
            </ul>
          </section>
          <StoresHeadBreadCrumb />
        </div>
      </section>

      <section className="mt-4 mt-sm-5 mb-5">
        <div className="container">
          {/* <!-- <h1 class="c:dark ff:bold fs:24 mb-3 pb-1 text-center text-lg-start">Turuncu Ürünler</h1> --> */}
          <section className="contentSearch mb-4">
            <div className="row align-items-center gx-0 gy-3 gy-lg-0">
              <div className="col-12 col-md-8 col-lg-9">
                <h1 className="c:dark ff:bold fs:24 text-center text-md-start">
                  Favori Depolarım
                </h1>
              </div>
            </div>
          </section>
          <FavoriteStoreList
            wareHouses={wareHouses}
            filteredWareHouses={filteredWareHouses}
            setRemovedWareHouseId={setRemovedWareHouseId}
            limit={limit}
            setLimit={setLimit}
            loading={loading}
          />
        </div>
      </section>
    </>
  );
};

export default FavoriteStores;
