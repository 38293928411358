import { createContext } from "react";

export interface ISettings {
  name: string;
  value: string;
  description: string;
}

interface IQuickOrderContext {
  loading?: boolean;
  success: boolean;
  error: string;
  settings: ISettings[];
  siteTitle: string;
  setSiteTitle: React.Dispatch<React.SetStateAction<string>>;
}

export const PublicContext = createContext<IQuickOrderContext>({
  loading: false,
  success: false,
  error: "",
  settings: [],
  setSiteTitle: () => {},
  siteTitle: "",
});
