import { useState } from "react";

const useSecondApi = () => {
  const [secondData, setSecondData] = useState<any>(null);
  const [secondError, setSecondError] = useState("");
  const [secondLoading, setSecondLoading] = useState(false);
  const [secondSuccess, setSecondSuccess] = useState(false);

  const handleSecondApi = async (service: any, payload: any) => {
    setSecondSuccess(false);
    setSecondLoading(true);
    setSecondError("");
    try {
      const response = await service(payload);
      setSecondData(response);
      setSecondSuccess(true);
      setSecondError("");
    } catch (error: any) {
      setSecondSuccess(false);
      if (error?.code === "ERR_NETWORK") {
        setSecondError(
          "Servislerimizde bir hata oluştu. Lütfen daha sonra tekrar deneyiniz."
        );
      }
      if (error?.response?.status && error.response.status === 500) {
        if (error.response.data.messages) {
          setSecondError(error.response.data.messages[0]);
        } else if (error.response.data.message) {
          setSecondError(error.response.data.message);
        }
      } else if (error?.response?.status && error?.response?.status === 404) {
        setSecondError("Error. Not Found");
      } else {
        if (error?.response?.data?.message) {
          setSecondError(error.response.data.message);
        } else {
          setSecondError("Bilinmeyen bir hata oluştu.");
        }
      }
    } finally {
      setSecondLoading(false);
    }
  };

  return {
    secondData,
    secondError,
    secondLoading,
    handleSecondApi,
    secondSuccess,
    setSecondError,
  };
};

export default useSecondApi;
