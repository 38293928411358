import React, { useState, useContext, useEffect } from "react";
import iconInfo from "@images/icon-info.svg";
import { z } from "zod";
import { glnValidation } from "validations/AuthValidations";
import useApi from "hooks/useApi";
import { userGlnUpdate } from "services/user.service";
import { formatInput } from "utility/FormatInput";
import { BodyContext } from "contexts/BodyContext/BodyContext";
import Field from "@components/Form/Field";
import { AuthContextSite } from "contexts/AuthContext/AuthContext";
import classNames from "classnames";

const GlnPopup = () => {
  const [error, setError] = useState("");
  const [gln, setGln] = useState("");

  const { success, error: apiError, loading, handleApi } = useApi();
  const { handlePopup } = useContext(BodyContext);
  const { handleLogin, user } = useContext(AuthContextSite);

  const [touched, setTouched] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const handleChange = (event: any) => {
    setError("");
    const { value } = event.target;
    if (touched === false) setTouched(true);
    setGln(value);

    const result = glnValidation.safeParse(value);

    if (result.success) {
      setError("");
      setDisabled(false);
    } else {
      setError(result.error.issues[0].message);
      setDisabled(true);
    }
  };

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setError("");

    try {
      glnValidation.parse(gln);
      await handleApi(userGlnUpdate, {
        gln: gln,
      });
      handleLogin({ ...user, gln: gln });
    } catch (err) {
      if (err instanceof z.ZodError) {
        setError(err.issues[0].message);
      }
    }
  };

  useEffect(() => {
    if (success) {
      handlePopup("", { accepted: true });
    }
  }, [success, handlePopup]);

  return (
    <section className="popupWrapper animate__animated animate__fadeIn animate__faster d-ndone">
      <div className="popupWrapper__content bg:white radius:20">
        <div className="p-4 p-sm-5">
          <div className="formElement">
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="row row-cols-1 g-0 gy-3 gy-sm-4">
                <div className="col">
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="me-2">
                      <img src={iconInfo} alt="" />
                    </div>
                    <div>
                      <p className="c:red fs:16">Lütfen GLN kodunuzu girin.</p>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <Field
                    type="text"
                    name="gln-number"
                    label="GLN Kodu"
                    value={gln}
                    errors={error}
                    loading={loading}
                    onChange={handleChange}
                    maxLength={13}
                    pattern={formatInput}
                  />
                </div>
                <div className="col">
                  {apiError && (
                    <div
                      className="alert  bg:red:2 p-3 radius:12 mb-3 mb-sm-4"
                      style={
                        {
                          "--alert-borderColor": "rgba(243, 52, 52, 0.22)",
                        } as React.CSSProperties
                      }
                    >
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          <img src={iconInfo} alt="" />
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <p className="c:red lt:2 ff:regular fs:12">
                            {apiError}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  <button
                    aria-label="onayla"
                    onClick={handleSubmit}
                    disabled={disabled}
                    className={classNames(
                      "buttonStyle buttonStyle--icon buttonStyle--submit c:white ff:bold fs:16 bg:gradient:orange d-block w-100 text-center radius:12",
                      {
                        disabledBtn: disabled,
                        spinnerElem: loading,
                      }
                    )}
                    type="submit"
                    style={{ "--form-height": "3rem" } as React.CSSProperties}
                  >
                    Onayla
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GlnPopup;
