import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useApi from "hooks/useApi";
import { Result } from "types/productTypes";
import Favorites from "@components/Favorites/Favorites";
import PopupApprove from "@components/General/PopUps/PopupApprove";
import iconHeartFill from "@images/icon-heart-fill.svg";
import iconSuccessTick from "@images/icon-success-tick.svg";
import {
  deleteFavoriteProducts,
  getFavoriteProducts,
} from "services/user.service";
import { BodyContext } from "contexts/BodyContext/BodyContext";
import { PublicContext } from "contexts/PublicContext/PublicContext";

const MyFavorites = () => {
  const {
    data,
    handleApi,
    loading,
    error: apiError,
  } = useApi({ loading: true });
  const [limit, setLimit] = useState(8);
  const [productName, setProductName] = useState("");
  const [productId, setProductId] = useState("");
  const [popupOpen, setPopupOpen] = useState(false);
  const { editProducts } = useContext(BodyContext);
  const { setSiteTitle } = useContext(PublicContext);
/* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setSiteTitle("Favorilerim");
  }, []);

  const handlePopup = (productName: string, productId: string) => {
    setPopupOpen((prev) => !prev);
    setProductName(productName);
    setProductId(productId);
  };

  useEffect(() => {
    handleApi(getFavoriteProducts, {});

    return () => {};
  }, [limit]);

  const products: Result[] = data && data;

  const removeFavorites = async () => {
    try {
      await deleteFavoriteProducts({ product_id: productId });
      handleApi(getFavoriteProducts, {});
      editProducts(productId, false);
      handlePopup("", "");
    } catch (error) {}
  };

  return (
    <>
      <section className="breadcrumb my-4 my-md-5">
        <div className="container">
          <ul className="c:dark fs:14 lt:3">
            <li>
              <Link to="/anasayfa">Anasayfa</Link>
            </li>
            <li>
              <Link to="/hesabim">Hesabım</Link>
            </li>
            <li>
              <Link to="/hesabim/favorilerim">Favorilerim</Link>
            </li>
          </ul>
        </div>
      </section>
      <section className="mb-4 pb-0 pb-md-2">
        <div className="container">
          <div className="row g-0 align-items-center justify-content-between">
            <div className="col-4 d-none d-md-block"></div>
            <div className="col-auto col-md-4">
              <div className="d-flex justify-content-start justify-content-md-center">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <img src={iconHeartFill} alt="" />
                  </div>
                  <div className="flex-grow-1 ms-2">
                    <h1 className="c:dark ff:bold fs:24">Favorilerim</h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-auto col-md-4">
              <p className="ff:regular fs:16 c:dark text-end">
                Ürünler&nbsp;
                {products && <strong>({products.length})</strong>}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="mb-5">
        <div className="container">
          <Favorites
            products={products}
            popupOpen={popupOpen}
            handlePopup={handlePopup}
            loading={loading}
          />

          {products && products.length > 8 && (
            <div className="d-flex justify-content-center mt-4 pt-0 pt-md-2">
              <button
                onClick={() => setLimit((prev) => prev + 4)}
                aria-label="daha fazla"
                // disabled={products && products.total < products.limit}
                className="buttonStyle buttonStyle--light buttonStyle--paddingElem c:dark ff:bold fs:16 bg:white shadow:4 d-inline-block text-center radius:16"
                style={
                  { "--btnPaddingElem": "1rem 4rem" } as React.CSSProperties
                }
              >
                Daha Fazla
              </button>
            </div>
          )}
        </div>
      </section>
      {popupOpen && (
        <PopupApprove
          productName={productName}
          popupOpen={popupOpen}
          setPopupOpen={setPopupOpen}
          approve={removeFavorites}
          popupText="favorilerimden kaldırmak istediğinize emin misiniz?"
          icon={iconSuccessTick}
          apiError={apiError}
        />
      )}
    </>
  );
};

export default MyFavorites;
