import BlogCard from "../BlogCard";
import LinkMore from "../LinkMore";
import { IBlog } from "views/Blog";

const BlogSection = ({ blog }: { blog: IBlog }) => {
  return (
    <>
      {blog.posts ? (
        <section className="mb-5 py-0 py-sm-4">
          <div className="container">
            <h1 className="c:dark ff:bold fs:24 text-center text-lg-start mb-4 pb-0 pb-sm-2">
              {blog.name}
            </h1>

            <div className="row row-cols-1 row-cols-lg-3 gx-0 gx-lg-3 gy-3">
              {blog &&
                blog.posts &&
                blog.posts.map((item) => (
                  <BlogCard key={item.id} color={item.blogColor} post={item} />
                ))}
            </div>
            <div className="d-flex justify-content-center mt-4 pt-0 pt-sm-2">
              <LinkMore url={`/blog/${blog.slug}`} />
            </div>
          </div>
        </section>
      ) : null}
    </>
  );
};

export default BlogSection;
