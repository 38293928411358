const getBaseUrl = () => {
  switch (process.env.REACT_APP_NODE_ENV) {
    case "dev":
      return process.env.REACT_APP_DEV_API_BASE_URL;
    case "uat":
      return process.env.REACT_APP_UAT_API_BASE_URL;
    case "test":
      return process.env.REACT_APP_TEST_API_BASE_URL;
    default:
      return process.env.REACT_APP_PROD_API_BASE_URL;
  }
};

const baseUrl = getBaseUrl();

export const endpoints: any = {
  baseUrl: baseUrl,
  products: `${baseUrl}/product`,
  user: `${baseUrl}/user`,
  shoppingCart: `${baseUrl}/shoppingCart`,
  account: `${baseUrl}/account`,
};
