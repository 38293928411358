import { Link } from "react-router-dom";

const Head = () => {
  return (
    <>
      <section className="breadcrumb mt-4 mt-sm-5">
        <div className="container">
          <ul className="c:dark fs:14 lt:3">
            <li>
              <Link to="/anasayfa">Anasayfa</Link>
            </li>
            <li>
              <Link to="/turuncu-donemsel-kampanyalar">
                Turuncu Dönemsel Kampanyalar
              </Link>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default Head;
