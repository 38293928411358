import { endpoints } from "constants/endpoints";
import api from "utility/request";

export const getNotifications = async (): Promise<any> => {
  const response = (await api.get(`${endpoints.baseUrl}/notifications`)).data;
  return response;
};
export const removeAllNotifications = async (): Promise<any> => {
  const response = (await api.delete(`${endpoints.baseUrl}/notifications/all`))
    .data;
  return response;
};

export const readAllNotifications = async (): Promise<any> => {
  const response = (await api.put(`${endpoints.baseUrl}/notifications/all`))
    .data;
  return response;
};

export const readSingleNotification = async (id: string): Promise<any> => {
  const response = (await api.put(`${endpoints.baseUrl}/notifications/${id}`))
    .data;
  return response;
};
export const deleteSingleNotification = async (id: string): Promise<any> => {
  const response = (
    await api.delete(`${endpoints.baseUrl}/notifications/${id}`)
  ).data;
  return response;
};
