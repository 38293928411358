import useApi from "hooks/useApi";
import React, { useContext, useReducer, useState } from "react";
import { createUser } from "services/user.service";
import infoicon from "@images/icon-info.svg";
import { z } from "zod";
import StepArrrows from "./StepArrrows";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { personalInformationValidations } from "validations/AuthValidations";
import { useAuth } from "hooks/useAuth";
import Field from "@components/Form/Field";
import ButtonContinue from "./ButtonContinue";
import {
  IRegisterState,
  RegisterContext,
} from "contexts/RegisterContext/RegisterContext";
import { genderOptions } from "utility/genderOptions";
import { formatInput } from "utility/FormatInput";
import Conditions from "./Conditions";
import PopupConditions from "./PopupConditions";
import { IUser } from "hooks/useUser";
import { PublicContext } from "contexts/PublicContext/PublicContext";
import {notificationTexts} from "../../../utility/notificationTexts";
import { useLocalStorage } from '../../../hooks/useLocalStorage';

const initialErrorState = {
  email: "",
  password: "",
  rePassword: "",
  gln: "",
  firstName: "",
  lastName: "",
  gender: "",
  mobile: "",
  termOfUse: "",
  privacyPolicy: "",
  acceptedPolicies: [],
};

export interface IForm {
  email: string;
  firstName: string;
  lastName: string;
  gender: string;
  mobile: string;
  password: string;
  rePassword: string;
  privacyPolicy: boolean;
  termOfUse: boolean;
  acceptedPolicies: Array<string>;
}

const Step1 = () => {
  const [errors, setErrors] = useState(initialErrorState);
  const [disabled, setDisabled] = useState(true);
  const [touched, setTouched] = useState(false); // Track whether the inputs have changed

  const [popupOpen, setPopupOpen] = useState(false);
  const [customClass, setCustomClass] = useState("d-none");
  const [activePolicy, setActivePolicy] = useState<any>();
  const [activePopup, setActivePopup] = useState("");
  const [isReaded, setIsReaded] = useState(false);
  /* eslint-disable react-hooks/exhaustive-deps */
  const navigate = useNavigate();
  const {
    success,
    error: apiError,
    handleApi,
    data,
    loading,
    setError,
  } = useApi();

  const validations = z.object(personalInformationValidations);

  const { setItem } = useLocalStorage();

  const { user, setUser, setToken } = useContext(
    RegisterContext
  ) as IRegisterState;

  const initialForm: IForm = {
    email: "",
    firstName: "",
    lastName: "",
    gender: "",
    mobile: "",
    password: "",
    rePassword: "",
    termOfUse: false,
    privacyPolicy: false,
    acceptedPolicies: [],
  };

  const [state, dispatch] = useReducer(
    (state: IForm, updates: any) => ({ ...state, ...updates }),
    user
      ? {
          email: user.email ?? "",
          firstName: user.firstName ?? "",
          lastName: user.lastName ?? "",
          gender: user.gender ?? "",
          mobile: user.phone ?? "",
          termOfUse: !user.forceTermOfUse ?? false,
          privacyPolicy: !user.forcePrivacyPolicy ?? false,
          password: "",
          rePassword: "",
          acceptedPolicies: [],
        }
      : initialForm
  );

  const { setSiteTitle } = useContext(PublicContext);
  useEffect(() => {
    setSiteTitle("Bilgilerinizi Girin");
  }, []);

  useEffect(() => {
    if (user) {
      dispatch({
        email: user.email ?? "",
        firstName: user.firstName ?? "",
        lastName: user.lastName ?? "",
        gender: user.gender ?? "",
        mobile: user.mobile ?? "",
      });
    }
  }, [user]);

  const handleChange = (event: any) => {
    setError("");
    const { name, value } = event.target;
    if (!touched) setTouched(true);

    dispatch({ [name]: value });

    const validation = personalInformationValidations[name];

    const result = validation.safeParse(value);

    if (result.success) {
      setErrors((prev) => {
        return { ...prev, [name]: "" };
      });
    } else {
      setErrors((prev) => {
        return { ...prev, [name]: result.error.issues[0].message };
      });
      setDisabled(true);
    }
  };

  const handleClick = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    // await handleApi(checkUserEmail, email);
    await handleApi(createUser, {
      email: state.email,
      password: state.password,
      firstName: state.firstName,
      lastName: state.lastName,
      mobile: state.mobile,
      gender: state.gender,
      acceptedPolicies: state.acceptedPolicies,
    });
  };

  const { login: LoginContext } = useAuth();

  useEffect(() => {
    if (success) {
      if (data.user && data.token) {
        const newUser: IUser = {
          ...data.user,
          gln: user?.gln,
          company: user?.company,
          city: user?.city,
          town: user?.town,
          address: user?.address,
        };
        setUser(newUser);
        setToken(data.token);
        setItem("accessToken", data.token);

        navigate(`/kayit-ol/adim-2`, { state: { userState: data.user } });
      } else {
        const user: any = { email: state.email, username: state.email };
        LoginContext(user);

        navigate(`/kayit-ol/adim-2`);
      }
    }
  }, [success, data, state]);

  useEffect(() => {
    if (touched) {
      const result = validations.safeParse(state);

      if (result.success) {
        if (state.password !== state.rePassword) {
          setErrors((prev) => {
            return {
              ...prev,
              password: "Şifreler eşleşmiyor.",
              rePassword: "Şifreler eşleşmiyor",
            };
          });
          setDisabled(true);
        } else {
          setErrors({ ...initialErrorState });
          setDisabled(false);
        }
      } else {
        if (
          result.error.issues.find((item) => item.path[0] === "password") ||
          result.error.issues.find((item) => item.path[0] === "rePassword")
        ) {
        } else {
          if (state.password !== state.rePassword) {
            setErrors((prev) => {
              return {
                ...prev,
                password: "Şifreler eşleşmiyor.",
                rePassword: "Şifreler eşleşmiyor",
              };
            });
          } else {
            setErrors((prev) => {
              return {
                ...prev,
                password: "",
                rePassword: "",
              };
            });
          }
        }

        setDisabled(true);
      }
    }
  }, [touched, state]);

  const handlePopup = () => {
    setCustomClass("");
    setPopupOpen(false);
    setIsReaded(false);
    setTimeout(() => {
      setCustomClass("d-none");
    }, 450);
  };

  const handleAccept = () => {
    setError("");
    let acceptedPolicies = state.acceptedPolicies;
    acceptedPolicies.push(activePolicy.id);
    acceptedPolicies = acceptedPolicies.filter(
      (item: string, index: number) => acceptedPolicies.indexOf(item) === index
    );
    if (activePopup === "privacyPolicy") {
      dispatch({
        privacyPolicy: !state.privacyPolicy,
        acceptedPolicies: acceptedPolicies,
      });
    } else if (activePopup === "termOfUse") {
      dispatch({
        termOfUse: !state.termOfUse,
        acceptedPolicies: acceptedPolicies,
      });
    }
    handlePopup();
  };

  return (
    <>
      <section className="w-100">
        <div className="container-fluid">
          <div className="row g-0 row-cols-1 row-cols-lg-2 row-cols-xl-3 justify-content-center">
            <div className="col">
              <div className="formElement ">
                <div className="mb-4">
                  <div className="row align-items-center row-cols-1 row-cols-sm-auto flex-column-reverse flex-sm-row justify-content-between g-0">
                    <div className="col">
                      <h1 className="ff:light c:dark fs:28 d-flex align-items-center justify-content-center">
                        Bilgilerinizi Girin
                      </h1>
                    </div>
                    <StepArrrows step={1} />
                  </div>
                </div>
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="formElement__block bg:white radius:12 shadow:3 p-3 p-sm-4">
                    <div className="p-2">
                      <div className="row row-cols-1 g-0 gy-3 gy-sm-4">
                        <div className="col">
                          <Field
                            type="text"
                            name="firstName"
                            label="ad"
                            value={state?.firstName}
                            errors={errors.firstName}
                            loading={loading}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col">
                          <Field
                            type="text"
                            name="lastName"
                            label="soyad"
                            value={state?.lastName}
                            errors={errors.lastName}
                            loading={loading}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col">
                          <Field
                            type="select"
                            name="gender"
                            label="Cinsiyet"
                            value={state?.gender}
                            options={genderOptions}
                            errors={errors.gender}
                            loading={loading}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col">
                          <Field
                            type="email"
                            name="email"
                            label="e-mail"
                            value={state.email}
                            errors={errors.email}
                            loading={loading}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col">
                          <Field
                            type="text"
                            name="mobile"
                            label="cep telefon numarası"
                            value={state?.mobile}
                            placeholder="5XX XXX XX XX"
                            errors={errors.mobile}
                            loading={loading}
                            onChange={handleChange}
                            minLength={10}
                            maxLength={10}
                            pattern={formatInput}
                          />
                        </div>
                        <div className="col">
                          <Field
                            type="password"
                            name="password"
                            label="şifre"
                            value={state.password}
                            onChange={handleChange}
                            errors={errors.password}
                            loading={loading}
                          />
                        </div>
                        <div className="col">
                          <Field
                            type="password"
                            name="rePassword"
                            label="Şifreyi doğrula"
                            value={state.reEmail}
                            errors={errors.rePassword}
                            loading={loading}
                            onChange={handleChange}
                          />
                        </div>

                        <div className="col">
                          <ul className="bulletList ff:regular fs:12 c:dark">
                            <li>{notificationTexts.passwordLength}</li>
                            <li>{notificationTexts.passwordRules}</li>
                          </ul>
                        </div>

                        <Conditions
                          state={state}
                          dispatch={dispatch}
                          setActivePopup={setActivePopup}
                          setPopupOpen={setPopupOpen}
                          setActivePolicy={setActivePolicy}
                        />

                        <div className="col">
                          {apiError &&
                          !errors.email.length &&
                          !errors.rePassword.length ? (
                            <div
                              className="alert  bg:red:2 p-3 radius:12 mb-3 mb-sm-4"
                              style={
                                {
                                  "--alert-borderColor":
                                    "rgba(243, 52, 52, 0.22)",
                                } as React.CSSProperties
                              }
                            >
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                  <img src={infoicon} alt="" />
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <p
                                    id="apierror"
                                    className="c:red lt:2 ff:regular fs:12"
                                  >
                                    {apiError && apiError}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <ButtonContinue
                            disabled={disabled}
                            loading={loading}
                            text="Devam Et"
                            onClick={handleClick}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className={`popupWrapper ${
          popupOpen
            ? "animate__animated animate__fadeIn animate__faster"
            : `animate__animated animate__fadeOut animate__faster ${customClass}`
        }`}
      >
        {popupOpen ? (
          <PopupConditions
            activePopup={activePopup}
            activeText={activePolicy.content}
            isReaded={isReaded}
            popupOpen={popupOpen}
            loading={loading}
            setIsReaded={setIsReaded}
            state={state}
            handleAccept={handleAccept}
            handlePopup={handlePopup}
          />
        ) : null}
      </section>
    </>
  );
};

export default Step1;
