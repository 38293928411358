import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import Sss from "@components/TuruncuHat/Sss";
import { PublicContext } from "contexts/PublicContext/PublicContext";
import useApi from "hooks/useApi";
import { getFaqs } from "services/faq.service";
/* eslint-disable react-hooks/exhaustive-deps */
const SSS = () => {
  const { handleApi, success, data } = useApi();
  const { setSiteTitle } = useContext(PublicContext);
  useEffect(() => {
    setSiteTitle("Sıkça Sorulan Sorular");
  }, []);

  useEffect(() => {
    handleApi(getFaqs, {});
  }, []);

  return (
    <>
      <section className="breadcrumb my-4 my-md-5">
        <div className="container">
          <ul className="c:dark fs:14 lt:3">
            <li>
              <Link to="/anasayfa">Anasayfa</Link>
            </li>
            <li>
              <Link to="/hesabim">Hesabım</Link>
            </li>
            <li>
              <Link to="/sss">Sıkça Sorulan Sorular</Link>
            </li>
          </ul>
        </div>
      </section>

      <section className="mb-5">
        <div className="container">
          <h1 className="c:dark ff:bold fs:24 mb-4 text-center text-lg-start">
            Sıkça Sorulan Sorular
          </h1>
          {success && <Sss data={data} />}
        </div>
      </section>
    </>
  );
};

export default SSS;
