let errorMessage: string;
let errorCode: string;
export const ErrorHandler = (error: any) => {
  if (error?.code === "ERR_NETWORK") {
    errorMessage =
      "Servislerimizde bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.";
  }
  if (error?.response) {
    if (error?.response?.status && error.response.status === 500) {
      if (error.response.data.messages) {
        errorMessage =
          "Servislerimizde bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.";
      } else if (error.response.data.message) {
        errorMessage =
          "Servislerimizde bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.";
      }
    } else if (error?.response?.status && error?.response?.status === 404) {
      if (error?.response?.data?.message) {
        errorMessage = error?.response?.data?.message;
      } else {
        errorMessage = "Error. Not Found";
      }
    } else if (error?.response?.data?.errors) {
      const keys = Object.keys(error?.response?.data?.errors);
      let errors: any = [];
      keys.map((key) => errors.push(error?.response?.data?.errors[key]));
      errorMessage = errors;
    } else {
      if (
        error.response.data.code === "HasPreOrderInShoppingCart" ||
        error.response.data.code === "HasNonPreOrderInShoppingCart" ||
        error.response.data.code === "WouldYouLikeToContinueCampaignUseLimit"
      ) {
        errorCode = error.response.data.code;
        errorMessage = error.response.data.message;
      } else if (error?.response?.data?.message) {
        errorMessage = error.response.data.message;
      } else {
        errorMessage = "Bilinmeyen bir hata oluştu.";
      }
    }
  }
  return { errorMessage, errorCode };
};
