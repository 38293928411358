import React, { useContext, useEffect, useState } from "react";
import useApi from "hooks/useApi";
import { Link } from "react-router-dom";
import { getDemands } from "services/user.service";
import { convertDatetime } from "utility/formatDate";
import Skeleton from "react-loading-skeleton";
import NoData from "@components/General/NoData";
import { PublicContext } from "contexts/PublicContext/PublicContext";

export interface IDemands {
  code: null | string;
  id: string;
  message: string;
  subject: string;
  date: string;
  status: string;
}
/* eslint-disable react-hooks/exhaustive-deps */
const SoruVeTalepler = () => {
  const { data, success, handleApi, loading } = useApi({ loading: true });

  const { setSiteTitle } = useContext(PublicContext);

  useEffect(() => {
    setSiteTitle("Soru ve Taleplerim");
  }, []);

  const [activeItem, setActiveItem] = useState("");

  useEffect(() => {
    handleApi(getDemands, {});
  }, []);

  const handleActiveItem = (itemId: string) => {
    if (itemId === activeItem) {
      setActiveItem("");
    } else {
      setActiveItem(itemId);
    }
  };

  const demands: IDemands[] = success && data;

  const handleStatusColor = (status: string) => {
    let activeColor;

    switch (status) {
      case "Talep Alındı":
        activeColor = "bg:orange";
        break;
      case "Çözüldü":
        activeColor = "bg:green";
        break;
      case "İnceleniyor":
        activeColor = "bg:yellow:4";
        break;
      default:
        activeColor = "";
        break;
    }
    return activeColor;
  };

  const SkeletonItem = () => (
    <li className={`collapseLayout__item `}>
      <h1
        className="collapseLayout__thumb d-flex align-items-center py-3 py-sm-4 px-3 px-sm-4 pe-5 bg:white radius:12 borderStyle shadow:4"
        style={
          {
            "--gsk-border-width": "0.031rem",
            "--gsk-border-color": "#d7d7d7",
          } as React.CSSProperties
        }
      >
        <div className="w-100 pe-4">
          <div className="row row-cols-1 row-cols-md-auto flex-column-reverse flex-md-row gx-0 gy-2 gy-md-0 align-items-center justify-content-between">
            <div className="col">
              <p className="ff:regular fs:16 c:dark smText smText--xs">
                <Skeleton width={70} height={24} />
              </p>
              <p className="text-uppercase ff:regular fs:13 c:dark:2 lt:2 mt-2">
                <Skeleton width={85} height={19} />
              </p>

              <Skeleton
                width={100.58}
                height={25}
                style={{ borderRadius: "0.3125rem" }}
              />
            </div>
            <div className="col">
              <p className="ff:regular fs:16 c:dark pe-4">
                Talep no:&nbsp;
                <Skeleton width={111.67} height={19} />
              </p>
            </div>
          </div>
        </div>
      </h1>
    </li>
  );
  return (
    <>
      <section className="breadcrumb my-4 my-md-5">
        <div className="container">
          <ul className="c:dark fs:14 lt:3">
            <li>
              <Link to="/anasayfa">Anasayfa</Link>
            </li>
            <li>
              <Link to="/hesabim">Hesabım</Link>
            </li>
            <li>
              <Link to="/hesabim/soru-ve-taleplerim">Soru ve Taleplerim</Link>
            </li>
          </ul>
        </div>
      </section>
      <section className="my-4 my-md-5">
        <div className="container">
          <div className="row row-cols-1 row-cols-sm-auto align-items-center justify-content-between gx-0 gy-3 gy-sm-0">
            <div className="col">
              <h1 className="c:dark ff:bold fs:24 text-center">
                Soru ve Taleplerim
              </h1>
            </div>
            <div className="col">
              <div className="d-flex justify-content-center">
                <Link
                  to="/hesabim/soru-ve-taleplerim/yeni-talep"
                  aria-label="Yeni Talep Oluştur"
                  className="buttonStyle buttonStyle--paddingElem buttonStyle--border c:orange ff:bold fs:16 bg:white d-inline-block text-center radius:12 shadow:10"
                  style={
                    {
                      "--btnPaddingElem": "0.7rem 2.8rem",
                      "--btn-border": "#FE9744",
                      "--btn-hover": "#FE9744",
                    } as React.CSSProperties
                  }
                >
                  Yeni Talep Oluştur
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mb-5">
        <div className="container">
          {/* <!-- accordion açıldığında aktif class, "collapseActiveItem" --> */}
          <section className="collapseSingle">
            <ul className="collapseLayout">
              {loading ? (
                <>
                  <SkeletonItem />
                  <SkeletonItem />
                  <SkeletonItem />
                </>
              ) : success && demands?.length ? (
                demands.map((item) => (
                  <li
                    key={item.id}
                    className={`collapseLayout__item ${
                      item.id === activeItem ? "collapseActiveItem" : ""
                    }`}
                  >
                    <h1
                      onClick={() => handleActiveItem(item.id)}
                      className="collapseLayout__thumb d-flex align-items-center py-3 py-sm-4 px-3 px-sm-4 pe-5 bg:white radius:12 borderStyle shadow:4"
                      style={
                        {
                          "--gsk-border-width": "0.031rem",
                          "--gsk-border-color": "#d7d7d7",
                        } as React.CSSProperties
                      }
                    >
                      <div className="w-100 pe-4">
                        <div className="row row-cols-1 row-cols-md-auto flex-column-reverse flex-md-row gx-0 gy-2 gy-md-0 align-items-center justify-content-between">
                          <div className="col">
                            <p className="ff:regular fs:16 c:dark smText smText--xs">
                              {item.subject}
                            </p>
                            <p className="text-uppercase ff:regular fs:13 c:dark:2 lt:2 mt-2">
                              {convertDatetime(new Date(item.date))}
                            </p>
                            {item.status === "Talep Alındı"}
                            <span
                              className={`mt-2 buttonStyle py-2 px-3 lh:1 c:white ff:bold fs:13 ${handleStatusColor(
                                item.status
                              )} text-center radius:5 d-inline-block`}
                            >
                              {item.status}
                            </span>
                          </div>
                          <div className="col">
                            <p className="ff:regular fs:16 c:dark pe-4">
                              Talep no:&nbsp;
                              <strong>{item.code ?? "0667688"}</strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    </h1>
                    <div
                      className={`collapseLayout__panel ${
                        activeItem === item.id ? "d-block" : "d-none"
                      }`}
                    >
                      <div className="mt-2 mb-5 p-3 p-sm-4 radius:12 border:1 bg:white shadow:4">
                        <h1 className="ff:bold fs:18 c:dark mb-2 mb-sm-3">
                          {item.subject}
                        </h1>
                        <p className="c:dark ff:regular fs:16 lh:7">
                          {item.message}
                        </p>
                      </div>
                    </div>
                  </li>
                ))
              ) : (
                <NoData text="Herhangi bir talebiniz bulunmamaktadır." />
              )}
            </ul>
          </section>
        </div>
      </section>
    </>
  );
};

export default SoruVeTalepler;
