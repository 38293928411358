import React, { useState } from 'react';

const Section2 = () => {
  const [activeItem, setActiveItem] = useState(1);
  return (
    <section className="landingTab scrollMargin" id="section-1">
      <div className="bg:orange">
        <div className="py-5">
          <div className="py-0 py-sm-5">
            <div className="container">
              <div className="row g-0 justify-content-center">
                <div className="col-12 col-lg-7">
                  {/*<nav className="landingTab__nav mb-4 pb-0 pb-md-2">*/}
                  {/*  <ul className="row row-cols-auto justify-content-center align-items-center gy-0 gx-4 gx-sm-5">*/}
                  {/*    <li>*/}
                  {/*      <button*/}
                  {/*        onClick={() => setActiveItem(1)}*/}
                  {/*        className={`landingTab__nav-btn c:white fs:20 ff:bold lh:1 ${*/}
                  {/*          activeItem === 1 ? "activeTabs" : ""*/}
                  {/*        }`}*/}
                  {/*        id="1"*/}
                  {/*        data-testid="tabs-first-button"*/}
                  {/*      >*/}
                  {/*        TuruncuHat Nedir?*/}
                  {/*      </button>*/}
                  {/*    </li>*/}
                  {/*    <li>*/}
                  {/*      <button*/}
                  {/*        onClick={() => setActiveItem(2)}*/}
                  {/*        className={`landingTab__nav-btn c:white fs:20 ff:bold lh:1 ${*/}
                  {/*          activeItem === 2 ? "activeTabs" : ""*/}
                  {/*        }`}*/}
                  {/*        id="2"*/}
                  {/*        data-testid="tabs-second-button"*/}
                  {/*      >*/}
                  {/*        Nasıl Çalışır?*/}
                  {/*      </button>*/}
                  {/*    </li>*/}
                  {/*  </ul>*/}
                  {/*</nav>*/}
                  <section className="landingTab__content">
                    <div
                      className={`landingTab__content-item ${
                        activeItem === 1 ? 'activeTabs animate__animated animate__fadeIn' : ''
                      }`}
                      data-tab="1"
                    >
                      <article className="text-center c:white fs:16 ff:light">
                        <h1 className="fs:44 ff:bold" data-testid="tabs-first-content">
                          TuruncuHat Nedir?
                        </h1>
                        <br />
                        <p>
                          <span className="d-block ff:bold fs:18">
                            {' '}
                            TuruncuHat, GSK tarafından 2015 yılında başlatılan; eczacıların
                            profesyonel yaşamlarını desteklemek ve onlara özel avantajlar sunmak
                            amacıyla oluşturulmuş bir platformdur..{' '}
                          </span>
                          <br /> TuruncuHat sayfasındaki özel fırsatlardan yararlanarak tek tıkla
                          sipariş verebilir, stoklarınızı güncel tutabilirsiniz. <br />
                          <br /> Ayrıca Turuncu Blog ile sektördeki güncel gelişmeleri takip
                          edebilir, mesleki gelişiminize katkı sunmak için hazırladığımız içeriklere
                          erişebilirsiniz.
                          <br />
                          <br />
                          Siparişlerinizin durumunu, soru ve taleplerinizi bize ulaştırabilirsiniz.
                          Almış olduğunuz hizmetleri uygulama üzerinden değerlendirebilirsiniz.
                        </p>
                      </article>
                    </div>
                    <div
                      className={`landingTab__content-item ${
                        activeItem === 2 ? 'activeTabs animate__animated animate__fadeIn' : ''
                      }`}
                      data-tab="2"
                    >
                      <article className="text-center c:white fs:16 ff:light">
                        <h1 className="fs:44 ff:bold" data-testid="tabs-second-content">
                          Nasıl Çalışır?
                        </h1>
                        <br />
                        <p>
                          <span className="d-block ff:bold fs:18">
                            {' '}
                            Donec convallis aliquet massa at iaculis. Maecenas luctus justo a enim
                            sodales, ut consequat nibh feugiat. Aenean rhoncus, tellus vel tempor
                            tempus.{' '}
                          </span>
                          <br /> Quisque ut urna quis magna condimentum scelerisque ac sed leo.
                          Suspendisse condimentum, ipsum non sodales fermentum, augue mi mattis
                          nibh, eu placerat sem velit vel nunc. Suspendisse consequat massa
                        </p>
                      </article>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section2;
