export const navigationData = {
  hesabim: "/hesabim",
  bilgilerim: "/hesabim/bilgilerim",
  password: "/hesabim/sifre",
  iletisimTercihleri: "/hesabim/iletisim-tercihleri",
  siparislerim: "/hesabim/siparislerim",
  favorilerim: "/hesabim/favorilerim",
  kampanyalarim: "/hesabim/kampanyalarim",
  soruVeTaleplerim: "/hesabim/soru-ve-taleplerim",
  ayarlar: "/ayarlar",
  //
  loyalty: "/hesabim/sadakat-programi",
  loyaltyMyPoints: "/hesabim/sadakat-programi/puanlarim",
};
