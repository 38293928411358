import Skeleton from "react-loading-skeleton";
import ProductsSlider from "@components/General/ProductsSlider";
import { ProductFilters } from "contexts/BodyContext/BodyContext";
import { Link } from "react-router-dom";

const ListCartItemSkeleton = () => (
  <tr>
    <td>
      <Link aria-label="link card" to={`#`}>
        <div className="d-flex align-items-center">
          <div className="flex-shrink-0">
            <div className="productListCard__box-img">
              <Skeleton width={90} height={90} />
            </div>
          </div>
          <div className="flex-grow-1 ms-3">
            <div>
              <Skeleton width={100} height={21} className="mb-1" />
              <Skeleton width={80} height={21} className="mb-1" />
              <Skeleton width={50} height={21} className="mb-1" />
            </div>
          </div>
        </div>
      </Link>
    </td>

    <td>
      <div className="d-flex justify-content-center">
        <Skeleton width={66.52} height={42.5} className="mb-1 radius:12" />
      </div>
    </td>
    <td>
      <div
        className="widthElem mx-auto"
        style={{ "--widthElem": "7.5rem" } as React.CSSProperties}
      >
        <div className={`formElementItem formElementItem--group`}>
          <Skeleton width={120} height={48} className="radius:12" />
        </div>
      </div>
    </td>
    <td>
      <button
        className="trashButton mx-auto"
        aria-label="sil butonu"
        type="button"
      >
        <Skeleton width={18} height={18} />
      </button>
    </td>
  </tr>
);

const CartsSkeleton = () => {
  return (
    <>
      <section className="my-5">
        <div className="container">
          <section className="mb-3 pb-1">
            <div className="row row-cols-auto justify-content-between align-items-center g-0">
              <div className="col">
                <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                  <Skeleton height={30} width={150} />
                </div>
              </div>
              <div className="col">
                <button
                  className="iconLinkBtn"
                  aria-label="sil butonu"
                  type="button"
                >
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0 iconSize:16">
                      <Skeleton width={180} height={18} />
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </section>

          <section className="tableLayout">
            <table className="tableLayout__table">
              <thead></thead>
              <tbody>
                <ListCartItemSkeleton />
              </tbody>
            </table>
          </section>
        </div>
      </section>
      <section className="my-5">
        <ProductsSlider
          filter={ProductFilters.Popular}
          title={"Popüler Ürünler"}
        />
      </section>
      <section className="my-5">
        <ProductsSlider
          filter={ProductFilters.Turuncu}
          title={"Turuncu Ürünler"}
        />
      </section>
    </>
  );
};

export default CartsSkeleton;
