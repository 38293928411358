import React, { useRef, useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, EffectFade, Autoplay } from 'swiper';
import useApi from 'hooks/useApi';
import { getCampaignSlider } from 'services/campaign.service';
import Skeleton from 'react-loading-skeleton';

interface ISliderData {
  slider: [
    {
      fileContents: string;
      contentType: string;
      fileDownloadName: string;
      lastModified: string;
      entityTag: string;
      enableRangeProcessing: boolean;
    }
  ];
  whatIsTdk: string;
}

/* eslint-disable react-hooks/exhaustive-deps */
const AnlikKampanyalarSlider = () => {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  // eslint-disable-next-line
  const [swiperSliderFirst, setSwiperSliderFirst] = useState<any>(null);

  const paginationRef = useRef(null);
  const { data, success, handleApi, loading } = useApi({ loading: true });

  useEffect(() => {
    handleApi(getCampaignSlider, {});
  }, []);

  const sliderData: ISliderData = success && data;

  return (
    <>
      <section className='mt-3 mt-sm-5 mb-3 mb-sm-5'>
        <div className='container'>
          {!sliderData?.slider?.length ? null : (
            <section>
              <div className='position-relative sliderType-3 sliderType-3--1'>
                <div className='swiper sliderType-3__item sliderType-3__item--1'>
                  {loading && !sliderData ? (
                    <Skeleton height={424.31} count={1} borderRadius={'1rem'} />
                  ) : (
                    <div className='swiper-wrapper'>
                      <Swiper
                        modules={[Pagination, Navigation, EffectFade, Autoplay]}
                        pagination={{
                          el: paginationRef.current,
                          clickable: true,
                        }}
                        autoplay={{
                          delay: 4000,
                          disableOnInteraction: false,
                        }}
                        onSwiper={(swiper) => setSwiperSliderFirst(swiper)}
                        spaceBetween={10}
                        navigation={{
                          prevEl: navigationPrevRef.current,
                          nextEl: navigationNextRef.current,
                        }}
                        watchSlidesProgress={true}
                        effect='fade'
                        fadeEffect={{ crossFade: true }}
                      >
                        {success &&
                          sliderData.slider.map((slide, index) => (
                            <SwiperSlide key={index} className='swiper-slide'>
                              <>
                                <img
                                  src={
                                    slide
                                      ? `data:${slide?.contentType};base64,${slide?.fileContents}`
                                      : '/brokenImage.svg'
                                  }
                                  alt={
                                    slide?.contentType
                                      ? slide?.contentType
                                      : 'alttext'
                                  }
                                  loading='lazy'
                                  onError={(e) => {
                                    e.currentTarget.src = '/brokenImage.svg';
                                  }}
                                />
                                <div className='swiper-lazy-preloader swiper-lazy-preloader-black'></div>
                              </>
                            </SwiperSlide>
                          ))}
                      </Swiper>
                    </div>
                  )}

                  <div ref={paginationRef} className='swiper-pagination'></div>
                </div>
                <div
                  ref={navigationNextRef}
                  className='swiper-button-next d-none d-md-block'
                ></div>
                <div
                  ref={navigationPrevRef}
                  className='swiper-button-prev d-none d-md-block'
                ></div>
              </div>
            </section>
          )}
        </div>
      </section>
      {success && sliderData.whatIsTdk && (
        <section className='contentArea ff:light fs:16 c:dark mb-5 container'>
          <article>
            <p>{sliderData.whatIsTdk}</p>
          </article>
        </section>
      )}
    </>
  );
};

export default React.memo(AnlikKampanyalarSlider);
