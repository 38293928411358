import React, { useContext } from "react";
import iconRating from "@images/icon-rating.svg";
import iconBlogStar from "@images/icon-blog-star.svg";
import { Link } from "react-router-dom";
import { convertDatetimeStrMonth } from "utility/formatDate";
import { BlogContext } from "contexts/BlogContext/BlogContext";

const HomeBlog = () => {
  const { blogs, loading } = useContext(BlogContext);

  const homePageBlogPosts = blogs?.blogs
    ?.map((blog) =>
      blog?.posts?.filter((post) => post.label.includes("Mainpage"))
    )
    .flat()
    .filter(Boolean);

  return (
    <section className="homeBlog">
      <div className="container">
        <div className="row gy-4 gx-0 gx-lg-4">
          {!loading && homePageBlogPosts.length ? (
            <div className="col-12">
              <Link
                aria-label="title blog"
                to={"/blogdetay/" + homePageBlogPosts[0].id}
                className="blogCard__box bg:white radius:12 shadow:7 p-4"
              >
                <div className="row row-cols-1 row-cols-lg-2 align-items-center gx-0 gx-lg-4 gy-3 gy-lg-0">
                  <div className="col">
                    <div className="px-0 px-xl-5">
                      <p
                        className="text-uppercase fs:12 ff:bold c:white d-inline-block radius:6 lh:1 paddingElem"
                        style={
                          {
                            backgroundColor:
                              homePageBlogPosts[0].blogColor ?? "",
                            "--paddingElem": "0.625rem 0.813rem",
                          } as React.CSSProperties
                        }
                      >
                        {homePageBlogPosts[0].blogName}
                      </p>
                      <h1 className="c:dark ff:regular lt:3 fs:30 mt-3">
                        {homePageBlogPosts[0].title}
                      </h1>
                    </div>
                  </div>
                  <div className="col">
                    <img
                      className="blogCard__box-img radius:12"
                      src={
                        homePageBlogPosts[0].banner
                          ? `data:${homePageBlogPosts[0].banner.contentType};base64,${homePageBlogPosts[0].banner.fileContents}`
                          : "/brokenImage.svg"
                      }
                      alt={
                        homePageBlogPosts[0].banner?.contentType
                          ? homePageBlogPosts[0].banner?.contentType
                          : "alttext"
                      }
                    />
                  </div>
                </div>
              </Link>
            </div>
          ) : null}

          {!loading &&
            homePageBlogPosts &&
            homePageBlogPosts.slice(1, 4).map((item) => (
              <div key={item.id} className="col-12 col-lg-4">
                <Link
                  aria-label="title blog"
                  to={"/blogdetay/" + item.id}
                  className="blogCard__box blogCard__box--sm bg:white radius:12 shadow:7"
                >
                  <div className="p-4">
                    <div className="d-flex align-items-center mb-3">
                      <div className="flex-grow-1 me-3">
                        <p
                          className="text-uppercase fs:12 lt:4 ff:bold"
                          style={{ color: item.blogColor ?? "" }}
                        >
                          {item.blogName}
                        </p>
                      </div>
                      <div className="flex-shrink-0">
                        <div className="row row-cols-auto gy-0 gx-2">
                          <div className="col">
                            <div className="blogCard__box-icon">
                              <img src={iconRating} alt="" />
                            </div>
                          </div>
                          <div className="col">
                            <div className="blogCard__box-icon">
                              <img src={iconBlogStar} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <h1 className="c:dark fs:20 lt:3">{item.title}</h1>

                    <p className="c:dark ff:light fs:14 lt:2 mt-3">
                      {convertDatetimeStrMonth(new Date(item.publishDate))}
                    </p>
                  </div>
                  <img
                    className="blogCard__box-img blogCard__box-img--sm radius:12"
                    src={
                      item.banner
                        ? `data:${item.banner.contentType};base64,${item.banner.fileContents}`
                        : "/brokenImage.svg"
                    }
                    alt={
                      item.banner?.contentType
                        ? item.banner?.contentType
                        : "alttext"
                    }
                  />
                </Link>
              </div>
            ))}
        </div>
        <div className="d-flex justify-content-center mt-4">
          <Link
            to="/blog"
            aria-label="daha fazla"
            className="buttonStyle buttonStyle--light buttonStyle--paddingElem c:dark ff:bold fs:16 bg:white shadow:4 d-inline-block text-center radius:16"
            style={
              {
                "--btnPaddingElem": "0.813rem 5.125rem",
              } as React.CSSProperties
            }
          >
            Daha Fazla
          </Link>
        </div>
      </div>
    </section>
  );
};

export default React.memo(HomeBlog);
