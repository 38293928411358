import { endpoints } from "constants/endpoints";
import api from "utility/request";

export const getCampaignSlider = async (): Promise<any> => {
  const response = (
    await api.get(`${endpoints.baseUrl}/campaign/instant
    `)
  ).data;
  return response;
};
