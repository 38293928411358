import React from "react";
import appCover from "@images/app-cover.png";
import badgeAppStore from "@images/badge-appStore.svg";
import badgeGplay from "@images/badge-gPlay.svg";

const Section5 = () => {
  return (
    <section className="landingAppDetail scrollMargin" id="section-4">
      <div className="bg:dark">
        <div className="py-5">
          <div className="py-0 py-sm-5">
            <div className="container">
              <div className="row justify-content-center g-0">
                <div className="col-12 col-lg-9">
                  <div className="row gx-4 gy-5 gy-lg-0 align-items-center">
                    <div className="col-12 col-lg-4">
                      <div className="landingAppDetail__animate">
                        <img className="parallaxElem-3" src={appCover} alt="" />
                      </div>
                    </div>
                    <div className="col-12 col-lg-8">
                      <p className="c:white ff:bold fs:13 lt:1 text-uppercase">
                        Mobil Uygulamamızı İndirin
                      </p>
                      <h1 className="c:white ff:bold fs:44 lh:2 mt-2">
                        TuruncuHat Artık Uygulama{" "}
                        <span className="bg:gradient:orange clipText">
                          Mağazalarında.
                        </span>
                      </h1>
                      <div className="seperatorLine mt-3"></div>
                      <p className="c:white ff:light fs:16 my-4">
                        Şimdi Yükle Avantajları Kaçırma
                      </p>
                      <div className="row row-cols-auto gx-3 gy-0 landingAppDetail__badge">
                        <div className="col">
                          <a
                            className="landingAppDetail__badge-img hover:opacity:7"
                            aria-label="apple app store"
                            target="_blank"
                            href="https://apps.apple.com/tr/app/turuncuhat-gsk/id1456364298"
                          >
                            <img src={badgeAppStore} alt="" />
                          </a>
                        </div>
                        <div className="col">
                          <a
                            className="landingAppDetail__badge-img hover:opacity:7"
                            aria-label="google play store"
                            target="_blank"
                            href="https://play.google.com/store/apps/details?id=com.gsk.turuncuhat&hl=tr&gl=TR"
                          >
                            <img src={badgeGplay} alt="" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section5;
