import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import StepArrrows from "./StepArrrows";
import EmailPopup from "@components/Hesabim/MyAccount/EmailPopup";
import {
  IRegisterState,
  RegisterContext,
} from "contexts/RegisterContext/RegisterContext";
import { PublicContext } from "contexts/PublicContext/PublicContext";
/* eslint-disable react-hooks/exhaustive-deps */
const Step3 = () => {
  const [emailConfirmed, setEmailConfirmed] = useState(false);
  const { user, setUser } = useContext(RegisterContext) as IRegisterState;
  const navigate = useNavigate();

  const { setSiteTitle } = useContext(PublicContext);

  useEffect(() => {
    setSiteTitle("Email Doğrulama");
  }, []);

  useEffect(() => {
    if (emailConfirmed) {
      const newUser = { ...user!, emailConfirmed: true };
      setUser(newUser);

      navigate(`/kayit-ol/adim-4`);
    }
  }, [emailConfirmed]);

  return (
    <section className="w-100">
      <div className="container-fluid">
        <div className="row g-0 row-cols-1 row-cols-lg-2 row-cols-xl-3 justify-content-center">
          <div className="col">
            <div className="mb-4">
              <div className="row align-items-center row-cols-1 row-cols-sm-auto flex-column-reverse flex-sm-row justify-content-between g-0">
                <div className="col">
                  <h1 className="ff:light c:dark fs:28 d-flex align-items-center justify-content-center">
                    E-Posta Doğrulama
                  </h1>
                </div>
                <StepArrrows step={3} />
              </div>
            </div>
            {user?.email ? (
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="formElement__block bg:white radius:12 shadow:3 p-3 p-sm-4">
                  <div className="p-2">
                    <EmailPopup
                      setEmailConfirmed={setEmailConfirmed}
                      user={user}
                      isRegisterPage={true}
                    />
                  </div>
                </div>
              </form>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Step3;
