import React from "react";
import Skeleton from "react-loading-skeleton";

const QuickOrdersAccordionItem = () => (
  <tr>
    <td>
      <div className="d-flex align-items-start">
        <div className="flex-shrink-0">
          <Skeleton
            width={"2.125rem"}
            height={"2.125rem"}
            borderRadius={"100%"}
          />
        </div>
        <div className="flex-grow-1 ms-3">
          <Skeleton width={197} height={23} />

          <Skeleton width={157} height={19} className="mt-2" />
        </div>
      </div>
    </td>
    <td>
      <p className="c:dark fs:14 ff:regular smText text-uppercase">
        <Skeleton width={107} height={21} />
      </p>
    </td>
    <td>
      <Skeleton width={30} height={23} />
    </td>
    <td>
      <Skeleton width={70} height={21} />
    </td>
    <td>
      <div className="row row-cols-auto align-items-center justify-content-end gx-3 gy-0">
        <div className="col">
          <button
            type="button"
            aria-label="Hızlı Sipariş Ver"
            className="buttonStyle buttonStyle--paddingElem buttonStyle--border c:orange ff:bold fs:16 bg:white d-inline-block text-center radius:12 shadow:10 lh:1"
            style={
              {
                "--btnPaddingElem": "0.8rem 2rem",
                "--btn-border": "#FE9744",
                "--btn-hover": "#FE9744",
              } as React.CSSProperties
            }
          >
            <Skeleton width={70} height={21} borderRadius={".75rem"} />
          </button>
        </div>
        <div className="col">
          <button
            className="trashButton"
            aria-label="sil butonu"
            type="button"
          ></button>
        </div>
      </div>
    </td>
  </tr>
);

const QuickOrdersSkeletonAccordion = () => {
  return (
    <section className="tableLayout tableLayout--sm">
      <table className="tableLayout__table">
        <thead>
          <tr className="text-uppercase lt:3 c:dark ff:bold fs:13">
            <th>ÜRÜN</th>
            <th>DEPO</th>
            <th>ÜRÜN ADEDİ</th>
            <th>SİPARİŞ TOPLAMI</th>
            {/* <!-- <th>PLAN</th> --> */}
            <th></th>
          </tr>
        </thead>
        <tbody>
          <QuickOrdersAccordionItem />
          <QuickOrdersAccordionItem />
          <QuickOrdersAccordionItem />
          <QuickOrdersAccordionItem />
        </tbody>
      </table>
    </section>
  );
};

export default QuickOrdersSkeletonAccordion;
