import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation, Pagination, Thumbs, EffectFade, Autoplay } from "swiper";
import "swiper/swiper.min.css";
import { ISlides } from "views/Home/Home";
import Skeleton from "react-loading-skeleton";

const MainSlider = (props: any) => {
  const [swiperSliderFirst, setSwiperSliderFirst] = useState<any>(null);
  const [thumbSlider, setThumbSlider] = useState<any>(null);

  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  const navigationPrevRef2 = React.useRef(null);
  const navigationNextRef2 = React.useRef(null);
  const paginationRef = React.useRef(null);

  const slides: ISlides[] = props.slides;
  const loading: boolean = props.loading;
  return (
    <section>
      <div className="container">
        <div className="position-relative sliderType-2 sliderType-2--2">
          <div className="swiper sliderType-2__item sliderType-2__item--2">
            {loading ? (
              <div className="">
                <Skeleton height={424.31} count={1} borderRadius={"1rem"} />
              </div>
            ) : (
              slides && (
                <div className="swiper-wrapper">
                  <Swiper
                    modules={[
                      Thumbs,
                      Pagination,
                      Navigation,
                      EffectFade,
                      Autoplay,
                    ]}
                    onSwiper={(swiper) => setSwiperSliderFirst(swiper)}
                    pagination={{
                      el: paginationRef.current,
                      dynamicBullets: true,
                      clickable: true,
                    }}
                    spaceBetween={10}
                    autoplay={{
                      delay: 4000,
                      disableOnInteraction: false,
                    }}
                    navigation={{
                      prevEl: navigationPrevRef.current,
                      nextEl: navigationNextRef.current,
                    }}
                    watchSlidesProgress={true}
                    effect="fade"
                    fadeEffect={{ crossFade: true }}
                    thumbs={{
                      swiper:
                        thumbSlider && !thumbSlider.destroyed
                          ? thumbSlider
                          : null,
                    }}
                  >
                    {slides?.map((item, index) =>
                      item.slide ? (
                        <SwiperSlide key={index} className="swiper-slide">
                          <>
                            <img
                              src={
                                item.slide?.fileContents
                                  ? `data:${item.slide.contentType};base64,${item.slide.fileContents}`
                                  : "/brokenImage.svg"
                              }
                              alt={
                                item.slide?.contentType
                                  ? item.slide?.contentType
                                  : "alttext"
                              }
                              loading="lazy"
                            />
                            <div className="swiper-lazy-preloader swiper-lazy-preloader-black"></div>
                          </>
                        </SwiperSlide>
                      ) : null
                    )}
                  </Swiper>
                </div>
              )
            )}
            <div
              ref={paginationRef}
              className="swiper-pagination mt-4 position-relative d-block d-lg-none"
            ></div>
          </div>
          <div
            ref={navigationNextRef}
            className="swiper-button-next d-none d-lg-block"
          ></div>
          <div
            ref={navigationPrevRef}
            className="swiper-button-prev d-none d-lg-block"
          ></div>
        </div>

        <div className="d-none d-lg-block mt-4">
          <div className="position-relative sliderType-1 sliderType-1--1">
            <div className="swiper sliderType-1__item sliderType-1__item--1">
              {loading ? (
                <div className="skeleton-home" style={{ display: "flex",}}>
                  <Skeleton
                    count={1}
                    circle
                    width={58}
                    height={58}
                    style={{ display: "flex" }}
                  />
                  <Skeleton
                    count={1}
                    circle
                    width={58}
                    height={58}
                    style={{ display: "flex", marginLeft: "15px" }}
                  />
                </div>
              ) : (
                slides && (
                  <Swiper
                    modules={[Thumbs, Navigation]}
                    spaceBetween={15}
                    slidesPerView={7}
                    onSwiper={(swiper) => setThumbSlider(swiper)}
                    navigation={{
                      prevEl: navigationPrevRef2.current,
                      nextEl: navigationNextRef2.current,
                    }}
                    watchSlidesProgress={true}
                    className="swiper-wrapper"
                  >
                    {slides?.map((item, index) =>
                      item.slide ? (
                        <SwiperSlide key={index} className="swiper-slide">
                          <>
                            <img
                              src={
                                item.thumbnail?.fileContents
                                  ? `data:${item.thumbnail.contentType};base64,${item.thumbnail.fileContents}`
                                  : "/brokenImage.svg"
                              }
                              alt={
                                item.thumbnail?.contentType
                                  ? item.thumbnail?.contentType
                                  : "alttext"
                              }
                              loading="lazy"
                            />
                            <div className="swiper-lazy-preloader swiper-lazy-preloader-black"></div>
                          </>
                        </SwiperSlide>
                      ) : null
                    )}
                  </Swiper>
                )
              )}
            </div>
            <div
              ref={navigationNextRef2}
              onClick={() => swiperSliderFirst.slideNext()}
              className="swiper-button-next"
            ></div>
            <div
              ref={navigationPrevRef2}
              onClick={() => swiperSliderFirst.slidePrev()}
              className="swiper-button-prev"
            ></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainSlider;
