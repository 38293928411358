import Footer from '@components/Footer';
import HeadMenu from '@components/HeadMenu';
import { Outlet } from 'react-router-dom';
import ScrollToTop from '../utility/scrollToTop';
import HelmetComponent from '@components/General/Helmet';
import PublicProvider from '../contexts/PublicContext/PublicProvider';
/* eslint-disable react-hooks/exhaustive-deps */
const LandingLayout = () => {

  return (
    <PublicProvider>
      <>
        <HelmetComponent />
        <HeadMenu />
        <main className='landingMain stickyTopMargin'>
          <Outlet />
          <ScrollToTop />
        </main>
        <Footer />
      </>
    </PublicProvider>
  );
};

export default LandingLayout;
