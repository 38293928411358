import { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { router } from "routes/router";
import AuthProvider from "contexts/AuthContext/AuthProvider";
import "./styles/app.min.css";
import "./styles/global.css";
import { AxiosInterceptor } from "utility/request";
import OneSignal from "react-onesignal";
import "pace-js";
import "./styles/pace-theme-turuncu.css";
import "react-loading-skeleton/dist/skeleton.css";

const getAppId = () => {
  switch (process.env.REACT_APP_NODE_ENV) {
    case "dev":
      return process.env.REACT_APP_DEV_APP_ID;
    case "uat":
      return process.env.REACT_APP_UAT_APP_ID;
    case "test":
      return process.env.REACT_APP_TEST_APP_ID;
    default:
      return process.env.REACT_APP_PROD_APP_ID;
  }
};

const getSafariWebId = () => {
  switch (process.env.REACT_APP_NODE_ENV) {
    case "dev":
      return process.env.REACT_APP_DEV_SAFARI_WEB_ID;
    case "uat":
      return process.env.REACT_APP_UAT_SAFARI_WEB_ID;
    case "test":
      return process.env.REACT_APP_TEST_SAFARI_WEB_ID;
    default:
      return process.env.REACT_APP_PROD_SAFARI_WEB_ID;
  }
};

function App() {

  useEffect(() => {
    if (window && window.top && window.self !== window.top) {
      window.top.location = window.self.location;
    } else {
      document.documentElement.style.display = "block";
    }
  }, []);

  useEffect(() => {
    OneSignal.init({
      appId: getAppId() || "",
      safari_web_id: getSafariWebId(),
      allowLocalhostAsSecureOrigin: true,
    });
    function hasTouch() {
      return (
        "ontouchstart" in document.documentElement ||
        navigator.maxTouchPoints > 0
      );
    }

    if (hasTouch()) {
      // remove all the :hover stylesheets
      try {
        // prevent exception on browsers not supporting DOM styleSheets properly
        for (var si in document.styleSheets) {
          var styleSheet = document.styleSheets[si];
          if (!styleSheet.rules) continue;

          for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
            if (!styleSheet.rules[ri].cssText) continue;

            if (styleSheet.rules[ri].cssText.match(":hover")) {
              styleSheet.deleteRule(ri);
            }
          }
        }
      } catch (ex) {}
    }
  }, []);

  return (
    <AuthProvider>
      <AxiosInterceptor>
        <RouterProvider router={router} />
      </AxiosInterceptor>
    </AuthProvider>
  );
}

export default App;
