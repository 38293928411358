import React, { useContext, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import { ICategory } from "types/categoryTypes";
import { Link } from "react-router-dom";
import { BodyContext } from "contexts/BodyContext/BodyContext";
import Skeleton from "react-loading-skeleton";
import NoData from "@components/General/NoData";

const CategorySlider = () => {
  const paginationRef = React.useRef<any>(null);

  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  const [setswiperCategories, setSetswiperCategories] = useState<any>(null);

  const { categories, loading, errorCategories } = useContext(BodyContext);

  const findOnlyChilds = () => {
    let childs: ICategory[] = [];
    categories?.filter((category: ICategory) =>
        category.children.map((child: ICategory) => {
          childs.push({ ...child, parentId: category.code });
          return childs;
        })
      );
    return childs;
  };

  const childCategories = findOnlyChilds();

  const SkeletonItem = () => (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Skeleton
        style={{ marginRight: "10px" }}
        count={1}
        borderRadius={"50%"}
      />
      <Skeleton
        style={{ marginRight: "10px", marginTop: "10px" }}
        height={17}
        width={110}
        count={1}
      />
    </div>
  );

  return (
    <section className="categorySlider">
      <div className="container">
        <h1 className="c:dark ff:bold fs:24 mb-4 mb-md-5 text-center text-lg-start">
          Kategoriye göre alışverişe başla
        </h1>
        {!loading && errorCategories ? <NoData text={errorCategories} /> : null}
        <div className="position-relative carouselType-1 carouselType-1--1">
          <div className="swiper carouselType-1__item carouselType-1__item--1">
            {loading ? (
              <Swiper
                modules={[Navigation, Pagination]}
                // onPaginationRender={() => console.log("show navigation")}
                // onPaginationUpdate={() => console.log("pag update")}
                onSwiper={(swiper) => setSetswiperCategories(swiper)}
                spaceBetween={10}
                slidesPerView={2}
                slidesPerGroup={2}
                navigation={{
                  prevEl: navigationPrevRef.current,
                  nextEl: navigationNextRef.current,
                }}
                className="swiper-wrapper"
                pagination={{
                  el: paginationRef.current,
                  clickable: true,
                  dynamicBullets: true,
                }}
                breakpoints={{
                  768: {
                    slidesPerView: 4,
                    slidesPerGroup: 1,
                  },
                  992: {
                    slidesPerView: 5,
                    slidesPerGroup: 1,
                  },
                  1200: {
                    slidesPerView: 7,
                    slidesPerGroup: 1,
                  },
                }}
              >
                <SwiperSlide className="swiper-slide">
                  <SkeletonItem />
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <SkeletonItem />
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <SkeletonItem />
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <SkeletonItem />
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <SkeletonItem />
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <SkeletonItem />
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <SkeletonItem />
                </SwiperSlide>
              </Swiper>
            ) : (
              <div className="swiper-wrapper">
                <Swiper
                  modules={[Navigation, Pagination]}
                  // onPaginationRender={() => console.log("show navigation")}
                  // onPaginationUpdate={() => console.log("pag update")}
                  onSwiper={(swiper) => setSetswiperCategories(swiper)}
                  spaceBetween={10}
                  slidesPerView={2}
                  slidesPerGroup={2}
                  navigation={{
                    prevEl: navigationPrevRef.current,
                    nextEl: navigationNextRef.current,
                  }}
                  className="swiper-wrapper"
                  pagination={{
                    el: paginationRef.current,
                    clickable: true,
                    dynamicBullets: true,
                  }}
                  breakpoints={{
                    768: {
                      slidesPerView: 4,
                      slidesPerGroup: 1,
                    },
                    992: {
                      slidesPerView: 5,
                      slidesPerGroup: 1,
                    },
                    1200: {
                      slidesPerView: 7,
                      slidesPerGroup: 1,
                    },
                  }}
                >
                  {childCategories &&
                    childCategories.map((category: ICategory) => (
                      <SwiperSlide key={category.id} className="swiper-slide">
                        <Link
                          to={`/urunler/liste?anakategori=${category.parentId}&altkategori=${category.code}`}
                        >
                          <div className="categorySlider__icon radius:full bg:light:10 d-flex align-items-center justify-content-center mx-auto mb-3">
                            <img
                              src={
                                category.thumbnail
                                  ? `data:${category.thumbnail.contentType};base64,${category.thumbnail.fileContents}`
                                  : "/brokenImage.svg"
                              }
                              alt={
                                category.thumbnail?.contentType
                                  ? category.thumbnail?.contentType
                                  : "alttext"
                              }
                            />
                          </div>
                          <p className="c:dark fs:16 ff:bold text-center">
                            {category.name}
                          </p>
                        </Link>
                      </SwiperSlide>
                    ))}
                </Swiper>
              </div>
            )}

            <div
              ref={paginationRef}
              className="swiper-pagination mt-4 pt-1 d-block d-md-none position-relative"
            ></div>
          </div>
          <div
            ref={navigationNextRef}
            className="swiper-button-next d-none d-md-block"
          ></div>
          <div
            ref={navigationPrevRef}
            className="swiper-button-prev d-none d-md-block"
          ></div>
        </div>
      </div>
    </section>
  );
};

export default React.memo(CategorySlider);
