import React from "react";
import { Link } from "react-router-dom";

const Section7 = () => {
  return (
    <section className="landingAppDetail scrollMargin" id="section-5">
      <div className="bg:dark">
        <div className="py-5">
          <div className="py-0 py-sm-4">
            <div className="container">
              <div className="row row-cols-1 row-cols-lg-auto align-items-center justify-content-center justify-content-lg-between gx-0 gy-4 gy-lg-0 text-center text-lg-start">
                <div className="col">
                  <p className="c:white ff:bold fs:13 lt:1 text-uppercase">
                    TuruncuHat'a Şimdi Katılın
                  </p>
                  <h1 className="c:white ff:bold fs:34 lh:2 mt-2 bg:gradient:orange clipText">
                    TuruncuHat Dünyasını Keşfedin.
                  </h1>
                </div>
                <div className="col">
                  <div className="row row-cols-auto justify-content-center gx-2">
                    <div className="col">
                      <Link
                        className="buttonStyle buttonStyle--icon bg:gradient:orange radius:12 c:white fs:16 ff:bold d-inline-block"
                        to="/giris"
                        data-testid="bottom-login"
                        aria-label="giriş yap"
                      >
                        Giriş Yap
                      </Link>
                    </div>
                    <div className="col">
                      <Link
                        className="buttonStyle buttonStyle--icon bg:orange radius:12 c:white fs:16 ff:bold d-inline-block"
                        to="/kayit-ol"
                        data-testid="bottom-register"
                        aria-label="üye ol"
                      >
                        Üye Ol
                      </Link>
                    </div>
                    <div className="col">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section7;
