import BlogCard from "../BlogCard";
import LinkMore from "../LinkMore";
import iconBlogStar from "@images/icon-blog-star.svg";
import { IBlogPosts } from "views/Blog/BlogCategories";

const EnBegenilen = ({ posts }: { posts: IBlogPosts[] }) => {
  return (
    <section className="my-5 pb-0 pb-sm-4">
      <div className="container">
        <div className="d-flex justify-content-center mb-4 pb-0 pb-sm-2">
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <img src={iconBlogStar} alt="" />
            </div>
            <div className="flex-grow-1 ms-3">
              <h1 className="c:dark ff:bold fs:24 text-center text-lg-start lt:3">
                En Beğenilen İçerikler
              </h1>
            </div>
          </div>
        </div>
        <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-4 gx-0 gx-md-3 gy-3 gy-xl-0">
          {posts &&
            posts.map(
              (post) =>
                post && (
                  <BlogCard key={post.id} color={post.blogColor} post={post} />
                )
            )}
        </div>
        <div className="d-flex justify-content-center mt-4 pt-0 pt-sm-2">
          <LinkMore url="/blog/label/TopLiked" />
        </div>
      </div>
    </section>
  );
};

export default EnBegenilen;
