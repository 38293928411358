import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, EffectFade, Autoplay } from 'swiper';
import { ITdkProducts } from 'types/productTypes';
import Skeleton from 'react-loading-skeleton';

interface IProps {
  data: ITdkProducts;
  loading: boolean;
}

const FirstSection = ({ data, loading }: IProps) => {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  // eslint-disable-next-line
  const [swiperSliderFirst, setSwiperSliderFirst] = useState<any>(null);

  const paginationRef = useRef(null);

  return (
    <section className='mt-3 mt-sm-5 mb-3 mb-sm-5'>
      <div className='container'>
        {!data?.slider?.length ? null : (
          <section>
            <div className='position-relative sliderType-3 sliderType-3--1'>
              <div className='swiper sliderType-3__item sliderType-3__item--1'>
                {loading && !data?.slider ? (
                  <Skeleton height={424.31} count={1} borderRadius={'1rem'} />
                ) : (
                  <div className='swiper-wrapper'>
                    <Swiper
                      modules={[Pagination, Navigation, EffectFade, Autoplay]}
                      pagination={{
                        el: paginationRef.current,
                        clickable: true,
                      }}
                      autoplay={{
                        delay: 4000,
                        disableOnInteraction: false,
                      }}
                      onSwiper={(swiper) => setSwiperSliderFirst(swiper)}
                      spaceBetween={10}
                      navigation={{
                        prevEl: navigationPrevRef.current,
                        nextEl: navigationNextRef.current,
                      }}
                      watchSlidesProgress={true}
                      effect='fade'
                      fadeEffect={{ crossFade: true }}
                    >
                      {data?.slider &&
                        data.slider.map((item, index) => (
                          <SwiperSlide key={index} className='swiper-slide'>
                            <>
                              <img
                                src={
                                  item.fileContents
                                    ? `data:${item.contentType};base64,${item.fileContents}`
                                    : '/brokenImage.svg'
                                }
                                alt={
                                  item.contentType ? item.contentType : 'alttext'
                                }
                                loading='lazy'
                                onError={(e) => {
                                  e.currentTarget.src = '/brokenImage.svg';
                                }}
                              />
                              <div className='swiper-lazy-preloader swiper-lazy-preloader-black'></div>
                            </>
                          </SwiperSlide>
                        ))}
                    </Swiper>
                  </div>
                )}
                <div ref={paginationRef} className='swiper-pagination'></div>
              </div>
              <div
                ref={navigationNextRef}
                className='swiper-button-next d-none d-md-block'
              ></div>
              <div
                ref={navigationPrevRef}
                className='swiper-button-prev d-none d-md-block'
              ></div>
            </div>
          </section>
        )}
      </div>
    </section>
  );
};

export default FirstSection;
