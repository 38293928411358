import { useContext, useEffect } from "react";
import ProductsCategory from "@components/ProductsList/ProductsCategory";
import { useSearchParams } from "react-router-dom";
import { ICategory } from "types/categoryTypes";
import { BodyContext } from "contexts/BodyContext/BodyContext";
import ProductsListFilter from "@components/ProductsList/ProductsListFilter";
import BreadCrumb from "@components/ProductsList/BreadCrumb";
import CategoriesSkeleton from "@components/ProductsList/CategoriesSkeleton";
import { PublicContext } from "contexts/PublicContext/PublicContext";

const ProductsList = () => {
  let [searchParams, setSearchParams] = useSearchParams();

  const { loading: bodyLoading, categories, errorCategories } = useContext(BodyContext);
  const { setSiteTitle } = useContext(PublicContext);

  const parentCategoryCode = searchParams.get("anakategori") ?? "all";

  const parentCategory =
    categories &&
    categories.find(
      (category: ICategory) => category.code === parentCategoryCode
    );

  const childCategoryCode = searchParams.get("altkategori") ?? "all";

  const childCategory: any =
    parentCategory &&
    parentCategory.children.find(
      (category: ICategory) => category.code === childCategoryCode
    );
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setSiteTitle(`${childCategory?.name ?? "Ürünler"} `);
  }, [childCategory]);

  const handleSearchParams = (categoryCode: string) => {
    setSearchParams({
      anakategori: parentCategoryCode ?? "",
      altkategori: categoryCode,
    });
  };

  return (
    <>
      <section className="mb-4 mb-md-5">
        <section className="bg:white shadow:9 py-4">
          <div className="container">
            <BreadCrumb
              childCategory={childCategory}
              parentCategory={parentCategory}
            />
            {parentCategory && !bodyLoading && !errorCategories ? (
              <ProductsCategory
                handleSearchParams={handleSearchParams}
                activeChildCategory={childCategoryCode ? childCategoryCode : ""}
                categories={
                  !parentCategory
                    ? categories
                    : categories.find(
                        (cat: ICategory) => cat.code === parentCategoryCode
                      )
                }
              />
            ) : errorCategories ? (
              errorCategories
            ) : (
              <CategoriesSkeleton />
            )}
          </div>
        </section>
      </section>
      <ProductsListFilter
        parentCategory={parentCategory}
        childCategoryCode={childCategoryCode}
      />
    </>
  );
};

export default ProductsList;
