import React, { useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import { ICategory } from "types/categoryTypes";

interface IProps {
  handleSearchParams: (categoryId: string) => void;
  activeChildCategory: string;
  categories: ICategory | any;
}

const ProductsCategory = ({
  handleSearchParams,
  activeChildCategory,
  categories,
}: IProps) => {
  const paginationRef = React.useRef<any>(null);

  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  const [customClass, setCustomClass] = useState("");

  const getInitialSlide = () => {
    const categoryindex = categories.children.findIndex(
      (child: { code: string; }) => child.code === activeChildCategory
    );
    return categoryindex !== -1 ? categoryindex : 0;
  };

  const sortedCategories = categories.children.sort(function (a: { name: number; }, b: { name: number; }) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  const [swiperSliderFirst, setSwiperSliderFirst] = useState<any>(null);
  return (
    <div className="position-relative carouselType-3 carouselType-3--5">
      <div
        className={`swiper carouselType-3__item carouselType-3__item--5 ${customClass}`}
      >
        <Swiper
          modules={[Navigation, Pagination]}
          spaceBetween={9}
          slidesPerView={"auto"}
          onSwiper={(swiper) => setSwiperSliderFirst(swiper)}
          freeMode
          initialSlide={getInitialSlide()}
          grabCursor
          // onReachBeginning={() => setCustomClass("rightShadow")}
          // onFromEdge={() => setCustomClass("rightShadow leftShadow")}
          // onReachEnd={() => setCustomClass("leftShadow")}
          speed={150}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          className="swiper-wrapper"
          pagination={{
            el: paginationRef.current,
            clickable: true,
            dynamicBullets: true,
          }}
        >
          <SwiperSlide key={"all"} className="swiper-slide">
            <button
              onClick={() => handleSearchParams("all")}
              className={`${
                "all" === activeChildCategory ? "active:orange" : ""
              } buttonStyle buttonStyle--paddingElem c:dark ff:regular fs:15 d-inline-block bg:light:13 text-center radius:100 hover:bg:orange active`}
              type="button"
              aria-label="category button"
              style={
                {
                  "--btnPaddingElem": "0.688rem 1.438rem",
                } as React.CSSProperties
              }
            >
              Tümü
            </button>
          </SwiperSlide>
          {sortedCategories &&
            sortedCategories.map((category: ICategory) => (
              <SwiperSlide key={category.id} className={`swiper-slide`}>
                <button
                  onClick={() => handleSearchParams(category.code)}
                  className={`${
                    category.code === activeChildCategory ? "active:orange" : ""
                  } buttonStyle buttonStyle--paddingElem c:dark ff:regular fs:15 d-inline-block bg:light:13 text-center radius:100 hover:bg:orange active`}
                  type="button"
                  aria-label="category button"
                  style={
                    {
                      "--btnPaddingElem": "0.688rem 1.438rem",
                    } as React.CSSProperties
                  }
                >
                  {category.name}
                </button>
              </SwiperSlide>
            ))}
        </Swiper>

        <div
          ref={paginationRef}
          className="swiper-pagination mt-3 d-block d-md-none position-relative"
        ></div>
      </div>
      <div
        ref={navigationNextRef}
        className="swiper-button-next d-none d-md-block"
      ></div>
      <div
        ref={navigationPrevRef}
        className="swiper-button-prev d-none d-md-block"
      ></div>
    </div>
  );
};

export default ProductsCategory;
