import { useEffect, useState } from "react";
import BlogDetail from "@components/Blog/BlogDetail";
import { Link, useParams } from "react-router-dom";
import BenzerKonular from "@components/Blog/BenzerKonular";
import useApi from "hooks/useApi";
import { getSinglePost } from "services/post.service";
import BlogMenuDetay from "@components/Blog/BlogMenuDetay";
/* eslint-disable react-hooks/exhaustive-deps */
const BlogDetay = () => {
  const { id } = useParams();
  const { loading, handleApi, success, data, error } = useApi({
    loading: true,
  });

  useEffect(() => {
    handleApi(getSinglePost, id);
  }, [id]);

  const [activeCategory, setActiveCategory] = useState("");

  return (
    <>
      <section className="bg:white shadow:9 pt-4">
        <div className="container">
          <section className="breadcrumb pb-4">
            <ul className="c:dark fs:14 lt:3">
              <li>
                <Link to="/anasayfa">Anasayfa</Link>
              </li>
              <li>
                <Link to="/blog">Turuncu Blog</Link>
              </li>
            </ul>
          </section>
          <BlogMenuDetay activeCategory={activeCategory} />
        </div>
      </section>
      <section className="mt-4 mt-sm-5 mb-5">
        <div className="container">
          {error ? (
            error
          ) : (
            <BlogDetail
              post={data}
              setActiveCategory={setActiveCategory}
              blogLoading={loading}
            />
          )}
        </div>
      </section>
      {error ? error : success && <BenzerKonular post={data} />}
    </>
  );
};

export default BlogDetay;
