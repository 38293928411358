const NoData = ({ text }: { text: string }) => {
  return (
    <section className="bg:white radius:12 py-5 px-3 text-center border:1 shadow:4">
      <div className="row g-0 justify-content-center w-100">
        <div className="col-12 col-lg-8">
          <div className="c:dark fs:16 ff:light">
            <p>{text}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NoData;
