import React, { useState, useEffect } from "react";
import iconClose from "@images/icon-close.svg";
import { IPlan, ITdkProduct, ITdkProducts } from "types/productTypes";
import iconTdkStar from "@images/iconTdkStar.svg";
import iconSuccessTick from "@images/icon-success-tick.svg";
import { ISelectedWareHouse } from "views/TuruncuHat/TuruncuDonemsel";
import { Link } from "react-router-dom";
import { useUser } from "hooks/useUser";

interface IProps {
  activePlan: string;
  selectedPlan: string;
  handleActivePopup: (
    popup: string,
    product?: any,
    wareHouse?: ISelectedWareHouse | any,
    isPlanPop?: boolean
  ) => void;
  handleActivePlan: (index: string) => void;
  handleSelectPlan: (index: string) => void;
  selectedProduct?: ITdkProduct;
  selectedWareHouse: ISelectedWareHouse;
  data: ITdkProducts;
}

const PlanPopup = ({
  activePlan,
  handleActivePopup,
  handleActivePlan,
  handleSelectPlan,
  selectedPlan,
  selectedProduct,
  selectedWareHouse,
  data,
}: IProps) => {
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [plan, setPlan] = useState<IPlan>();
  const [loading, setLoading] = useState(false);

  const handleSelect = (plan: IPlan) => {
    setPlan(plan);
    setConfirmDialog(true);
  };

  const handleConfirm = () => {
    setLoading(true);
    handleSelectPlan(plan!.Id);
  };

  useEffect(() => {
    setConfirmDialog(false);
    setLoading(false);
    return () => {};
  }, [selectedPlan]);

  const checkPlans = (planId: string, index: number) => {
    const product = data?.products?.find(
      (elem) => elem.productId === selectedProduct?.productId
    );

    const planIndex = product?.plans?.findIndex(
      (pln) => pln.Id === selectedPlan
    );

    if (index < planIndex!) {
      return true;
    } else return false;
  };

  const { checkUserRole } = useUser();

  return (
    <>
      {confirmDialog ? (
        <section className="popupWrapper popupWrapper--sm animate__animated animate__fadeIn animate__faster">
          <div className="popupWrapper__content bg:white radius:20">
            <div className="popupWrapper__content-head d-flex align-items-center justify-content-between px-3 px-sm-4 radius:20 bg:white">
              <div></div>
              <div className="ps-4">
                <button
                  onClick={() => setConfirmDialog(false)}
                  aria-label="close popup"
                  className="popupWrapper__close buttonStyle buttonStyle--light buttonStyle--paddingElem radius:full bg:white shadow:4"
                  type="button"
                  style={{ "--btnPaddingElem": "0" } as React.CSSProperties}
                >
                  <img src={iconClose} alt="" />
                </button>
              </div>
            </div>
            <div className="px-3 px-sm-4 pb-3 pb-sm-4">
              <div className="row row-cols-1 text-center gx-0 gy-3">
                <div className="col">
                  <div className="iconSize:32">
                    <img className="mx-auto" src={iconSuccessTick} alt="" />
                  </div>
                </div>
                <div className="col">
                  <p className="ff:regular c:dark fs:16">
                    <strong className="c:orange d-block mb-1">
                      {plan?.name}
                    </strong>
                    Yukarıdaki plana katılmak istediğinize
                    <br />
                    emin misiniz?
                  </p>
                </div>
                <div className="col">
                  <div className="row gx-2 gx-sm-3 gy-0">
                    <div className="col">
                      <button
                        onClick={handleConfirm}
                        disabled={loading}
                        aria-label="Popup Button"
                        className={`buttonStyle hover:opacity:8 buttonStyle--submit c:white ff:bold fs:14 bg:orange d-flex align-items-center justify-content-center w-100 radius:12 ${
                          loading ? "spinnerElem" : null
                        }`}
                        style={
                          { "--form-height": "2.8rem" } as React.CSSProperties
                        }
                      >
                        Katıl
                      </button>
                    </div>
                    <div className="col">
                      <button
                        onClick={() => setConfirmDialog(false)}
                        aria-label="Popup Button"
                        className="buttonStyle hover:opacity:8 buttonStyle--submit c:white ff:bold fs:14 bg:orange:8 d-flex align-items-center justify-content-center w-100 radius:12"
                        style={
                          { "--form-height": "2.8rem" } as React.CSSProperties
                        }
                      >
                        Vazgeç
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className="popupWrapper animate__animated animate__fadeIn animate__faster">
          <div className="popupWrapper__content bg:white radius:20">
            <div className="popupWrapper__content-head d-flex align-items-center justify-content-between px-4 radius:20 bg:white">
              <div>
                <h1 className="ff:bold fs:18 c:dark lh:2">Plan Detayı</h1>
              </div>
              <div className="ps-4">
                <button
                  onClick={() => handleActivePopup("null")}
                  aria-label="close popup"
                  className="popupWrapper__close buttonStyle buttonStyle--light buttonStyle--paddingElem radius:full bg:white shadow:4"
                  type="button"
                  style={{ "--btnPaddingElem": "0" } as React.CSSProperties}
                >
                  <img src={iconClose} alt="" />
                </button>
              </div>
            </div>
            <div className="px-4 pb-4">
              <section className="planPopup">
                <div className="row row-cols-1 gx-0 gy-4">
                  <div className="col">
                    <div className="productListCard">
                      <div className="productListCard__box">
                        <Link
                          className="productHover"
                          aria-label="link card"
                          to={`/urun/${selectedProduct?.productId}`}
                        >
                          <div className="row row-cols-md-auto row-cols-1 align-items-center gy-3 gy-md-0 gx-0 gx-md-4">
                            <div className="col">
                              <div className="productListCard__box-img">
                                <img
                                  src={`data:${selectedProduct?.thumbnail?.contentType};base64,${selectedProduct?.thumbnail?.fileContents}`}
                                  alt={
                                    selectedProduct?.thumbnail?.contentType
                                      ? selectedProduct.thumbnail?.contentType
                                      : "alttext"
                                  }
                                  loading="lazy"
                                />
                              </div>
                            </div>
                            <div className="col">
                              <p className="c:dark ff:bold fs:16 mb-2 text-center text-md-start mb-2 lh:1">
                                {selectedProduct?.displayBrandName}
                              </p>
                              <p className="c:dark ff:regular text-center text-md-start fs:14">
                                {selectedProduct?.displayProductForm}
                              </p>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>

                  {selectedWareHouse.name && (
                    <div className="col">
                      <p className="ff:bold c:dark fs:16 mb-2">Depo</p>
                      <div
                        className="bg:orange:11 borderStyle radius:12 shadow:9 p-3 text-center"
                        style={
                          {
                            "--gsk-border-width": "0.5px",
                            "--gsk-border-color": "#FFD278",
                          } as React.CSSProperties
                        }
                      >
                        <div className="row row-cols-1 row-cols-md-auto gx-0 gy-2 gy-md-0 justify-content-between align-items-center">
                          <div className="col">
                            <div className="d-flex justify-content-center align-items-center">
                              <p className="c:dark fs:14 ff:regular smText smText--xs">
                                {selectedWareHouse.name}
                              </p>
                            </div>
                          </div>

                          {data.termState === 1 ? (
                            <div className="col">
                              <button
                                onClick={() =>
                                  handleActivePopup("depo", "", "", true)
                                }
                                type="button"
                                aria-label="depo sec"
                                className="buttonStyle hover:opacity:8 py-2 px-3 c:white ff:bold fs:13 bg:orange text-center radius:5 d-inline-block"
                              >
                                Depo Değiştir
                              </button>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="col">
                    <div className="mb-2">
                      <div className="row row-cols-auto justify-content-between align-items-center g-0">
                        <div className="col">
                          <p className="ff:bold c:dark fs:16">Plan Seçin</p>
                        </div>
                        <div className="col">
                          <p className="text-uppercase fs:11 lt:4 c:orange ff:bold">
                            ZORUNLU
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* <!-- aktif plan state class'ı "activePlan", accordion açıldığında iconun değişmesi için gerekli class, "collapseActiveItem" --> */}
                    <section className="collapsePopup">
                      <ul className="collapseLayout">
                        {selectedProduct?.plans?.map((plan, index) => (
                          <li
                            key={plan.Id}
                            onClick={() => handleActivePlan(plan.Id)}
                            className={`collapseLayout__item ${
                              selectedPlan === plan.Id ? "activePlan" : ""
                            } ${
                              activePlan === plan.Id ? "collapseActiveItem" : ""
                            }`}
                          >
                            <h1
                              className="collapseLayout__thumb d-flex align-items-center py-3 px-3 pe-5 ff:regular fs:14 c:dark bg:white radius:12 borderStyle shadow:4"
                              style={
                                {
                                  "--gsk-border-width": "0.031rem",
                                  "--gsk-border-color": "#d7d7d7",
                                } as React.CSSProperties
                              }
                            >
                              <div className="d-block d-sm-flex align-items-center">
                                <span className="pe-3 d-block">
                                  {plan.name}
                                </span>
                                {plan.suggested && (
                                  <div className="d-flex align-items-center bg:gradient:green text-uppercase ff:bold c:white fs:11 py-2 px-0 px-3 radius:100 lh:1 d-inline-block mt-2 mt-sm-0">
                                    <div className="flex-shrink-0 iconSize:13">
                                      <img src={iconTdkStar} alt="" />
                                    </div>
                                    <div className="flex-grow-1 ms-1 ps-1">
                                      <p>Önerilen</p>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </h1>
                            <div
                              className={`collapseLayout__panel ${
                                plan.Id === activePlan ? "" : "d-none"
                              }`}
                            >
                              <div className="my-3 p-3 radius:12 border:1">
                                <div>
                                  <table>
                                    <tbody>
                                      <tr>
                                        <td>Plan</td>
                                        <td>{plan.name}</td>
                                      </tr>
                                      <tr>
                                        <td>MF Dahil Toplam Alım</td>
                                        <td>{plan.target}</td>
                                      </tr>
                                      <tr>
                                        <td>
                                          Anamal Alımına Verilecek Ek MF Oranı
                                        </td>
                                        <td>%{plan.percentageFreeGoods}</td>
                                      </tr>
                                      <tr>
                                        <td>
                                          Ek MF Hak Edişi İçin Azami Alım Limiti
                                        </td>
                                        <td>{plan.limit}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                {/* <div className="col">
                              <div className="row row-cols-1 gx-0 gy-4">
                                <div className="col">
                                  <div
                                    className="alert bg:green:2 p-3 radius:12"
                                    style={
                                      {
                                        "--alert-borderColor":
                                          "rgba(57, 189, 141, 0.22)",
                                      } as React.CSSProperties
                                    }
                                  >
                                    <div className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img src={iconInfoGreen} alt="" />
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <p className="c:green lt:2 ff:regular fs:14">
                                          {" "}
                                          success text{" "}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div> */}
                                {plan.Id !== selectedPlan &&
                                  !checkPlans(plan.Id, index) &&
                                  data.termState === 1 &&
                                  checkUserRole("OnlyPharmacy") && (
                                    <button
                                      onClick={() => handleSelect(plan)}
                                      aria-label="plana katıl"
                                      className={`buttonStyle buttonStyle--icon buttonStyle--submit c:white ff:bold fs:16 bg:orange d-block w-100 text-center radius:12 mt-3`}
                                      type="button"
                                      style={
                                        {
                                          "--form-height": "3rem",
                                        } as React.CSSProperties
                                      }
                                    >
                                      Plana Katıl
                                    </button>
                                  )}
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </section>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default PlanPopup;
