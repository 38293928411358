import OrderList from "@components/Orders/OrderList";
import React, { useEffect } from "react";
import { getMyWarehouseOrders } from "services/user.service";
import useApi from "hooks/useApi";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
/* eslint-disable react-hooks/exhaustive-deps */
function StoreOrders({ id }: any) {
  const { loading, handleApi, data } = useApi({
    loading: true,
  });
  useEffect(() => {
    handleApi(getMyWarehouseOrders, id);
    return () => {};
  }, [id]);

  return (
    <section className="mb-5">
      <div className="container">
        <div className="mb-3 pb-1">
          <div className="row row-cols-1 row-cols-sm-auto gx-0 gy-3 gy-sm-0 justify-content-between align-items-center">
            <div className="col">
              {loading ? (
                <Skeleton height={30} width={150} />
              ) : (
                <h1 className="c:dark ff:bold fs:24 text-center text-lg-start">
                  Daha önceki siparişlerim
                </h1>
              )}
            </div>
            <div className="col">
              <div className="d-flex justify-content-center">
                {loading ? (
                  <Skeleton
                    height={49.59}
                    width={179}
                    borderRadius={"0.75rem"}
                  />
                ) : (
                  <Link
                    to="/hesabim/gecmis-siparislerim"
                    aria-label="sepete ekle"
                    className="buttonStyle buttonStyle--paddingElem hover:opacity:8 c:white ff:bold fs:16 bg:orange text-center radius:12 d-inline-block"
                    style={
                      {
                        "--btnPaddingElem": "0.8rem 2.8rem",
                      } as React.CSSProperties
                    }
                  >
                    Tümünü Gör
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
        <OrderList loading={loading} orders={data} isPreorder={false} />
      </div>
    </section>
  );
}

export default StoreOrders;
