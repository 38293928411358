import { notificationTexts } from "utility/notificationTexts";
import { z } from "zod";

const passwordValidation: any = z
  .string({
    required_error: notificationTexts.emptyInput,
    invalid_type_error: "Lütfen bir şifre giriniz",
  })
  .min(6, { message: "Şifreniz en az 6 Karakter olmalı." })
  .nonempty({ message: "Lütfen bir şifre giriniz." })
  .regex(new RegExp(".*[a-z].*"), "En az bir küçük karakter olmalı.")
  .regex(new RegExp(".*[A-Z].*"), "En az bir büyük karakter olmalı.")
  .regex(new RegExp(".*\\d.*"), "En az bir rakam olmalı.")
  .regex(
    new RegExp("(?=.*[!?@#$%^&*+-.,]+)"),
    "En az bir özel karakter olmalı."
  );

export const passwordForm = z
  .object({
    password: passwordValidation,
    rePassword: passwordValidation,
  })
  // .refine((data) => data.password === data.rePassword, {
  //   message: "Şifreler eşleşmiyor",
  //   path: ["confirmPassword"], // path of error
  // });

const addressValidation = z
  .string({
    required_error: "Lütfen adres alanını boş bırakmayınız.",
    invalid_type_error: "Lütfen adres alanını boş bırakmayınız.",
  })
  .nonempty({ message: "Lütfen adres alanını boş bırakmayınız." })
  .min(5, { message: notificationTexts.addressMin })
  .max(500, { message: notificationTexts.addressMax });

const companyValidation = z
  .string({
    required_error: notificationTexts.emptyInput,
    invalid_type_error: notificationTexts.emptyInput,
  })
  .nonempty({ message: notificationTexts.emptyInput })
  .min(2, { message: notificationTexts.companyMin })
  .max(500, { message: notificationTexts.companyMax });

const nameValidation = z
  .string({
    required_error: notificationTexts.emptyInput,
    invalid_type_error: notificationTexts.emptyInput,
  })
  .nonempty({ message: notificationTexts.company })
  .min(2, { message: notificationTexts.nameMin })
  .max(500, { message: notificationTexts.nameMin });

const surnameValidation = z
  .string({
    required_error: notificationTexts.emptyInput,
    invalid_type_error: notificationTexts.emptyInput,
  })
  .nonempty({ message: notificationTexts.company })
  .min(2, { message: notificationTexts.surnameMin })
  .max(500, { message: notificationTexts.surnameMin });

const cityValidation = z
  .string({
    required_error: notificationTexts.noCity,
    invalid_type_error: notificationTexts.noCity,
  })
  .nonempty({ message: notificationTexts.noCity });

const townValidation = z
  .string({
    required_error: notificationTexts.noTown,
    invalid_type_error: notificationTexts.noTown,
  })
  .nonempty({ message: notificationTexts.noTown });

const genderValidation = z
  .string({
    required_error: notificationTexts.emptyInput,
    invalid_type_error: notificationTexts.emptyInput,
  })
  .nonempty({ message: notificationTexts.emptyInput });

export const emailValidation = z
  .string({
    required_error: notificationTexts.emptyInput,
    invalid_type_error: notificationTexts.wrongFormat,
  })
  .nonempty({ message: notificationTexts.emptyInput })
  .email({ message: notificationTexts.wrongFormat });

export const phoneValidation = z
  .string({
    required_error: notificationTexts.emptyInput,
    invalid_type_error: "Lütfen geçerli bir cep telefon numarası giriniz.",
  })
  .startsWith("5", { message: "Lütfen geçerli bir cep telefon numarası giriniz." })
  .nonempty({ message: "Lütfen geçerli bir cep telefon numarası giriniz." })
  .min(10, {
    message: 'Cep Telefon numarası başında "0" olmadan En az 10 Karakter olmalı.',
  })
  .max(10, {
    message: 'Cep Telefon numarası başında "0" olmadan En çok 10 Karakter olmalı.',
  });

export const glnValidation = z
  .string()
  .nonempty({ message: notificationTexts.glnKodu })
  .startsWith("868", { message: "Gln Kodunuz 868 ile başlamalıdır." })
  .min(13, { message: "Gln Kodu 13 Karakter olmalı." })
  .max(13, { message: "Gln Kodu 13 Karakter olmalı." });

export const smsCodeValidation = z
  .string()
  .nonempty({ message: notificationTexts.emptyInput })
  .min(6, { message: "En az 6 Karakter olmalı." })
  .max(6, { message: "En çok 6 Karakter olmalı." });

//------------------------------------------------------------------------------------------------//

export const tokenValidation = z
  .string()
  .nonempty({ message: notificationTexts.emptyInput })
  .min(6, { message: "En az 6 Karakter olmalı." })
  .max(6, { message: "En çok 6 Karakter olmalı." });

export const resetPasswordValidations: any = {
  password: passwordValidation,
  passwordVerify: passwordValidation,
};

export const changePasswordValidations = z.object({
  currentPassword: passwordValidation,
  password: passwordValidation,
  rePassword: passwordValidation,
});

export const emailsValidations: any = {
  email: emailValidation,
  reEmail: emailValidation,
};

export const personalInformationValidations: any = {
  email: emailValidation,
  firstName: z
    .string({
      required_error: notificationTexts.emptyInput,
      invalid_type_error: notificationTexts.emptyInput,
    })
    .nonempty({ message: notificationTexts.emptyInput })
    .min(2, { message: notificationTexts.nameMin })
    .max(50, { message: notificationTexts.nameMax }),
  lastName: z
    .string({
      required_error: notificationTexts.emptyInput,
      invalid_type_error: notificationTexts.emptyInput,
    })
    .nonempty({ message: notificationTexts.emptyInput })
    .min(2, { message: notificationTexts.surnameMin })
    .max(50, { message: notificationTexts.surnameMax }),
  gender: genderValidation,
  mobile: phoneValidation,
  password: passwordValidation,
  rePassword: passwordValidation,
  privacyPolicy: z.literal(true),
  termOfUse: z.literal(true),
};

export const updateProfileValidations: any = {
  name: nameValidation,
  surname: surnameValidation,
  gln: glnValidation,
  gender: genderValidation,
  mobile: phoneValidation,
  company: companyValidation,
  city: cityValidation,
  town: townValidation,
  address: addressValidation,
};

export const externalProfileValidations: any = {
  name: nameValidation,
  surname: surnameValidation,
  mobile: phoneValidation,
};

export const gskProfileValidations: any = {
  name: nameValidation,
  surname: surnameValidation,
};

export const companyInformationValidations: any = {
  gln: glnValidation,
  company: companyValidation,
  city: cityValidation,
  town: townValidation,
  address: addressValidation,
};
