import { useRef, useContext } from "react";
import { IProductAll } from "types/productTypes";
import AnlikKampanyalarProduct from "./AnlikKampanyalarProduct";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";
import { BodyContext } from "contexts/BodyContext/BodyContext";
import NoData from "@components/General/NoData";

interface IProps {
  limit: number;
  totalProductsLength: number;
  setLimit: (limit: number) => void;
  hasOfferProducts: IProductAll[];
  activeItem: string;
  setActiveItem: (id: string) => void;
}

const AnlikKampanyalarProducts = ({
  limit,
  setLimit,
  hasOfferProducts,
  totalProductsLength,
  activeItem,
  setActiveItem,
}: IProps) => {
  const titleRef = useRef<HTMLHeadingElement>(null);

  const getData = () => {
    setLimit(limit + 6);
  };

  const { loading, error } = useContext(BodyContext);

  return (
    <>
      <h1
        ref={titleRef}
        className="c:dark ff:bold fs:24 mb-3 pb-1 text-center text-lg-start"
      >
        Anlık Kampanyalar
      </h1>
      {error ? <NoData text={error} /> : null}
      {(!error && hasOfferProducts?.length === 0 && !loading) ?
          <NoData text='Henüz bir Anlık Kampanya bulunmamaktadır. Anlık Kampanyalar için takipte kalın' />
         : null}
      <InfiniteScroll
        className='row row-cols-1 row-cols-lg-2 row-cols-xl-3 row-cols-xxl-4 g-3'
        style={{ overflow: 'revert !important' }}
        dataLength={totalProductsLength && totalProductsLength - limit}
        next={getData}
        hasMore={hasOfferProducts && totalProductsLength > limit}
        loader={<></>}
      >
        {loading ? (
          <>
            <div className="col">
              <Skeleton
                height={362.05}
                width={"100%"}
                count={1}
                borderRadius={"1rem"}
              />
            </div>
            <div className="col">
              <Skeleton
                height={362.05}
                width={"100%"}
                count={1}
                borderRadius={"1rem"}
              />
            </div>
            <div className="col">
              <Skeleton
                height={362.05}
                width={"100%"}
                count={1}
                borderRadius={"1rem"}
              />
            </div>
            <div className="col">
              <Skeleton
                height={362.05}
                width={"100%"}
                count={1}
                borderRadius={"1rem"}
              />
            </div>
            <div className="col">
              <Skeleton
                height={362.05}
                width={"100%"}
                count={1}
                borderRadius={"1rem"}
              />
            </div>
            <div className="col">
              <Skeleton
                height={362.05}
                width={"100%"}
                count={1}
                borderRadius={"1rem"}
              />
            </div>
          </>
        ) : hasOfferProducts?.length > 0 ? (
          hasOfferProducts.map((product: IProductAll) => (
            <AnlikKampanyalarProduct
              product={product}
              key={product.id}
              setActiveItem={setActiveItem}
              activeItem={activeItem}
            />
          ))
        ) : null}
      </InfiniteScroll>

      {/* {products && (
        <ListPagination
          path="/anlik-kampanyalar"
          searchParams={searchParams}
          items={products}
        />
      )} */}
    </>
  );
};

export default AnlikKampanyalarProducts;
