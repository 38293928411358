import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { endpoints } from "constants/endpoints";
import jwt_decode from "jwt-decode";
import { v4 as uuidv4 } from "uuid";
import { AppState } from "contexts/AppContext/AppState";
import { useAuth } from "hooks/useAuth";
import { decryptData, encryptData } from "./cryptoLocal";

let isTokenRefreshing = false; // Flag to prevent multiple refresh-token calls
let tokenRefreshSubscribers: ((token: string) => void)[] = []; // Array to hold waiting requests

const axiosInstance = axios.create({
  baseURL: endpoints.baseUrl,
});

export const generateSessionKey = () => {
  return uuidv4();
};
export const getKongApiKey = () => {
  switch (process.env.REACT_APP_NODE_ENV) {
    case "dev":
      return process.env.REACT_APP_DEV_KONG_API_KEY;
    case "uat":
      return process.env.REACT_APP_UAT_KONG_API_KEY;
    case "test":
      return process.env.REACT_APP_TEST_KONG_API_KEY;
    default:
      return process.env.REACT_APP_PROD_KONG_API_KEY;
  }
};

export const getOriginUrl = () => {
  switch (process.env.REACT_APP_NODE_ENV) {
    case "dev":
      return process.env.REACT_APP_DEV_ORIGIN_URL;
    case "uat":
      return process.env.REACT_APP_UAT_ORIGIN_URL;
    case "test":
      return process.env.REACT_APP_TEST_ORIGIN_URL;
    default:
      return process.env.REACT_APP_PROD_ORIGIN_URL;
  }
};
/* eslint-disable react-hooks/exhaustive-deps */
const AxiosInterceptor = ({ children }: { children: React.ReactElement }) => {
  const [isSet, setIsSet] = useState(false);
  //const navigate = useNavigate();
  const { sendRequest, completed } = useContext(AppState);
  const { logout } = useAuth();

  if (!sessionStorage.getItem("sessionKey")?.length) {
    const sessionKey = generateSessionKey();
    sessionStorage.setItem("sessionKey", sessionKey);
  }
  if (!localStorage.getItem("clientId")?.length) {
    const clientId = generateSessionKey();
    localStorage.setItem("clientId", clientId);
  }
  if (!localStorage.getItem("applicationId")?.length) {
    const applicationId = generateSessionKey();
    localStorage.setItem("applicationId", applicationId);
  }

  const isTokenExpired = (token: string) => {
    const props: any = jwt_decode(token);
    const expiresIn: number = props.exp;
    // Calculate a buffer time before the token actually expires
    const bufferTimeInSeconds = 60; // You can adjust this buffer time
    const currentTimeInSeconds = Math.floor(Date.now() / 1000);
    return expiresIn < bufferTimeInSeconds + currentTimeInSeconds;
  };

  const setItem = (key: string, value: string) => {
    localStorage.setItem(key, encryptData(value));
  };

  const setLocalStorage = (resp: any) => {
    setItem("user", JSON.stringify(resp.data.applicationUser));
    setItem("accessToken", resp.data.accessToken);
    setItem("refreshToken", resp.data.refreshToken);
    setItem("accessTokenExpireDate", resp.data.accessTokenExpireDate);
    setItem("refreshTokenExpireDate", resp.data.refreshTokenExpireDate);
  };

  useEffect(() => {
    const requestInterceptor = axiosInstance.interceptors.request.use(
      async (request) => {
        request.headers.Accept = "application/json";
        let accessToken;
        const val = localStorage.getItem("accessToken");
        if (val) {
          accessToken = decryptData(localStorage.getItem("accessToken"));
        }

        const clientId = localStorage.getItem("clientId");
        request.headers["X-Client-UID"] = clientId;
        request.headers["Access-Control-Allow-Origin"] = getOriginUrl();

        request.headers["apikey"] = getKongApiKey();
        request.headers["X-Tab-Session-UID"] =
          sessionStorage.getItem("sessionKey");
        request.headers["X-Application-UID"] =
          localStorage.getItem("applicationId");
        if (accessToken) {
          request.headers.Authorization = `Bearer ${accessToken}`;
          if (isTokenExpired(accessToken)) {
            if (!isTokenRefreshing) {
              isTokenRefreshing = true;
              const refreshToken = decryptData(
                localStorage.getItem("refreshToken")
              );
              try {
                const resp = await axios.post(
                  `${endpoints.user}/refresh-token`,
                  {
                    accessToken: accessToken,
                    refreshToken: refreshToken,
                  },
                  {
                    headers: {
                      "Content-Type": "application/json",
                      apikey: getKongApiKey(),
                      "Access-Control-Allow-Origin": getOriginUrl(),
                      "X-Client-UID": localStorage.getItem("clientId"),
                      "X-Tab-Session-UID": sessionStorage.getItem("sessionKey"),
                      "X-Application-UID":
                        localStorage.getItem("applicationId"),
                    },
                  }
                );

                setLocalStorage(resp);

                // Notify all waiting requests with the new access token

                tokenRefreshSubscribers.forEach((callback) =>
                  callback(resp.data.accessToken)
                );
                tokenRefreshSubscribers = [];
                isTokenRefreshing = true;
                accessToken = resp.data.accessToken;
              } catch (error) {
                // console.log(error);
                localStorage.clear();
                logout();
              }
            } else {
              // If a refresh-token call is already in progress, wait and retry the original request
              const refresTokenAsync = () =>
                new Promise<string>((resolve) => {
                  tokenRefreshSubscribers.push((token) => {
                    resolve(token);
                  });
                });
              accessToken = await refresTokenAsync();
            }
            // console.log("Access-Token", accessToken);
          }
        }

        const request_id = uuidv4();
        Object.assign(request, { metadata: { request_id } });
        sendRequest(request_id);
        request.headers.Authorization = `Bearer ${accessToken}`;
        return request;
      }
    );

    // console.log("Add interceptor", axiosInstance);
    const responseInterceptor = axiosInstance.interceptors.response.use(
      (response) => {
        if (response.status === 401) {
          // console.log("response.status === 401", response);
          localStorage.clear();
          logout();
          return response;
        }
        if (response.config &&"metadata" in response.config) {
          const { request_id } = response.config.metadata as any;
          completed(request_id);
        }

        return response;
      },
      async (error) => {
        if (error.response?.config && "metadata" in error.response.config) {
          const { request_id } = error.response.config.metadata as any;
          completed(request_id);
        }

        if (error.response && error.response.status === 401) {
          localStorage.clear();
          logout();
          return Promise.reject(error);
        }
        return Promise.reject(error);
      }
    );

    setIsSet(true);
    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  return isSet ? children : null;
};

const api = axiosInstance;

export default api;
export { AxiosInterceptor };
