import { createContext } from "react";
import { IProductAll } from "types/productTypes";
import { ICategory } from "types/categoryTypes";

interface IBodyContext {
  handleBody: (text: string) => void;
  handlePopup: (type: string, popupData?: any) => void;
  editProducts: (prodId: string, val: boolean) => void;
  customClass: string;
  activePopup: string;
  data: any;
  loading: boolean;
  products: IProductAll[] | [];
  categories: ICategory[] | [];
  error?: string;
  errorCategories?: string;
}

export const BodyContext = createContext<IBodyContext>({
  handleBody: () => {},
  handlePopup: () => {},
  editProducts: () => {},
  customClass: "",
  activePopup: "",
  data: "",
  loading: false,
  products: [],
  categories: [],
});

export const enum ProductFilters {
  Popular,
  Turuncu,
  BestSeller,
  Suggested,
}
