import { useContext, useEffect } from "react";
import QuickOrdersAccordion from "@components/QuickOrders/QuickOrdersAccordion";
import { QuickOrderContext } from "contexts/QuickOrderContext/QuickOrderContext";
import { ReactComponent as Spinning } from "assets/spinning.svg";
import QuickOrdersSkeletonAccordion from "@components/QuickOrders/QuickOrdersSkeletonAccordion";
import NoData from "@components/General/NoData";
import { PublicContext } from "contexts/PublicContext/PublicContext";
/* eslint-disable react-hooks/exhaustive-deps */
const QuickOrders = () => {
  const { quickOrders, loading } = useContext(QuickOrderContext);

  const { setSiteTitle } = useContext(PublicContext);

  useEffect(() => {
    setSiteTitle("Hızlı Siparişlerim");
  }, []);

  return (
    <div className="container">
      <section className="lineTitle mb-4 pb-4">
        <div className="d-flex align-items-center">
          <div className="flex-shrink-0 me-3">
            <h1 className="c:dark ff:bold fs:24">Hızlı Siparişlerim</h1>
          </div>
          <div
            className="d-inline-block bg:orange radius:8 px-1 c:white fs:14 ff:bold ms-3 heightElem d-flex align-items-center"
            style={
              {
                "--heightElem": "1.875rem",
              } as React.CSSProperties
            }
          >
            {loading ? (
              <div className="px-2 lh:1">
                <Spinning />
              </div>
            ) : (
              <p className="px-2 py-1 lh:1">
                {" "}
                {quickOrders && quickOrders.length}{" "}
              </p>
            )}
          </div>
        </div>
      </section>
      {/* <!--#include virtual="/parts/components/fastOrder/accordion.html" --> */}
      {loading ? (
        <QuickOrdersSkeletonAccordion />
      ) : quickOrders && !quickOrders.length ? <NoData text="Hızlı siparişlerime eklenen bir ürün bulunamadı."/> : (
        <QuickOrdersAccordion orders={quickOrders} />
      )}
    </div>
  );
};

export default QuickOrders;
