import { useState, useEffect } from "react";
import iconFilter from "@images/icon-filter.svg";
import { getForms } from "services/brand.service";
import ContentSearch from "@components/General/ContentSearch";
import Skeleton from "react-loading-skeleton";

interface logo {
  fileContents: string;
  contentType: string;
  fileDownloadName: string;
  lastModified: string;
  entityTag: string;
  enableRangeProcessing: boolean;
}
export interface IBrands {
  code: string;
  name: string;
  logo: null | logo;
  id: string;
}
export interface IForm {
  code: string;
  name: string;
  id: string;
}

interface IProps {
  label: string;
  setLabel: (label: string) => void;
  brands: IBrands[] | undefined;
  selectedBrands: IBrands[];
  selectedForms: IBrands[];
  handleSelectBrands: (brand: IBrands) => void;
  handleSelectForms: (form: IBrands) => void;
  clearSelectedBrands: () => void;
  clearSelectedForms: () => void;
  setForms: (data: IBrands[]) => void;
  isOrangeProducts: boolean;
  setIsOrangeProducts: (val: boolean) => void;
  isHasOfferProducts: boolean;
  setIsHasOfferProducts: (val: boolean) => void;
  forms: IBrands[] | undefined;
}

const ProductsFilter = ({
  handleSelectBrands,
  brands,
  clearSelectedBrands,
  clearSelectedForms,
  handleSelectForms,
  selectedBrands,
  selectedForms,
  isOrangeProducts,
  setIsOrangeProducts,
  isHasOfferProducts,
  setIsHasOfferProducts,
  setForms,
  forms,
}: IProps) => {
  const [mobileFilterIsOpen, setMobileFilterIsOpen] = useState(false);
  const [brandsIsVisible, setBrandsIsVisible] = useState(false);
  const [productTypeIsVisible, setProductTypeIsVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");


/* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const handleGetForms = async () => {
      setLoading(true);
      try {
        const resp: IBrands[] = await getForms();
        setForms(resp);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    handleGetForms();
  }, []);

  if (loading) return <Skeleton width={200} height={328.16} />;

  return (
    <div className="productFilterColumn shadow:4 w-100 bg:white radius:12">
      {/* <!-- <p className="listArrow listArrow--active"> asd</p> --> */}
      <p className="c:dark fs:16:v2 ff:regular p-4 d-none d-md-block">Filtre</p>

      <div className="d-block d-md-none ">
        {/* <!-- aktiflik için  .listArrow classı yanına ".listArrow--active" classı eklenmeli. --> */}
        <button
          onClick={() => setMobileFilterIsOpen((prev) => !prev)}
          type="button"
          aria-label="toggle filter"
          className="productFilterColumn__btn productFilterColumn__btn--mobile d-block w-100 text-start p-4"
        >
          <div
            className={`listArrow ${
              mobileFilterIsOpen ? "listArrow--active" : ""
            }`}
          >
            <div className="d-flex align-items-center">
              <img
                className="productFilterColumn__btn-icon"
                src={iconFilter}
                alt=""
              />
              <p className="productFilterColumn__btn-text c:dark fs:16:v2 ff:regular ms-2">
                Filtrele
              </p>
            </div>
          </div>
        </button>
      </div>

      {/* <!-- yukarıdaki butona tıklandığında ".mobileTrigger" kısım açılıp kapanacak. responsive olduğu için bu açılıp kapanma özelliği masaüstü için geçerli değildir. İster aşağıdaki "d-none d-md-block" ile ister resize'a bağlanarak js ile media query yazılıp (767px) gerekli işlem yapılabilir.  --> */}
      <div
        className={`mobileTrigger ${
          mobileFilterIsOpen ? "" : "d-none"
        } d-md-block pb-5`}
      >
        <div className="productFilterColumn__list row row-cols-1 gx-0 gy-0">
          <div className="col">
            {/* <!-- aktiflik için butona ".productFilterColumn__btn-active" classı ve  .listArrow classı yanına ".listArrow--active" classı eklenmeli. --> */}
            <button
              onClick={() => {
                setBrandsIsVisible((prev) => !prev);
                setProductTypeIsVisible(false);
              }}
              type="button"
              aria-label="toggle filter"
              className={`productFilterColumn__btn ${
                brandsIsVisible ? "productFilterColumn__btn-active" : ""
              }  d-block w-100 text-start px-4 py-3`}
            >
              <div
                className={`listArrow ${
                  brandsIsVisible ? "listArrow--active" : ""
                }`}
              >
                <p className="productFilterColumn__btn-text c:dark fs:16:v2 ff:regular">
                  Markalar
                </p>
              </div>
            </button>
            {/* <!-- yukarıdaki butona tıklanınca  "productFilterColumn__check" classına d-none toggle --> */}
            {brandsIsVisible && (
              <div className="productFilterColumn__wrapper">
                <div className="w-100 px-2 pt-4">
                  <ContentSearch
                    setSearchQuery={setSearchQuery}
                    searchQuery={searchQuery}
                    placeHolder="Marka Adı Ara"
                  />
                </div>
                <div
                  className={`productFilterColumn__check ${
                    brandsIsVisible ? "" : "d-none"
                  } p-4`}
                >
                  <div className="scrollContentElem">
                    <div className="kz-check">
                      <div>
                        <label className="kz-check__label kz-check__label--type2">
                          <span className="d-block ff:regular fs:16:v2 c:dark">
                            Tümü
                          </span>
                          <input
                            onChange={() => clearSelectedBrands()}
                            type="checkbox"
                            checked={selectedBrands.length ? false : true}
                            id="checkbox-1"
                            name="checkbox-1"
                            aria-label="blankInput"
                            required
                          />
                          <div className="kz-check__input"></div>
                        </label>
                      </div>
                      {brands &&
                        brands
                          .filter((item) =>
                            item.name
                              ?.toLowerCase()
                              .includes(searchQuery?.toLowerCase())
                          )
                          .map((brand) => (
                            <div key={brand.code}>
                              <label className="kz-check__label kz-check__label--type2">
                                <span className="d-block ff:regular fs:16:v2 c:dark">
                                  {brand.name}
                                </span>
                                <input
                                  onChange={() => handleSelectBrands(brand)}
                                  type="checkbox"
                                  id="checkbox-2"
                                  checked={
                                    selectedBrands.filter(
                                      (slected) => slected.code === brand.code
                                    ).length
                                      ? true
                                      : false
                                  }
                                  value={brand.name}
                                  name="checkbox-2"
                                  aria-label="blankInput"
                                  required
                                />
                                <div className="kz-check__input"></div>
                              </label>
                            </div>
                          ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="col">
            <button
              onClick={() => {
                setProductTypeIsVisible((prev) => !prev);
                setBrandsIsVisible(false);
              }}
              type="button"
              aria-label="toggle filter"
              className={`productFilterColumn__btn ${
                productTypeIsVisible ? "productFilterColumn__btn-active" : ""
              }  d-block w-100 text-start px-4 py-3`}
            >
              <div
                className={`listArrow ${
                  productTypeIsVisible ? "listArrow--active" : ""
                }`}
              >
                <p className="productFilterColumn__btn-text c:dark fs:16:v2 ff:regular">
                  Ürün formu
                </p>
              </div>
            </button>

            <div
              className={`productFilterColumn__check p-4 ${
                productTypeIsVisible ? "" : "d-none"
              }`}
            >
              <div className="scrollContentElem">
                <div className="kz-check">
                  <div>
                    <label className="kz-check__label kz-check__label--type2">
                      <span className="d-block ff:regular fs:16:v2 c:dark">
                        Tümü
                      </span>
                      <input
                        onChange={() => clearSelectedForms()}
                        type="checkbox"
                        checked={selectedForms.length ? false : true}
                        id="checkbox-7"
                        name="checkbox-7"
                        aria-label="blankInput"
                        required
                      />
                      <div className="kz-check__input"></div>
                    </label>
                  </div>
                  {forms?.map((form) => (
                    <div key={form.code}>
                      <label className="kz-check__label kz-check__label--type2">
                        <span className="d-block ff:regular fs:16:v2 c:dark">
                          {form.name}
                        </span>
                        <input
                          onChange={() => handleSelectForms(form)}
                          type="checkbox"
                          id="checkbox-2"
                          name="checkbox-2"
                          checked={
                            selectedForms.filter(
                              (sForm) => sForm.code === form.code
                            ).length
                              ? true
                              : false
                          }
                          value={form.id}
                          aria-label="blankInput"
                          required
                        />
                        <div className="kz-check__input"></div>
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="switchTemplate">
          <ul>
            <li className="px-4 py-3">
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 me-3">
                  <p className="ff:regular fs:16:v2 c:dark lh:2">
                    Turuncu ürünler
                  </p>
                </div>
                <div className="flex-shrink-0">
                  <label className="switchButton">
                    <input
                      aria-label="turuncu ürünler"
                      type="checkbox"
                      onChange={() => setIsOrangeProducts(!isOrangeProducts)}
                      checked={isOrangeProducts}
                    />
                    <span className="switchButton__slider switchButton__round"></span>
                  </label>
                </div>
              </div>
            </li>
            <li className="px-4 py-3">
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 me-3">
                  <p className="ff:regular fs:16:v2 c:dark lh:2">
                    Anlık kampanyalar
                  </p>
                </div>
                <div className="flex-shrink-0">
                  <label className="switchButton">
                    <input
                      aria-label="turuncu ürünler"
                      type="checkbox"
                      onChange={() =>
                        setIsHasOfferProducts(!isHasOfferProducts)
                      }
                      checked={isHasOfferProducts}
                    />
                    <span className="switchButton__slider switchButton__round"></span>
                  </label>
                </div>
              </div>
            </li>
          </ul>
        </section>
      </div>
    </div>
  );
};

export default ProductsFilter;
