import React from 'react';
import { IPlans, ITdkProducts } from 'types/productTypes';
import { ISelectedWareHouse } from 'views/TuruncuHat/TuruncuDonemsel';
import iconCogFill from '@images/icon-cog-fill.svg';
import iconBing from '@images/icon-bing.svg';
import Skeleton from 'react-loading-skeleton';

interface IProps {
  handleActivePopup: (
    popup: string,
    product?: any,
    wareHouse?: any,
    isPlanPop?: boolean,
  ) => void;
  selectedWareHouse: ISelectedWareHouse;
  activeWareHouse: ISelectedWareHouse;
  data: ITdkProducts;
  setSelectedWareHouse: (warehouse: ISelectedWareHouse) => void;
  setSelectedPlan: (id: string) => void;
  loading: boolean;
}

const ActiveCampaigns = ({
  handleActivePopup,
  activeWareHouse,
  data,
  setSelectedPlan,
  loading,
}: IProps) => {
  const handlePlanView = async (item: IPlans) => {
    const product = data.products?.find(
      (elem) => elem.productId === item.productId,
    );

    if (product) {
      const activePlan = product?.plans.find((elem) => elem.Id === item.planId);
      if (activePlan) {
        setSelectedPlan(activePlan?.Id);
        handleActivePopup(
          'plan',
          product,
          {
            name: data.warehouseName,
            id: data.warehouseId,
          },
          true,
        );
      }
    }
  };

  return (
    <section className='mb-5'>
      <div className='container'>
        {loading && !data && (
          <div className='d-flex align-items-center my-4 w-100'>
            <div className='w-100'>
              <Skeleton height={22.5} width={'100%'} borderRadius={'1rem'} />
            </div>
          </div>
        )}

        {data?.termMessage && (
          <div className='d-flex align-items-center my-4 w-100'>
            <div className='flex-grow-1 ff:regular c:black fs:15'>
              <p>{data?.termMessage}</p>
            </div>
          </div>
        )}

        {loading ? (
          <Skeleton height={200} count={2} borderRadius={'1rem'} />
        ) : (
          <>
            {data?.termState === 0 ? (
              <section className='bg:white border:1 radius:12 py-5 px-3 text-center shadow:4'>
                <div className='row g-0 justify-content-center w-100'>
                  <div className='col-12 col-lg-8'>
                    <div
                      className='widthElem heightElem iconSize:28 mx-auto bg:orange:2 radius:full d-flex align-items-center justify-content-center mb-4'
                      style={
                        {
                          '--widthElem': '5rem',
                          '--heightElem': '5rem',
                        } as React.CSSProperties
                      }
                    >
                      <img src={iconBing} alt='' />
                    </div>
                    <div className='c:dark fs:16 ff:light'>
                      <p>TuruncuHat'ta özel kampanyalar için takipte kalın.</p>
                    </div>
                  </div>
                </div>
              </section>
            ) : null}
            {data?.termState === 1 && !data?.plans?.length ? (
              <section className='bg:white border:1 radius:12 py-5 px-3 text-center shadow:4'>
                <div className='row g-0 justify-content-center w-100'>
                  <div className='col-12 col-lg-8'>
                    <div
                      className='widthElem heightElem iconSize:28 mx-auto bg:orange:2 radius:full d-flex align-items-center justify-content-center mb-4'
                      style={
                        {
                          '--widthElem': '5rem',
                          '--heightElem': '5rem',
                        } as React.CSSProperties
                      }
                    >
                      <img src={iconCogFill} alt='' />
                    </div>
                    <div className='c:dark fs:16 ff:light'>
                      <p>
                        Şu anda katıldığınız aktif bir{' '}
                        <strong>Turuncu Dönemsel Kampanya</strong>{' '}
                        bulunmamaktadır.{' '}
                        {activeWareHouse.name
                          ? null
                          : 'Turuncu Dönemsel kampanyalara katılmak için öncelikle bir depo seçimi yapmalısınız.'}
                      </p>
                    </div>

                    {activeWareHouse.name ? null : (
                      <button
                        onClick={() => handleActivePopup('depo')}
                        aria-label='depo seç'
                        className='buttonStyle buttonStyle--icon buttonStyle--submit buttonStyle--paddingElem c:white ff:bold fs:15 bg:orange d-inline-block text-center radius:12 mt-4'
                        type='button'
                        style={
                          {
                            '--btnPaddingElem': '0 4rem',
                            '--form-height': '3rem',
                          } as React.CSSProperties
                        }
                      >
                        Depo Seç
                      </button>
                    )}
                  </div>
                </div>
              </section>
            ) : null}
            {data?.termState === 2 ? (
              <section className='bg:white border:1 radius:12 py-5 px-3 text-center shadow:4'>
                <div className='row g-0 justify-content-center w-100'>
                  <div className='col-12 col-lg-8'>
                    <div
                      className='widthElem heightElem iconSize:28 mx-auto bg:orange:2 radius:full d-flex align-items-center justify-content-center mb-4'
                      style={
                        {
                          '--widthElem': '5rem',
                          '--heightElem': '5rem',
                        } as React.CSSProperties
                      }
                    >
                      <img src={iconBing} alt='' />
                    </div>
                    <div className='c:dark fs:16 ff:light'>
                      <p>
                        TuruncuHat'ta özel kampanyalara katılım süreci
                        bitmiştir. Bir sonraki dönem için takipte kalın.
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            ) : null}
            {activeWareHouse.id && (
              <section className='mt-5'>
                <div
                  className='bg:orange:11 borderStyle radius:12 shadow:9 p-4 p-sm-3'
                  style={
                    {
                      '--gsk-border-width': '0.5px',
                      '--gsk-border-color': '#FFD278',
                    } as React.CSSProperties
                  }
                >
                  <div className='row gx-0 gy-3 gy-md-0 justify-content-between align-items-center'>
                    <div className='col-12 col-md-7'>
                      <div className='d-flex flex-column flex-md-row align-items-center'>
                        <div className='flex-shrink-0'>
                          <p className='text-uppercase c:orange fs:13 ff:bold lt:3 mb-2 mb-md-0'>SEÇİLEN DEPO</p>
                        </div>
                        <div className='flex-grow-1 ms-0 ms-md-3'>
                          <p className='c:dark fs:14 ff:regular'>{activeWareHouse && activeWareHouse.name}</p>
                        </div>
                      </div>
                    </div>
                    {data.termState === 1 ? (
                      <div className='col-12 col-md-5'>
                        <div className='d-flex justify-content-center justify-content-md-end'>
                          <button
                            onClick={() =>
                              handleActivePopup('depo', '', '', false)
                            }
                            aria-label='depo değiştir'
                            className='buttonStyle buttonStyle--icon buttonStyle--submit buttonStyle--paddingElem c:white ff:bold fs:15 bg:orange d-inline-block text-center radius:12'
                            type='button'
                            style={
                              {
                                '--btnPaddingElem': '0 3rem',
                                '--form-height': '3rem',
                              } as React.CSSProperties
                            }
                          >
                            Depo Değiştir
                          </button>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </section>
            )}
            {data?.plans && data.plans?.length ? (
              <>
                <h1 className='c:dark ff:bold fs:24 text-center text-lg-start mt-5'>
                  Aktif Kampanyalar
                </h1>
                <section className='tableLayout mt-3'>
                  <table className='tableLayout__table'>
                    <thead>
                    <tr className='text-uppercase lt:3 c:dark ff:bold fs:13'>
                      <th>TAMAMLANAN</th>
                      <th>DURUM</th>
                      <th>KAZANILAN</th>
                      <th>ÜRÜN</th>
                      <th>PLAN</th>
                      <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {data.plans.length &&
                      data.plans.map((item, index) => (
                        <tr key={item.planId + index}>
                          <td>
                            <div className='circleArea'>
                              <svg
                                width='100%'
                                height='100%'
                                viewBox='0 0 40 40'
                                className='donut'
                              >
                                <circle
                                  className='donut-hole'
                                  cx='20'
                                  cy='20'
                                  r='15.91549430918954'
                                  fill='#fff'
                                ></circle>
                                <circle
                                  className='donut-ring'
                                  cx='20'
                                  cy='20'
                                  r='15.91549430918954'
                                  fill='transparent'
                                  strokeWidth='3'
                                ></circle>
                                <defs>
                                  <linearGradient
                                    id='greenLinear'
                                    x1='1.40606'
                                    y1='0'
                                    x2='58'
                                    y2='0'
                                    gradientUnits='userSpaceOnUse'
                                  >
                                    <stop stopColor='#39BD8D' />
                                    <stop offset='1' stopColor='#63D98B' />
                                  </linearGradient>
                                </defs>
                                <defs>
                                  <linearGradient id='orangeLinear' x1='29' y1='0' x2='29' y2='58'
                                                  gradientUnits='userSpaceOnUse'>
                                    <stop stopColor='#FE6260' />
                                    <stop offset='1' stopColor='#FF8E33' />
                                  </linearGradient>
                                </defs>
                                <circle
                                  className='donut-segment'
                                  cx='20'
                                  cy='20'
                                  r='15.91549430918954'
                                  fill='transparent'
                                  strokeWidth='3'
                                  strokeDasharray={`${item.percentage} ${
                                    100 - item.percentage
                                  }`}
                                  strokeDashoffset='25'
                                  stroke={`url(#${
                                    item.percentage === 100
                                      ? 'greenLinear'
                                      : 'orangeLinear'
                                  })`}
                                ></circle>
                                <g className='donut-text'>
                                  <text y='50%' transform='translate(0, 2)'>
                                    <tspan
                                      x='50%'
                                      y='53.5%'
                                      dominantBaseline='auto'
                                      textAnchor='middle'
                                      className='donut-percent'
                                      style={{
                                        fill:
                                          item.percentage === 0
                                            ? '#aaa'
                                            : item.percentage === 100
                                              ? '#39BD8D'
                                              : '#F46700',
                                      }}
                                    >
                                      &#xfe6a;{item.percentage}
                                    </tspan>
                                  </text>
                                </g>
                              </svg>
                            </div>
                          </td>
                          <td>
                            <div
                              className='bg:green:3 shadow:4 c:dark ff:regular fs:13 d-inline-block borderStyle radius:12 paddingElem'
                              style={
                                {
                                  '--gsk-border-width': '0.063rem',
                                  '--gsk-border-color': '#39BD8D',
                                  '--paddingElem': '0.563rem 1.25rem',
                                } as React.CSSProperties
                              }
                            >
                              {item.status}
                            </div>
                          </td>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div
                                className={`${item.performedQuantity === 100 ? 'bg:green:3' : 'bg:orange:3'} shadow:4 c:green ff:bold fs:13 d-inline-block borderStyle radius:12 paddingElem`}
                                style={
                                  {
                                    '--gsk-border-width': '0.063rem',
                                    '--gsk-border-color': `${item.performedQuantity === 100 ? '#39BD8D' : '#F46700'}`,
                                    'color': `${item.performedQuantity === 100 ? '#39BD8D' : '#F46700'}`,
                                    '--paddingElem': '0.563rem 1.25rem',
                                  } as React.CSSProperties
                                }
                              >
                                {item.performedQuantity}/{item.targetQuantity}
                              </div>
                              <div
                                className={`${item.mfQuantity > 1 ? 'bg:green:3' : 'bg:orange:3'} shadow:4 c:green ff:bold fs:13 d-inline-block borderStyle radius:12 paddingElem ms-2`}
                                style={
                                  {
                                    '--gsk-border-width': '0.063rem',
                                    '--gsk-border-color': `${item.mfQuantity > 1 ? '#39BD8D' : '#F46700'}`,
                                    '--paddingElem': '0.563rem 1.25rem',
                                  } as React.CSSProperties
                                }
                              >
                                <div className='d-flex'>
                                  <div className='me-1'>
                                    <p className='c:dark'>KAZANIM</p>
                                  </div>
                                  <div className={`${!item.mfQuantity && 'c:orange'}`}> {item.mfQuantity} KUTU</div>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <p className='c:dark fs:13 ff:regular smText'>
                              {item.productName}
                            </p>
                          </td>
                          <td>
                            <p className='c:dark fs:13 ff:regular smText'>
                              {item.planName}
                            </p>
                          </td>
                          <td>
                            <button
                              onClick={() => handlePlanView(item)}
                              aria-label='kampanya detayı'
                              type='button'
                              className='buttonStyle buttonStyle--light buttonStyle--paddingElem c:dark ff:bold fs:16 bg:white shadow:4 d-block w-100 text-center radius:16'
                              style={
                                {
                                  '--btnPaddingElem': '0.9rem 2rem',
                                } as React.CSSProperties
                              }
                            >
                              Kampanya Detayı
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </section>
              </>
            ) : null}
          </>
        )}
      </div>
    </section>
  );
};

export default ActiveCampaigns;
