import OrderItem from "./OrderItem";

function OrderItemList({ items }: any) {
  return items.map((item: any, idx: number) => (
    <div key={idx} className="col">
      <OrderItem item={item} />
    </div>
  ));
}

export default OrderItemList;
