import { useNavigate } from "react-router-dom";

const StepArrrows = ({ step }: { step: number }) => {
  const navigate = useNavigate();

  return (
    <div className="col">
      <div className="mb-4 mb-sm-0">
        <div className="row row-cols-auto justify-content-center gx-3 gy-0">
          {step > 1 && (
            <div className="col">
              <button
                onClick={() => navigate(`/kayit-ol/adim-${step - 1}`)}
                aria-label="form control back"
                className="formController formController--left bg:white"
              ></button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StepArrrows;
