import React, { useState } from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import remarkGfm from "remark-gfm";
import { IFaq } from "types/productTypes";

interface IProps {
  data: IFaq[];
}

const Sss = ({ data }: IProps) => {
  const [activeFaq, setActiveFaq] = useState("");

  const handleAccordion = (title: string) => {
    if (title === activeFaq) {
      setActiveFaq("null");
    } else {
      setActiveFaq(title);
    }
  };
  return (
    <section className="collapseContent">
      <ul className="collapseLayout">
        {data?.map((faq) => (
          <li
            key={faq.question}
            className={`collapseLayout__item ${
              faq.question === activeFaq ? "collapseActiveItem" : ""
            }`}
          >
            <h1
              onClick={() => handleAccordion(faq.question)}
              className="collapseLayout__thumb d-flex align-items-center py-4 px-4 pe-5 ff:regular fs:16 c:dark bg:white radius:8 borderStyle shadow:11"
              style={
                {
                  "--gsk-border-width": "0.063rem",
                  "--gsk-border-color": "#E9E9E9",
                } as React.CSSProperties
              }
            >
              <span>{faq.question}</span>
            </h1>
            <div
              className={`collapseLayout__panel ${
                faq.question === activeFaq ? "" : "d-none"
              }`}
            >
              <div className="p-4 pb-4 pb-sm-5">
                <section className="contentArea ff:light fs:16 c:dark">
                  <article>
                    <ReactMarkdown
                      children={faq.answer}
                      remarkPlugins={[remarkGfm]}
                    />
                  </article>
                </section>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default Sss;
