import { endpoints } from "constants/endpoints";
import { GetProducts } from "../types/productTypes";
import api from "../utility/request";

export const getProductsByParams = async (data: GetProducts): Promise<any> => {
  const { category, brand, label, has_offer, offset, limit, query } = data;
  const response = (
    await api.get(
      `${endpoints.products}?categoryCode=${category ?? ""}&brand=${
        encodeURIComponent(brand) ?? ""
      }&label=${label ?? ""}&has_offer=${has_offer ?? ""}&offset=${
        offset ?? ""
      }&limit=${limit ?? ""}&q=${query ?? ""}`
    )
  ).data;
  return response;
};

export const getProducts = async (data: GetProducts): Promise<any> => {
  const { limit } = data;
  const response = (await api.get(`${endpoints.products}?limit=${limit ?? ""}`))
    .data;
  return response;
};
export const getProductsForCards = async (): Promise<any> => {
  const response = (await api.get(`${endpoints.products}/card`)).data;
  return response;
};

export const getTdkProducts = async (): Promise<any> => {
  const response = (await api.get(`/campaign/tdk`)).data;
  return response;
};

export const getProductById = async (id: string): Promise<any> => {
  const response = (await api.get(`${endpoints.products}/${id}`)).data;
  return response;
};

export const getProductPlan = async (data: {
  productId: string;
  wareHouseId: string;
}): Promise<any> => {
  const { productId, wareHouseId } = data;
  const response = (
    await api.get(
      `${endpoints.products}/${productId}/plan?warehouseId=${wareHouseId}`
    )
  ).data;
  return response;
};

export const getSearchDetails = async (data: {
  query: string;
}): Promise<any> => {
  const { query } = data;
  const response = (await api.get(`/home/search/${encodeURIComponent(query)}`))
    .data;
  return response;
};

export const suggest = async (query: string): Promise<any> => {
  const response = (
    await api.get(
      `${endpoints.products}/suggest?query=${encodeURIComponent(query)}`
    )
  ).data;
  return response;
};
