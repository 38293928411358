import classNames from "classnames";
import { CartContext } from "contexts/CartContext/CartContext";
import React, { useContext, useState } from "react";
import { IOffer, IProductAll, ResultDetail } from "types/productTypes";
import { ICartItem } from "types/shoppingCartTypes";
import { v4 as uuidv4 } from "uuid";

function AddButton({
  product,
  selectedOffer,
  preOrder,
  color,
  backgroundColor,
  cartItem,
  setActiveItem,
}: {
  product: ResultDetail | IProductAll;
  selectedOffer?: IOffer;
  preOrder: boolean;
  color: string;
  backgroundColor: string;
  cartItem: ICartItem | undefined;
  setActiveItem?: (id: string) => void;
}) {
  const [_transactionId] = useState(uuidv4());
  const {
    cart,
    addToCart,
    updateCartItem,
    loading,
    transactionId,
    loadingPlusMinus,
  } = useContext(CartContext);

  const handleAddToCart = () => {
    if (setActiveItem) {
      setActiveItem(product.id);
    }
    if (!cart?.items.length) {
      addToCart!(
        product,
        selectedOffer?.quantity ?? 1,
        selectedOffer?.id ?? "",
        preOrder,
        _transactionId
      );
      return;
    } else if (cartItem?.productId !== product.id) {
      addToCart!(
        product,
        selectedOffer?.quantity ?? 1,
        selectedOffer?.id ?? "",
        preOrder,
        _transactionId
      );
    } else {
      updateCartItem!(
        cartItem,
        selectedOffer?.quantity ?? 1,
        selectedOffer?.id ?? "",
        preOrder,
        _transactionId
      );
    }
  };

  const disabled =
    (cartItem && selectedOffer?.id === cartItem?.selectedOffer) ||
    product.isLimitReached ||
    (cartItem && !selectedOffer) ||
    loading;

  return product.thSalable ? (
    <button
      onClick={handleAddToCart}
      disabled={disabled}
      aria-label="sepete ekle"
      className={classNames(
        "buttonStyle buttonStyle--icon buttonStyle--submit ff:bold fs:16 d-block w-100 text-center radius:12",
        color,
        backgroundColor,
        {
          disabledBtn: disabled,
          spinnerElem:
            (loading || loadingPlusMinus) && _transactionId === transactionId,
          "spinnerElem--orange":
            (loading || loadingPlusMinus) &&
            _transactionId === transactionId &&
            color === "c:orange",
        }
      )}
      style={{ "--form-height": "3rem" } as React.CSSProperties}
    >
      {!cartItem ? "Sepete Ekle" : "Sepeti Güncelle"}
    </button>
  ) : (
    <button
      aria-label="Ürün satışa kapalıdır."
      className={classNames(
        "buttonStyle buttonStyle--icon buttonStyle--submit ff:bold fs:16 d-block w-100 text-center radius:12 disabledBtn c:orange"
      )}
      style={{ "--form-height": "3rem" } as React.CSSProperties}
    >
      Ürün satışa kapalıdır.
    </button>
  );
}

export default AddButton;
