import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import Account from "@components/Hesabim/MyAccount/Account";
import { PublicContext } from "contexts/PublicContext/PublicContext";
/* eslint-disable react-hooks/exhaustive-deps */
const MyAccount = () => {
  const { setSiteTitle } = useContext(PublicContext);
  useEffect(() => {
    setSiteTitle("Hesabım");
  }, []);
  return (
    <>
      <section className="breadcrumb my-4 my-md-5">
        <div className="container">
          <ul className="c:dark fs:14 lt:3">
            <li>
              <Link to="/anasayfa">Anasayfa</Link>
            </li>
            <li>
              <Link to="/hesabim">Hesabım</Link>
            </li>
          </ul>
        </div>
      </section>
      <section className="mb-5 accountCard">
        <div className="container">
          <h1 className="c:dark ff:bold fs:24 mb-3 pb-1 text-center text-lg-start">
            Hesabım
          </h1>

          <Account />
        </div>
        <p>NP-TR-NA-WCNT-240013</p>
      </section>
    </>
  );
};

export default MyAccount;
