import React, { useContext } from "react";
import iconTrashList from "@images/icon-trash-list.svg";
import { ICartItem } from "types/shoppingCartTypes";
import BasketSelect from "./BasketSelect";
import { BodyContext } from "contexts/BodyContext/BodyContext";
import { CartContext } from "contexts/CartContext/CartContext";
import { Link } from "react-router-dom";
import { formatCurrency } from "utility/formatCurrency";
import { IProductAll } from "types/productTypes";

interface IProps {
  setActiveProduct: (prod: IProductAll) => void;
  activeProduct: IProductAll | undefined;
  cartItem: ICartItem;
}

const ListCartItem = ({
  cartItem,
  activeProduct,
  setActiveProduct,
}: IProps) => {
  const { handlePopup } = useContext(BodyContext);
  const { getProductInCart, error } = useContext(CartContext);
  const product = getProductInCart!(cartItem.productId);
  return (
    <tr>
      <td>
        <Link aria-label="link card" to={`/urun/${cartItem.productId}`}>
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <div className="productListCard__box-img">
                <img
                  src={
                    product?.thumbnail
                      ? `data:${product.thumbnail.contentType};base64,${product.thumbnail.fileContents}`
                      : "/brokenImage.svg"
                  }
                  alt={
                    product?.thumbnail?.contentType
                      ? product.thumbnail?.contentType
                      : "alttext"
                  }
                />
              </div>
            </div>
            <div className="flex-grow-1 ms-3">
              <div>
                <p className="c:dark ff:bold fs:14 mb-1">
                  {getProductInCart!(cartItem.productId)?.displayBrandName}
                </p>
                <p className="c:dark ff:light fs:14 mb-1">
                  {getProductInCart!(cartItem.productId)?.displayProductForm}
                </p>
                <p className="c:dark ff:bold fs:16 c:orange">
                  &#8378;&nbsp;
                  {formatCurrency(cartItem.salePrice.value)}
                </p>
              </div>
            </div>
          </div>
        </Link>
      </td>
      {/* <td>
  <div className="d-flex justify-content-center">
    <p className="fs:15 ff:bold stockElem">&nbsp;Stokta var</p>
  </div>
</td> */}
      <td>
        {/* <!-- <p className="c:dark fs:13 ff:regular smText text-uppercase">DERMOVATE OINTMENT 0.05%</p> --> */}
        <div className="d-flex justify-content-center">
          <p
            className="bg:orange:2 radius:12 paddingElem fs:15 ff:bold c:orange text-center d-inline-block"
            style={{ "--paddingElem": "0.625rem 1rem" } as React.CSSProperties}
          >
            MF {cartItem.quantity.freeAmount}
          </p>
        </div>
      </td>
      <td>
        {/* <!-- <p className="c:dark fs:13 ff:regular text-uppercase smText">Turuncu Plan 01</p> --> */}
        <div
          className="widthElem mx-auto"
          style={{ "--widthElem": "7.5rem" } as React.CSSProperties}
        >
          {/* <!-- sepet ekle çıkar inputunun disable olması için aşağıdaki classa disabledInput eklenmeli. --> */}
          <div className={`formElementItem formElementItem--group`}>
            <BasketSelect
              cartItem={cartItem}
              product={product}
              activeProduct={activeProduct}
              setActiveProduct={setActiveProduct}
            />
            {/* <!--#include virtual="/parts/components/general/basket-select.html" --> */}
          </div>
        </div>
        {error && cartItem.productId === activeProduct?.id && (
          <div className="d-flex justify-content-center mt-2">
            <div
              className="alert py-2 px-4 radius:8 shadow:10 bg:yellow:3 d-inline-block"
              style={
                { "--alert-borderColor": "#F5CB5F" } as React.CSSProperties
              }
            >
              <p className="c:dark ff:regular fs:13">{error}</p>
            </div>
          </div>
        )}
        {cartItem.campaignLimitMessage && (
          <div className="d-flex justify-content-center mt-2">
            <div
              className="alert py-2 px-4 radius:8 shadow:10 bg:yellow:3 d-inline-block"
              style={
                { "--alert-borderColor": "#F5CB5F" } as React.CSSProperties
              }
            >
              <p className="c:dark ff:regular fs:13">
                {cartItem.campaignLimitMessage}
              </p>
            </div>
          </div>
        )}
      </td>
      <td>
        {/* <button aria-label="kampanya detayı" type="button" class="buttonStyle buttonStyle--light buttonStyle--paddingElem c:dark ff:bold fs:16 bg:white shadow:4 d-block w-100 text-center radius:16" style="--btnPaddingElem:0.9rem 2rem">Kampanya Detayı</button> */}
        <button
          onClick={() =>
            handlePopup("deleteFromCart", {
              cartItem: cartItem,
              product: product,
            })
          }
          className="trashButton mx-auto"
          aria-label="sil butonu"
          type="button"
        >
          <img src={iconTrashList} alt="" />
        </button>
      </td>
    </tr>
  );
};

export default ListCartItem;
