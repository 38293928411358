import Skeleton from "react-loading-skeleton";

const BookMarkSkeletonItem = () => {
  return (
    <div className="col">
      <div className="row align-items-center gy-md-0 gy-4 gx-0 gx-md-4">
        <div className="col-12 col-md-10">
          <div className="row row-cols-1 gx-0 gy-3 gy-sm-4">
            <div className="col">
              <button
                className="trashButton"
                aria-label="sil butonu"
                type="button"
              >
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <Skeleton width={18} height={18} />
                  </div>
                  <div className="flex-grow-1 ms-2">
                    <p className="text-uppercase fs:13 lt:3 c:dark:2 ff:bold">
                      <Skeleton width={50} height={14} />
                    </p>
                  </div>
                </div>
              </button>
            </div>
            <div className="col">
              <Skeleton width={50} height={16} />
            </div>
            <div className="col">
              <Skeleton width={90} height={24} />
            </div>
            <div className="col">
              <div className="tagListItem">
                <ul className="c:dark ff:bold fs:14">
                  <li>
                    <Skeleton width={125} height={31} />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-2">
          <div className="blogList__thumb">
            <Skeleton width={166} height={166} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookMarkSkeletonItem;
