import React from "react";
import ProgressBarItem from "./ProgressBarItem";
import classNames from "classnames";

interface IStepItem {
  step: number;
  title: string;
  titleTop: boolean;
}

interface IProps {
  className?: string;
  currentStep: number;
  steps: IStepItem[];
}

const StepList = (steps: IStepItem[], currentStep: number) => {
  return (
    <ul className="gridSelector row g-0 justify-content-center text-center">
      {steps.map((item, idx) => (
        <ProgressBarItem
          key={idx}
          currentStep={currentStep}
          step={item.step}
          title={item.title}
          titleTop={item.titleTop}
        />
      ))}
    </ul>
  );
}

function ProgressBar({ className, currentStep, steps }: IProps) {
  const wrapperStyle = {
    "--select-enable-item": currentStep,
    "--area-width": "100%",
    "--select-progress-item": steps.length,
    "--progress-width":
      "calc(var(--area-width) / var(--select-progress-item) * var(--select-enable-item))",
  } as React.CSSProperties;

  return (
    <section className={classNames("registerProgress", className)}>
      <div className="registerProgress__wrapper" style={wrapperStyle}>
        {className === "registerProgress--carts" ? (
          <div className="registerProgress__bottom">
            {StepList(steps, currentStep)}
          </div>
        ) : (
          StepList(steps, currentStep)
        )}
      </div>
    </section>
  );
}

export default ProgressBar;
