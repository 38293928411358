import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
interface IProps {
  searchQuery?: string;
  placeHolder: string;
  setSearchQuery: (query: string) => void;
}
/* eslint-disable react-hooks/exhaustive-deps */
const ContentSearch = ({
  searchQuery,
  setSearchQuery,
  placeHolder,
}: IProps) => {
  const [search, setSearch] = useState("");

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setSearchQuery(inputRef.current?.value!);
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [search]);

  let { state } = useLocation();

  useEffect(() => {
    if (state && state.orderId) {
      setSearch(state.orderId);
    }

    return () => {};
  }, [state]);

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <div className="formElementItem">
        <input
          autoComplete="off"
          ref={inputRef}
          className="formElementItem__input formElementItem__input--padding formElementItem__input--search bg:white radius:12 w-100 fs:14 pe ff:regular c:dark shadow:4"
          type="text"
          name="search"
          id="search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder={placeHolder}
          style={
            {
              "--inputPadding": "0 1rem 0 2.25rem",
              "--form-borderColor": "#D8D8D8",
              "--form-height": "2.5rem",
            } as React.CSSProperties
          }
        />
      </div>
    </form>
  );
};

export default ContentSearch;
