import React, { useContext, useEffect, useState } from "react";
import useSecondApi from "hooks/useSecondApi";
import infoicon from "@images/icon-info.svg";
import phoneImage from "@images/icon-phone.svg";
import { sendSmsCode, smsConfirm } from "services/user.service";

import useApi from "hooks/useApi";
import {
  phoneValidation,
  smsCodeValidation,
} from "validations/AuthValidations";
import { z } from "zod";
import Skeleton from "react-loading-skeleton";
import { useTimer } from "hooks/useTimer";
import { notificationTexts } from "utility/notificationTexts";
import { formatInput } from "utility/FormatInput";
import Field from "@components/Form/Field";
import { PublicContext } from "contexts/PublicContext/PublicContext";
/* eslint-disable react-hooks/exhaustive-deps */
interface IProps {
  state: any;
  setMobileApproved: (bool: boolean) => void;
  handleUpdateAfterPhoneConfirmed: () => void;
}

const SmsPopup = ({
  state,
  setMobileApproved,
  handleUpdateAfterPhoneConfirmed,
}: IProps) => {
  const [smsCode, setSmsCode] = useState("");
  const [smsSended, setSmsSended] = useState(false);
  const [error, setError] = useState("");
  const {
    settings,
    error: settingsError,
    success: successSettings,
  } = useContext(PublicContext);

  const { time, setStatus, reStartTimer } = useTimer(
    !settingsError.length && successSettings
      ? Number(
          settings.find((item) => item.name === "PROFILE_VERIFY_PHONE_TIMER")
            ?.value
        )
      : notificationTexts.PROFILE_VERIFY_PHONE_TIMER
  );
  const { error: apiError, loading, handleApi, success } = useApi();
  const { secondError, secondSuccess, handleSecondApi } = useSecondApi();

  useEffect(() => {
    setError("");
  }, [smsCode]);

  const handleSendSmsCode = async () => {
    setError("");

    try {
      const isValidPhone = phoneValidation.parse(state?.mobile);

      if (isValidPhone.length) {
        const phoneNumber: string = state.mobile;
        const editedPhone = phoneNumber.startsWith("0")
          ? phoneNumber.slice(1, 11)
          : phoneNumber;
        await handleApi(sendSmsCode, editedPhone);
        reStartTimer(
          !settingsError.length && successSettings
            ? Number(
                settings.find(
                  (item) => item.name === "PROFILE_VERIFY_PHONE_TIMER"
                )?.value
              )
            : notificationTexts.PROFILE_VERIFY_PHONE_TIMER
        );
      }
    } catch (err) {
      if (err instanceof z.ZodError) {
        setError(err.issues[0].message);
      }
    }
  };

  useEffect(() => {
    handleSendSmsCode();
  }, []);

  useEffect(() => {
    if (success) {
      setSmsSended(true);
      setStatus(true);
    }
  }, [success]);

  const handleConfirmCode = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setError("");
    try {
      const isValidCode = smsCodeValidation.parse(smsCode);

      if (isValidCode.length) {
        const phoneNumber: string = state.mobile;
        const editedPhone = phoneNumber.startsWith("0")
          ? phoneNumber.slice(1, 11)
          : phoneNumber;
        await handleSecondApi(smsConfirm, {
          phoneNumber: editedPhone,
          smsCode: smsCode,
        });
      }
    } catch (err) {
      if (err instanceof z.ZodError) {
        setError(err.issues[0].message);
      }
    }
  };

  useEffect(() => {
    if (secondSuccess) {
      handleUpdateAfterPhoneConfirmed();
      setMobileApproved(true);
      // handleApi(getUser, {});
    }
  }, [secondSuccess]);

  const handleReset = () => {
    handleSendSmsCode();
    setError("");
  };

  return (
    <div className="p-4 p-sm-5">
      <div className="formElement">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="row row-cols-1 gx-0 gy-3 gy-sm-4">
            <div className="col">
              <p className="ff:light c:dark fs:24 text-center text-md-start">
                Telefonu Doğrula
              </p>
            </div>
            <div className="col">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <img src={phoneImage} alt="" />
                </div>
                <div className="flex-grow-1 ms-3">
                  <p className="fs:16 ff:light">
                    Telefonunuza sms ile gelen doğrulama kodunu girerek,
                    doğrulamayı tamamlayın.
                  </p>
                </div>
              </div>
            </div>
            <div className="col">
              {loading ? (
                <div className="formElementItem">
                  <Skeleton height={20} />
                  <Skeleton height={60} />
                </div>
              ) : (
                smsSended && (
                  <Field
                    type="text"
                    label="sms doğrulama kodu"
                    name="sms-code"
                    minLength={6}
                    maxLength={6}
                    placeholder="* * * * * *"
                    value={smsCode}
                    onChange={(e) => setSmsCode(e.target.value)}
                    pattern={formatInput}
                  />
                )
              )}
            </div>
            <div className="col">
              {/* <!-- sms doğrulama adımındaki timer --> */}
              {loading ? (
                <Skeleton height={20} className="mb-3 mb-sm-4" />
              ) : (
                smsSended && (
                  <div className="timerText text-center mb-3 mb-sm-4">
                    {time && time.minutes !== -1 ? (
                      <p className="ff:bold c:dark:2 fs:15">
                        Süre:{" "}
                        <span className="d-inline-block" id="timerElem">
                          {time.minutes.toString().padStart(2, "0")}:
                          {time.seconds.toString().padStart(2, "0")}
                        </span>
                      </p>
                    ) : (
                      <p className="ff:bold c:orange fs:15">
                        Süre doldu:{" "}
                        <span className="d-inline-block" id="timerElem">
                          00:00
                        </span>
                      </p>
                    )}
                  </div>
                )
              )}
              {apiError.length && !error.length ? (
                <div
                  className="alert  bg:red:2 p-3 radius:12 mb-3 mb-sm-4"
                  style={
                    {
                      "--alert-borderColor": "rgba(243, 52, 52, 0.22)",
                    } as React.CSSProperties
                  }
                >
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <img src={infoicon} alt="" />
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <p className="c:red lt:2 ff:regular fs:12">{apiError}</p>
                    </div>
                  </div>
                </div>
              ) : null}
              {secondError.length && !error.length ? (
                <div
                  className="alert  bg:red:2 p-3 radius:12 mb-3 mb-sm-4"
                  style={
                    {
                      "--alert-borderColor": "rgba(243, 52, 52, 0.22)",
                    } as React.CSSProperties
                  }
                >
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <img src={infoicon} alt="" />
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <p className="c:red lt:2 ff:regular fs:12">
                        {secondError}
                      </p>
                    </div>
                  </div>
                </div>
              ) : null}

              {smsSended && time.minutes !== -1 && (
                <button
                  disabled={loading}
                  aria-label="continue"
                  className={`buttonStyle buttonStyle--icon buttonStyle--submit c:white ff:bold fs:16 bg:gradient:orange d-block w-100 text-center radius:12 ${
                    loading ? "spinnerElem" : null
                  }`}
                  type="submit"
                  onClick={handleConfirmCode}
                  style={
                    {
                      "--form-height": "3rem",
                    } as React.CSSProperties
                  }
                >
                  Doğrula
                </button>
              )}
              {/* <!-- süre dolduktan sonra butonun stili */}
              {time.minutes === -1 && smsSended && (
                <button
                  aria-label="resendCode"
                  onClick={handleReset}
                  className="buttonStyle buttonStyle--icon buttonStyle--submit c:white ff:bold fs:16 bg:orange d-block w-100 text-center radius:12"
                  type="submit"
                  style={
                    {
                      "--form-height": "3rem",
                    } as React.CSSProperties
                  }
                >
                  Kodu Tekrar Gönder
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SmsPopup;
