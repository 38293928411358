import { createContext } from "react";
import { IProductAll } from "types/productTypes";
import { ICart } from "types/shoppingCartTypes";

interface ICartContext {
  setCart: (data: any) => void;
  setError: (data: string) => void;
  setSuccess: (data: boolean) => void;
  addToCart?: (
    data: any,
    qty?: number,
    selectedOfferId?: string,
    preOrder?: boolean,
    transactionId?: string
  ) => void;
  updateCartItem?: (
    data: any,
    qty?: number,
    selectedOfferId?: string,
    preOrder?: boolean,
    transactionId?: string
  ) => void;
  handleMinusFromCart?: (
    data: any,
    selectedOfferId?: string,
    product?: any,
    preOrder?: boolean
  ) => void;
  handleAddMoreItemToCart?: (
    data: any,
    selectedOfferId?: string,
    preOrder?: boolean
  ) => void;
  handleRemoveItemFromCart?: (data: any) => void;
  updateWareHouseId?: (wareHouseId: string, type?: string) => void;
  handleRepeatOrder: (orderId: string) => void;
  getProductInCart?: (prodId: string) => IProductAll | undefined;
  cart?: ICart;
  loading?: boolean;
  error?: string;
  success?: boolean;
  loadingPlusMinus?: boolean;
  transactionId?: string;
}

export const CartContext = createContext<ICartContext>({
  setCart: () => {},
  setError: () => {},
  addToCart: () => {},
  setSuccess: () => {},
  handleRepeatOrder: () => {},
});
