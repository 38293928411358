import { useContext, useEffect } from "react";
import { PublicContext } from "contexts/PublicContext/PublicContext";
import { Link, useSearchParams } from "react-router-dom";
import { IBlogPosts } from "views/Blog/BlogCategories";

interface IProps {
  post: IBlogPosts;
}
/* eslint-disable react-hooks/exhaustive-deps */
const ListItem = ({ post }: IProps) => {
  let [searchParams] = useSearchParams();
  const slug = searchParams.get("slug");
  const { setSiteTitle } = useContext(PublicContext);
  useEffect(() => {
    if (slug) {
      setSiteTitle(slug);
    }
  }, [slug]);

  return (
    <li className="col">
      <div className="row align-items-center gy-md-0 gy-4 gx-0 gx-md-4">
        <div className="col-12 col-md-10">
          <div className="row row-cols-1 gx-0 gy-3 gy-sm-4">
            <div className="col">
              <p
                className={`text-uppercase fs:12 lt:4 ff:bold`}
                style={{ color: post.blogColor }}
              >
                {post.blogName}
              </p>
            </div>
            <div className="col">
              <Link
                className="c:dark fs:22 ff:regular hover:c:orange"
                to={`/blogdetay/${post.id}`}
              >
                {post.title}
              </Link>
            </div>
            <div className="col">
              <div className="tagListItem">
                <ul className="c:dark ff:bold fs:14">
                  {post.hashtag &&
                    post.hashtag.map((hashTag) => (
                      <li key={hashTag}>
                        <Link
                          className={
                            slug === hashTag ? "tagListItem__active" : ""
                          }
                          to={`/tag?slug=${hashTag}`}
                        >
                          {hashTag}
                        </Link>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-2">
          <div className="blogList__thumb">
            <img
              src={
                post.banner
                  ? `data:${post.banner.contentType};base64,${post.banner.fileContents}`
                  : "/brokenImage.svg"
              }
              alt={
                post.banner?.contentType ? post.banner?.contentType : "alttext"
              }
              loading="lazy"
              onError={(e) => {
                e.currentTarget.src = "/brokenImage.svg";
              }}
            />
          </div>
        </div>
      </div>
    </li>
  );
};

export default ListItem;
