import React, { useState, useContext, useEffect, useRef } from 'react';
import iconInfo from '@images/icon-info.svg';
import iconClose from '@images/icon-close.svg';
import { BodyContext } from 'contexts/BodyContext/BodyContext';
import useApi from 'hooks/useApi';
import { getPolicies, getUser, setPolicies } from 'services/user.service';
import { AuthContextSite } from 'contexts/AuthContext/AuthContext';
import { useUser } from 'hooks/useUser';
import useSecondApi from 'hooks/useSecondApi';
import Skeleton from 'react-loading-skeleton';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import remarkGfm from 'remark-gfm';
/* eslint-disable react-hooks/exhaustive-deps */
const KvkkPopup = ({ type }: { type: string }) => {
  const { user } = useContext(AuthContextSite);
  const [customClass, setCustomClass] = useState('d-none');
  const [error, setError] = useState('');
  const [privacyPolicy, setPrivacyPolicy] = useState(
    user?.forcePrivacyPolicy !== undefined ? !user?.forcePrivacyPolicy : false,
  );
  const [termsOfUse, setTermsOfUse] = useState(
    user?.forceTermOfUse !== undefined ? !user?.forceTermOfUse : false,
  );

  const [activeText, setActiveText] = useState('');
  const [activePopup, setActivePopup] = useState('');
  const [popupOpen, setPopupOpen] = useState(false);

  const {
    success,
    error: apiError,
    handleApi,
    loading: apiLoading,
  } = useApi({ loading: false });

  const {
    secondData,
    secondError,
    secondSuccess,
    secondLoading,
    handleSecondApi,
  } = useSecondApi();
  const { handlePopup } = useContext(BodyContext);
  const { addUser } = useUser();
  const [loading, setLoading] = useState(false);
  const [succesGetUser, setSuccesGetUser] = useState(false);

  const handleGetUser = async () => {
    setSuccesGetUser(false);
    try {
      setLoading(true);
      const resp = await getUser();
      setSuccesGetUser(true);
      addUser(resp);
    } catch (error) {
      setError('Kullanıcı bilgileri getirilemedi. Lütfen tekrar deneyiniz.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if ((succesGetUser || success) && user) {
      if (user.forcePrivacyPolicy) {
        setPrivacyPolicy(false);
      }
      if (user.forceTermOfUse) {
        setTermsOfUse(false);
      }
    }
  }, [succesGetUser, user, success]);

  useEffect(() => {
    handleSecondApi(getPolicies, {});
  }, []);

  const handleAcceptPolicies = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setError('');
    if (!privacyPolicy && user?.forcePrivacyPolicy) {
      setError('Aydınlatma metnini onaylamadınız.');
      return;
    }
    if (!termsOfUse && user?.forceTermOfUse) {
      setError('Kullanım Şartları Metnini onaylamadınız.');
      return;
    }

    if (type === 'both') {
      await handleApi(setPolicies, {
        terms_of_use_id:
          secondData &&
          secondData?.find(
            (item: { type: string }) => item.type === 'terms_of_use',
          ).id,
        clarification_text_id:
          secondData &&
          secondData.find(
            (item: { type: string }) => item.type === 'privacy_policy',
          ).id,
      });
    } else if (type === 'kvkk') {
      await handleApi(setPolicies, {
        terms_of_use_id: null,
        clarification_text_id:
          secondData &&
          secondData.find(
            (item: { type: string }) => item.type === 'privacy_policy',
          ).id,
      });
    } else if (type === 'terms') {
      await handleApi(setPolicies, {
        terms_of_use_id:
          secondData &&
          secondData?.find(
            (item: { type: string }) => item.type === 'terms_of_use',
          ).id,
        clarification_text_id: null,
      });
    }
  };

  useEffect(() => {
    if (success) {
      handleGetUser();
      handlePopup('', { accepted: true });
    }
  }, [success]);

  const handleChange = (name: string, popup: React.SetStateAction<string>) => {
    const data =
      secondData &&
      secondData.find((item: { type: string }) => item.type === name);

    setActiveText(data.content);
    setActivePopup(popup);
    setPopupOpen(true);
  };

  const handleTextPopup = () => {
    setCustomClass('');
    setPopupOpen(false);
    setTimeout(() => {
      setCustomClass('d-none');
    }, 450);
  };

  const handleAccept = () => {
    if (activePopup === 'privacyPolicy') {
      setPrivacyPolicy((prev) => !prev);
      setError('');
    } else if (activePopup === 'termOfUse') {
      setTermsOfUse((prev) => !prev);
      setError('');
    }
    handleTextPopup();
    setIsReaded(false);
  };

  const [isReaded, setIsReaded] = useState(false);

  const popupRef = useRef<HTMLDivElement>(null);

  const handleScroll = (e: any) => {
    if (popupRef.current) {
      const observer = new IntersectionObserver(([entry]) => {
        setIsReaded(entry.isIntersecting);
      });

      observer.observe(popupRef.current);
      return () => observer.disconnect();
    }
  };

  return (
    <>
      <section className='popupWrapper animate__animated animate__fadeIn animate__faster d-nne'>
        <div className='popupWrapper__content bg:white radius:20'>
          <div className='p-4 p-sm-5'>
            <h1 className='ff:bold fs:16 c:dark mb-4 mb-sm-5 text-center'>
              Gizlilik Politikalarımız güncellendi. Lütfen okuyup onaylayınız.
            </h1>
            {secondLoading ? (
              <Skeleton height={40} />
            ) : (
              secondSuccess && (
                <div className='formElement'>
                  <form onSubmit={(e) => e.preventDefault()}>
                    <div className='row row-cols-1 g-0 gy-3 gy-sm-4'>
                      <div className='col'>
                        <div className='mb-3'>
                          <ul className='formElement__block-list row row-cols-1 g-0'>
                            {(type === 'kvkk' || type === 'both') && (
                              <li className='col'>
                                <div className='formElement__block-list__arrow'>
                                  <div className='kz-check'>
                                    <div>
                                      <label className='kz-check__label'>
                                        <span className='d-block ff:regular fs:13 c:dark text-uppercase pe-4'>
                                          {' '}
                                          KVKK AYDINLATMA METNİ’Nİ OKU{' '}
                                        </span>
                                        <input
                                          onChange={() =>
                                            handleChange(
                                              'privacy_policy',
                                              'privacyPolicy',
                                            )
                                          }
                                          checked={privacyPolicy}
                                          type='checkbox'
                                          id='radio'
                                          name='radio'
                                          aria-label='blankInput'
                                          required
                                        />
                                        <div className='kz-check__input'></div>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            )}
                            {(type === 'terms' || type === 'both') && (
                              <li className='col'>
                                <div className='formElement__block-list__arrow'>
                                  <div className='kz-check'>
                                    <div>
                                      <label className='kz-check__label'>
                                        <span className='d-block ff:regular fs:13 c:dark text-uppercase pe-4'>
                                          {' '}
                                          KULLANIM ŞARTLARI’NI OKU{' '}
                                        </span>
                                        <input
                                          checked={termsOfUse}
                                          onChange={() =>
                                            handleChange(
                                              'terms_of_use',
                                              'termOfUse',
                                            )
                                          }
                                          type='checkbox'
                                          id='radio-2'
                                          name='radio-2'
                                          aria-label='blankInput-2'
                                          required
                                        />
                                        <div className='kz-check__input'></div>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                      <div className='col'>
                        {error.length || apiError || secondError ? (
                          <div
                            className='alert bg:red:2 p-3 radius:12 mb-3 mb-sm-4'
                            style={
                              {
                                '--alert-borderColor':
                                  'rgba(243, 52, 52, 0.22)',
                              } as React.CSSProperties
                            }
                          >
                            <div className='d-flex align-items-center'>
                              <div className='flex-shrink-0'>
                                <img src={iconInfo} alt='' />
                              </div>
                              <div className='flex-grow-1 ms-3'>
                                <p className='c:red lt:2 ff:regular fs:12'>
                                  {error
                                    ? error
                                    : apiError
                                      ? apiError
                                      : secondError ?? ''}
                                </p>
                              </div>
                            </div>
                          </div>
                        ) : null}

                        <button
                          onClick={handleAcceptPolicies}
                          aria-label='Tümünü Kabul Et'
                          disabled={apiLoading}
                          className={`buttonStyle buttonStyle--icon buttonStyle--submit c:white ff:bold fs:16 bg:gradient:orange d-block w-100 text-center radius:12 ${
                            apiLoading
                              ? 'spinnerElem spinnerElem--orange'
                              : null
                          } ${apiLoading ? 'disabledBtn' : null}`}
                          type='submit'
                          style={
                            { '--form-height': '3rem' } as React.CSSProperties
                          }
                        >
                          Devam Et
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              )
            )}
          </div>
        </div>
      </section>

      <section
        className={`popupWrapper ${
          popupOpen
            ? 'animate__animated animate__fadeIn animate__faster'
            : `animate__animated animate__fadeOut animate__faster ${customClass}`
        }`}
      >
        {' '}
        {popupOpen ? (
          <div
            onScroll={(e) => handleScroll(e)}
            className='popupWrapper__content bg:white radius:20'
          >
            <div>
              <div
                className='popupWrapper__content-head d-flex align-items-center justify-content-between px-4 px-sm-5 radius:20 bg:white'>
                <div>
                  <h1 className='ff:bold fs:16 c:dark lt:1'>
                    {activePopup === 'privacyPolicy'
                      ? 'KVKK AYDINLATMA METNİ'
                      : 'KULLANIM ŞARTLARI'}
                  </h1>
                </div>
                <div className='ps-4'>
                  <button
                    aria-label='close popup'
                    onClick={handleTextPopup}
                    className='popupWrapper__close buttonStyle buttonStyle--light buttonStyle--paddingElem radius:full bg:white shadow:4'
                    type='button'
                    style={{ '--btnPaddingElem': '0' } as React.CSSProperties}
                  >
                    <img src={iconClose} alt='' />
                  </button>
                </div>
              </div>
              <div className='px-4 px-sm-5'>
                <section className="contentArea ff:light fs:16 c:dark">
                  <article style={{whiteSpace: "pre-wrap"}}>
                    <ReactMarkdown
                      children={activeText}
                      remarkPlugins={[remarkGfm]}
                    />
                  </article>
                </section>
              </div>
              <div
                style={{ height: '30px', width: '100%' }}
                ref={popupRef}
              ></div>
              <div className='popupWrapper__content-footer d-flex align-items-center px-4 px-sm-5 radius:20 bg:white'>
                <button
                  onClick={handleAccept}
                  aria-label='decline'
                  disabled={!isReaded || loading}
                  className={`buttonStyle buttonStyle--border buttonStyle--submit c:dark ff:bold fs:16 bg:white d-block w-100 text-center radius:12 ${
                    !isReaded ? 'disabledBtn' : null
                  }`}
                  type='submit'
                  style={
                    {
                      '--form-height': '3rem',
                      '--btn-border': '#3F4148',
                      '--btn-hover': '#3F4148',
                    } as React.CSSProperties
                  }
                >
                  Okudum,{' '}
                  {(activePopup === 'privacyPolicy' && privacyPolicy) ||
                  (activePopup === 'termOfUse' && termsOfUse)
                    ? 'Onaylamıyorum'
                    : 'Onaylıyorum'}
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </section>
    </>
  );
};

export default KvkkPopup;
