import React, { useContext, useEffect } from "react";
import { CartContext } from "contexts/CartContext/CartContext";
import { getShoppingCart } from "services/shoppingCart.service";
import iconBroken from "@images/icon-broken.svg";
import { Link } from "react-router-dom";
import { useTealium } from "hooks/useTealium";
/* eslint-disable react-hooks/exhaustive-deps */
const CartConfirm = ({ orderId }: { orderId: string }) => {
  const { setCart } = useContext(CartContext);
  const tealium = useTealium();

  useEffect(() => {
    async function handleShopppingCart() {
      try {
        const resp = await getShoppingCart();
        setCart!(resp);
      } catch (error) {}
    }
    handleShopppingCart();
  }, []);

  useEffect(() => {
    tealium.linkEvent({
      tealium_event: "order_complete",
      orderId: orderId,
    });
  }, [orderId])
  

  return (
    <>
      <section className="my-5">
        <div className="container">
          <section className="bg:white radius:12 py-4 py-sm-5 px-3 text-center shadow:4 mb-5">
            <div className="d-flex align-items-center justify-content-center">
              <div className="iconSize:20 me-3">
                <img src={iconBroken} alt="" />
              </div>
              <div className="c:dark fs:16 ff:light">
                <p>
                  Siparişiniz <strong>{orderId}</strong> numarasıyla başarıyla
                  oluşturuldu.
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-4">
              <Link
                to="/anasayfa"
                aria-label="anasayfa"
                className="buttonStyle buttonStyle--paddingElem hover:opacity:8 c:white ff:bold fs:16 bg:orange text-center radius:12 d-block"
                style={
                  { "--btnPaddingElem": "0.8rem 2.8rem" } as React.CSSProperties
                }
              >
                Ana Sayfa
              </Link>
            </div>
          </section>
        </div>
      </section>
    </>
  );
};

export default CartConfirm;
