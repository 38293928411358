import { useEffect } from "react";
import Field from "@components/Form/Field";
import useApi from "hooks/useApi";
import { getDemandSubjects } from "services/user.service";
import { ITalepForm } from "views/MyAccount/SoruVeTaleplerim/YeniTalep";
interface IProps {
  handleSubmit: () => void;
  state: ITalepForm;
  dispatch: any;
  error: { subject: string; message: string };
}
/* eslint-disable react-hooks/exhaustive-deps */
const TalepForm = ({ handleSubmit, state, dispatch, error }: IProps) => {
  const { data, success, handleApi } = useApi();
  useEffect(() => {
    handleApi(getDemandSubjects, {});
  }, []);

  interface ISubject {
    value: string;
    text: string;
  }

  const subjects: ISubject[] = success && data;
  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <div className="row row-cols-1 gx-0 gy-3">
        <div className="col">
          <Field
            type="select"
            name="gender"
            label="Cinsiyet"
            value={state?.subject}
            options={
              success
                ? subjects.map((subject) => {
                    return { value: subject.value, label: subject.value };
                  })
                : []
            }
            errors={error.subject}
            onChange={(e) => dispatch({ subject: e.target.value })}
          />
        </div>
        <div className="col">
          <Field
            type="textarea"
            name="textarea"
            value={state.message}
            onChange={(e) => dispatch({ message: e.target.value })}
            maxLength={250}
            errors={error.message}
          />
        </div>
        <div className="col">
          <div className="d-flex justify-content-center">
            <button
              onClick={handleSubmit}
              aria-label="sepete ekle"
              className="buttonStyle buttonStyle--icon c:white ff:bold fs:16 bg:orange py-3 px-5 text-center radius:12 d-flex align-items-center"
              type="submit"
            >
              Temsilcim Bana Ulaşsın
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default TalepForm;
