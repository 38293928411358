import  { useEffect, useRef, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import iconHamburger from "@images/icon-hamburger.svg";
import Search from "./Search";
import { HeaderPropsTypes } from ".";
import useOnClickOutside from "hooks/useOnClickOutside";
import { useOnHoverOutside } from "hooks/useOnHoverOutside";
/* eslint-disable react-hooks/exhaustive-deps */
const HeaderBottom = ({
  handleBody,
  handleMobileMenu,
  mobileMenuRef,
  menuData,
}: HeaderPropsTypes) => {
  const [megaMenuIsVisible, setMegaMenuIsVisible] = useState(false);
  const [customClass, setCustomClass] = useState("d-none");
  const [activeItem, setActiveItem] = useState<number>();
  const [activeChildItem, setActiveChildItem] = useState<number>();

  const hoverMegaMenu = (item: any) => {
    setMegaMenuIsVisible(true);
    setActiveItem(item.id);
    setActiveChildItem(item.child[0].id);
  };

  const closeHoverMenu = () => {
    setMegaMenuIsVisible(false);
    setActiveItem(0);
  };

  useEffect(() => {
    setTimeout(() => {
      if (megaMenuIsVisible) {
        handleBody!("generalOverlay");
        setCustomClass("");
      } else {
        setCustomClass("d-none");
        handleBody!("");
      }
    }, 100);
    return () => {};
  }, [megaMenuIsVisible]);

  const handleChildItem = (childId: number) => {
    setActiveChildItem(childId);
  };

  const megaMenuRef = useRef<any>(null);

  const handleMegaMenuOnClickOutside = () => {
    setMegaMenuIsVisible(false);
    setActiveItem(0);
  };

  useOnClickOutside(
    megaMenuRef,
    () => megaMenuIsVisible && handleMegaMenuOnClickOutside()
  );

  useOnHoverOutside(megaMenuRef, closeHoverMenu);
  const navigate = useNavigate();

  const handleChildNavigate = (uri: string, e: any) => {
    e.preventDefault();
    setMegaMenuIsVisible(false);
    handleBody!("");
    navigate(uri);
  };

  return (
    <section ref={megaMenuRef} className="header__bottom bg:gradient:orange:3">
      <div className="container">
        <div className="d-flex d-lg-none align-items-center justify-content-between">
          <section className="me-3">
            <button
              ref={mobileMenuRef}
              onClick={handleMobileMenu}
              type="button"
              aria-label="mobile menu button"
            >
              <div className="d-flex align-items-center">
                <div>
                  <img src={iconHamburger} alt="" />
                </div>
                <div className="ms-2">
                  <p className="c:white ff:bold fs:16:v2">Menü</p>
                </div>
              </div>
            </button>
          </section>
          <Search />
        </div>
        <nav className="header__bottom-nav d-none d-lg-block">
          <ul className="row row-cols-auto gy-0 gx-2 c:white ff:bold fs:15">
            {menuData &&
              menuData.map((item: any) => (
                <li
                  key={item.id}
                  onMouseOver={() =>
                    item.child.length
                      ? item.id !== activeItem && hoverMegaMenu(item)
                      : handleMegaMenuOnClickOutside()
                  }
                  // onClick={() => handleMegaMenu(item)}
                  className="col"
                >
                  {/* <!-- ana renklerin aktif classı "bg:orange:4" --> */}

                  <NavLink
                    to={item.url ?? "#"}
                    className={({ isActive, isPending }) =>
                      isPending ? "pending" : isActive ? "bg:orange:4" : ""
                    }
                  >
                    {item.title}
                  </NavLink>
                  {/* <!-- menü açıldığında body'e generalOverlay class'ı eklenmeli. -->
              <!-- kapandığında aşağıdaki class'tan "animate__fadeIn" silinir "animate__fadeOut" eklenir. --> */}
                  {item.id === activeItem && (
                    <div
                      className={`header__bottom-mega bg:white animate__animated animate__faster ${
                        megaMenuIsVisible
                          ? "animate__fadeIn"
                          : "animate__fadeOut"
                      } ${customClass}`}
                    >
                      <div className="row g-0 h-100">
                        <div className="col bg:white">
                          <p className="ff:bold c:dark fs:16 px-4 pt-4 pb-3">
                            {item.title}
                          </p>
                          {item.child.length && (
                            <ul className="header__bottom-mega__tab ff:regular fs:15 c:dark">
                              {item.child.map((childitem: any) => (
                                <li
                                  key={childitem.id}
                                  className={
                                    childitem.id === activeChildItem
                                      ? "activeDesktopMenu"
                                      : ""
                                  }
                                  onMouseOver={() =>
                                    handleChildItem(childitem.id)
                                  }
                                >
                                  <Link to={childitem.url ?? "#"}>
                                    {childitem.title}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                        {item.child.map(
                          (chilItem: any) =>
                            chilItem.children &&
                            chilItem.id === activeChildItem && (
                              <div key={chilItem.id} className="col bg:light:9">
                                <div className="p-4">
                                  <ul className="header__bottom-mega__tabDetail row row-cols-1 c:dark fs:15 ff:regular gy-4">
                                    {chilItem.children.map((children: any) => (
                                      <li key={children.id} className="col">
                                        <Link
                                          onClick={(e) =>
                                            handleChildNavigate(children.url, e)
                                          }
                                          to={children.url ?? ""}
                                        >
                                          {children.title}
                                        </Link>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            )
                        )}
                      </div>
                    </div>
                  )}
                </li>
              ))}
          </ul>
        </nav>
      </div>
    </section>
  );
};

export default HeaderBottom;
