import React from "react";

const TagHead = ({
  tag,
  bookmarksLength,
  loading,
}: {
  tag: string;
  bookmarksLength?: number;
  loading: boolean;
}) => {
  return (
    <div className="row row-cols-1 row-cols-sm-auto gy-sm-0 gy-1 gx-0 align-items-center justify-content-between text-center">
      <div className="col">
        <h1 className="c:dark ff:bold fs:28 lt:3">{tag}</h1>
      </div>
      <div className="col">
        <p className="ff:light c:dark fs:16">
          <strong>
            {loading ? null : bookmarksLength && bookmarksLength > 0 ? bookmarksLength : null}
          </strong>
          &nbsp;{loading ? "" : bookmarksLength ? "sonuç bulundu." : "Sonuç bulunamadı."}
        </p>
      </div>
    </div>
  );
};

export default TagHead;
