import React, { useContext, useEffect, useState } from 'react';
import iconInfo from '@images/icon-info.svg';
import Select from '@components/General/Select';
import { ICalculatedData, IOffer, ResultDetail } from 'types/productTypes';
import { CartContext } from 'contexts/CartContext/CartContext';
import InputGroupProductDetail from '@components/General/InputGroupProductDetail';
import { calculateShoppingCart } from 'services/shoppingCart.service';
import { ICartItem } from 'types/shoppingCartTypes';
import { convertDatetimeStrMonth } from 'utility/formatDate';
import { formatCurrency } from 'utility/formatCurrency';
import Skeleton from 'react-loading-skeleton';
import { ErrorHandler } from '@components/General/ErrorHandler';

/* eslint-disable react-hooks/exhaustive-deps */
interface IProps {
  product: ResultDetail;
}

const ProductSidebar = ({ product }: IProps) => {
  const { cart, loading, loadingPlusMinus, error, setError } = useContext(CartContext);
  const [selectedOffer, setSelectedOffer] = useState<IOffer | undefined>();
  const [calculatedData, setCalculatedData] = useState<ICalculatedData>();
  const [isPreOrder, setIsPreOrder] = useState(product.preOrder);
  const [cartItem, setCartItem] = useState<ICartItem | undefined>();
  const [qty, setQty] = useState<number>(1);
  const [calculateLoading, setCalculateLoading] = useState(false);


  useEffect(() => {
    const cartItemData = cart && cart.items.find((item) => item.productId === product.id);
    if(cartItemData){
      setIsPreOrder(cartItemData?.preOrder);
    }
  }, [cart]);


  useEffect(() => {
    setError("");
    const cartItemData =
      cart && cart.items.find((item) => item.productId === product.id);
    const handleCalculate = async (offer: IOffer) => {
      setCalculateLoading(true);
      try {
        const data = await calculateShoppingCart({
          productId: product.id,
          quantity: {
            amount: offer ? offer.quantity : 1,
            freeAmount: 0,
            units: 'string',
          },
          selectedOffer: offer?.id,
        });
        setCalculatedData(data);
      } catch (error) {
        const { errorMessage } = ErrorHandler(error);
        setError(errorMessage);
      } finally {
        setCalculateLoading(false);
      }
    };
    selectedOffer && !cartItemData && handleCalculate(selectedOffer);
    !selectedOffer && setCalculatedData(undefined);
  }, [selectedOffer, product.id, cart]);

  useEffect(() => {
    const cartItemData =
      cart &&
      cart.items.find(
        (item) => item.productId === product.id && item.preOrder === isPreOrder,
      );
    setCartItem(cartItemData);
    setQty(cartItemData ? cartItemData?.quantity?.amount : 1);
    return () => setError!('');
  }, [cart, product.id, isPreOrder]);

  const handlePreOrder = () => {
    setIsPreOrder(!isPreOrder);
    setSelectedOffer(undefined);
  };

  const offerType = !isPreOrder ? 'Normal' : 'PreOrder';
  const whichOffer = product.offers?.find(
    (offr) => offr?.offerType === offerType,
  );
  // console.log("ProductSidebar.render", cartItem);

  return (
    <div className='productDetails__sidebar'>
      {product.preOrder && (
        <div className='shadow:4 w-100 bg:white border:1 shadow:4 radius:12 px-4 py-3 mb-2'>
          <div className='row row-cols-auto g-0 justify-content-between align-items-center'>
            <div className='col'>
              {!isPreOrder ? (
                <p className='ff:bold c:orange fs:15'>Ön siparişi aç</p>
              ) : product.onlyPreOrder ? (
                <p className='ff:bold c:orange fs:15'>
                  Sadece ön siparişli ürün
                </p>
              ) : (
                <p className='ff:bold c:orange fs:15'>Ön siparişi kapa</p>
              )}
            </div>
            {!product.onlyPreOrder ? (
              <div className='col'>
                <label className='switchButton switchButton--single'>
                  <input
                    aria-label='switch input'
                    type='checkbox'
                    disabled={product.onlyPreOrder}
                    checked={isPreOrder}
                    onChange={() => handlePreOrder()}
                  />
                  <span className='switchButton__slider switchButton__round'></span>
                </label>
              </div>
            ) : null}
          </div>
          {isPreOrder && (
            <div className='mt-3'>
              <div className='row row-cols-1 g-0 c:dark fs:14 flexTable flexTable--topBorder text-capitalize'>
                <div className='col flexTable__item'>
                  <div
                    className='row row-cols-1 row-cols-xxl-auto justify-content-between text-center gx-0 gy-1 gy-xxl-0'>
                    <div className='col'>
                      <p>Başlangıç Tarihi:</p>
                    </div>
                    <div className='col'>
                      <p>
                        <strong>
                          {convertDatetimeStrMonth(
                            new Date(product.preOrderStartDate),
                          )}
                        </strong>
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col flexTable__item'>
                  <div
                    className='row row-cols-1 row-cols-xxl-auto justify-content-between text-center gx-0 gy-1 gy-xxl-0'>
                    <div className='col'>
                      <p>Bitiş Tarihi:</p>
                    </div>
                    <div className='col'>
                      <p>
                        <strong>
                          {convertDatetimeStrMonth(
                            new Date(product.preOrderEndDate),
                          )}
                        </strong>
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col flexTable__item'>
                  <div
                    className='row row-cols-1 row-cols-xxl-auto justify-content-between text-center gx-0 gy-1 gy-xxl-0'>
                    <div className='col'>
                      <p>Gerçek Siparişe Dönme Tarihi:</p>
                    </div>
                    <div className='col'>
                      <p>
                        <strong>
                          {convertDatetimeStrMonth(
                            new Date(product.preOrderActualDate),
                          )}
                        </strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      <div className='shadow:4 w-100 bg:white border:1 radius:12 p-4'>
        <div className='row row-cols-1 gx-0 gy-4'>
          <div className='col'>
            <p className='c:dark ff:bold fs:28'>
              &#8378;&nbsp;
              {!cartItem
                ? formatCurrency(product.price.value)
                : formatCurrency(cartItem.totalPrice.value)}
            </p>
          </div>

          {product.offers?.length ? (
            <div className='col'>
              <Select
                isPreOrder={isPreOrder}
                product={product}
                selectedOffer={selectedOffer}
                setSelectedOffer={setSelectedOffer}
                whichOffer={whichOffer}
                cartItem={cartItem}
              />
            </div>
          ) : null}
          <div className='col'>
            <div className='row row-cols-1 g-0 c:dark fs:16 ff:regular flexTable'>
              <div className='col flexTable__item'>
                <div className='row row-cols-auto justify-content-between g-0'>
                  <div className='col'>
                    <p>Perakende Satış Fiyatı</p>
                  </div>
                  <div className='col'>
                    <p>
                      <strong>
                        &#8378;&nbsp;{formatCurrency(product.retailPrice.value)}
                      </strong>
                    </p>
                  </div>
                </div>
              </div>
              <div className='col flexTable__item'>
                <div className='row row-cols-auto justify-content-between g-0'>
                  <div className='col'>
                    <p>Depo Satış Fiyatı</p>
                  </div>
                  <div className='col'>
                    <p>
                      <strong>
                        &#8378;&nbsp;{formatCurrency(product.salePrice.value)}
                      </strong>
                    </p>
                  </div>
                </div>
              </div>

              <>
                <div className='col flexTable__item'>
                  <div className='row row-cols-auto justify-content-between g-0'>
                    <div className='col'>
                      <p>Olası Kar Oranı</p>
                    </div>
                    <div className='col'>
                      <p>
                        <strong>
                          &#37;&nbsp;
                          {loading || loadingPlusMinus ? (
                            <Skeleton width={40} height={19} />
                          ) : cartItem ? (
                            cartItem.gainPercentage?.toFixed(2)
                          ) : calculateLoading ? (
                            <Skeleton width={57} height={19} />
                          ) : calculatedData ? (
                            calculatedData.gainPercentage?.toFixed(2)
                          ) : (
                            product.gainPercentage.toFixed(2)
                          )}
                        </strong>
                      </p>
                    </div>
                  </div>
                </div>

                <div className='col flexTable__item'>
                  <div className='row row-cols-auto justify-content-between g-0'>
                    <div className='col'>
                      <p>Olası Kar Tutarı</p>
                    </div>
                    <div className='col'>
                      <p>
                        &#8378;&nbsp;
                        <strong>
                          {loading || loadingPlusMinus ? (
                            <Skeleton width={60} height={19} />
                          ) : cartItem ? (
                            formatCurrency(cartItem.gainPrice.value)
                          ) : calculateLoading ? (
                            <Skeleton width={77} height={19} />
                          ) : calculatedData ? (
                            formatCurrency(calculatedData.gainPrice?.value)
                          ) : (
                            formatCurrency(product.gainPrice.value)
                          )}
                        </strong>
                      </p>
                    </div>
                  </div>
                </div>
              </>

              {(calculateLoading || loadingPlusMinus) && product.hasOffer ? (
                <div className='col flexTable__item'>
                  <div className='row row-cols-auto justify-content-between g-0'>
                    <div className='col'>
                      <p>Mal Fazlası Kazanım</p>
                    </div>
                    <div className='col'>
                      <p className='c:yellow:4'>
                        <Skeleton width={18} height={19} />
                      </p>
                    </div>
                  </div>
                </div>
              ) : calculatedData &&
              calculatedData.quantity &&
              !cartItem &&
              product.hasOffer ? (
                <div className='col flexTable__item'>
                  <div className='row row-cols-auto justify-content-between g-0'>
                    <div className='col'>
                      <p>Mal Fazlası Kazanım</p>
                    </div>
                    <div className='col'>
                      <p className='c:yellow:4'>
                        <strong>{calculatedData.quantity.freeAmount}</strong>
                      </p>
                    </div>
                  </div>
                </div>
              ) : (loading || loadingPlusMinus) && product.hasOffer ? (
                <div className='col flexTable__item'>
                  <div className='row row-cols-auto justify-content-between g-0'>
                    <div className='col'>
                      <p>Mal Fazlası Kazanım</p>
                    </div>
                    <div className='col'>
                      <p className='c:yellow:4'>
                        <Skeleton width={18} height={19} />
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                cartItem &&
                product.hasOffer && (
                  <div className='col flexTable__item'>
                    <div className='row row-cols-auto justify-content-between g-0'>
                      <div className='col'>
                        <p>Mal Fazlası Kazanım</p>
                      </div>
                      <div className='col'>
                        <p className='c:yellow:4'>
                          <strong>{cartItem?.quantity.freeAmount}</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
            {error && (
              <div className='mt-4'>
                <div
                  className='alert p-3 radius:12 shadow:10 bg:yellow:3'
                  style={
                    {
                      '--alert-borderColor': '#F5CB5F',
                    } as React.CSSProperties
                  }
                >
                  <div className='d-flex align-items-center'>
                    <div className='flex-shrink-0'>
                      <img src={iconInfo} alt='' />
                    </div>
                    <div className='flex-grow-1 ms-2'>
                      <p className='c:dark ff:regular fs:14'>{error}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {cartItem?.campaignLimitMessage && (
              <div className='mt-4'>
                <div
                  className='alert p-3 radius:12 shadow:10 bg:yellow:3'
                  style={
                    {
                      '--alert-borderColor': '#F5CB5F',
                    } as React.CSSProperties
                  }
                >
                  <div className='d-flex align-items-center'>
                    <div className='flex-shrink-0'>
                      <img src={iconInfo} alt='' />
                    </div>
                    <div className='flex-grow-1 ms-2'>
                      <p className='c:dark ff:regular fs:14'>{cartItem?.campaignLimitMessage}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className='col'>
            <div className='row row-cols-1 gx-0 gy-3'>
              <div className='col'>
                <InputGroupProductDetail
                  whichOffer={whichOffer}
                  product={product}
                  qty={qty!}
                  setQty={setQty}
                  isPreOrder={isPreOrder}
                  selectedOffer={selectedOffer}
                  cartItem={cartItem}
                  setSelectedOffer={setSelectedOffer}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductSidebar;
