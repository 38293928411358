import { AuthContextSite } from "contexts/AuthContext/AuthContext";
import { useContext, useEffect } from "react";

// Replace 'your_tealium_account' and 'your_environment' with your Tealium account and environment information

// Define the Tealium initialization function
function initializeTealium() {
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.async = true;
  script.defer = true;
  script.src = `https://tags.tiqcdn.com/utag/gsk/profile-rx-tr/prod/utag.js`;
  script.onload = () => {
    // Tealium has loaded, you can initialize it here if needed
  };

  document.head.appendChild(script);
}

// Define your Tealium hook
export function useTealium() {
  const { user } = useContext(AuthContextSite);

  useEffect(() => {
    // Initialize Tealium when the component mounts

    initializeTealium();
    const script = document.querySelector(
      `script[src*="https://tags.tiqcdn.com/utag/gsk/profile-rx-tr/prod/utag.js"]`
    );
    window.utag_cfg_ovrd = window.utag_cfg_ovrd || {};
    window.utag_cfg_ovrd.noview = true;

    // Clean up the Tealium script when the component unmounts
    return () => {
      if (script) {
        script.remove();
      }
    };
  }, []);

  return {
    // You can add Tealium functions or event tracking methods here
    trackEvent: (eventName: string, data: Record<string, any>) => {
      // Replace with your Tealium event tracking logic

      const newData = { ...data };
      delete newData.tealium_event;
      window.utag?.track(eventName, {
        tealium_event: data.tealium_event,
        source: "web",
        site: "TuruncuHat",
        data: {
          ...newData,
          oneKeyKode: user?.oneKeyCode,
          sessionId: localStorage.getItem("sessionId"),
        },
      });
    },
    viewEvent: (data: Record<string, any>) => {
      // Replace with your Tealium event tracking logic
      const newData = { ...data };
      delete newData.tealium_event;
      window.utag?.view({
        tealium_event: data.tealium_event,
        source: "web",
        site: "TuruncuHat",
        data: {
          ...newData,
          oneKeyKode: user?.oneKeyCode,
          sessionId: localStorage.getItem("sessionId"),
        },
      });
    },
    linkEvent: (data: Record<string, any>) => {
      // Replace with your Tealium event tracking logic
      const newData = { ...data };
      delete newData.tealium_event;
      window.utag?.link({
        tealium_event: data.tealium_event,
        source: "web",
        site: "TuruncuHat",
        data: {
          ...newData,
          oneKeyKode: user?.oneKeyCode,
          sessionId: localStorage.getItem("sessionId"),
        },
      });
    },
    // Add more Tealium functions as needed
  };
}
