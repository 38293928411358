import { useContext, useEffect } from "react";
import Password from "@components/Hesabim/MyAccount/Password";
import { Link } from "react-router-dom";
import { navigationData } from "data/NavigationData";
import MyAccountTabNav from "./MyAccountTabNav";
import { PublicContext } from "contexts/PublicContext/PublicContext";
/* eslint-disable react-hooks/exhaustive-deps */
const PasswordChange = () => {
  const { setSiteTitle } = useContext(PublicContext);
  useEffect(() => {
    setSiteTitle("Şifre Değişikliği");
  }, []);

  return (
    <>
      <section className="bg:white shadow:9 pt-4">
        <div className="container">
          <section className="breadcrumb pb-4">
            <ul className="c:dark fs:14 lt:3">
              <li>
                <Link to="/anasayfa">Anasayfa</Link>
              </li>
              <li>
                <Link to={navigationData.hesabim}>Hesabım</Link>
              </li>
              <li>
                <Link to={navigationData.password}>Şifre Değişikliği</Link>
              </li>
            </ul>
          </section>

          <MyAccountTabNav />
        </div>
      </section>
      <section className="mt-4 mt-sm-5 mb-5">
        <div className="container">
          <div className="row g-0 justify-content-center">
            <div className="col-12 col-lg-9 col-xl-8 col-xxl-7">
              <h1 className="c:dark ff:bold fs:24 mb-4 text-center text-md-start">
                Şifre Değişikliği
              </h1>
              <Password />
              {/* <!--#include virtual="/parts/components/account/password.html" --> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PasswordChange;
