import React, { useEffect } from "react";
import useApi from "hooks/useApi";
import { getPolicies } from "services/user.service";
import { IForm } from "./Step1";

interface IProps {
  state: IForm;
  dispatch: React.Dispatch<any>;
  setActivePopup: (val: string) => void;
  setActivePolicy: (val: any) => void;
  setPopupOpen: (val: boolean) => void;
}
/* eslint-disable react-hooks/exhaustive-deps */
const Conditions = ({
  state,
  dispatch,
  setActivePopup,
  setPopupOpen,
  setActivePolicy,
}: IProps) => {
  const { handleApi, data } = useApi();

  useEffect(() => {
    handleApi(getPolicies, {});
  }, []);

  const handleChange = (name: string, popup: string) => {
    const policy =
      data && data.find((item: { type: string }) => item.type === name);

    setActivePolicy(policy);
    setActivePopup(popup);
    setPopupOpen(true);
  };

  return (
    <div className="row row-cols-1 g-0 gy-3 gy-sm-4">
      <div className="col">
        <div className="mb-3">
          <ul className="formElement__block-list row row-cols-1 g-0">
            <li
              className="col"
              onClick={() => handleChange("privacy_policy", "privacyPolicy")}
            >
              <div className="formElement__block-list__arrow">
                <div className="kz-check">
                  <div>
                    <label className="kz-check__label">
                      <span className="d-block ff:regular fs:13 c:dark text-uppercase pe-4">
                        KVKK Aydınlatma Metni
                      </span>
                      <input
                        type="checkbox"
                        id="radio"
                        checked={state?.privacyPolicy}
                        onChange={() =>
                          handleChange("privacy_policy", "privacyPolicy")
                        }
                        name="radio"
                        aria-label="blankInput"
                        required
                      />
                      <div id="inputradio-1" className="kz-check__input"></div>
                    </label>
                  </div>
                </div>
              </div>
            </li>
            <li
              className="col"
              onClick={() => handleChange("terms_of_use", "termOfUse")}
            >
              <div className="formElement__block-list__arrow">
                <div className="kz-check">
                  <div>
                    <label className="kz-check__label">
                      <span className="d-block ff:regular fs:13 c:dark text-uppercase pe-4">
                        Kullanım Şartları
                      </span>
                      <input
                        type="checkbox"
                        id="radio-2"
                        name="radio-2"
                        checked={state?.termOfUse}
                        onChange={() =>
                          handleChange("terms_of_use", "termOfUse")
                        }
                        aria-label="blankInput-2"
                        required
                      />
                      <div id="inputradio-2" className="kz-check__input"></div>
                    </label>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Conditions;
