import { useContext, useEffect } from "react";
import ProductsBadge from "@components/Products/ProductsBadge";
import ProductsSlider from "@components/General/ProductsSlider";
import AppBanner from "@components/General/AppBanner";
import { Link } from "react-router-dom";
import { ProductFilters } from "contexts/BodyContext/BodyContext";
import { PublicContext } from "contexts/PublicContext/PublicContext";
/* eslint-disable react-hooks/exhaustive-deps */
const Products = () => {
  const { setSiteTitle } = useContext(PublicContext);

  useEffect(() => {
    setSiteTitle("Ürünler");
  }, []);

  return (
    <>
      <section className="breadcrumb my-4 my-md-5">
        <div className="container">
          <ul className="c:dark fs:14 lt:3">
            <li>
              <Link to="/anasayfa">Anasayfa</Link>
            </li>
            <li>
              <Link to="/urunler">Ürünler</Link>
            </li>
          </ul>
        </div>
      </section>
      <section className="mb-5">
        <ProductsBadge />
      </section>
      <section className="my-5">
        <ProductsSlider
          filter={ProductFilters.Popular}
          title={"Popüler Ürünler"}
        />
      </section>
      <section className="my-5">
        <ProductsSlider
          filter={ProductFilters.Turuncu}
          title={"Turuncu Ürünler"}
        />
      </section>
      <section className="my-5">
        <AppBanner />
        <p>NP-TR-NA-WCNT-240008</p>
      </section>
    </>
  );
};

export default Products;
