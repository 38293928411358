import { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import FreePage from '@components/General/FreePage';
import { PublicContext } from 'contexts/PublicContext/PublicContext';
/* eslint-disable react-hooks/exhaustive-deps */
const Contact = () => {
  const { setSiteTitle } = useContext(PublicContext);
  useEffect(() => {
    setSiteTitle('İletişim');
  }, []);
  return (
    <>
      <section className='breadcrumb mt-4 mt-sm-5'>
        <div className='container'>
          <ul className='c:dark fs:14 lt:3'>
            <li>
              <Link to='/anasayfa'>Anasayfa</Link>
            </li>
            <li>
              <Link to='/iletisim'>İletişim</Link>
            </li>
          </ul>
        </div>
      </section>
      <FreePage
        title='İletişim'
        content={`Esentepe Mah. Bahar Sok. No: 13 River Plaza
         
Kat: 22 Kapı No: 58 & Kat: 23 Kapı No: 61/62/63 34394 Şişli İstanbul
(0212) 339 44 00 - 339 45 75

Site sorumlusu : Canan Şeheri

https://tr.gsk.com`}
      />
    </>
  );
};

export default Contact;
