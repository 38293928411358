import React, { useState, useEffect, useContext } from "react";
import useApi from "../../hooks/useApi";
import { useAuth } from "../../hooks/useAuth";
import { checkUserEmailLogin, loginUser } from "../../services/user.service";
import loginImage from "@images/login-img.png";
import { z } from "zod";
import infoicon from "@images/icon-info.svg";
import { emailValidation } from "validations/AuthValidations";
import { Link, useNavigate } from "react-router-dom";
import { useLocalStorage } from "hooks/useLocalStorage";
import Field from "@components/Form/Field";
import classNames from "classnames";
import jwtDecode from "jwt-decode";
import { PublicContext } from "contexts/PublicContext/PublicContext";
import { useTealium } from "hooks/useTealium";
import { ErrorHandler } from "@components/General/ErrorHandler";
/* eslint-disable react-hooks/exhaustive-deps */
const Login = () => {
  const { login: LoginContext } = useAuth();
  const { setItem } = useLocalStorage();
  const {
    data,
    success,
    error: apiError,
    loading,
    handleApi,
    setError: setApiError,
  } = useApi();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [step, setStep] = useState(1);
  const [error, setError] = useState("");
  const [checkEmailLoading, setCheckEmailLoading] = useState(false);
  const [touched, setTouched] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const { setSiteTitle } = useContext(PublicContext);
  const tealium = useTealium();

  const navigate = useNavigate();

  const handleChange = (event: any) => {
    setError("");
    const { value } = event.target;
    if (touched === false) setTouched(true);
    setEmail(value);

    const validation = emailValidation;

    const result = validation.safeParse(value);

    if (result.success) {
      setDisabled(false);
      setError("");
    } else {
      setError(result.error.issues[0].message);
      setDisabled(true);
    }
  };

  const handleLogin = async () => {
    setError("");
    setApiError("");

    try {
      if (!email.includes("gsk.com")) {
        await handleApi(loginUser, {
          email: email,
          password: password,
        });
      } else {
        navigate("/gsk-giris");
      }
    } catch (err) {
      const { errorMessage } = ErrorHandler(error);
      setError(errorMessage);
    }
  };

  const handleErrorRegisterSteps = async () => {
    setCheckEmailLoading(true);

    try {
      const resp = await checkUserEmailLogin(email);
      console.log("resp", resp);
    } catch (error: any) {
      console.log(error);
      const { errorMessage } = ErrorHandler(error);
      setError(errorMessage);
    } finally {
      setCheckEmailLoading(false);
    }
  };

  useEffect(() => {
    setSiteTitle("Giriş");
    if (success) {
      tealium.linkEvent({
        tealium_event: "user_login_success",
        user_email: data.applicationUser.email,
      });
      const decode: any = jwtDecode(data.accessToken);
      const { iat } = decode;
      localStorage.setItem("sessionId", iat);
      LoginContext(data.applicationUser);
      setItem("accessToken", data.accessToken);
      setItem("accessTokenExpireDate", data.accessTokenExpireDate);
      setItem("refreshToken", data.refreshToken);
      setItem("refreshTokenExpireDate", data.refreshTokenExpireDate);
      navigate("/anasayfa");
    } else if (
      apiError &&
      apiError === "Lütfen tüm kayıt adımlarınızı tamamlayın."
    ) {
      handleErrorRegisterSteps();
    }
  }, [success, data, apiError]);

  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    window.addEventListener("resize", () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
  }, []);

  const handleStep = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (email.endsWith("gsk.com")) {
      navigate("/gsk-giris");
      return;
    }
    setError("");
    try {
      const isValidEmail = emailValidation.parse(email);
      if (isValidEmail.length) {
        setCheckEmailLoading(true);
        try {
          await checkUserEmailLogin(email);
          setStep(2);
        } catch (error: any) {
          if (error) {
            if (
              error.response.status === 409 &&
              error.response.data.code === "Migrated"
            ) {
              navigate("/sifremi-degistir", { state: { email: email } });
            }
            const { errorMessage } = ErrorHandler(error);
            setError(errorMessage);
          }
        } finally {
          setCheckEmailLoading(false);
        }
      }
    } catch (err) {
      if (err instanceof z.ZodError) {
        setError(err.issues[0].message);
      }
    }
  };

  const handleBackward = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setStep(1);
    setError("");
    setApiError("");
  };

  return (
    <div className="layoutBody">
      <main className="layoutMain layoutMain--login">
        <section className="w-100">
          <div className="container">
            <div className="row gx-0 gy-4 gy-lg-0 justify-content-between align-items-center">
              <div className="col-12 col-lg-6 col-xl-7">
                <div className="row row-cols-1 row-cols-xl-auto align-items-center gx-0 gx-xl-4 gy-3 gy-xl-0 loginTextBlock text-center text-xl-start">
                  <div className="col">
                    <div className="loginTextBlock__img">
                      <img className="mx-auto" src={loginImage} alt="" />
                    </div>
                  </div>
                  <div className="col">
                    <p className="c:orange ff:bold fs:12 lt:1 text-uppercase">
                      TURUNCUHAT ECZACILARIN PLATFORMU
                    </p>
                    <h1 className="c:dark ff:bold fs:44 lh:2 mt-2">
                      TuruncuHat’a
                      <br />
                      <span data-testid="welcometext">Hoş Geldiniz.</span>
                    </h1>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6 col-xl-5">
                <div className="formElement ">
                  <div className="mb-4">
                    <div className="row align-items-center row-cols-1 row-cols-sm-auto flex-column-reverse flex-sm-row justify-content-between g-0">
                      <div className="col">
                        <h1 className="ff:light c:dark fs:28 d-flex align-items-center justify-content-center">
                          {step === 1 ? "Giriş Yap" : "Şifrenizi girin"}
                        </h1>
                      </div>
                      <div className="col">
                        <div className="mb-4 mb-sm-0">
                          <div className="row row-cols-auto justify-content-center gx-3 gy-0">
                            {step === 2 && (
                              <div className="col">
                                <button
                                  onClick={handleBackward}
                                  aria-label="form control back"
                                  className="formController formController--left bg:white"
                                ></button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <form onSubmit={(e) => e.preventDefault()}>
                    <div className="formElement__block bg:white radius:12 shadow:3 p-3 p-sm-4">
                      <div className="p-2">
                        <div className="row row-cols-1 g-0 gy-3 gy-sm-4">
                          {step === 1 && (
                            <div className="col">
                              <Field
                                type="text"
                                name="email"
                                label="e-mail"
                                value={email}
                                errors={error}
                                loading={loading}
                                onChange={handleChange}
                              />
                            </div>
                          )}
                          {/* <!-- email işleminden sonra email inputu gizlenir aşağıdaki şifre inputu görünür --> */}
                          {step === 2 && (
                            <div className="col">
                              <Field
                                type="password"
                                name="password"
                                label="şifre"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                errors={error}
                                required={true}
                              >
                                <Link
                                  aria-label="forgot password"
                                  className="ff:bold fs:12 c:orange d-flex justify-content-end mt-2 hover:line"
                                  to={`/sifremi-unuttum`}
                                  state={{ email: email }}
                                >
                                  Şifremi Unuttum
                                </Link>
                              </Field>
                            </div>
                          )}
                          <div className="col">
                            {apiError.length ? (
                              <div
                                className="alert  bg:red:2 p-3 radius:12 mb-3 mb-sm-4"
                                style={
                                  {
                                    "--alert-borderColor":
                                      "rgba(243, 52, 52, 0.22)",
                                  } as React.CSSProperties
                                }
                              >
                                <div className="d-flex align-items-center">
                                  <div className="flex-shrink-0">
                                    <img src={infoicon} alt="" />
                                  </div>
                                  <div className="flex-grow-1 ms-3">
                                    <p
                                      data-testid="api-error-text"
                                      className="c:red lt:2 ff:regular fs:12"
                                    >
                                      {apiError}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            <button
                              aria-label="continue"
                              disabled={
                                loading || checkEmailLoading || disabled
                              }
                              onClick={step === 1 ? handleStep : handleLogin}
                              className={classNames(
                                "buttonStyle buttonStyle--icon buttonStyle--submit c:white ff:bold fs:16 bg:gradient:orange d-block w-100 text-center radius:12",
                                {
                                  spinnerElem: loading || checkEmailLoading,
                                  disabledBtn: disabled,
                                }
                              )}
                              type="submit"
                              data-testid="login-button"
                              style={
                                {
                                  "--form-height": "3rem",
                                } as React.CSSProperties
                              }
                            >
                              {step === 1 ? "Devam Et" : "Giriş Yap"}
                            </button>
                            {/* <!--email işleminden sonra aşağıdaki buton gizlenir.--> */}
                            {step === 1 && (
                              <Link
                                aria-label="yeni hesap oluştur"
                                to={"/kayit-ol"}
                                className="buttonStyle buttonStyle--icon buttonStyle--lightOrange buttonStyle--submit c:orange ff:bold fs:16 bg:orange:2 d-flex align-items-center justify-content-center w-100 text-center radius:12 mt-3 mt-sm-4"
                                style={
                                  {
                                    "--form-height": "3rem",
                                  } as React.CSSProperties
                                }
                              >
                                Yeni Hesap Oluştur
                              </Link>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Login;
