import { createContext } from "react";
import { INotification } from "types/notificationTypes";
import { IPreference } from "views/MyAccount/ContactPreferences";

interface IBodyContext {
  loading: boolean;
  notifications: INotification[] | [];
  reFetchNotifications: () => void;
  unreadedNotifications: number;
  preferences?: IPreference[];
  toggleState: number;
  setToggleState: React.Dispatch<React.SetStateAction<number>>;
}

export const NotificationContext = createContext<IBodyContext>({
  reFetchNotifications: () => {},
  loading: false,
  notifications: [],
  preferences: [],
  unreadedNotifications: 0,
  toggleState: 1,
  setToggleState: ()=> {}
});
