import React, { useEffect, useState } from "react";

import { getBlogHome } from "services/blog.service";
import { IHomeBlogs } from "views/Blog";
import { ErrorHandler } from "@components/General/ErrorHandler";
import { BlogContext } from "./BlogContext";

interface IFetchData {
  loading: boolean;
  blogs: IHomeBlogs;
  error?: string;
}
/* eslint-disable react-hooks/exhaustive-deps */
const BlogProvider = ({ children }: { children: React.ReactElement }) => {
  const [blogMenu, setBlogMenu] = useState<any>();

  const [fetchData, setFetchData] = useState<IFetchData>({
    loading: true,
    blogs: {
      blogs: [],
      slider: [],
    },
    error: "",
  });

  const loadData = async () => {
    try {
      const [blogs] = await Promise.all([getBlogHome()]);
      setFetchData({
        loading: false,
        blogs,
      });
    } catch (error) {
      const { errorMessage } = ErrorHandler(error);
      setFetchData({ ...fetchData, loading: false, error: errorMessage });
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const { loading, blogs, error } = fetchData;

  return (
    <BlogContext.Provider
      value={{
        loading,
        blogs,
        blogMenu,
        setBlogMenu,
        error,
      }}
    >
      {children}
    </BlogContext.Provider>
  );
};

export default BlogProvider;
