import CartHead from "./CartHead";
import ListCarts from "./ListCarts";
import ProductsSlider from "@components/General/ProductsSlider";
import { ProductFilters } from "contexts/BodyContext/BodyContext";

const Carts = () => {
  return (
    <>
      <section className="my-5">
        <div className="container">
          {/* <!-- <h1 className="c:dark ff:bold fs:24 mb-3 pb-1 text-center text-lg-start">Daha Önceki Siparişlerim</h1> --> */}
          <CartHead />

          <ListCarts />
        </div>
      </section>
      <section className="my-5">
        <ProductsSlider
          filter={ProductFilters.Popular}
          title={"Popüler Ürünler"}
        />
      </section>
      <section className="my-5">
        <ProductsSlider
          filter={ProductFilters.Turuncu}
          title={"Turuncu Ürünler"}
        />
      </section>
    </>
  );
};

export default Carts;
