import { useEffect } from "react";
import BlogCard from "./BlogCard";
import { IPost } from "./BlogDetail";
import useApi from "hooks/useApi";
import { getPostsBySlug } from "services/post.service";
import { IBlogPosts } from "views/Blog/BlogCategories";
import Skeleton from "react-loading-skeleton";
/* eslint-disable react-hooks/exhaustive-deps */
const BenzerKonular = ({ post }: IPost) => {
  const { loading, handleApi, success, data } = useApi();
  useEffect(() => {
    handleApi(getPostsBySlug, post.blogName);
  }, [post.blogName]);

  const postData: IBlogPosts[] = data;
  const similarPosts =
    postData && postData.filter((elem) => elem.id !== post.id).slice(0, 3);

  return (
    <>
      {loading ? (
        <section className="my-5 py-0 py-lg-5">
          <div className="container">
            <div className="row row-cols-1 row-cols-lg-3 gx-0 gx-lg-3 gy-3 gy-lg-4">
              <div className="col">
                <div className="blogCard">
                  <div className="blogCard__box blogCard__box--sm bg:white radius:12 shadow:7">
                    <Skeleton height={384} />
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="blogCard">
                  <div className="blogCard__box blogCard__box--sm bg:white radius:12 shadow:7">
                    <Skeleton height={384} />
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="blogCard">
                  <div className="blogCard__box blogCard__box--sm bg:white radius:12 shadow:7">
                    <Skeleton height={384} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : similarPosts?.length ? (
        <section className="my-5 py-0 py-lg-5">
          <div className="container">
            <h1 className="c:dark ff:bold fs:24 text-center text-lg-start mb-4">
              Benzer Konular
            </h1>

            <div className="row row-cols-1 row-cols-lg-3 gx-0 gx-lg-3 gy-3 gy-lg-4">
              {success &&
                similarPosts?.map((post) => (
                  <BlogCard key={post.id} color={""} post={post} />
                ))}
            </div>
          </div>
        </section>
      ) : null}
    </>
  );
};

export default BenzerKonular;
