import { useContext, useEffect, useState } from "react";
import Carts from "@components/Cart/Carts";
import { CartContext } from "contexts/CartContext/CartContext";
import CartEmpty from "@components/Cart/CartEmpty";
import CartStore from "@components/Cart/CartStore";
import CartFooter from "@components/Cart/CartFooter";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CartStepper from "@components/Cart/CartStepper";
import CartSummary from "@components/Cart/CartSummary";
import CartConfirm from "@components/Cart/CartConfirm";
import {
  completeShoppingCart,
  completeShoppingCartStepTwo,
} from "services/shoppingCart.service";
import CartsSkeleton from "@components/Cart/CartsSkeleton";
import { ISelectedWareHouse } from "types/wareHouseTypes";
import { PublicContext } from "contexts/PublicContext/PublicContext";
import { ErrorHandler } from "@components/General/ErrorHandler";
import { BodyContext } from "contexts/BodyContext/BodyContext";
import PopupApprove from "@components/General/PopUps/PopupApprove";
import iconBag from "@images/icon-bag.svg";
/* eslint-disable react-hooks/exhaustive-deps */
const Cart = () => {
  let { state } = useLocation();
  const [selectedWareHouse, setSelectedWareHouse] =
    useState<ISelectedWareHouse>({
      wareHouseId: "",
      wareHouseName: "",
      isQuickOrder: false,
    });
  const {
    cart,
    updateWareHouseId,
    success: cartSuccess,
    loading: cartLoading,
    error,
    setError,
  } = useContext(CartContext);

  const { setSiteTitle } = useContext(PublicContext);

  const { handlePopup, activePopup, data } = useContext(BodyContext);

  const [step, setStep] = useState("cart");
  const [orderId, setOrderId] = useState("");

  useEffect(() => {
    if (state?.warehouseId?.length) {
      setSelectedWareHouse({
        wareHouseId: state.warehouseId,
        wareHouseName: state.warehouseName,
        isQuickOrder: state.isQuickOrder ?? false,
      });
    }
  }, [state]);

  const navigate = useNavigate();
  let { id } = useParams();

  useEffect(() => {
    if (id) {
      setStep(id);
    } else {
      setStep("cart");
    }
  }, [id]);

  const [loading, setLoading] = useState(false);

  const handleCompleteShoppingCart = async () => {
    setLoading(true);
    try {
      const data = await completeShoppingCart();
      setOrderId(data.orderCode);
      navigate("/sepet/onay");
    } catch (error) {
      const { errorCode, errorMessage } = ErrorHandler(error);
      if (errorCode === "WouldYouLikeToContinueCampaignUseLimit") {
        handlePopup("completeShoppingCart", {
          popupMessage: errorMessage,
        });
      } else {
        setError(errorMessage);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCompleteShoppingCartStepTwo = async () => {
    setLoading(true);
    handlePopup("", null);
    try {
      const data = await completeShoppingCartStepTwo();
      setOrderId(data.orderCode);
      navigate("/sepet/onay");
    } catch (error: any) {
      const { errorMessage } = ErrorHandler(error);
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleStep = () => {
    switch (step) {
      case "cart":
        navigate("/sepet/depo");
        break;
      case "depo":
        if (cart && selectedWareHouse) {
          updateWareHouseId!(selectedWareHouse.wareHouseId, "send");
        }
        break;
      case "ozet":
        handleCompleteShoppingCart();
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (cartSuccess && step === "depo" && cart && cart.warehouseId) {
      navigate("/sepet/ozet");
    }
  }, [cartSuccess, cart]);

  useEffect(() => {
    setSiteTitle("Sepetim");
  }, []);

  useEffect(() => {
    return () => {
      setError("");
    };
  }, []);

  if (step === "onay") return <CartConfirm orderId={orderId} />;

  return (
    <>
      {activePopup === "completeShoppingCart" && (
        <PopupApprove
          popupOpen={activePopup === "completeShoppingCart"}
          popupText={
            data?.popupMessage ? data.popupMessage : `Bir hata oluştu.`
          }
          approve={handleCompleteShoppingCartStepTwo}
          icon={iconBag}
          buttonText={"Onayla"}
          loading={loading}
          apiError={error || ""}
        />
      )}
      <CartStepper step={step} />
      {step === "cart" && cartLoading ? (
        <CartsSkeleton />
      ) : step === "cart" && !cartLoading && (!cart || cart?.quantity === 0) ? (
        <CartEmpty />
      ) : (
        step === "cart" && <Carts />
      )}
      {step === "depo" && (
        <CartStore
          selectedWareHouse={selectedWareHouse}
          setSelectedWareHouse={setSelectedWareHouse}
        />
      )}
      {step === "ozet" && <CartSummary cart={cart!} />}
      <CartFooter
        handleStep={handleStep}
        step={step}
        selectedWareHouse={selectedWareHouse}
        apiLoading={loading}
      />
    </>
  );
};

export default Cart;
