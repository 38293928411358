import TdkProduct from "@components/TuruncuHat/TdkProduct";
import { ITdkProduct, ITdkProducts } from "types/productTypes";
import { ISelectedWareHouse } from "views/TuruncuHat/TuruncuDonemsel";

interface IProps {
  handleActivePopup: (popup: string, product: ITdkProduct) => void;
  selectedWareHouse: ISelectedWareHouse;
  data: ITdkProducts;
  setSelectedPlan: (id: string) => void;
}

const TdkProducts = ({
  handleActivePopup,
  selectedWareHouse,
  data,
  setSelectedPlan,
}: IProps) => {
  return (
    <>
      <section>
        {data && data.products?.length ? (
          <div className="container">
            <h1 className="c:dark ff:bold fs:24 mb-3 pb-1 text-center text-lg-start">
              Kampanyalı Ürünler
            </h1>

            <section className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-5 g-3">
              {data.products.map((product, index) => (
                <TdkProduct
                  data={data}
                  product={product}
                  key={product.productId + index}
                  handleActivePopup={handleActivePopup}
                  selectedWareHouse={selectedWareHouse}
                  setSelectedPlan={setSelectedPlan}
                />
              ))}
            </section>
            {/* {products && (
     <ListPagination
       path="/turuncu-donemsel-kampanyalar"
       searchParams={searchParams}
       items={products}
     />
   )} */}
          </div>
        ) : null}
      </section>
    </>
  );
};

export default TdkProducts;
