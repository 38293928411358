import tick from "@images/icon-tick.svg";
import classNames from "classnames";

function ProgressBarItem({ currentStep, step, title, titleTop }: any) {
  const className = classNames("col", {
    registerProgress__enable: currentStep >= step,
    registerProgress__current: currentStep === step,
  });

  const textClassName = classNames(
    "registerProgress__text ff:bold",
    {
      "fs:10": !titleTop,
      "c:orange": !titleTop,
      "fs:12": titleTop,
      "lt:2": titleTop,
      "c:light:20": titleTop,
    },
    "text-uppercase px-1"
  );
  return (
    <li className={className}>
      <div
        className={classNames("registerProgress__item", { "mt-4": !titleTop })}
      >
        <p className={textClassName}>{title}</p>
        {!titleTop && (
          <img className="registerProgress__icon mx-auto" src={tick} alt="" />
        )}
      </div>
    </li>
  );
}

export default ProgressBarItem;
