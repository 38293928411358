import { createContext } from "react";
import { IHomeBlogs } from "views/Blog";
import { IBlogNav } from "@components/Blog/BlogMenu";

interface IBlogContext {
  setBlogMenu: (menu: IBlogNav[]) => void;
  blogMenu: IBlogNav[];
  loading: boolean;
  blogs: IHomeBlogs;
  error?: string;
}

export const BlogContext = createContext<IBlogContext>({
  setBlogMenu: () => {},
  blogMenu: [],
  loading: false,
  blogs: {
    blogs: [],
    slider: [],
  },
});

