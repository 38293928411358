import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RegisterContext } from "./RegisterContext";
import { useLocalStorage } from "hooks/useLocalStorage";
import { IUser } from "hooks/useUser";
/* eslint-disable react-hooks/exhaustive-deps */
export const RegisterProvider = ({ children }: any) => {
  const [token, setToken] = useState("");
  const location = useLocation();
  const { getItem } = useLocalStorage();
  const [user, setUser] = useState<IUser>();
  const navigate = useNavigate();
  useEffect(() => {
    // const userData = getItem("registeruser");
    const registertoken = getItem("registertoken");
    if (user) {
    } else if (step !== 1) {
      navigate(`/kayit-ol/adim-1`);
    }
    if (registertoken) {
      setToken(registertoken);
    }
  }, [user]);

  const onPop = () => {
    navigate(`/giris`);
  };

  useEffect(() => {
    window.addEventListener("popstate", onPop);
    return () => window.removeEventListener("popstate", onPop);
  }, [window]);

  const findStep = (): number => {
    try {
      const array = location.pathname.split("/");
      if (array.length > 1) {
        const lastPath = array.slice(-1)[0];
        if (lastPath.startsWith("adim")) {
          const step = lastPath.split("-").slice(-1)[0];
          return Number(step);
        }
      }
    } catch (error) {}
    return 1;
  };

  const [step, setStep] = useState<number>(findStep());

  useEffect(() => {
    setStep(findStep());
  }, [location]);

  // Wrap the context provider around our component
  return (
    <RegisterContext.Provider value={{ step, user, setUser, token, setToken }}>
      {children}
    </RegisterContext.Provider>
  );
};
