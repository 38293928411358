import React, { useContext, useEffect, useState } from "react";
import {
  addQuickOrder,
  applyQuickOrder,
  deleteQuickOrder,
  getQuickOrders,
} from "services/user.service";
import { IQuickOrder } from "types/userTypes";
import { QuickOrderContext } from "./QuickOrderContext";
import { CartContext } from "contexts/CartContext/CartContext";
import { useNavigate } from "react-router-dom";
import { ErrorHandler } from "@components/General/ErrorHandler";
import { BodyContext } from "contexts/BodyContext/BodyContext";
import { useTealium } from "hooks/useTealium";

const QuickOrderProvider = ({ children }: { children: React.ReactElement }) => {
  const [quickOrders, setQuickOrders] = useState<IQuickOrder[]>([]);
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const { handlePopup } = useContext(BodyContext);
  const { setCart } = useContext(CartContext);
  const tealium = useTealium();

  const loadData = async () => {
    try {
      const [quickOrders] = await Promise.all([getQuickOrders()]);
      const orders: IQuickOrder[] = quickOrders;
      const sortOrders = orders.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return (
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      });

      setQuickOrders(sortOrders);
      setSuccess(true);
    } catch (error) {
      const { errorMessage } = ErrorHandler(error);
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const reFetchQuickOrders = async () => {
    try {
      const data = await getQuickOrders();
      setQuickOrders(data);
      setSuccess(true);
    } catch (error) {
      const { errorMessage } = ErrorHandler(error);
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleAddQuickOrder = async (quickOrderData: any) => {
    try {
      await addQuickOrder(quickOrderData);
      tealium.linkEvent({
        tealium_event: "quick_order_add",
        data: quickOrderData,
      });
      await reFetchQuickOrders();
      setSuccess(true);
    } catch (error) {
      const { errorMessage } = ErrorHandler(error);
      setError(errorMessage);
    }
  };
  const handleRemoveQuickOrder = async (quickOrderId: string) => {
    try {
      await deleteQuickOrder(quickOrderId);
      tealium.linkEvent({
        tealium_event: "quick_order_remove",
        quickOrderId: quickOrderId,
      });
      await reFetchQuickOrders();
      setSuccess(true);
    } catch (error) {
      const { errorMessage } = ErrorHandler(error);
      setError(errorMessage);
    }
  };
  const navigate = useNavigate();

  const handleRepeatQuickOrder = async (orderId: string) => {
    setError("");
    try {
      setLoading(true);
      const resp = await applyQuickOrder(orderId);
      setCart(resp);
      setSuccess(true);
      navigate("/sepet", {
        state: {
          warehouseId: resp.warehouseId,
          warehouseName: resp.warehouseName,
          isQuickOrder: true,
        },
      });
      tealium.linkEvent({
        tealium_event: "quick_order_repeat",
        warehouseId: resp.warehouseId,
        warehouseName: resp.warehouseName,
        totalPrice: resp.totalPrice,
        product_quantity: resp.items[0].quantity,
        product_price: resp.items[0].salePrice,
        product_id: resp.items[0].productId,
      });
      handlePopup("", null);
    } catch (error: any) {
      const { errorMessage } = ErrorHandler(error);
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <QuickOrderContext.Provider
      value={{
        quickOrders,
        loading,
        reFetchQuickOrders,
        handleAddQuickOrder,
        handleRemoveQuickOrder,
        success,
        handleRepeatQuickOrder,
        error,
      }}
    >
      {children}
    </QuickOrderContext.Provider>
  );
};

export default QuickOrderProvider;
