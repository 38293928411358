import { useContext, useEffect } from "react";
import StoreMapDetail from "@components/Stores/StoreMapDetail";
import useApi from "hooks/useApi";
import { getWareHouseById } from "services/warehouse.service";
import { Link, useParams } from "react-router-dom";
import { IWareHousesResult } from "types/wareHouseTypes";
import StoresHeadBreadCrumb from "@components/Stores/StoresHeadBreadCrumb";
import StoreOrders from "./StoreOrders";
import { PublicContext } from "contexts/PublicContext/PublicContext";
/* eslint-disable react-hooks/exhaustive-deps */
const StoreDetails = () => {
  const { success, handleApi, data, loading } = useApi();
  const { setSiteTitle } = useContext(PublicContext);
  
  const { id } = useParams();
  useEffect(() => {
    handleApi(getWareHouseById, { id: id });
    return () => {};
  }, [id]);

  const wareHouse: IWareHousesResult = success && data;

  useEffect(() => {
    setSiteTitle(wareHouse?.name);
  }, [wareHouse]);
  return (
    <>
      <section className="bg:white shadow:9 pt-4">
        <div className="container">
          <section className="breadcrumb pb-4">
            <ul className="c:dark fs:14 lt:3">
              <li>
                <Link to="/anasayfa">Anasayfa</Link>
              </li>
              <li>
                <Link to="/depolar">Depolar</Link>
              </li>
              <li>
                <Link to={`/depolar/${wareHouse?.id}`}>{wareHouse?.name}</Link>
              </li>
            </ul>
          </section>
          <StoresHeadBreadCrumb />
        </div>
      </section>

      <StoreMapDetail wareHouse={wareHouse} loading={loading} />

      <StoreOrders id={id} />
    </>
  );
};

export default StoreDetails;
