import React from "react";
import iconStarFill from "@images/icon-star-fill.svg";
import iconHour from "@images/icon-hour.svg";
import { Link } from "react-router-dom";
import { ITdkProducts } from "types/productTypes";
import { ISearchProduct } from "views/Search/SearchDetails";
import HeartButtonSearch from "./HeartButtonSearch";

interface IProps {
  product: ISearchProduct;
  data: ITdkProducts;
}

const SearchOrangeProductCard = ({ product }: IProps) => {
  return (
    <div className="col">
      <div className="productListCard h-100">
        <div className="border:1 bg:white radius:12 p-3 shadow:4 w-100 h-100">
          <div
            className="productListCard__box d-flex flex-column heightElem"
            style={{ "--heightElem": "17.75rem" } as React.CSSProperties}
          >
            <div>
              <div
                className="d-flex justify-content-between align-items-center mb-3 heightElem"
                style={{ "--heightElem": "2rem" } as React.CSSProperties}
              >
                <div>
                  <div className="row row-cols-auto gy-0 gx-1">
                    {product?.label?.includes("PreOrder") && (
                      <div className="col">
                        <div
                          className="widthElem heightElem bg:orange:5 radius:full d-flex align-items-center justify-content-center iconSize:16"
                          style={
                            {
                              "--widthElem": "2rem",
                              "--heightElem": "2rem",
                            } as React.CSSProperties
                          }
                        >
                          <img src={iconHour} alt="" />
                        </div>
                      </div>
                    )}

                    {product?.label?.includes("Turuncu") && (
                      <div className="col">
                        <div
                          className="widthElem heightElem bg:orange radius:full d-flex align-items-center justify-content-center iconSize:16"
                          style={
                            {
                              "--widthElem": "2rem",
                              "--heightElem": "2rem",
                            } as React.CSSProperties
                          }
                        >
                          <img src={iconStarFill} alt="" />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="ms-auto">
                  <HeartButtonSearch product={product} />
                </div>
              </div>
              <Link
                className="productHover"
                aria-label="link card"
                to={`/urun/${product.id}`}
              >
                <div className="productListCard__box-img">
                  <img
                    src={
                      product.thumbnail
                        ? `data:${product.thumbnail.contentType};base64,${product.thumbnail.fileContents}`
                        : "/brokenImage.svg"
                    }
                    alt={
                      product.thumbnail?.contentType
                        ? product.thumbnail?.contentType
                        : "alttext"
                    }
                    loading="lazy"
                    onError={(e) => {
                      e.currentTarget.src = "/brokenImage.svg";
                    }}
                  />
                </div>
                <p className="c:dark ff:bold fs:16 text-center mt-3 mb-2 lh:1">
                  {product.displayBrandName}
                </p>
                <p className="c:dark ff:regular fs:14 text-center">
                  {product.displayProductForm}
                </p>
              </Link>
            </div>
            {/* {!data.plans.includes(activePlan!) && (
              <div className="mt-auto">
                <button
                //   onClick={handleNewPlanView}
                  aria-label="plan detayı"
                  className="buttonStyle buttonStyle--icon buttonStyle--lightOrange buttonStyle--submit c:orange ff:bold fs:15 bg:orange:2 d-flex align-items-center justify-content-center w-100 text-center radius:12"
                  style={{ "--form-height": "3rem" } as React.CSSProperties}
                >
                  Plan Detayı
                </button>
              </div>
            )} */}
            <div className="mt-auto">
              <Link
                state={{ productId: product.id }}
                to="/turuncu-donemsel-kampanyalar"
                aria-label="plan detayı"
                className="buttonStyle buttonStyle--icon buttonStyle--lightOrange buttonStyle--submit c:orange ff:bold fs:15 bg:orange:2 d-flex align-items-center justify-content-center w-100 text-center radius:12"
                style={{ "--form-height": "3rem" } as React.CSSProperties}
              >
                Plana Git
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchOrangeProductCard;
