import { endpoints } from "constants/endpoints";
import { ShopingCartTypes } from "../types/shoppingCartTypes";
import api from "../utility/request";

export const getShoppingCart = async (): Promise<any> => {
  const response = (await api.get(`${endpoints.shoppingCart}`)).data;
  return response;
};

export const setShoppingCart = async (data: any): Promise<any> => {
  const response = (await api.patch(`${endpoints.shoppingCart}`, data)).data;
  return response;
};

export const calculateShoppingCart = async (data: any): Promise<any> => {
  const response = (await api.post(`${endpoints.shoppingCart}/calculate`, data))
    .data;
  return response;
};

export const completeShoppingCart = async (): Promise<any> => {
  const response = (await api.post(`${endpoints.shoppingCart}/complete/false`))
    .data;
  return response;
};
export const completeShoppingCartStepTwo = async (): Promise<any> => {
  const response = (await api.post(`${endpoints.shoppingCart}/complete/true`))
    .data;
  return response;
};

export const getShoppingCartById = async (data: {
  id: string;
  fields: string[];
}): Promise<any> => {
  const { id, fields } = data;
  const response = (
    await api.post(`${endpoints.shoppingCart}/${id}?fields=${fields.join(",")}`)
  ).data;
  return response;
};

export const editShoppingCart = async (data: {
  id: string;
  shoppingCart: ShopingCartTypes;
}): Promise<any> => {
  const { id, shoppingCart } = data;
  const response = (
    await api.patch(`${endpoints.shoppingCart}/${id}`, shoppingCart)
  ).data;
  return response;
};

export const deleteShoppingCart = async (data: any): Promise<any> => {
  const response = (await api.patch(`${endpoints.shoppingCart}`, data)).data;
  return response;
};

export const clearShoppingCart = async (): Promise<any> => {
  const response = (await api.delete(`${endpoints.shoppingCart}`)).data;
  return response;
};
