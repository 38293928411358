import { createContext } from "react";
import { IQuickOrder } from "types/userTypes";

interface IQuickOrderContext {
  loading?: boolean;
  quickOrders: IQuickOrder[] | [];
  handleRepeatQuickOrder: (orderId: string) => void;
  reFetchQuickOrders: () => void;
  handleAddQuickOrder: (quickOrderData: any) => void;
  handleRemoveQuickOrder: (quickOrderId: string) => void;
  success: boolean;
  error: string;
}

export const QuickOrderContext = createContext<IQuickOrderContext>({
  loading: false,
  quickOrders: [],
  reFetchQuickOrders: () => {},
  handleAddQuickOrder: () => {},
  handleRemoveQuickOrder: () => {},
  handleRepeatQuickOrder: () => {},
  success: false,
  error: ""
});
