import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import bgCover from "@images/bg-cover.jpg";
// import iconDiamondSm from "@images/icon-diamond-sm.svg";
import iconAccountBag from "@images/icon-account-bag.svg";
import iconAccountTag from "@images/icon-account-tag.svg";
import iconAccountComment from "@images/icon-account-comment.svg";
import iconAccountContact from "@images/icon-account-contact.svg";
import iconAccountHeart from "@images/icon-account-heart.svg";
import iconSignOut from "@images/icon-signout.svg";
import { useUser } from "hooks/useUser";
import { navigationData } from "data/NavigationData";

const Account = () => {
  const { logout } = useAuth();
  const { user } = useUser();

  return (
    <>
      <div className="row align-items-end g-3">
        <div className="col-12 col-lg-4">
          <div
            className="bg:light:14 radius:12 shadow:4 borderStyle p-4"
            style={
              {
                "--gsk-border-width": "0.5px",
                "--gsk-border-color": "#F0DCB4",
              } as React.CSSProperties
            }
          >
            <section className="accountCard__info">
              <img src={bgCover} alt="" />
              <div className="my-3">
                <p className="c:brown ff:bold fs:20 lt:2">
                  TuruncuHat+ Fırsatlarını Keşfedin.
                </p>
              </div>
              <div className="d-flex justify-content-end">
                <Link
                  aria-label="Detaylı Bilgi"
                  to={navigationData.siparislerim}
                  className="buttonStyle buttonStyle--hoverBorder buttonStyle--paddingElem c:white ff:bold fs:15 bg:brown d-inline-block text-center radius:12"
                  style={
                    {
                      "--btnPaddingElem": "0.5rem 1.5rem",
                      "--btn-border": "#C89B63",
                      "--btn-hover": "#fff",
                      "--btn-hover-color": "#C89B63",
                    } as React.CSSProperties
                  }
                >
                  Detaylı Bilgi
                </Link>
              </div>
            </section>
          </div>
        </div>
        <div className="col-12 col-lg-8">
          <div className="row g-3">
            <div className="col-12">
              <div className="bg:white radius:12 border:1 shadow:4 p-4">
                <div className="row row-cols-1 gx-0 gy-4 gy-lg-0">
                  <div className="col">
                    {/* <div className="d-flex justify-content-end">
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0 iconSize:16">
                          <img src={iconDiamondSm} alt="" />
                        </div>
                        <div className="flex-grow-1 ms-2">
                          <p className="c:dark fs:16 ff:bold lt:2 lh:1">
                            Kıdemli Üye
                          </p>
                        </div>
                      </div>
                    </div> */}
                    <div
                      className="w-100 heightElem"
                      style={{ "--heightElem": "1rem" } as React.CSSProperties}
                    ></div>
                  </div>
                  <div className="col">
                    <div className="accountCard__profile">
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          <div className="accountCard__profile-badge radius:25 bg:gradient:orange d-flex align-items-center justify-content-center">
                            <p className="lh:1 c:white ff:bold text-uppercase fs:22">
                              {user?.firstName && user.firstName[0]}
                              {user?.lastName && user.lastName[0]}
                            </p>
                          </div>
                        </div>
                        <div className="flex-grow-1 ms-3 ms-sm-4">
                          <p className="c:dark fs:16 ff:bold lt:2 text-capitalize">
                            Merhaba,
                            <span className="d-block">
                              {user?.firstName && user?.firstName}{" "}
                              {user?.lastName && user?.lastName}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="d-flex justify-content-end">
                      <Link
                        to={navigationData.bilgilerim}
                        aria-label="Bilgilerimi Güncelle"
                        className="buttonStyle buttonStyle--light buttonStyle--paddingElem c:dark ff:bold fs:16 bg:white shadow:4 d-inline-block text-center radius:16"
                        style={
                          {
                            "--btnPaddingElem": "0.7rem 2rem",
                          } as React.CSSProperties
                        }
                      >
                        Bilgilerimi Güncelle
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <Link
                className="accountCard__link"
                to={navigationData.siparislerim}
              >
                <div className="bg:white radius:12 border:1 shadow:4 p-4">
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <div className="accountCard__icon bg:orange:2 radius:full d-flex align-items-center justify-content-center">
                        <img src={iconAccountBag} alt="" />
                      </div>
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <p className="c:dark lt:4 ff:bold fs:13 text-uppercase">
                        siparişlerim
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col">
              <Link
                className="accountCard__link"
                to={navigationData.favorilerim}
              >
                <div className="bg:white radius:12 border:1 shadow:4 p-4">
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <div className="accountCard__icon bg:orange:2 radius:full d-flex align-items-center justify-content-center">
                        <img src={iconAccountHeart} alt="" />
                      </div>
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <p className="c:dark lt:4 ff:bold fs:13 text-uppercase">
                        favorilerim
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3">
        <div className="row row-cols-1 row-cols-lg-3 g-3">
          <div className="col">
            <Link
              className="accountCard__link"
              to={navigationData.kampanyalarim}
            >
              <div className="bg:white radius:12 border:1 shadow:4 p-4">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <div className="accountCard__icon bg:orange:2 radius:full d-flex align-items-center justify-content-center">
                      <img src={iconAccountTag} alt="" />
                    </div>
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <p className="c:dark lt:4 ff:bold fs:13 text-uppercase">
                      kampanyalarım
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col">
            <Link
              className="accountCard__link"
              to={navigationData.soruVeTaleplerim}
            >
              <div className="bg:white radius:12 border:1 shadow:4 p-4">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <div className="accountCard__icon bg:orange:2 radius:full d-flex align-items-center justify-content-center">
                      <img src={iconAccountComment} alt="" />
                    </div>
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <p className="c:dark lt:4 ff:bold fs:13 text-uppercase">
                      soru ve taleplerim
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col">
            <Link
              className="accountCard__link"
              to={navigationData.iletisimTercihleri}
            >
              <div className="bg:white radius:12 border:1 shadow:4 p-4">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <div className="accountCard__icon bg:orange:2 radius:full d-flex align-items-center justify-content-center">
                      <img src={iconAccountContact} alt="" />
                    </div>
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <p className="c:dark lt:4 ff:bold fs:13 text-uppercase">
                      iletişim tercihleri
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center justify-content-lg-end mt-4">
        <button
          onClick={logout}
          aria-label="çıkış yap"
          className="d-block hover:opacity:6"
          type="button"
        >
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <img src={iconSignOut} alt="" />
            </div>
            <div className="flex-grow-1 ms-2">
              <p className="c:dark ff:bold lt:2 fs:16">Çıkış Yap</p>
            </div>
          </div>
        </button>
      </div>
    </>
  );
};

export default Account;
