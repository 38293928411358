import { NavLink } from "react-router-dom";

const Navigation = () => {
  const navData = [
    { uri: "/hesabim/siparislerim", icon: null, title: "Devam Eden Siparişlerim" },
    { uri: "/hesabim/on-siparislerim", icon: null, title: "Ön Siparişlerim" },
    {
      uri: "/hesabim/gecmis-siparislerim",
      icon: null,
      title: "Geçmiş Siparişlerim",
    },
    {
      uri: "/hesabim/hizli-siparislerim",
      icon: null,
      title: "Hızlı Siparişlerim",
    },
  ];
  return (
    <section className="singleMenu flex-nowrap">
      <nav>
        <ul className="fs:16 c:dark:2 ff:bold">
          {navData.map((item) => (
            <li key={item.uri}>
              <NavLink
                to={item.uri}
                end={true}
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "singleMenu__active" : ""
                }
              >
                {item.icon ? <img src={item.icon} alt="" /> : item.title}
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
    </section>
  );
};

export default Navigation;
