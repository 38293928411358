import { useContext, useEffect, useRef, useState } from "react";
import useOnClickOutside from "hooks/useOnClickOutside";
import HeaderBottom from "./HeaderBottom";
import HeaderMid from "./HeaderMid";
import HeaderTop from "./HeaderTop";
import MobileMenu from "./MobileMenu";
import Notification from "./Notification";
import { BodyContext } from "contexts/BodyContext/BodyContext";

export interface HeaderPropsTypes {
  handleBody?: (text: string) => void;
  handleMobileMenu?: () => void;
  handleNotificationDrawer?: () => void;
  mobileMenuIsOpen?: boolean;
  customClass?: string;
  mobileMenuRef?: any;
  drawerRef?: any;
  notificationDrawerIsOpen?: boolean;
  setNotificationDrawerIsOpen?: any;
  menuData?: any;
}
  /* eslint-disable react-hooks/exhaustive-deps */
const Header = () => {
  const { handleBody, categories } = useContext(BodyContext);
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
  const [notificationDrawerIsOpen, setNotificationDrawerIsOpen] =
    useState(false);
  const [customClass, setCustomClass] = useState("d-none");
  const [customNotificationClass, setCustomNotificationClass] =
    useState("d-none");

  const handleMobileMenu = () => {
    setMobileMenuIsOpen((prev) => !prev);
  };

  useEffect(() => {
    if (mobileMenuIsOpen) {
      setCustomClass("");
      handleBody!("overflowHidden");
    } else {
      handleBody!("");
      setTimeout(() => {
        setCustomClass("d-none");
      }, 700);
    }
  }, [mobileMenuIsOpen]);

  useEffect(() => {
    if (notificationDrawerIsOpen) {
      setCustomNotificationClass("");
      setTimeout(() => {
        handleBody!("overflowHidden");
      }, 500);
    } else {
      setTimeout(() => {
        handleBody!("");
      }, 500);
      setTimeout(() => {
        setCustomNotificationClass("d-none");
      }, 600);
    }
  }, [notificationDrawerIsOpen]);

  const mobileMenuRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(
    mobileMenuRef,
    () => mobileMenuIsOpen && setMobileMenuIsOpen(false)
  );

  const drawerRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(
    drawerRef,
    () => notificationDrawerIsOpen && setNotificationDrawerIsOpen(false)
  );

  const handleNotificationDrawer = () => {
    setNotificationDrawerIsOpen((prev) => !prev);
  };

  const menuData: any = [
    {
      id: 1,
      title: "Ürünler",
      url: "/urunler",
      child:
        categories &&
        categories.map((item) => {
          const data = {
            id: item.id,
            title: item.name,
            url: `/urunler/liste?anakategori=${item.code}`,
            children: item.children
              .sort(function (a, b) {
                if (a.name < b.name) {
                  return -1;
                }
                if (a.name > b.name) {
                  return 1;
                }
                return 0;
              })
              .map((child) => {
                const data = {
                  id: child.id,
                  title: child.name,
                  url: `/urunler/liste?anakategori=${item.code}&altkategori=${child.code}`,
                };
                return data;
              }),
          };
          return data;
        }),
    },
    {
      id: 2,
      title: "Turuncu Dönemsel Kampanyalar",
      url: "/turuncu-donemsel-kampanyalar",
      child: [],
      // child: [
      //   {
      //     id: 22,
      //     title: "Dönemsel Kampanyalar",
      //     url: "/turuncu-donemsel-kampanyalar",
      //   },
      //   {
      //     id: 23,
      //     title: "Turuncu Ürünler",
      //     url: "/turuncu-urunler",
      //   },
      // ],
    },
    {
      id: 3,
      title: "Anlık Kampanyalar",
      url: "/anlik-kampanyalar",
      child: [],
    },
    {
      id: 4,
      title: "Turuncu Blog",
      url: "/blog",
      child: [],
    },
    {
      id: 5,
      title: "Depolar",
      url: "/depolar",
      child: [],
    },
    // {
    //   id: 6,
    //   title: "Quizler",
    //   url: "/quizler",
    //   child: [],
    // },
  ];

  return (
    <header className="header" style={{ zIndex: 1031, position: "relative" }}>
      <HeaderTop />
      <HeaderMid
        notificationDrawerIsOpen={notificationDrawerIsOpen}
        setNotificationDrawerIsOpen={setNotificationDrawerIsOpen}
      />
      <HeaderBottom
        handleBody={handleBody}
        handleMobileMenu={handleMobileMenu}
        mobileMenuRef={mobileMenuRef}
        menuData={menuData}
      />
      <Notification
        handleBody={handleBody}
        notificationDrawerIsOpen={notificationDrawerIsOpen}
        customClass={customNotificationClass}
        handleNotificationDrawer={handleNotificationDrawer}
        drawerRef={drawerRef}
        setNotificationDrawerIsOpen={setNotificationDrawerIsOpen}
      />
      <MobileMenu
        handleBody={handleBody}
        mobileMenuIsOpen={mobileMenuIsOpen}
        customClass={customClass}
        handleMobileMenu={handleMobileMenu}
        mobileMenuRef={mobileMenuRef}
        menuData={menuData}
      />
    </header>
  );
};

export default Header;
