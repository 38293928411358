import { Outlet } from "react-router-dom";
import Footer from "@components/Footer";
import Header from "@components/Header";
import GeneralNotification from "@components/General/GeneralNotification";
import ScrollToTop from "utility/scrollToTop";
import CartProvider from "contexts/CartContext/CartProvider";
import BodyProvider from "contexts/BodyContext/BodyProvider";
import HelmetComponent from "@components/General/Helmet";
import PopupWrapper from "@components/General/PopupWrapper";
import QuickOrderProvider from "contexts/QuickOrderContext/QuickOrderProvider";
import NotificationProvider from "contexts/NotificationContext/NotificationProvider";
import PublicProvider from "contexts/PublicContext/PublicProvider";

const MainLayout = () => {
  return (
    <PublicProvider>
      <BodyProvider>
        <CartProvider>
          <QuickOrderProvider>
            <NotificationProvider>
              <>
                <HelmetComponent />
                <Header />
                <main className="landingMain">
                  {/* <GeneralLoading /> */}
                  <Outlet />
                  <PopupWrapper />
                  <GeneralNotification />
                  <ScrollToTop />
                </main>
                <Footer />
              </>
            </NotificationProvider>
          </QuickOrderProvider>
        </CartProvider>
      </BodyProvider>
    </PublicProvider>
  );
};

export default MainLayout;
