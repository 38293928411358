import { IPostItem } from "../BlogDetail";

const BlogImage = ({ item }: { item: IPostItem }) => {
  return (
    <section className="contentImg my-4 my-sm-5">
      <img
        src={
          item.image
            ? `data:${item.image.contentType};base64,${item.image.fileContents}`
            : "/brokenImage.svg"
        }
        alt={item.image?.contentType ? item.image?.contentType : "alttext"}
        loading="lazy"
        onError={(e) => {
          e.currentTarget.src = "/brokenImage.svg";
        }}
      />
    </section>
  );
};

export default BlogImage;
