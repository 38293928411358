import { Link } from "react-router-dom";
import { toElementDimentionStyle, toElementPaddingStyle } from "utility/CSSProperties";

function OrderItem({ item } : any) {
  return (<div className="row row-cols-1 row-cols-lg-2 gx-0 gx-lg-4 gy-4 gy-lg-0 align-items-center">
  <div className="col">
    <div className="d-flex align-items-center">
      <div className="flex-shrink-0">
        <div className="layoutCollapse__content-product">
          <img
            src={
              item.image.fileContents
                ? `data:${item.image.contentType};base64,${item.image.fileContents}`
                : "/brokenImage.svg"
            }
            alt=""
          />
        </div>
      </div>
      <div className="flex-grow-1 ms-3">
        <p className="c:dark ff:bold fs:14 mb-1">
          {item.displayBrandName}
        </p>
        <p className="c:dark ff:light fs:14 mb-1">
          {item.displayProductForm}
        </p>
        <p className="c:dark ff:bold fs:16">
          &#8378;&nbsp;{item.price.value}
        </p>
      </div>
    </div>
  </div>
  <div className="col">
    <div className="row row-cols-1 row-cols-sm-auto gx-0 gy-4 gy-sm-0 align-items-center justify-content-between">
      <div className="col">
        <div className="row row-cols-auto justify-content-center">
          <div className="col">
            <div className="d-flex align-items-center justify-content-center">
              <p className="c:dark ff:regular fs:16 me-2">
                Adet
              </p>
              <div
                className="widthElem heightElem bg:orange:2 radius:8 d-flex align-items-center justify-content-center"
                style={toElementDimentionStyle("1.875")}
              >
                <p className="c:orange fs:16 ff:bold lh:1">
                  {item.count}
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="d-flex align-items-center justify-content-center">
              <p className="c:dark ff:regular fs:16 me-2">MF</p>
              <div
                className="widthElem heightElem bg:orange:2 radius:8 d-flex align-items-center justify-content-center"
                style={toElementDimentionStyle("1.875")}
              >
                <p className="c:orange fs:16 ff:bold lh:1">
                  {item.mf}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col">
        <div className="d-flex justify-content-center">
          <Link
            to={`/urun/${item.id}`}
            aria-label="ürüne git"
            className="buttonStyle buttonStyle--light buttonStyle--paddingElem c:dark ff:bold fs:16 bg:white shadow:4 d-inline-block text-center radius:12"
            style={toElementPaddingStyle("0.5", "2.2")}
          >
            Ürüne Git
          </Link>
        </div>
      </div>
    </div>
  </div>
</div>);
}

export default OrderItem;
