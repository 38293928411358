import React from "react";
import iconClose from "@images/icon-close.svg";

interface IProps {
  handleActivePopup: (popup: string) => void;
}

const PopupNoWareHouse = ({ handleActivePopup }: IProps) => {
  return (
    <section className="popupWrapper animate__animated animate__fadeIn animate__faster">
      <div className="popupWrapper__content bg:white radius:20">
        <div className="popupWrapper__content-head d-flex align-items-center justify-content-between px-4 radius:20 bg:white">
          <div>
            {/* <!-- <h1 class="ff:bold fs:18 c:dark lh:2">Plan Detayı</h1> --> */}
          </div>
          <div className="ps-4">
            <button
              onClick={() => handleActivePopup("null")}
              aria-label="close popup"
              className="popupWrapper__close buttonStyle buttonStyle--light buttonStyle--paddingElem radius:full bg:white shadow:4"
              type="button"
              style={{ "--btnPaddingElem": "0" } as React.CSSProperties}
            >
              <img src={iconClose} alt="" />
            </button>
          </div>
        </div>
        <div className="px-4 pb-4">
          <div className="row row-cols-1 text-center gx-0 gy-3">
            {/* <div className="col">
            <div className="iconSize:32">
              <img className="mx-auto" src={iconSuccessTick} alt="" />
              </div>
            </div> */}
            <div className="col">
              <p className="ff:light c:dark fs:16 pb-4">
                Plan ekleyebilmek için öncelikle depo seçimi yapmalısınız.
              </p>
            </div>
            <div className="col">
              <button
                type="button"
                onClick={()=> handleActivePopup("depo")}
                aria-label="Aktif Kampanyalar"
                className="buttonStyle buttonStyle--icon buttonStyle--submit c:white ff:bold fs:16 bg:orange d-flex align-items-center justify-content-center w-100 radius:12"
                style={
                  {
                    "--form-height": "3rem",
                  } as React.CSSProperties
                }
              >
               Depo Seçimi Yapın
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PopupNoWareHouse;
