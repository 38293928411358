import { endpoints } from "constants/endpoints";
import api from "utility/request";

export const toggleLikePost = async (postId: string): Promise<any> => {
  const response = (await api.post(`${endpoints.account}/like/${postId}`)).data;
  return response;
};

export const toggleDislikePost = async (postId: string): Promise<any> => {
  const response = (await api.post(`${endpoints.account}/dislike/${postId}`))
    .data;
  return response;
};

export const toggleBookMarkPost = async (postId: string): Promise<any> => {
  const response = (await api.post(`${endpoints.account}/bookmarks/${postId}`))
    .data;
  return response;
};
export const getAllBookmarks = async (): Promise<any> => {
  const response = (await api.get(`${endpoints.account}/bookmarks`)).data;
  return response;
};
export const getPreferences = async (): Promise<any> => {
  const response = (await api.get(`${endpoints.account}/preferences`)).data;
  return response;
};

export const getSearchSuggests = async (): Promise<any> => {
  const response = (await api.get(`/home/search/suggest`)).data;
  return response;
};
export const deleteSearchKeyword = async (keyword: string): Promise<any> => {
  const response = (await api.delete(`/home/search/${keyword}`)).data;
  return response;
};

export const togglePreferences = async (id: string): Promise<any> => {
  const response = (await api.put(`${endpoints.account}/preferences/${id}`))
    .data;
  return response;
};

export const deleteBookmark = async (postId: string): Promise<any> => {
  const response = (
    await api.delete(`${endpoints.account}/bookmark/${postId}/reading/list`)
  ).data;
  return response;
};

export const rateOrder = async (data: any): Promise<any> => {
  const { id, rating, description } = data;
  const response = (
    await api.post(`${endpoints.account}/my/order/${id}/rating`, {
      rating: rating,
      description: description,
    })
  ).data;
  return response;
};
