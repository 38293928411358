import React, { useEffect, useState } from "react";
import { NotificationContext } from "./NotificationContext";
import { getNotifications } from "services/notification.service";
import { INotification } from "types/notificationTypes";
import { IPreference } from "views/MyAccount/ContactPreferences";
import useApi from "hooks/useApi";
import { getPreferences } from "services/account.service";

interface IFetchData {
  loading: boolean;
  notifications: any[];
  unreadedNotifications: number;
}

const NotificationProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const [fetchData, setFetchData] = useState<IFetchData>({
    loading: true,
    notifications: [],
    unreadedNotifications: 0,
  });
  const { handleApi, data, success } = useApi();
  const [preferences, setPreferences] = useState<IPreference[]>();
  const [toggleState, setToggleState] = useState(0);

  const [reFetchCount, setReFetchCount] = useState(0);
  const loadData = async () => {
    setFetchData({ ...fetchData, loading: true });
    try {
      const notifications: INotification[] = await getNotifications();
      const unreadedNotifications = notifications?.filter(
        (notif) => !notif.isRead
      )?.length;
      setFetchData({ loading: false, notifications, unreadedNotifications });
    } catch (error) {
      console.log(error);
      setFetchData({ ...fetchData, loading: false });
    }
  };

  const reFetchNotifications = async () => {
    setFetchData({ ...fetchData, loading: true });
    try {
      const data: INotification[] = await getNotifications();
      const unreadedNotifications = data?.filter(
        (notif) => !notif.isRead
      )?.length;
      setFetchData({
        notifications: data,
        loading: false,
        unreadedNotifications,
      });
    } catch (error) {
      setFetchData({ ...fetchData, loading: false });
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    loadData();
  }, [reFetchCount]);

  useEffect(() => {
    handleApi(getPreferences, {});
    return () => {};
  }, [toggleState]);

  useEffect(() => {
    if (success) {
      setPreferences(data);
    }
  }, [success, data]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setReFetchCount((prev) => prev + 1);
    }, 300000);
    return () => clearTimeout(timer);
  }, [reFetchCount]);

  const { loading, notifications, unreadedNotifications } = fetchData;

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        loading,
        reFetchNotifications,
        unreadedNotifications,
        preferences,
        toggleState,
        setToggleState
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
