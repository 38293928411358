import { useEffect, useReducer, useRef, useState, useContext } from "react";
import { Link } from "react-router-dom";
import MyAccountTabNav from "./MyAccountTabNav";
import SmsPopup from "@components/Hesabim/MyAccount/SmsPopup";
import { useUser } from "hooks/useUser";
import { updateUser } from "services/user.service";
import PersonalInterests from "@components/Hesabim/MyAccount/PersonalInterests";
import useApi from "hooks/useApi";
import EmailPopup from "@components/Hesabim/MyAccount/EmailPopup";
import { AuthContextSite } from "contexts/AuthContext/AuthContext";
import PersonalInfoSummary from "@components/Hesabim/MyAccount/PersonalInfoSummary";
import PersonalInfoEditable from "@components/Hesabim/MyAccount/PersonalInfoEditable";
import { PublicContext } from "contexts/PublicContext/PublicContext";

export interface IState {
  name: string | undefined;
  surname: string | undefined;
  phone: string | undefined;
  mobile: string | undefined;
  gln: string | undefined;
  gender: string | undefined;
  company: string | undefined;
  address: string | undefined;
  city: string | undefined;
}
/* eslint-disable react-hooks/exhaustive-deps */
const PersonalInfo = () => {
  const [loading, setLoading] = useState(false);
  const [updateProfile, setUpdateProfile] = useState(false);
  const [confirmEmail, setConfirmEmail] = useState(false);

  const [isMobilePhoneChanged, setIsMobilePhoneChanged] = useState(false);
  const [mobileApproved, setMobileApproved] = useState(false);
  const [emailConfirmed, setEmailConfirmed] = useState(false);

  const { addUser } = useUser();
  const { user } = useContext(AuthContextSite);
  const { setSiteTitle } = useContext(PublicContext);

  useEffect(() => {
    setSiteTitle("Kişisel Bilgiler");
  }, []);

  const initialState: IState = {
    name: user?.firstName ?? "",
    surname: user?.lastName ?? "",
    phone: user?.phone ?? "",
    mobile: user?.mobile ?? "",
    gln: user?.gln ?? "",
    gender: user?.gender,
    company: user?.company ?? "",
    address: user?.address ?? "",
    city: user?.city ?? "",
  };

  const [state, dispatch] = useReducer(
    (state: IState, updates: any) => ({ ...state, ...updates }),
    initialState
  );

  const { handleApi, setSuccess, success, data } = useApi();

  useEffect(() => {
    if (updateProfile) {
      dispatch(user);
    }
  }, [updateProfile]);

  const handleUpdateAfterPhoneConfirmed = async () => {
    await handleApi(updateUser, {
      gln: state.gln,
      phone: state.phone,
      mobile: state.mobile,
      gender: state.gender,
      company: state.company,
      address: state.address,
      city: state.city,
    });
  };

  useEffect(() => {
    if (success) {
      addUser(data);
    }
  }, [success]);

  const popupRef = useRef<HTMLDivElement>(null);

  const handleOutside = () => {
    setIsMobilePhoneChanged(false);
    setSuccess(false);
    setConfirmEmail(false);
  };

  const handleConfirmPhone = () => {
    dispatch({ ...initialState, mobile: user?.mobile });
    setIsMobilePhoneChanged(true);
  };

  return (
    <>
      <section className="bg:white shadow:9 pt-4">
        <div className="container">
          <section className="breadcrumb pb-4">
            <ul className="c:dark fs:14 lt:3">
              <li>
                <Link to="/anasayfa">Anasayfa</Link>
              </li>
              <li>
                <Link to="/hesabim">Hesabım</Link>
              </li>
              <li>
                <Link to="/hesabim/bilgilerim">Kişisel Bilgiler</Link>
              </li>
            </ul>
          </section>
          <MyAccountTabNav />
        </div>
      </section>
      <section className="mt-4 mt-sm-5 mb-5">
        <div className="container">
          <div className="row g-0 justify-content-center">
            <div className="col-12 col-lg-9 col-xl-8 col-xxl-7">
              <h1 className="c:dark ff:bold fs:24 mb-4 text-center text-md-start">
                Bilgilerimi Düzenle
              </h1>
              <div className="row row-cols-1 gx-0 gy-3">
                <div className="col">
                  <section className="bg:white radius:12 shadow:4 p-4 p-md-5">
                    <h1 className="c:dark ff:bold fs:20 text-center text-md-start mb-4 mb-md-5 lh:1">
                      Kişisel Bilgiler
                    </h1>

                    {/* <!-- ilk görünüm --> */}
                    {!updateProfile && user ? (
                      <PersonalInfoSummary
                        user={user}
                        handleConfirmPhone={handleConfirmPhone}
                        setConfirmEmail={setConfirmEmail}
                        setUpdateProfile={setUpdateProfile}
                      />
                    ) : (
                      user && (
                        <PersonalInfoEditable
                          mobileApproved={mobileApproved}
                          setIsMobilePhoneChanged={setIsMobilePhoneChanged}
                          setMobileApproved={setMobileApproved}
                          setUpdateProfile={setUpdateProfile}
                          user={user}
                          success={success}
                          dispatch={dispatch}
                          state={state}
                          setSuccessFirst={setSuccess}
                        />
                      )
                    )}
                    {/* <!-- güncelle butonuna tıkladıktan sonraki görünüm --> */}
                  </section>
                </div>
                {user && (
                  <PersonalInterests
                    user={user}
                    loading={loading}
                    setUser={addUser}
                    setLoading={setLoading}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {isMobilePhoneChanged && !mobileApproved && (
        <section
          onClick={handleOutside}
          className="popupWrapper animate__animated animate__fadeIn animate__faster"
        >
          <div
            onClick={(e) => e.stopPropagation()}
            ref={popupRef}
            className="popupWrapper__content bg:white radius:20"
          >
            <SmsPopup
              state={state}
              setMobileApproved={setMobileApproved}
              handleUpdateAfterPhoneConfirmed={handleUpdateAfterPhoneConfirmed}
            />
          </div>
        </section>
      )}
      {confirmEmail && !emailConfirmed && (
        <section
          onClick={handleOutside}
          className="popupWrapper animate__animated animate__fadeIn animate__faster"
        >
          <div
            onClick={(e) => e.stopPropagation()}
            ref={popupRef}
            className="popupWrapper__content bg:white radius:20"
          >
            <div className="p-4 p-sm-5">
              <div className="formElement">
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="formElement__block">
                    <EmailPopup
                      user={user}
                      setEmailConfirmed={setEmailConfirmed}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default PersonalInfo;
