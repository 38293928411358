import moment from "moment";
import "moment/locale/tr";

export const convertDate = (dateStr: string) => {
  const date = moment(dateStr).format("DD.MM.YYYY");
  return date;
};

export const convertDatetime = (dateStr: Date) => {
  const date = moment(dateStr).format("Do MMMM dddd, HH:mm");
  return date;
};

export const convertDatetimeStrMonth = (dateStr: Date) => {
  const date = moment(dateStr).format("Do MMMM dddd");
  return date;
};

export const untilHours = (dateStr: Date) => {
  const date = moment(dateStr).fromNow();
  return date;
};

export const diffDate = (dateStr: Date) => {
  const date = moment().diff(dateStr, "seconds");
  return date;
};
