import Skeleton from "react-loading-skeleton";

const ProductDetailsSkeleton = () => {
  return (
    <div className="container my-4 my-md-5">
      <Skeleton width={200} height={19} />
      <div
        className="row gx-0 gx-xl-4 gy-4 gy-xl-0"
        style={{ marginTop: "2.75rem" }}
      >
        <div className="col-12 col-xl-8">
          <div className="productDetails">
            <div className="row gx-0 gx-md-4 gy-4 gy-md-0">
              <div className="col-12 col-md-6">
                <div className="productDetails__img p-4">
                  <div className="productDetails__img-content">
                    <Skeleton height={366} style={{ objectFit: "contain" }} />
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1 me-3">
                    <h1 className="c:dark ff:bold fs:22">
                      <Skeleton width={200} height={20} />
                    </h1>
                    <h1 className="c:dark ff:light fs:22">
                      <Skeleton width={200} height={20} />
                    </h1>
                  </div>
                  <div className="flex-shrink-0">
                    <Skeleton width={20} height={20} borderRadius={50} />
                  </div>
                </div>
                <div className="mt-2">
                  <div className="row row-cols-auto gx-2 gy-0"></div>
                </div>
                <div className="mt-4 mt-md-5 mb-3">
                  <p className="c:dark fs:16">
                    <strong>Ürün Detayı</strong>
                  </p>
                </div>
                <div className="responsiveTable responsiveTable--noScroll">
                  <table>
                    <tbody>
                      <tr>
                        <td>Geri Ödeme Kodu</td>
                        <td>
                          <Skeleton width={100} height={20} />
                        </td>
                      </tr>
                      <tr>
                        <td>Original / Jenerik</td>
                        <td>
                          <Skeleton width={100} height={20} />
                        </td>
                      </tr>
                      <tr>
                        <td>Reçete Durumu</td>
                        <td>
                          <Skeleton width={100} height={20} />
                        </td>
                      </tr>
                      <tr>
                        <td>Barkodu</td>
                        <td>
                          <Skeleton width={100} height={20} />
                        </td>
                      </tr>
                      <tr>
                        <td>Etkin Madde</td>
                        <td>
                          <Skeleton width={100} height={20} />
                        </td>
                      </tr>
                      <tr>
                        <td>ATC Kodu</td>
                        <td>
                          <Skeleton width={100} height={20} />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4 mb-4">
            <p className="ff:bold fs:18 c:dark">Ürün hakkında</p>
          </div>
          <section className="contentArea ff:light fs:16 c:dark">
            <article>
              <Skeleton height={400} />
            </article>
          </section>
        </div>
        <div className="col-12 col-xl-4">
          <Skeleton width={416} height={431} borderRadius={"0.75rem"} />
        </div>
      </div>
    </div>
  );
};

export default ProductDetailsSkeleton;
