import React from "react";
import { NavLink } from "react-router-dom";
import { navigationData } from "data/NavigationData";

const MyAccountTabNav = () => {
  return (
    <section className="singleMenu flex-nowrap">
      <nav>
        <ul className="fs:16 c:dark:2 ff:bold">
          <li>
            <NavLink
              to={navigationData.bilgilerim}
              end={true}
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "singleMenu__active" : ""
              }
            >
              Kişisel Bilgiler
            </NavLink>
          </li>
          <li>
            <NavLink
              to={navigationData.password}
              end={true}
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "singleMenu__active" : ""
              }
            >
              Şifre Değişikliği
            </NavLink>
          </li>
          <li>
            <NavLink
              to={navigationData.iletisimTercihleri}
              end={true}
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "singleMenu__active" : ""
              }
            >
              İletişim Tercihleri
            </NavLink>
          </li>
        </ul>
      </nav>
    </section>
  );
};

export default MyAccountTabNav;
