import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, EffectFade, Autoplay } from "swiper";
import { Link } from "react-router-dom";
import { ISlider } from "views/Blog";
import Skeleton from "react-loading-skeleton";

const BlogSlider = ({
  slider,
  loading,
}: {
  slider: ISlider[];
  loading: boolean;
}) => {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const [swiperSliderFirst, setSwiperSliderFirst] = useState<any>(null);

  const paginationRef = useRef(null);

  return (
    <section>
      <div className="sliderType-4 sliderType-4--1">
        <div className="swiper sliderType-4__item sliderType-4__item--1">
          {loading ? (
            <Skeleton height={598.81} count={1} borderRadius={"1rem"} />
          ) : slider ? (
            <Swiper
              modules={[Pagination, Navigation, EffectFade, Autoplay]}
              pagination={{
                el: paginationRef.current,
                clickable: true,
              }}
              className="swiper-wrapper"
              onSwiper={(swiper) => setSwiperSliderFirst(swiper)}
              spaceBetween={10}
              autoplay={{
                delay: 4000,
                disableOnInteraction: false,
              }}
              navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
              }}
              watchSlidesProgress={true}
              effect="fade"
              fadeEffect={{ crossFade: true }}
            >
              {slider &&
                slider.map((slide, index) => (
                  <SwiperSlide key={index} className="swiper-slide">
                    <Link
                      className="overlayLink"
                      aria-label="slider link"
                      to={slide.postId ? `/blogdetay/${slide.postId}` : `#`}
                    >
                      <div className="overlaySlider">
                        <div className="overlaySlider__item"></div>
                      </div>
                      <img
                        src={
                          slide.slide
                            ? `data:${slide.slide.contentType};base64,${slide.slide.fileContents}`
                            : "/brokenImage.svg"
                        }
                        alt={
                          slide.slide?.contentType
                            ? slide.slide?.contentType
                            : "alttext"
                        }
                        loading="lazy"
                        onError={(e) => {
                          e.currentTarget.src = "/brokenImage.svg";
                        }}
                      />
                      <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                    </Link>
                  </SwiperSlide>
                ))}

              <div
                ref={navigationNextRef}
                className="swiper-button-next d-none d-md-block"
              ></div>
              <div
                ref={navigationPrevRef}
                className="swiper-button-prev d-none d-md-block"
              ></div>
            </Swiper>
          ) : null}

          <div
            ref={paginationRef}
            className="swiper-pagination mt-2 mt-sm-3"
          ></div>
        </div>
      </div>
    </section>
  );
};

export default BlogSlider;
