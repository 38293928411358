import React, {  useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import useApi from "hooks/useApi";
import { getAccountPlans } from "services/user.service";
import { IPlans } from "types/productTypes";
import TdkDetailsPopup from "@components/Hesabim/Kampanyalarim/TdkDetailsPopup";
import TdkPlanCard from "@components/Hesabim/Kampanyalarim/TdkPlanCard";
import TdkSkeletonCards from "@components/Hesabim/Kampanyalarim/TdkSkeletonCard";
import { PublicContext } from "contexts/PublicContext/PublicContext";

interface IProduct {
  productId: string;
  displayName: string;
  plans: [
    {
      Id: string;
      name: string;
      target: number;
      percentageFreeGoods: number;
      limit: number;
      suggested: boolean;
    }
  ];
}

export interface IKatilTdk {
  termId: string;
  warehouseId: string;
  warehouseName: string;
  campaignEndDate: string;
  finalApplicationDate: string;
  plans: IPlans[];
  products: IProduct[];
}

export interface IDetails {
  product: IProduct;
  activePlan: IPlans;
}

const KatildigimTdk = () => {
  const [showDetails, setShowDetails] = useState(false);
  const [details, setDetails] = useState<IDetails | undefined>();
  const { data, success, loading, handleApi } = useApi();
/* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    handleApi(getAccountPlans, {});
  }, []);

  const tdkData: IKatilTdk = success && data;

  const handleShowDetails = (plan: IPlans) => {
    const product =
      tdkData &&
      tdkData.products.length &&
      tdkData.products.find((product) => product.productId === plan.productId);

    if (product) {
      const data = { product: product, activePlan: plan };
      if (data) {
        setDetails(data);
        setShowDetails((prev) => !prev);
      }
    }
  };

  const { setSiteTitle } = useContext(PublicContext);
  
  useEffect(() => {
    setSiteTitle("Katıldığım Turuncu Dönemsel Kampanyalar");
  }, []);

  return (
    <>
      <section className="breadcrumb my-4 my-md-5">
        <div className="container">
          <ul className="c:dark fs:14 lt:3">
            <li>
              <Link to="/anasayfa">Anasayfa</Link>
            </li>
            <li>
              <Link to="/hesabim">Hesabim</Link>
            </li>
            <li>
              <Link to="/hesabim/kampanyalarim">Kampanyalarım</Link>
            </li>
          </ul>
        </div>
      </section>
      <section className="mb-5">
        <div className="container">
          <h1 className="c:dark ff:bold fs:24 mb-4 pb-2 py-sm-0 text-center text-lg-start">
            Katıldığım Turuncu Dönemsel Kampanyalar
          </h1>
          {loading ? (
            <TdkSkeletonCards />
          ) : success && !tdkData.plans?.length ? (
            <section className="bg:white px-3 py-5 text-center shadow:4 radius:12 border:1">
              <div className="row g-0 justify-content-center">
                <div className="col-12 col-sm-7 col-md-6 col-lg-5 col-xl-4 col-xxl-3">
                  <div className="row row-cols-1 text-center gx-0 gy-3">
                    <div className="col">
                      <p className="ff:light c:dark fs:16">
                        Katıldığınız henüz bir Turuncu Dönemsel Kampanya yok.
                      </p>
                    </div>
                    <div className="col">
                      <Link
                        to={`/turuncu-donemsel-kampanyalar`}
                        aria-label="sepete ekle"
                        className="buttonStyle buttonStyle--paddingElem hover:opacity:8 c:white ff:bold fs:16 bg:orange text-center radius:12 d-inline-block"
                        style={
                          {
                            "--btnPaddingElem": "0.8rem 2.8rem",
                          } as React.CSSProperties
                        }
                      >
                        Kampanyalara Git
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ) : (
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3 gx-0 gx-lg-3">
              {tdkData &&
                tdkData.plans?.map((plan) => (
                  <TdkPlanCard
                    campaignEndDate={tdkData.campaignEndDate}
                    handleShowDetails={handleShowDetails}
                    plan={plan}
                    key={plan.planId}
                  />
                ))}
            </div>
          )}
        </div>
      </section>
      {showDetails && details && (
        <TdkDetailsPopup
          details={details}
          showDetails={showDetails}
          setShowDetails={setShowDetails}
          tdkData={tdkData}
        />
      )}
    </>
  );
};

export default KatildigimTdk;
