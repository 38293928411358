import React from "react";
import iconStarFill from "@images/icon-star-fill.svg";
import iconHour from "@images/icon-hour.svg";
import { Link } from "react-router-dom";
import { IProductAll } from "types/productTypes";

import HeartButtonAllProducts from "./HeartButtonAllProducts";
import { formatCurrency } from "utility/formatCurrency";

const ProductHome = ({ product }: { product: IProductAll }) => {
  return (
    <div className="productListCard h-100">
      <div className="border:1 bg:white radius:12 p-3 w-100 h-100">
        <div
          className="productListCard__box d-flex flex-column heightElem"
          style={{ "--heightElem": "15.5rem" } as React.CSSProperties}
        >
          <div>
            <div
              className="d-flex justify-content-between align-items-center mb-3 heightElem"
              style={{ "--heightElem": "2rem" } as React.CSSProperties}
            >
              <div>
                <div className="row row-cols-auto gy-0 gx-1">
                  {product?.label?.includes("PreOrder") && (
                    <div className="col">
                      <div
                        className="widthElem heightElem bg:orange:5 radius:full d-flex align-items-center justify-content-center iconSize:16"
                        style={
                          {
                            "--widthElem": "2rem",
                            "--heightElem": "2rem",
                          } as React.CSSProperties
                        }
                      >
                        <img src={iconHour} alt="" />
                      </div>
                    </div>
                  )}

                  {product?.label?.includes("Turuncu") && (
                    <div className="col">
                      <div
                        className="widthElem heightElem bg:orange radius:full d-flex align-items-center justify-content-center iconSize:16"
                        style={
                          {
                            "--widthElem": "2rem",
                            "--heightElem": "2rem",
                          } as React.CSSProperties
                        }
                      >
                        <img src={iconStarFill} alt="" />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="ms-auto">
                <HeartButtonAllProducts product={product} />
              </div>
            </div>
            <Link
              className="productHover"
              aria-label="link card"
              to={`/urun/${product.id}`}
            >
              <div className="productListCard__box-img">
                <img
                  src={
                    product.thumbnail
                      ? `data:${product.thumbnail.contentType};base64,${product.thumbnail.fileContents}`
                      : "/brokenImage.svg"
                  }
                  alt={
                    product.thumbnail?.contentType
                      ? product.thumbnail?.contentType
                      : "alttext"
                  }
                  loading="lazy"
                  onError={(e) => {
                    e.currentTarget.src = "/brokenImage.svg";
                  }}
                />
              </div>
              <p className="c:dark ff:bold fs:16 text-center mt-3 mb-2 lh:1">
                {product.displayBrandName}
              </p>
              <p className="c:dark ff:regular fs:14 text-center">
                {product.displayProductForm}
              </p>
            </Link>
          </div>
          <div className="mt-auto">
            <p className="c:dark ff:bold fs:18 text-center c:orange lh:1">
              &#8378;&nbsp;
              {formatCurrency(product.price.value)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductHome;
