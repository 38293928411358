import React, { useState, useContext, useEffect } from "react";
import { HeaderPropsTypes } from ".";
import iconDot from "@images/icon-dot.svg";
import iconNotification from "@images/icon-notification.svg";
import HandleNotificationsButton from "./Notification/HandleNotificationsButton";
import { NotificationContext } from "contexts/NotificationContext/NotificationContext";
import NotificationItem from "./Notification/NotificationItem";
import Skeleton from "react-loading-skeleton";

const Notification = ({
  notificationDrawerIsOpen,
  customClass,
  drawerRef,
  setNotificationDrawerIsOpen,
}: HeaderPropsTypes) => {
  const [allHandleIsOpen, setAllHandleIsOpen] = useState(false);

  const [activeNotification, setActiveNotification] = useState("");
  const { reFetchNotifications, notifications, loading } =
    useContext(NotificationContext);

  const handleActiveNotification = (
    notId: string,
    e: { stopPropagation: () => void }
  ) => {
    e.stopPropagation();
    if (activeNotification === notId) {
      setActiveNotification("");
    } else {
      setActiveNotification(notId);
    }

    setAllHandleIsOpen(false);
  };
/* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (notificationDrawerIsOpen) {
      reFetchNotifications();
    }
  }, [notificationDrawerIsOpen]);

  const handleOutSide = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    setActiveNotification("");
    setAllHandleIsOpen(false);
  };

  return (
    // <!-- bu panel açıldığında body -- > overflowHidden classı eklenmeli -->
    <section
      onClick={(e) => handleOutSide(e)}
      className={`notificationBar ${customClass}`}
    >
      {/* <!-- kapandığında aşağıdaki class'tan "animate__slideInRight" silinir "animate__slideOutRight" eklenir. --> */}
      <div
        ref={drawerRef}
        className={`notificationBar__wrapper bg:white shadow:5 animate__animated ${
          notificationDrawerIsOpen
            ? "animate__slideInRight"
            : "animate__slideOutRight"
        } animate__faster delay:1`}
      >
        <ul>
          <li>
            <div className="row row-cols-auto align-items-center justify-content-between g-0">
              <div className="col">
                <p className="fs:20 c:dark ff:bold">Bildirimler</p>
              </div>
              <div className="col">
                <div className="row row-cols-auto justify-content-end align-items-center gx-3 gy-0">
                  <div className="col">
                    <button
                      onClick={() => setNotificationDrawerIsOpen(false)}
                      aria-label="plana git"
                      className="buttonStyle buttonStyle--light c:dark ff:bold fs:14 bg:white shadow:4 d-inline-block text-center radius:12"
                      type="submit"
                    >
                      Kapat
                    </button>
                  </div>
                  <div className="col">
                    {/* <!-- aşağıdaki butona tıklandığında butona "notificationBtnActive" class'ı eklenecek --> */}
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setAllHandleIsOpen((prev) => !prev);
                        setActiveNotification("");
                      }}
                      style={
                        {
                          "--btn-width-size": "2.506rem",
                          "--btn-height-size": "2.506rem",
                        } as React.CSSProperties
                      }
                      aria-label="bildirim ayarı"
                      className="buttonStyle  buttonStyle--size buttonStyle--light c:dark ff:bold fs:14 bg:white shadow:4 d-flex align-items-center justify-content-center text-center radius:full"
                      type="submit"
                    >
                      <img src={iconDot} alt="" />
                    </button>
                    {allHandleIsOpen && (
                      <HandleNotificationsButton
                        setAllHandleIsOpen={setAllHandleIsOpen}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </li>
          {loading ? (
            <>
              {Array.from({ length: 6 }).map((_, index) => (
                <li key={index}>
                  <div className="row align-items-center justify-content-between gx-0 gy-3 gy-sm-0">
                    <Skeleton height={84.5} width={"100%"} />
                  </div>
                </li>
              ))}
            </>
          ) : (
            notifications?.map((notif) => (
              <NotificationItem
                key={notif.id}
                notification={notif}
                handleActiveNotification={handleActiveNotification}
                setActiveNotification={setActiveNotification}
                activeNotification={activeNotification}
                iconDot={iconDot}
                iconNotification={iconNotification}
                setNotificationDrawerIsOpen={setNotificationDrawerIsOpen}
              />
            ))
          )}
        </ul>
      </div>
    </section>
  );
};

export default Notification;
