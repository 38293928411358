import React, { useState } from "react";
import { Zoom } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { IPostItem } from "./BlogDetail";

const BlogZoomImage = ({ item }: { item: IPostItem }) => {
  const [swiperFirst, setSwiperFirst] = useState<any>(null);
  return (
    <div className="col">
      <div className="swiper zoomContent w-100 h-100">
        <Swiper
          className="swiper-wrapper"
          modules={[Zoom]}
          onSwiper={(swiper) => setSwiperFirst(swiper)}
          zoom={true}
        >
          <SwiperSlide className="swiper-slide">
            <div className="swiper-zoom-container">
              <img
                src={
                  item.image
                    ? `data:${item.image.contentType};base64,${item.image.fileContents}`
                    : "/brokenImage.svg"
                }
                alt={
                  item.image?.contentType ? item.image?.contentType : "alttext"
                }
                loading="lazy"
                onError={(e) => {
                  e.currentTarget.src = "/brokenImage.svg";
                }}
              />
              <div className="swiper-lazy-preloader swiper-lazy-preloader-black"></div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default BlogZoomImage;
