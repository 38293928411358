import React from "react";
import { Link } from "react-router-dom";
import iconSuccessTick from "@images/icon-success-tick.svg";
import { IDemands } from "views/MyAccount/SoruVeTaleplerim/SoruVeTalepler";

interface IProps {
  setFormSucces: (val: boolean) => void;
  data: any;
}

const Result = ({ setFormSucces, data }: IProps) => {
  const demand: IDemands = data && data;
  return (
    <section className="bg:white px-3 py-5 text-center shadow:4 radius:12 border:1">
      <div className="row g-0 justify-content-center">
        <div className="col-12 col-sm-7 col-md-6 col-lg-5 col-xl-4 col-xxl-3">
          <div className="row row-cols-1 text-center gx-0 gy-4">
            <div className="col">
              <div className="iconSize:32">
                <img className="mx-auto" src={iconSuccessTick} alt="" />
              </div>
            </div>
            <div className="col">
              <p className="ff:light c:dark fs:16">
                Talep numaranız: <strong>{demand.code}</strong> <br></br>
                Teşekkürler, talebiniz başarıyla alınmıştır.
              </p>
            </div>
            <div className="col">
              <div className="row row-cols-1 gx-0 gy-2">
                <div className="col">
                  <button
                    onClick={() => setFormSucces(false)}
                    aria-label="Yeni Talep Oluştur"
                    className="buttonStyle buttonStyle--paddingElem buttonStyle--border c:orange ff:bold fs:16 bg:white d-block w-100 text-center radius:12 shadow:10"
                    style={
                      {
                        "--btnPaddingElem": "0.8rem 2.8rem",
                        "--btn-border": "#FE9744",
                        "--btn-hover": "#FE9744",
                      } as React.CSSProperties
                    }
                  >
                    Yeni Talep Oluştur
                  </button>
                </div>
                <div className="col">
                  <Link
                    to="/anasayfa"
                    aria-label="sepete ekle"
                    className="buttonStyle buttonStyle--paddingElem hover:opacity:8 c:white ff:bold fs:16 bg:orange text-center radius:12 d-block w-100"
                    style={
                      {
                        "--btnPaddingElem": "0.8rem 2.8rem",
                      } as React.CSSProperties
                    }
                  >
                    Ana Sayfa
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Result;
