import { Link, useSearchParams } from "react-router-dom";
import { ICategory } from "types/categoryTypes";

interface IProps {
  parentCategory: ICategory | undefined;
  childCategory: ICategory;
}

const BreadCrumb = ({ parentCategory, childCategory }: IProps) => {
  let [searchParams] = useSearchParams();
  return (
    <section className="breadcrumb pb-4">
      <ul className="c:dark fs:14 lt:3">
        <li>
          <Link to="/anasayfa">Anasayfa</Link>
        </li>
        <li>
          <Link to="/urunler">Ürünler</Link>
        </li>
        <li>
          <Link
            to={`/urunler/liste?anakategori=${parentCategory?.code}&altkategori=all`}
          >
            {parentCategory?.name}
          </Link>
        </li>
        {searchParams.get("altkategori") && (
          <li>
            <Link
              to={`/urunler/liste?anakategori=${parentCategory?.code}&altkategori=${childCategory?.code}`}
            >
              {childCategory?.name}
            </Link>
          </li>
        )}
      </ul>
    </section>
  );
};

export default BreadCrumb;
