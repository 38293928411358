import React, { useEffect, useState } from "react";
import { ReactComponent as Spinning } from "assets/spinning.svg";
import ContentSearch from "@components/General/ContentSearch";
/* eslint-disable react-hooks/exhaustive-deps */
function OrderSearch({ loading, title, count, onSearchChanged }: any) {
  const [searchQuery, setSearchQuery] = useState("");
  useEffect(() => {
    onSearchChanged && onSearchChanged(searchQuery);
  }, [searchQuery]);

  return (
    <section className="lineTitle mb-4 pb-4">
      <section className="contentSearch">
        <div className="row align-items-center gx-0 gy-3 gy-md-0">
          <div className="col-12 col-md-8 col-lg-9">
            <div className="d-flex align-items-center justify-content-center justify-content-md-start">
              <div>
                <h1 className="c:dark ff:bold fs:24">{title}</h1>
              </div>
              <div>
                <div
                  className="d-inline-block bg:orange radius:8 px-1 c:white fs:14 ff:bold ms-3 heightElem d-flex align-items-center"
                  style={
                    {
                      "--heightElem": "1.875rem",
                    } as React.CSSProperties
                  }
                >
                  {loading ? (
                    <div className="px-2 lh:1">
                      <Spinning />
                    </div>
                  ) : (
                    <p className="px-2 py-1 lh:1"> {count} </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 col-lg-3">
            <ContentSearch
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              placeHolder="Sipariş No, Ürün Adı ya da Depo Adı ile Ara"
            />
          </div>
        </div>
      </section>
    </section>
  );
}

export default OrderSearch;
