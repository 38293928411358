import { useContext, useEffect, useState } from "react";
import ContentSearch from "@components/General/ContentSearch";
import StoresBg from "@components/Stores/Bg";
import StoreList from "@components/Stores/StoreList";
import { Link } from "react-router-dom";
import StoresHeadBreadCrumb from "@components/Stores/StoresHeadBreadCrumb";
import useApi from "hooks/useApi";
import { getAllWareHouse } from "services/warehouse.service";
import { IWareHouses, IWareHousesResult } from "types/wareHouseTypes";
import { PublicContext } from "contexts/PublicContext/PublicContext";
import useSecondApi from "hooks/useSecondApi";
import StoreItem from "@components/Stores/StoreItem";
import NoData from "@components/General/NoData";
import StoreSkeletonItem from "@components/Stores/StoreSkeletonItem";
/* eslint-disable react-hooks/exhaustive-deps */
const Stores = () => {
  const { handleApi, data, loading, success } = useApi({ loading: true });
  const { handleSecondApi, secondData, secondLoading, secondSuccess } =
    useSecondApi();
  const [searchQuery, setSearchQuery] = useState("");
  const [offset, setOffset] = useState(20);
  const [wareHouseResult, setWareHouseResult] = useState<IWareHousesResult[]>(
    []
  );

  const { setSiteTitle } = useContext(PublicContext);

  useEffect(() => {
    if (offset > 20) {
      handleApi(getAllWareHouse, {
        limit: 20,
        offset: offset - 20,
        sort: "name",
        query: "",
      });
    }

    return () => {};
  }, [offset]);

  useEffect(() => {
    if (data && success) {
      setWareHouseResult((prev) => {
        return [...prev, ...data.result];
      });
    }
  }, [data, success]);

  useEffect(() => {
    if (searchQuery.length) {
      handleSecondApi(getAllWareHouse, {
        offset: 0,
        sort: "name",
        query: searchQuery,
        limit: 1000000,
      });
    } else {
      setWareHouseResult([]);
      setOffset(20);
      handleApi(getAllWareHouse, {
        limit: 20,
        offset: 0,
        sort: "name",
        query: "",
      });
    }
    return () => {};
  }, [searchQuery]);

  useEffect(() => {
    if (secondSuccess) {
      setWareHouseResult(secondData.result);
    }
    return () => {};
  }, [secondSuccess, secondData]);

  const wareHouses: IWareHouses = data;

  useEffect(() => {
    setSiteTitle("Depolar");
  }, []);

  const skeletonArr = [...Array(offset)];

  return (
    <>
      <section className="bg:white shadow:9 pt-4">
        <div className="container">
          <section className="breadcrumb pb-4">
            <ul className="c:dark fs:14 lt:3">
              <li>
                <Link to="/anasayfa">Anasayfa</Link>
              </li>
              <li>
                <Link to="/depolar">Depolar</Link>
              </li>
            </ul>
          </section>
          <StoresHeadBreadCrumb />
        </div>
      </section>
      <section className="mt-3 mt-sm-5 mb-3 mb-sm-5">
        <div className="container">
          <StoresBg loading={loading} />
        </div>
      </section>
      <section className="mb-5">
        <div className="container">
          {/* <!-- <h1 class="c:dark ff:bold fs:24 mb-3 pb-1 text-center text-lg-start">Turuncu Ürünler</h1> --> */}
          <section className="contentSearch mb-4">
            <div className="row align-items-center gx-0 gy-3 gy-lg-0">
              <div className="col-12 col-md-8 col-lg-9">
                <h1 className="c:dark ff:bold fs:24 text-center text-md-start">
                  Depolar
                </h1>
              </div>
              <div className="col-12 col-md-4 col-lg-3">
                <ContentSearch
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  placeHolder="Depo Ara"
                />
              </div>
            </div>
          </section>

          {secondLoading || loading ? (
            <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-3 g-3">
              {skeletonArr.map((elem, index) => (
                <StoreSkeletonItem key={index} />
              ))}
            </div>
          ) : searchQuery.length && wareHouseResult.length ? (
            <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-3 g-3">
              {wareHouseResult.map((warehouse) => (
                <StoreItem item={warehouse} key={warehouse.id} />
              ))}
            </div>
          ) : wareHouseResult.length && !searchQuery.length ? (
            <StoreList
              offset={offset}
              setOffset={setOffset}
              wareHouses={wareHouses}
              wareHouseResult={wareHouseResult}
            />
          ) : (
            <NoData text="Sonuç bulunamadı." />
          )}
        </div>
        <p>NP-TR-NA-WCNT-240012</p>
      </section>
    </>
  );
};

export default Stores;
