import { useState } from "react";
import { encryptData, decryptData } from "utility/cryptoLocal";

export const useLocalStorage = () => {
  const [value, setValue] = useState<string | null>(null);

  const setItem = (key: string, value: string) => {
    localStorage.setItem(key, encryptData(value));
    setValue(value);
  };

  const getItem = (key: string) => {
    const val = localStorage.getItem(key);
    if (val) {
      const decyrpted = decryptData(val);
      setValue(decyrpted);
      return decyrpted;
    } else {
      return null;
    }
  };

  const removeItem = (key: string) => {
    localStorage.removeItem(key);
    setValue(null);
  };

  return { value, setItem, getItem, removeItem };
};
