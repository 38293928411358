import { Link } from "react-router-dom";
import { IBlogPosts } from "./BlogCategories";
import iconTrashList from "@images/icon-trash-list.svg";

interface IProps {
  bookmark: IBlogPosts;
  handlePopup: (name: string, id: string) => void;
}

const BookMarkItem = ({ handlePopup, bookmark }: IProps) => {
  return (
    <li className="col">
      <div className="row align-items-center gy-md-0 gy-4 gx-0 gx-md-4">
        <div className="col-12 col-md-10">
          <div className="row row-cols-1 gx-0 gy-3 gy-sm-4">
            <div className="col">
              <button
                onClick={() => handlePopup(bookmark.title, bookmark.id)}
                className="trashButton"
                aria-label="sil butonu"
                type="button"
              >
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <img src={iconTrashList} alt="" />
                  </div>
                  <div className="flex-grow-1 ms-2">
                    <p className="text-uppercase fs:13 lt:3 c:dark:2 ff:bold">
                      Kaldır
                    </p>
                  </div>
                </div>
              </button>
            </div>
            <div className="col">
              <p
                className={`text-uppercase fs:12 lt:4 ff:bold`}
                style={{ color: bookmark.blogColor }}
              >
                {bookmark.blogName}
              </p>
            </div>
            <div className="col">
              <Link
                className="c:dark fs:22 ff:regular hover:c:orange"
                to={`/blogdetay/${bookmark.id}`}
              >
                {bookmark.title}
              </Link>
            </div>
            <div className="col">
              <div className="tagListItem">
                <ul className="c:dark ff:bold fs:14">
                  {bookmark.hashtag &&
                    bookmark.hashtag.map((hashtag) => (
                      <li key={hashtag}>
                        <Link
                          className="tagListItem__active"
                          to={`/tag?slug=${hashtag}`}
                        >
                          {hashtag}
                        </Link>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-2">
          <div className="blogList__thumb">
            <img
              src={
                bookmark.banner
                  ? `data:${bookmark.banner.contentType};base64,${bookmark.banner.fileContents}`
                  : "/brokenImage.svg"
              }
              alt={
                bookmark.banner.contentType
                  ? bookmark.banner.contentType
                  : "alttext"
              }
              loading="lazy"
              onError={(e) => {
                e.currentTarget.src = "/brokenImage.svg";
              }}
            />
          </div>
        </div>
      </div>
    </li>
  );
};

export default BookMarkItem;
