import { Navigate } from "react-router-dom";
import { decryptData } from "utility/cryptoLocal";

const GuestGuard: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const val = localStorage.getItem("user");

  const user = val ? decryptData(val) : null;

  if (user && JSON.parse(user).userStatus > 4) {
    return <Navigate to="/anasayfa" replace />;
  }
  return children;
};

export default GuestGuard;
