import ReactDOM from "react-dom/client";
import App from "App";
import { AuthProvider } from "react-oidc-context";
import { AppProvider } from "contexts/AppContext/AppProvider";

const oidcConfig = {
  authority: process.env.REACT_APP_AUTHORITY || "",
  client_id: process.env.REACT_APP_CLIENT_ID || "",
  redirect_uri: `${window.location.origin}/gsk-giris`,
  scope: process.env.REACT_APP_SCOPE,
  //metadata: {
  //  authorization_endpoint:
  //    "https://federation-qa.gsk.com/as/authorization.oauth2",
  //  issuer: "https://federation-qa.gsk.com",
  //  token_endpoint: "https://federation-qa.gsk.com/as/token.oauth2",
  //  userinfo_endpoint: "https://federation-qa.gsk.com/idp/userinfo.openid",
  //},
};

// import { AxiosInterceptor } from "./utility/request";

// Sentry.init({
//   dsn: "https://e167a6cb9a4946b796a18a5af659ffc2@o1367721.ingest.sentry.io/4505197865926656",
//   integrations: [
//     new Sentry.BrowserTracing({
//       routingInstrumentation: Sentry.reactRouterV6Instrumentation(
//         React.useEffect,
//         useLocation,
//         useNavigationType,
//         createRoutesFromChildren,
//         matchRoutes
//       ),
//     }),
//     new Sentry.Replay(),
//     new Sentry.Integrations.Breadcrumbs({
//       console: false,
//     }),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

// console.log = (message?: any, ...optionalParams: any[]): void => {
//   // sendLogsToAPI(args);
//   //console.log("args", args);
//   originalConsoleLog(message);
// };

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <AppProvider>
    <AuthProvider {...oidcConfig}>
      <App />
    </AuthProvider>
  </AppProvider>
);
