import { useEffect, useState, useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import iconHome from "@images/icon-home.svg";
import useApi from "hooks/useApi";
import { getBlog } from "services/blog.service";
import { BlogContext } from "contexts/BlogContext/BlogContext";

function BlogMenuDetay({ activeCategory }: { activeCategory?: string }) {
  const { handleApi, data, success } = useApi();

  const { blogMenu, setBlogMenu } = useContext(BlogContext);
/* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (!blogMenu?.length) {
      handleApi(getBlog, {});
    }
  }, [blogMenu]);

  useEffect(() => {
    if (success && data) {
      setBlogMenu(data);
    }
  }, [success, data]);

  const [activeSlug, setActiveSlug] = useState("");

  useEffect(() => {
    if (blogMenu && activeCategory) {
      const blogNavItem = blogMenu?.find(
        (item) => item.name === activeCategory
      );
      if (blogNavItem) {
        setActiveSlug(blogNavItem.slug);
      }
    }
    return () => {
      setActiveSlug("");
    };
  }, [blogMenu, activeCategory]);

  return (
    <section className="singleMenu flex-nowrap">
      <nav>
        <ul className="fs:16 c:dark:2 ff:bold">
          <li>
            <NavLink
              to={"/blog"}
              end={true}
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "singleMenu__active" : ""
              }
            >
              <img src={iconHome} alt="" />
            </NavLink>
          </li>

          {blogMenu &&
            blogMenu.map((item) => (
              <li key={item.id}>
                <Link
                  to={`/blog/${item.slug}`}
                  className={
                    item.slug === activeSlug ? "singleMenu__active" : "pending"
                  }
                >
                  {item.name}
                </Link>
              </li>
            ))}
          <li>
            <NavLink
              to={"/blog/okuma-listem"}
              end={true}
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "singleMenu__active" : ""
              }
            >
              Okuma Listem
            </NavLink>
          </li>
        </ul>
      </nav>
    </section>
  );
}

export default BlogMenuDetay;
