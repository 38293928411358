import axios from "axios";
import { useLocalStorage } from "hooks/useLocalStorage";
import { useEffect, useState } from "react";
import { useAuth as useOAuth2 } from "react-oidc-context";
import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { endpoints } from "constants/endpoints";
import GeneralLoading from "@components/General/GeneralLoading";
import { getKongApiKey, getOriginUrl } from "utility/request";
/* eslint-disable react-hooks/exhaustive-deps */
function LoginExternal() {
  const { setItem } = useLocalStorage();
  const { login: LoginContext } = useAuth();
  const auth = useOAuth2();
  const navigate = useNavigate();
  const [once, setOnce] = useState(true);

  useEffect(() => {
    var loadAsync = async () => {
      try {
        const token = auth.user?.access_token;
        const response = await axios.post(
          `${endpoints.baseUrl}/user/external-login`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              apikey: getKongApiKey(),
              "Access-Control-Allow-Origin": getOriginUrl(),
              "X-Client-UID": localStorage.getItem("clientId"),
              "X-Tab-Session-UID": sessionStorage.getItem("sessionKey"),
              "X-Application-UID": localStorage.getItem("applicationId"),
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 200) {
          var data = response.data;
          LoginContext(data.applicationUser);
          setItem("accessToken", data.accessToken);
          setItem("accessTokenExpireDate", data.accessTokenExpireDate);
          setItem("refreshToken", data.refreshToken);
          setItem("refreshTokenExpireDate", data.refreshTokenExpireDate);
          navigate("/anasayfa");
        }
        console.log(response);
      } catch (e) {
        console.error(e);
      }
    };
    if (auth.isAuthenticated) loadAsync();
    else if (!(auth.isLoading || auth.error)) {
      if (once) {
        setOnce(false);
        auth.signinRedirect();
      }
    }
  }, [auth]);

  if (auth.isLoading) {
    return <GeneralLoading />;
  }

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }

  if (auth.isAuthenticated) {
    //handleLogin();
    // return (
    //   <div>
    //     Hello {auth.user?.profile.sub}{" "}
    //     <button onClick={() => void auth.removeUser()}>Log out</button>
    //   </div>
    // );
    return <GeneralLoading />;
  }

  return <button onClick={() => void auth.signinRedirect()}>Log in</button>;
}

export default LoginExternal;
