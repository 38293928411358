import React from "react";
import iconClose from "@images/icon-close.svg";
// import iconInfo from "@images/icon-info.svg";
// import iconInfoGreen from "@images/icon-info-green.svg";
import ContentSearch from "@components/General/ContentSearch";
import { ITdkProduct, ITdkProducts } from "types/productTypes";
import { IWareHouses } from "types/wareHouseTypes";
import InfiniteScroll from "react-infinite-scroll-component";
import { IWareHousesResult } from "types/wareHouseTypes";
import { ISelectedWareHouse } from "views/TuruncuHat/TuruncuDonemsel";
import { uniqueBy } from "utility/uniquBy";
import Skeleton from "react-loading-skeleton";
import NoData from "../NoData";
import classNames from "classnames";

interface IProps {
  handleActivePopup: (popup: string) => void;
  handleSelectWareHouse: (wareHouse: any) => void;
  selectedWareHouse: ISelectedWareHouse;
  activeWareHouse: ISelectedWareHouse;
  selectedProduct?: ITdkProduct;
  wareHouses: IWareHouses;
  wareHouseResult: IWareHousesResult[];
  offset: number;
  setOffset: (offset: number) => void;
  searchQuery: string;
  setSearchQuery: (q: string) => void;
  setSelectedWareHouse: (data: any) => void;
  data: ITdkProducts;
  loading: boolean;
  apiLoading: boolean;
}

const PopupWareHouse = ({
  handleActivePopup,
  handleSelectWareHouse,
  selectedWareHouse,
  activeWareHouse,
  selectedProduct,
  offset,
  setOffset,
  wareHouses,
  searchQuery,
  setSearchQuery,
  setSelectedWareHouse,
  loading,
  wareHouseResult,
  apiLoading,
}: IProps) => {
  const getData = () => {
    if (!searchQuery.length) {
      setOffset(offset + 10);
    }
  };

  const handleWareHouse = () => {
    handleSelectWareHouse(selectedWareHouse);
  };

  const handleClose = () => {
    handleActivePopup("null");
    setSearchQuery("");
    setSelectedWareHouse({
      name: activeWareHouse.name,
      id: activeWareHouse.id,
    });
  };
  const skeletonArr = [...Array(offset)];

  return (
    <>
      <section className="popupWrapper animate__animated animate__fadeIn animate__faster">
        <div
          className="popupWrapper__content bg:white radius:20"
          id="scrollableDiv"
        >
          <div className="popupWrapper__content-head d-flex align-items-center justify-content-between px-4 radius:20 bg:white">
            <div>
              <h1 className="ff:bold fs:18 c:dark lh:2">Depo Seçin</h1>
            </div>
            <div className="ps-4">
              <button
                onClick={handleClose}
                disabled={loading}
                aria-label="close popup"
                className="popupWrapper__close buttonStyle buttonStyle--light buttonStyle--paddingElem radius:full bg:white shadow:4"
                type="button"
                style={{ "--btnPaddingElem": "0" } as React.CSSProperties}
              >
                <img src={iconClose} alt="" />
              </button>
            </div>
          </div>
          <div className="px-4 pb-2">
            <section className="planPopup">
              <div className="row row-cols-1 gx-0 gy-4">
                <div className="col">
                  <p className="ff:regular fs:16 c:dark:2">
                    Turuncu Dönemsel kampanyalara katılmak için öncelikle bir
                    depo seçimi yapmalısınız.
                  </p>
                </div>
                <div className="col">
                  <section className="contentSearch">
                    <div className="row align-items-center gx-0 gy-0">
                      <div className="col-8">
                        <ContentSearch
                          searchQuery={searchQuery}
                          setSearchQuery={setSearchQuery}
                          placeHolder="Depo Ara"
                        />
                      </div>
                      <div className="col-4">
                        <p className="text-uppercase text-end fs:11 lt:4 c:orange ff:bold">
                          ZORUNLU
                        </p>
                      </div>
                    </div>
                  </section>
                </div>

                <div className="col">
                  {apiLoading ? (
                    skeletonArr.map((elem, index) => (
                      <Skeleton
                        key={index}
                        height={56.5}
                        className="w-100 radius:12"
                      />
                    ))
                  ) : wareHouseResult?.length ? (
                    <InfiniteScroll
                      className="row row-cols-1 gx-0 gy-3"
                      dataLength={wareHouses && wareHouses.total - offset}
                      next={getData}
                      hasMore={wareHouses && wareHouses.total > offset}
                      scrollableTarget="scrollableDiv"
                      loader={<></>}
                    >
                      {uniqueBy(wareHouseResult, (el) => el.id).map(
                        (warehouse) => (
                          <div key={warehouse.id} className="col">
                            <div
                              className="w-100 bg:white radius:12 borderStyle shadow:4 p-3"
                              style={
                                {
                                  "--gsk-border-width": "0.031rem",
                                  "--gsk-border-color": "#d7d7d7",
                                } as React.CSSProperties
                              }
                            >
                              <div className="d-flex align-items-center">
                                {warehouse.isFavorite ? (
                                  <div className="flex-shrink-0 me-2 pe-1">
                                    <button
                                      disabled
                                      aria-label="star button"
                                      className="d-block"
                                      type="button"
                                    >
                                      <div className="starButton starButtonStrokeColor">
                                        <svg
                                          className="starButton__main starButtonStroke"
                                          viewBox="0 0 512 512"
                                          width="100%"
                                        >
                                          <path d="M274.17 43.2767L331.545 158.902C332.85 161.837 334.902 164.379 337.498 166.275C340.094 168.171 343.143 169.355 346.34 169.708L472.998 188.439C476.666 188.909 480.123 190.412 482.967 192.77C485.811 195.129 487.924 198.247 489.059 201.759C490.194 205.272 490.305 209.035 489.378 212.608C488.451 216.181 486.524 219.417 483.824 221.938L392.529 312.349C390.199 314.522 388.45 317.242 387.441 320.261C386.433 323.281 386.197 326.505 386.755 329.638L408.767 456.79C409.404 460.444 409.001 464.203 407.604 467.639C406.207 471.075 403.872 474.051 400.864 476.228C397.856 478.405 394.296 479.695 390.59 479.952C386.884 480.209 383.179 479.423 379.899 477.682L265.87 417.528C262.95 416.097 259.741 415.353 256.488 415.353C253.235 415.353 250.026 416.097 247.106 417.528L133.077 477.682C129.796 479.423 126.092 480.209 122.386 479.952C118.68 479.695 115.12 478.405 112.112 476.228C109.104 474.051 106.769 471.075 105.372 467.639C103.975 464.203 103.572 460.444 104.209 456.79L126.221 328.198C126.779 325.064 126.543 321.84 125.534 318.821C124.526 315.801 122.777 313.081 120.447 310.908L28.0696 221.938C25.3366 219.348 23.4146 216.022 22.5364 212.364C21.6583 208.706 21.8616 204.872 23.1219 201.327C24.3821 197.782 26.6451 194.677 29.6368 192.39C32.6285 190.102 36.2205 188.73 39.9776 188.439L166.636 169.708C169.833 169.355 172.882 168.171 175.477 166.275C178.073 164.379 180.126 161.837 181.431 158.902L238.806 43.2767C240.369 39.9091 242.864 37.058 245.997 35.0598C249.13 33.0617 252.77 32 256.488 32C260.206 32 263.846 33.0617 266.979 35.0598C270.112 37.058 272.607 39.9091 274.17 43.2767Z" />
                                        </svg>
                                        <svg
                                          className="starButton__background starButtonFade"
                                          viewBox="0 0 512 512"
                                          width="100"
                                        >
                                          <path d="M274.17 43.2767L331.545 158.902C332.85 161.837 334.902 164.379 337.498 166.275C340.094 168.171 343.143 169.355 346.34 169.708L472.998 188.439C476.666 188.909 480.123 190.412 482.967 192.77C485.811 195.129 487.924 198.247 489.059 201.759C490.194 205.272 490.305 209.035 489.378 212.608C488.451 216.181 486.524 219.417 483.824 221.938L392.529 312.349C390.199 314.522 388.45 317.242 387.441 320.261C386.433 323.281 386.197 326.505 386.755 329.638L408.767 456.79C409.404 460.444 409.001 464.203 407.604 467.639C406.207 471.075 403.872 474.051 400.864 476.228C397.856 478.405 394.296 479.695 390.59 479.952C386.884 480.209 383.179 479.423 379.899 477.682L265.87 417.528C262.95 416.097 259.741 415.353 256.488 415.353C253.235 415.353 250.026 416.097 247.106 417.528L133.077 477.682C129.796 479.423 126.092 480.209 122.386 479.952C118.68 479.695 115.12 478.405 112.112 476.228C109.104 474.051 106.769 471.075 105.372 467.639C103.975 464.203 103.572 460.444 104.209 456.79L126.221 328.198C126.779 325.064 126.543 321.84 125.534 318.821C124.526 315.801 122.777 313.081 120.447 310.908L28.0696 221.938C25.3366 219.348 23.4146 216.022 22.5364 212.364C21.6583 208.706 21.8616 204.872 23.1219 201.327C24.3821 197.782 26.6451 194.677 29.6368 192.39C32.6285 190.102 36.2205 188.73 39.9776 188.439L166.636 169.708C169.833 169.355 172.882 168.171 175.477 166.275C178.073 164.379 180.126 161.837 181.431 158.902L238.806 43.2767C240.369 39.9091 242.864 37.058 245.997 35.0598C249.13 33.0617 252.77 32 256.488 32C260.206 32 263.846 33.0617 266.979 35.0598C270.112 37.058 272.607 39.9091 274.17 43.2767Z" />
                                        </svg>
                                      </div>
                                    </button>
                                  </div>
                                ) : null}

                                <div className="flex-grow-1">
                                  <div className="kz-check">
                                    <div>
                                      <label className="kz-check__label">
                                        <span className="d-block ff:regular fs:15 c:dark text-capitalize">
                                          <p>{warehouse.name}</p>
                                        </span>
                                        <input
                                          type="radio"
                                          id="radio-1"
                                          name="radio-1"
                                          aria-label="blankInput"
                                          required
                                          checked={
                                            warehouse.id ===
                                            selectedWareHouse.id
                                          }
                                          onChange={() =>
                                            setSelectedWareHouse(warehouse)
                                          }
                                        />
                                        <div className="kz-check__input"></div>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </InfiniteScroll>
                  ) : (
                    <NoData text="Sonuç bulunamadı." />
                  )}
                </div>
              </div>
            </section>
          </div>
          <div className="popupWrapper__content-footer d-flex align-items-center px-4 radius:20 bg:white">
            <button
              onClick={handleWareHouse}
              aria-label="Devam Et"
              disabled={!selectedWareHouse.id}
              className={classNames(
                "buttonStyle buttonStyle--icon buttonStyle--submit c:white ff:bold fs:16 bg:orange d-block w-100 text-center radius:12",
                {
                  spinnerElem: loading,
                  disabledBtn: !selectedWareHouse.id,
                }
              )}
              type="button"
              style={{ "--form-height": "3rem" } as React.CSSProperties}
            >
              Devam Et
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default PopupWareHouse;
