import { ICart } from "types/shoppingCartTypes";
import AccordionSummary from "./AccordionSummary";
import GoPrevStep from "./GoPrevStep";

interface IProps {
  cart: ICart;
}
const CartSummary = ({ cart }: IProps) => {
  return (
    <section className="my-5">
      <div className="container">
        <section className="mb-3 pb-1">
          <div className="row row-cols-auto justify-content-between align-items-center g-0">
            <div className="col">
              <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                <p className="c:dark ff:bold fs:24">Özet</p>
                <p className="ms-2 ff:regular c:dark fs:16">
                  ({cart?.quantity} ürün)
                </p>
              </div>
            </div>
            <div className="col">
              <GoPrevStep uri="/sepet/depo" />
            </div>
          </div>
        </section>

        <AccordionSummary />
      </div>
    </section>
  );
};

export default CartSummary;
