import { useContext, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import OrderList from "@components/Orders/OrderList";
import { getMyOrders } from "services/user.service";
import useApi from "hooks/useApi";
import { PublicContext } from "contexts/PublicContext/PublicContext";
/* eslint-disable react-hooks/exhaustive-deps */
const Orders = () => {
  const [title] = useOutletContext<any>();
  const { handleApi, data, loading } = useApi({ loading: true });

  useEffect(() => {
    handleApi(getMyOrders, {});

    return () => {};
  }, []);

  const { setSiteTitle } = useContext(PublicContext);
  useEffect(() => {
    setSiteTitle("Devam Eden Siparişlerim");
  }, []);

  return (
    <div className="container">
      {/* <!--#include virtual="/parts/components/fastOrder/accordion.html" --> */}
      <OrderList
        title={title}
        loading={loading}
        orders={data}
        isPreorder={false}
      />
    </div>
  );
};

export default Orders;
