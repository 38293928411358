import OrderList from "@components/Orders/OrderList";
import { useEffect, useContext } from "react";
import { getMyOrderHistory } from "services/user.service";
import useApi from "hooks/useApi";
import { useOutletContext } from "react-router-dom";
import { BodyContext } from "contexts/BodyContext/BodyContext";
import { PublicContext } from "contexts/PublicContext/PublicContext";
/* eslint-disable react-hooks/exhaustive-deps */
function OrdersHistory() {
  const [title] = useOutletContext<any>();
  const { handleApi, data, loading } = useApi({ loading: true });
  const { activePopup, handlePopup } = useContext(BodyContext);
  const { setSiteTitle } = useContext(PublicContext);

  useEffect(() => {
    handleApi(getMyOrderHistory, {});
    return () => {};
  }, []);

  useEffect(() => {
    if (activePopup === "reFetchOrders") {
      handleApi(getMyOrderHistory, {});
      handlePopup("", null);
    }
    return () => {};
  }, [activePopup]);

  useEffect(() => {
    setSiteTitle("Geçmiş Siparişlerim");
  }, []);

  return (
    <div className="container">
      <OrderList
        title={title}
        loading={loading}
        orders={data}
        isPreorder={false}
        isRateable={true}
      />
    </div>
  );
}

export default OrdersHistory;
