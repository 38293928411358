import { useState, useContext, useEffect } from "react";
import FirstSection from "@components/TuruncuHat/FirstSection";
import SecondSection from "@components/TuruncuHat/SecondSectionAccordion";
import ActiveCampaigns from "@components/TuruncuHat/ActiveCampaigns";
import TdkProducts from "@components/Products/TdkProducts";
import Sss from "@components/TuruncuHat/Sss";
import PlanPopup from "@components/General/PopUps/PopupPlan";
import { ITdkProduct, ITdkProducts } from "types/productTypes";
import Head from "@components/TuruncuHat/Head";
import { BodyContext } from "contexts/BodyContext/BodyContext";
import useApi from "hooks/useApi";
import { getAllWareHouse } from "services/warehouse.service";
import { IWareHouses, IWareHousesResult } from "types/wareHouseTypes";
import useSecondApi from "hooks/useSecondApi";
import { getTdkProducts } from "services/product.service";
import { addPlan, setWareHouse } from "services/user.service";
import { useLocation } from "react-router-dom";
import PopupWareHouse from "@components/General/PopUps/PopupWareHouse";
import PopupSuccess from "@components/General/PopUps/PopupSuccess";
import PopupNoWareHouse from "@components/General/PopUps/PopupNoWareHouse";
import { ErrorHandler } from "@components/General/ErrorHandler";
import { PublicContext } from "contexts/PublicContext/PublicContext";
/* eslint-disable react-hooks/exhaustive-deps */
export interface ISelectedWareHouse {
  name: string;
  id: string;
}

const TuruncuDonemsel = () => {
  const { handleBody } = useContext(BodyContext);
  const { setSiteTitle } = useContext(PublicContext);
  const [activePopup, setActivePopup] = useState("null");
  const [selectedWareHouse, setSelectedWareHouse] =
    useState<ISelectedWareHouse>();
  const [activePlan, setActivePlan] = useState<string>("null");
  const [selectedPlan, setSelectedPlan] = useState<string>("null");
  const [selectedProduct, setSelectedProduct] = useState<ITdkProduct>();
  const [searchQuery, setSearchQuery] = useState("");
  const [activeWareHouse, setActiveWareHouse] = useState<ISelectedWareHouse>({
    name: "",
    id: "",
  });

  useEffect(() => {
    setSiteTitle("Turuncu Dönemsel Kampanyalar");
  }, []);

  const {
    handleApi,
    data,
    success,
    loading: apiLoading,
    setError,
    error,
  } = useApi({ loading: true });
  const {
    handleSecondApi,
    secondData,
    secondError,
    secondLoading,
    secondSuccess,
  } = useSecondApi();

  const [offset, setOffset] = useState(10);
  const [plansData, setPlansData] = useState<ITdkProducts>(secondData);
  const [isPlanPopup, setIsPlanPopup] = useState<any>(false);
  const [loading, setLoading] = useState(true);
  const [wareHouseResult, setWareHouseResult] = useState<IWareHousesResult[]>(
    []
  );

  useEffect(() => {
    if (offset > 20) {
      handleApi(getAllWareHouse, {
        limit: 20,
        offset: offset - 20,
        sort: "name",
        query: "",
      });
    }

    return () => {};
  }, [offset]);

  useEffect(() => {
    if (data && success) {
      setWareHouseResult((prev) => {
        return [...prev, ...data.result];
      });
    }
  }, [data, success]);

  useEffect(() => {
    if (searchQuery.length) {
      handleSecondApi(getAllWareHouse, {
        offset: 0,
        sort: "name",
        query: searchQuery,
        limit: 1000000,
      });
    } else {
      setWareHouseResult([]);
      setOffset(20);
      handleApi(getAllWareHouse, {
        limit: 20,
        offset: 0,
        sort: "name",
        query: "",
      });
    }
    return () => {};
  }, [searchQuery]);

  useEffect(() => {
    if (secondSuccess) {
      setWareHouseResult(secondData.result);
    }
    return () => {};
  }, [secondSuccess, secondData]);

  const handleActivePopup = (
    popup: string,
    product?: any,
    wareHouse?: ISelectedWareHouse | any,
    isPlanPop?: boolean
  ) => {
    setIsPlanPopup(isPlanPop);

    if (product) {
      setSelectedProduct(product);
    }
    if (popup === "noPlan") {
      setActivePopup("noPlan");
    }
    if (popup === "null") {
      setActivePopup("null");
    }
    if (popup === "depo") {
      setActivePopup("depo");
      return;
    }
    if (popup === "plan" && !selectedWareHouse && !wareHouse) {
      setActivePopup("noPlan");
    } else if (selectedWareHouse && popup === "plan") {
      setActivePopup(isPlanPop ? popup : "");
      handleBody("");
    }
    if (wareHouse) {
      setActivePopup(popup);
      setSelectedWareHouse(wareHouse);
    }
  };

  const handleSelectWareHouse = async (wareHouse: IWareHousesResult) => {
    try {
      setLoading(true);
      await setWareHouse({
        wareHouseId: wareHouse.id,
        termId: plansData.termId,
      });
      setPlansData({
        ...plansData,
        warehouseId: selectedWareHouse!.id,
        warehouseName: selectedWareHouse!.name,
      });
      setSearchQuery("");
      if (isPlanPopup) {
        handleActivePopup("plan", "", "", true);
      } else {
        setActivePopup("null");
        setIsPlanPopup(false);
      }
      setSelectedWareHouse(wareHouse);
      setActiveWareHouse(wareHouse);
    } catch (error) {
      const { errorMessage } = ErrorHandler(error);
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
    // setActivePlan("null");
    // setSelectedPlan("null");
  };

  const handleActivePlan = (plan: string) => {
    if (activePlan === plan) {
      setActivePlan("null");
    } else {
      setActivePlan(plan);
    }
  };

  const handleSelectPlan = async (plan: string) => {
    try {
      const dataTdk: ITdkProducts = await addPlan({ plan_id: plan });
      setSelectedPlan(plan);
      setPlansData({
        ...plansData,
        plans: dataTdk.plans,
        warehouseId: dataTdk.warehouseId,
        warehouseName: dataTdk.warehouseName,
      });
    } catch (error) {
      const { errorMessage } = ErrorHandler(error);
      setError(errorMessage);
    }
  };

  useEffect(() => {
    if (activePopup !== "null") {
      handleBody("overflowHidden");
    } else {
      handleBody("null");
    }
    return () => {
      handleBody("");
    };
  }, [activePopup]);

  const getTdkData = async () => {
    setLoading(true);
    try {
      const dataTdk: ITdkProducts = await getTdkProducts();

      setPlansData(dataTdk);
      if (dataTdk) {
        setSelectedWareHouse({
          name: dataTdk.warehouseName,
          id: dataTdk.warehouseId,
        });
        setActiveWareHouse({
          name: dataTdk.warehouseName,
          id: dataTdk.warehouseId,
        });
      }
    } catch (error) {
      const { errorMessage } = ErrorHandler(error);
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getTdkData();
  }, []);

  const wareHouses: IWareHouses = success && data;

  let { state } = useLocation();

  useEffect(() => {
    if (state && state.productId && plansData) {
      const product = plansData.products.find(
        (item) => item.productId === state.productId
      );
      const activePlan =
        product &&
        plansData.plans.find(
          (item: { productId: string }) => item.productId === product.productId
        );
      if (plansData.termState === 1) {
        handleActivePopup(
          selectedWareHouse?.name ? "plan" : "noPlan",
          product,
          "",
          true
        );
        setSelectedPlan("");
      }

      if (activePlan) setSelectedPlan(activePlan.planId);
    }
  }, [state, plansData]);

  return (
    <>
      <Head />
      {secondError || (error && <div className="container my-5">{secondError}</div>)}
      <FirstSection data={plansData} loading={loading} />

      <SecondSection data={plansData} loading={loading} />

      <ActiveCampaigns
        handleActivePopup={handleActivePopup}
        selectedWareHouse={selectedWareHouse!}
        activeWareHouse={activeWareHouse!}
        setSelectedWareHouse={setSelectedWareHouse}
        data={plansData}
        setSelectedPlan={setSelectedPlan}
        loading={secondLoading}
      />

      {plansData && (
        <TdkProducts
          handleActivePopup={handleActivePopup}
          selectedWareHouse={selectedWareHouse!}
          data={plansData}
          setSelectedPlan={setSelectedPlan}
        />
      )}

      {activePopup === "plan" && (
        <PlanPopup
          data={plansData}
          activePlan={activePlan}
          handleActivePlan={handleActivePlan}
          handleActivePopup={handleActivePopup}
          handleSelectPlan={handleSelectPlan}
          selectedPlan={selectedPlan}
          selectedProduct={selectedProduct}
          selectedWareHouse={selectedWareHouse!}
        />
      )}
      {activePopup === "depo" && (
        <PopupWareHouse
          activeWareHouse={activeWareHouse!}
          data={plansData}
          setSelectedWareHouse={setSelectedWareHouse}
          handleActivePopup={handleActivePopup}
          handleSelectWareHouse={handleSelectWareHouse}
          selectedWareHouse={selectedWareHouse!}
          selectedProduct={selectedProduct}
          offset={offset}
          setOffset={setOffset}
          wareHouses={wareHouses}
          wareHouseResult={wareHouseResult}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          loading={loading}
          apiLoading={apiLoading || secondLoading}
        />
      )}
      {activePopup === "success" && (
        <PopupSuccess handleActivePopup={handleActivePopup} />
      )}
      {activePopup === "noPlan" && (
        <PopupNoWareHouse handleActivePopup={handleActivePopup} />
      )}
      {plansData && plansData.sss && (
        <section className="my-5">
          <div className="container">
            <h1 className="c:dark ff:bold fs:32 mb-3 pb-1 text-center">
              Sıkça Sorulan Sorular
            </h1>
            <Sss data={plansData.sss} />
          </div>
          <p>NP-TR-NA-WCNT-240009</p>
        </section>
      )}
    </>
  );
};

export default TuruncuDonemsel;
