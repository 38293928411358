import iconStores from "@images/icon-stores.svg";
import { toElementDimentionStyle } from "utility/CSSProperties";
import OrderItemList from "./OrderItemList";
import StarsRating from "@components/General/StarsRating";
import { Order } from "types/Order";
import RepeatOrder from "./RepeatOrder";
import { convertDatetimeStrMonth } from "utility/formatDate";
import { formatCurrency } from "utility/formatCurrency";
import { useContext } from "react";
import { CartContext } from "contexts/CartContext/CartContext";
import iconInfo from "@images/icon-info.svg";

interface IProps {
  order: Order;
  isPreorder: boolean;
  selected: string;
  onSelectionChanged: any;
  isRateable?: boolean;
}

function OrderDetail({
  order,
  isPreorder,
  selected,
  onSelectionChanged,
  isRateable,
}: IProps) {
  const { error } = useContext(CartContext);
  return (
    <div className="bg:white radius:12 shadow:4 p-4 layoutCollapse">
      <div
        onClick={() => onSelectionChanged(order.id)}
        className={`layoutCollapse__box ${
          order.id === selected ? "layoutCollapse__active" : ""
        }`}
      >
        <section className="scrollingMobile flex-nowrap">
          <div className="layoutTables">
            <ul className="layoutTables__content">
              <li className="layoutTables__content-item bg:blues">
                <div className="d-flex align-items-start">
                  <div className="flex-shrink-0">
                    <div
                      className="widthElem heightElem iconSize:16 bg:orange radius:full d-flex align-items-center justify-content-center"
                      style={toElementDimentionStyle("2.125")}
                    >
                      <img src={iconStores} alt="" />
                    </div>
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <p
                      className="c:dark ff:regular fs:16 smText"
                      title={order.warehouseName}
                    >
                      {order.warehouseName}
                    </p>
                    {order.preOrderActualDate && (
                      <p className="text-uppercase lt:2 ff:bold fs:12 c:orange mt-1">
                        gerçek siparişe dönme tarihi
                      </p>
                    )}
                    <p className="text-uppercase lt:2 ff:regular fs:14 c:dark:2 mt-1">
                      {convertDatetimeStrMonth(
                        new Date(
                          order.preOrderActualDate
                            ? order.preOrderActualDate
                            : order.date
                        )
                      )}
                    </p>
                  </div>
                </div>
              </li>
              <li className="layoutTables__content-item bg:reds">
                <p
                  className="fs:16 ff:regular text-center"
                  style={{ color: order.status.color }}
                >
                  &#x2022;&nbsp;{order.status.text}
                </p>
              </li>
              <li className="layoutTables__content-item bg:yellows">
                <div className="d-flex align-items-center justify-content-center">
                  <p className="c:dark ff:regular fs:16 me-2">Adet</p>
                  <div
                    className="widthElem heightElem bg:orange:2 radius:8 d-flex align-items-center justify-content-center"
                    style={toElementDimentionStyle("1.875")}
                  >
                    <p className="c:orange fs:16 ff:bold lh:1">
                      {order.productCount}
                    </p>
                  </div>
                </div>
              </li>
              <li className="layoutTables__content-item bg:darks">
                <div className="text-end">
                  <p className="c:dark fs:16 ff:regular">
                    Sipariş No: <strong>{order.code}</strong>
                  </p>
                  <p className="c:orange ff:bold fs:18 mt-1">
                    &#8378;&nbsp;{formatCurrency(order.totalAmount.value)}
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </section>
      </div>
      <div
        className={`layoutCollapse__content   ${
          order.id === selected ? "d-block" : "d-none"
        }`}
      >
        <div className="pt-5">
          <div className="row row-cols-1 gx-0 gy-5">
            <OrderItemList items={order.items} />
          </div>
          <div className="mt-5 mb-4">
            <ul className="row row-cols-1 gx-0 gy-4 listInfo listInfo--border">
              <li className="col">
                <div className="listInfo__item">
                  <div className="row row-cols-1 row-cols-md-auto gy-2 gy-md-0 gx-0 justify-content-between text-center align-items-center">
                    <div className="col">
                      <p className="c:dark text-capitalize fs:16 ff:regular lt:2">
                        Depo
                      </p>
                    </div>
                    <div className="col">
                      <p className="c:dark:4 fs:16 ff:bold text-capitalize">
                        {order.warehouseName.toLowerCase()}
                      </p>
                    </div>
                  </div>
                </div>
              </li>
              <li className="col">
                <div className="listInfo__item">
                  <div className="row row-cols-1 row-cols-md-auto gy-2 gy-md-0 gx-0 justify-content-between text-center align-items-center">
                    <div className="col">
                      <p className="c:dark text-capitalize fs:16 ff:regular lt:2">
                        Sipariş verilen Kanal
                      </p>
                    </div>
                    <div className="col">
                      <p className="c:dark:4 fs:16 ff:bold">{"TuruncuHat"}</p>
                    </div>
                  </div>
                </div>
              </li>
              <li className="col">
                <div className="listInfo__item">
                  <div className="row row-cols-1 row-cols-md-auto gy-2 gy-md-0 gx-0 justify-content-between text-center align-items-center">
                    <div className="col">
                      <p className="c:dark text-capitalize fs:16 ff:regular lt:2">
                        Toplam Ürün Sayısı
                      </p>
                    </div>
                    <div className="col">
                      <p className="c:dark:4 fs:16 ff:bold">
                        {order.productCount}
                      </p>
                    </div>
                  </div>
                </div>
              </li>
              {!isPreorder && isRateable && order && (
                <li className="col">
                  <div className="listInfo__item">
                    <div className="row row-cols-1 row-cols-md-auto gy-2 gy-md-0 gx-0 justify-content-between text-center align-items-center">
                      <div className="col">
                        <p className="c:dark text-capitalize fs:16 ff:regular lt:2">
                          Değerlendirme
                        </p>
                      </div>
                      <div className="col">
                        <StarsRating
                          orderId={order.id}
                          isDisabled={true}
                          currentRating={order.rating}
                        />
                      </div>
                    </div>
                  </div>
                </li>
              )}
            </ul>
          </div>
          {!isPreorder && (
            <div className="d-flex justify-content-center">
              <RepeatOrder orderId={order.id.toString()} />
            </div>
          )}
          {error ? (
            <div className="mt-3">
              <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-3 justify-content-center">
                <div className="col">
                  <div
                    className="alert bg:red:2 p-3 radius:12 mb-3 mb-sm-4"
                    style={
                      {
                        "--alert-borderColor": "rgba(243, 52, 52, 0.22)",
                      } as React.CSSProperties
                    }
                  >
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0">
                        <img src={iconInfo} alt="" />
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <p className="c:red lt:2 ff:regular fs:14">{error}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default OrderDetail;
