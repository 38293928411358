import React, { useRef } from 'react';
import useOnClickOutside from 'hooks/useOnClickOutside';
import closeIcon from '@images/icon-close.svg';
import { IForm } from './Step1';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import remarkGfm from 'remark-gfm';

interface IProps {
  popupOpen: boolean;
  handlePopup: () => void;
  handleAccept: () => void;
  activePopup: string;
  activeText: string;
  isReaded: boolean;
  loading: boolean;
  setIsReaded: (val: boolean) => void;
  state: IForm;
}

const PopupConditions = ({ activePopup, activeText, popupOpen, handlePopup, handleAccept, isReaded, setIsReaded, loading, state, }: IProps) => {
  const popupRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(popupRef, () => popupOpen && handlePopup());

  const divRef = useRef<HTMLDivElement>(null);

  const handleScroll = (e: any) => {
    if (divRef.current) {
      const observer = new IntersectionObserver(([entry]) => {
        setIsReaded(entry.isIntersecting);
      });

      observer.observe(divRef.current);
      return () => observer.disconnect();
    }
  };
  return (
    <>
      {popupOpen ? (
        <div
          onScroll={(e) => handleScroll(e)}
          className='popupWrapper__content bg:white radius:20'
          ref={popupRef}
        >
          <div>
            <div
              className='popupWrapper__content-head d-flex align-items-center justify-content-between px-4 px-sm-5 radius:20 bg:white'>
              <div>
                <h1 className='ff:bold fs:16 c:dark lt:1'>
                  {activePopup === 'privacyPolicy'
                    ? 'KVKK AYDINLATMA METNİ'
                    : 'KULLANIM ŞARTLARI'}
                </h1>
              </div>
              <div className='ps-4'>
                <button
                  aria-label='close popup'
                  onClick={handlePopup}
                  className='popupWrapper__close buttonStyle buttonStyle--light buttonStyle--paddingElem radius:full bg:white shadow:4'
                  type='button'
                  style={{ '--btnPaddingElem': '0' } as React.CSSProperties}
                >
                  <img src={closeIcon} alt='' />
                </button>
              </div>
            </div>
            <div className='px-4 px-sm-5'>
              <section className='contentArea ff:light fs:16 c:dark'>
                <article style={{ whiteSpace: 'pre-wrap' }}>
                  <ReactMarkdown
                    children={activeText}
                    remarkPlugins={[remarkGfm]}
                  />
                </article>
              </section>
            </div>
            <div style={{ height: '30px', width: '100%' }} ref={divRef}></div>
            <div className='popupWrapper__content-footer d-flex align-items-center px-4 px-sm-5 radius:20 bg:white'>
              <button
                onClick={handleAccept}
                aria-label='decline'
                disabled={!isReaded || loading}
                className={`buttonStyle buttonStyle--border buttonStyle--submit c:dark ff:bold fs:16 bg:white d-block w-100 text-center radius:12 ${
                  !isReaded ? 'disabledBtn' : null
                }`}
                type='submit'
                style={
                  {
                    '--form-height': '3rem',
                    '--btn-border': '#3F4148',
                    '--btn-hover': '#3F4148',
                  } as React.CSSProperties
                }
              >
                Okudum,{' '}
                {activePopup === 'privacyPolicy'
                  ? state.privacyPolicy
                    ? 'Onaylamıyorum'
                    : 'Onaylıyorum'
                  : activePopup === 'termOfUse' && state.termOfUse
                    ? 'Onaylamıyorum'
                    : 'Onaylıyorum'}
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default PopupConditions;
