import React, { useContext, useRef } from "react";
import iconClose from "@images/icon-close.svg";
import useOnClickOutside from "hooks/useOnClickOutside";
import { BodyContext } from "contexts/BodyContext/BodyContext";
import DOMPurify from "dompurify";

interface IProps {
  productName?: string;
  popupText: string;
  approve: () => void;
  popupOpen: boolean;
  icon: string;
  apiError: string;
  buttonText?: string;
  loading?: boolean;
  setPopupOpen?: (val: boolean) => void;
}

const PopupApprove = ({
  productName,
  popupOpen,
  approve,
  popupText,
  icon,
  apiError,
  buttonText,
  loading,
  setPopupOpen,
}: IProps) => {
  const popupRef = useRef<HTMLDivElement>(null);

  const { data, handlePopup } = useContext(BodyContext);

  useOnClickOutside(popupRef, () =>
    popupOpen && setPopupOpen
      ? setPopupOpen(false)
      : handlePopup(
          "",
          data
            ? {
                qty: data?.cartItem?.quantity?.amount ?? null,
                item: data?.productId ?? null,
              }
            : null
        )
  );

  return (
    <section className="popupWrapper popupWrapper--sm animate__animated animate__fadeIn animate__faster">
      <div ref={popupRef} className="popupWrapper__content bg:white radius:20">
        <div className="popupWrapper__content-head d-flex align-items-center justify-content-between px-3 px-sm-4 radius:20 bg:white">
          <div>{apiError}</div>
          <div className="ps-4">
            <button
              onClick={() =>
                popupOpen && setPopupOpen
                  ? setPopupOpen(false)
                  : handlePopup(
                      "",
                      data
                        ? {
                            qty: data?.cartItem?.quantity?.amount ?? null,
                            item: data?.productId ?? null,
                          }
                        : null
                    )
              }
              aria-label="close popup"
              className="popupWrapper__close buttonStyle buttonStyle--light buttonStyle--paddingElem radius:full bg:white shadow:4"
              type="button"
              style={{ "--btnPaddingElem": "0" } as React.CSSProperties}
            >
              <img src={iconClose} alt="" />
            </button>
          </div>
        </div>
        <div className="px-3 px-sm-4 pb-3 pb-sm-4">
          <div className="row row-cols-1 text-center gx-0 gy-3">
            <div className="col">
              <div className="iconSize:32">
                <img className="mx-auto" src={icon} alt="" />
              </div>
            </div>
            <div className="col">
              <p className="ff:regular c:dark fs:16">
                {productName ? (
                  <strong className="c:orange d-block mb-1">
                    {productName}
                  </strong>
                ) : null}
                {popupText ? (
                  <span
                    className="d-block"
                   dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(popupText) }}
                  ></span>
                ) : null}
              </p>
            </div>
            <div className="col">
              <div className="row gx-2 gx-sm-3 gy-0">
                <div className="col">
                  <button
                      disabled={loading}
                      onClick={() => approve()}
                      aria-label="Popup Button"
                      className={`buttonStyle hover:opacity:8 buttonStyle--submit c:white ff:bold fs:14 bg:orange d-flex align-items-center justify-content-center w-100 radius:12 ${
                        loading ? "spinnerElem" : null
                      }`}
                      style={{ "--form-height": "2.8rem" } as React.CSSProperties}
                    >
                      {buttonText ?? "Kaldır"}
                    </button>
                </div>
                <div className="col">
                  <button
                    onClick={() =>
                      popupOpen && setPopupOpen
                        ? setPopupOpen(false)
                        : handlePopup(
                            "",
                            data
                              ? {
                                  qty: data?.cartItem?.quantity?.amount ?? null,
                                  item: data?.productId ?? null,
                                }
                              : null
                          )
                    }
                    aria-label="Popup Button"
                    className="buttonStyle hover:opacity:8 buttonStyle--submit c:white ff:bold fs:14 bg:orange:8 d-flex align-items-center justify-content-center w-100 radius:12"
                    style={{ "--form-height": "2.8rem" } as React.CSSProperties}
                  >
                    Vazgeç
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PopupApprove;
