import { ICategory } from "types/categoryTypes";
import { IProductAll } from "types/productTypes";
import { uniqueBy } from "utility/uniquBy";
import { IBrands } from "./ProductsFilter";

interface IProps {
  parentCategory: ICategory | undefined;
  childCategoryCode: string;
  sortBy: string;
  searchQuery: string;

  products: IProductAll[];
}
export const filterProducts = ({
  parentCategory,
  sortBy,
  childCategoryCode,
  products,
  searchQuery,
}: IProps) => {
  const children = parentCategory?.children.map((item: ICategory) => item.code);
  let data = products?.filter(
    (product) => product.displayBrandName && product.displayProductForm
  );
  data = products?.filter(
    (item) =>
      item.categoryCode === childCategoryCode || 
      (childCategoryCode === "all" && item.categoryCode === "") || 
      (children?.includes(item.categoryCode) && childCategoryCode === "all" )
  );

  if (sortBy === "aZ") {
    data = data.sort(function (a: { brand: string }, b: { brand: string }) {
      return a.brand
        .toLocaleLowerCase("tr")
        .localeCompare(b.brand, "tr", { sensitivity: "base" });
    });
  }

  if (sortBy === "zA") {
    data = data.sort(function (a: { brand: string }, b: { brand: string }) {
      return b.brand
        .toLocaleLowerCase("tr")
        .localeCompare(a.brand, "tr", { sensitivity: "base" });
    });
  }

  const brands = data.map((item) => {
    if (item.brand.length) {
      const data: IBrands = {
        code: item.brandCode,
        id: item.formCode,
        name: item.brand,
        logo: null,
      };
      return data;
    } else {
      const data2: IBrands = {
        code: "x",
        id: item.formCode,
        name: "x",
        logo: null,
      };
      return data2;
    }
  });

  const brandsData = uniqueBy(brands, (el) => el.code);

  if (searchQuery.length) {
    data = data.filter((elem) => {
      if (searchQuery.length) {
        return (
          elem.displayProductForm
            .toLowerCase()
            ?.includes(searchQuery.toLowerCase()) ||
          elem.displayBrandName
            .toLowerCase()
            ?.includes(searchQuery.toLowerCase()) ||
          elem.barcode?.includes(searchQuery) ||
          elem.code?.includes(searchQuery)
        );
      }
      return true;
    });
  }

  return {
    sortedData: data,
    brandsData: brandsData.sort(),
  };
};
