import { createContext } from "react";
import { IUser } from "../../hooks/useUser";

interface IAuthContext {
  user: IUser | null;
  handleLogin: (data: any) => void;
  handleNotification?: (data: any) => void;
  notificationType?: string;
}

export const AuthContextSite = createContext<IAuthContext>({
  user: null,
  handleLogin: () => {},
  handleNotification: () => {},
  notificationType: "",
});
