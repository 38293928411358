import React, { useCallback, useEffect, useState } from "react";
import { useBeforeUnload, useLocation } from "react-router-dom";
import useApi from "hooks/useApi";
import { PublicContext } from "./PublicContext";
import { getApplicationSettings } from "services/applicationSettings.service";
import { diffDate } from "utility/formatDate";
import { useTealium } from "hooks/useTealium";
/* eslint-disable react-hooks/exhaustive-deps */
const PublicProvider = ({ children }: { children: React.ReactElement }) => {
  const { handleApi, data, success, loading, error, setError } = useApi();
  const tealium = useTealium();
  const [siteTitle, setSiteTitle] = useState<string>("");
  const [startDate, setStartDate] = useState(new Date());
  const location = useLocation();
  const [prevPage, setPrevPage] = useState(location.pathname + location.search);

  useEffect(() => {
    setError("");
    handleApi(getApplicationSettings, {});
    return () => {};
  }, []);

  useBeforeUnload(
    useCallback(() => {
      localStorage.stuff = diffDate(startDate);
      tealium.viewEvent({
        timeCountOnPrevPage: diffDate(startDate),
        event: "Page_Close_Or_Refresh",
        tealium_event: "page_view",
        title: siteTitle.trim() + " - TuruncuHat",
      });
    }, [startDate])
  );

  useEffect(() => {
    if (siteTitle) {
      tealium.viewEvent({
        timeCountOnPrevPage: diffDate(startDate),
        event: "Page_Title_Load",
        tealium_event: "page_view",
        prevPage: prevPage,
        title: siteTitle.trim() + " - TuruncuHat",
      });
      setPrevPage(location.pathname + location.search);
      setStartDate(new Date());
    }
    return () => {};
  }, [siteTitle]);

  // useEffect(() => {
  //   tealium.viewEvent({
  //     site: "TuruncuHat",
  //     event: "Page_First_Load",
  //     tealium_event: "page_view",
  //     prevPage: "null",
  //     title: siteTitle + " - TuruncuHat",
  //   });
  // }, [])

  return (
    <PublicContext.Provider
      value={{
        loading,
        success,
        error,
        settings: data,
        setSiteTitle,
        siteTitle,
      }}
    >
      {children}
    </PublicContext.Provider>
  );
};

export default PublicProvider;
