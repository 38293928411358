import React from "react";
import image1 from "@images/neler-yapabiliriz.png";
import image2 from "@images/neler-yapabiliriz-2.png";
import image3 from "@images/neler-yapabiliriz-3.png";
import image4 from "@images/neler-yapabiliriz-4.png";

const Section3 = () => {
  return (
    <section className="landingInfo scrollMargin" id="section-2">
      <div className="bg:light">
        <div className="py-5">
          <div className="py-0 py-sm-5">
            <div className="container">
              {/*<p className="c:orange ff:bold fs:13 lt:1 text-uppercase text-center">*/}
              {/*  TuruncuHat ile Neler Yapabilirsiniz?*/}
              {/*</p>*/}
              <h1 className="c:dark ff:bold fs:44 text-center lh:3 mt-2">
                Hepsi ve daha fazlası{" "}
                <span className="bg:gradient:orange clipText">
                  TuruncuHat’ta
                </span>
              </h1>
              <div className="seperatorLine mx-auto mt-3"></div>
              {/*<div className="seperatorLine mx-auto mt-3 mb-5"></div>*/}
              {/*<div className="row row-cols-1 row-cols-sm-2 row-cols-xl-4 gy-3 gy-sm-4 gy-xl-0">*/}
              {/*  <div className="col d-flex">*/}
              {/*    <div className="landingInfo__item bg:white radius:18 p-4 text-center text-sm-start">*/}
              {/*      <img*/}
              {/*        className="landingInfo__item-icon"*/}
              {/*        src={image1}*/}
              {/*        alt=""*/}
              {/*      />*/}
              {/*      <div className="my-3 pt-2">*/}
              {/*        <p className="ff:bold c:dark fs:18">*/}
              {/*          TuruncuHat’a Özel Avantajları Yakalayın*/}
              {/*        </p>*/}
              {/*      </div>*/}
              {/*      <p className="ff:light c:dark fs:16">*/}
              {/*        GSK’nın tüm kampanyalarına ek olarak sadece TuruncuHat’ta özel*/}
              {/*        kampanyalardan yararlanırken sipariş durumunuzu canlı takip edin.*/}
              {/*      </p>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*  <div className="col d-flex">*/}
              {/*    <div className="landingInfo__item bg:white radius:18 p-4 text-center text-sm-start">*/}
              {/*      <img*/}
              {/*        className="landingInfo__item-icon"*/}
              {/*        src={image2}*/}
              {/*        alt=""*/}
              {/*      />*/}
              {/*      <div className="my-3 pt-2">*/}
              {/*        <p className="ff:bold c:dark fs:18">*/}
              {/*          Turuncu Blog ile Sektör Öncüsü Olun*/}
              {/*        </p>*/}
              {/*      </div>*/}
              {/*      <p className="ff:light c:dark fs:16">*/}
              {/*        Canlı yayınlar, videolar ve zengin içerikler ile hep bir adım önde*/}
              {/*        olurken medikal bilgilerinizi de hep güncel tutun.*/}
              {/*      </p>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*  <div className="col d-flex">*/}
              {/*    <div className="landingInfo__item bg:white radius:18 p-4 text-center text-sm-start">*/}
              {/*      <img*/}
              {/*        className="landingInfo__item-icon"*/}
              {/*        src={image3}*/}
              {/*        alt=""*/}
              {/*      />*/}
              {/*      <div className="my-3 pt-2">*/}
              {/*        <p className="ff:bold c:dark fs:18">*/}
              {/*          Güncel Haberleri ve Sektör Gelişmeleri Kaçırmayın*/}
              {/*        </p>*/}
              {/*      </div>*/}
              {/*      <p className="ff:light c:dark fs:16">*/}
              {/*        Mesleki gelişmeleri yakından takip edin. Önemli gelişmelerden anında*/}
              {/*        haberdar olun.*/}
              {/*      </p>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*  <div className="col d-flex">*/}
              {/*    <div className="landingInfo__item bg:white radius:18 p-4 text-center text-sm-start">*/}
              {/*      <img*/}
              {/*        className="landingInfo__item-icon"*/}
              {/*        src={image4}*/}
              {/*        alt=""*/}
              {/*      />*/}
              {/*      <div className="my-3 pt-2">*/}
              {/*        <p className="ff:bold c:dark fs:18">*/}
              {/*          Tüm İhtiyaçlarınız İçin Bize Doğrudan Ulaşın*/}
              {/*        </p>*/}
              {/*      </div>*/}
              {/*      <p className="ff:light c:dark fs:16">*/}
              {/*        Her türlü soru işaretinde ve destek ihtiyacınızda ekibimize anında*/}
              {/*        erişin.*/}
              {/*      </p>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section3;
