import { createContext } from "react";

interface IAppState {
  sendRequest: (id: string) => void;
  completed: (id: string) => void;
  pendingRequests: string[] | [];
  loading: boolean;
}

// Create the context
export const AppState = createContext<IAppState>({
  sendRequest: () => {},
  completed: () => {},
  pendingRequests: [],
  loading: false,
});
AppState.displayName = "AppState";
