import { useCallback, useEffect, useState } from "react";
import OrderDetail from "./OrderDetail";
import { Order } from "types/Order";
import OrderSearch from "views/MyAccount/Orders/OrderSearch";
import NoData from "@components/General/NoData";
import OrderSkeletonDetail from "./OrderSkeletonDetail";
import { useLocation } from "react-router-dom";

interface IProps {
  orders: Order[] | [];
  isPreorder: boolean;
  loading: boolean;
  title?: string;
  isRateable?: boolean;
}

const addSearchPhrase = (order: Order) => {
  return {
    ...order,
    searchPhrase: [order.code, order.warehouseName, order.id]
      .concat(order.items.map((item) => item.displayBrandName))
      .concat(order.items.map((item) => item.displayProductForm))
      .concat(order.items.map((item) => item.id))
      .join("+"),
  };
};

function OrderList({ title, loading, orders, isPreorder, isRateable }: IProps) {
  const [filtered, setFiltered] = useState<any>();
  const [selected, setSelected] = useState("");

  const onSelectionChanged = (itemId: string) => {
    if (itemId === selected) {
      setSelected("");
    } else {
      setSelected(itemId);
    }
  };

  const handleSearch = useCallback(
    (searchQuery: string) => {
      if (orders) {
        const mapped = orders.map((order) => addSearchPhrase(order));
        var collection = mapped.filter((elem) => {
          if (searchQuery.length) {
            return elem.searchPhrase
              .toLocaleLowerCase()
              .includes(searchQuery.toLowerCase());
          }
          return true;
        });
        setFiltered(collection);
      }
    },
    [orders]
  );

  useEffect(() => {
    setFiltered(orders);
  }, [orders]);

  let { state } = useLocation();

  useEffect(() => {
    if (state && state.orderId) {
      handleSearch(state.orderId);
      setSelected(state.orderId);
    }

    return () => {};
  }, [state, handleSearch]);

  return (
    <>
      {title && (
        <OrderSearch
          title={title}
          loading={loading}
          count={filtered?.length}
          onSearchChanged={handleSearch}
        />
      )}
      {loading ? (
        <div className="row row-cols-1 gx-0 gy-3">
          <OrderSkeletonDetail />
          <OrderSkeletonDetail />
          <OrderSkeletonDetail />
        </div>
      ) : filtered?.length ? (
        <div className="row row-cols-1 gx-0 gy-3">
          {filtered.map((order: Order) => (
            <div key={order.id} className="col">
              <OrderDetail
                order={order}
                isPreorder={isPreorder}
                selected={selected}
                onSelectionChanged={onSelectionChanged}
                isRateable={isRateable}
              />
            </div>
          ))}
        </div>
      ) : (
        !loading && <NoData text="Sipariş bulunamadı." />
      )}
    </>
  );
}

export default OrderList;
