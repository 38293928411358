import React, { useContext, useEffect, useState } from 'react';
import { IOfferItem, IProductAll } from 'types/productTypes';
import { Link } from 'react-router-dom';
import iconStarFill from '@images/icon-star-fill.svg';
import iconHour from '@images/icon-hour.svg';
import iconInfo from '@images/icon-info.svg';
import { CartContext } from 'contexts/CartContext/CartContext';
import SelectAnlik from '@components/General/SelectAnlik';
import HeartButtonAllProducts from '@components/General/HeartButtonAllProducts';
import AddButton from '@components/Cart/AddButton';
import { ICartItem } from 'types/shoppingCartTypes';
import moment from 'moment-timezone';

interface IProps {
  product: IProductAll;
  activeItem: string;
  setActiveItem: (id: string) => void;
}

const AnlikKampanyalarProduct = ({
                                   product,
                                   activeItem,
                                   setActiveItem,
                                 }: IProps) => {
  const { cart, error } = useContext(CartContext);
  const [selectedOffer, setSelectedOffer] = useState<IOfferItem | undefined>();
  const [itemInCart, setItemInCart] = useState<ICartItem>();

  useEffect(() => {
    setItemInCart(
      cart?.items.find(
        (item) => item.productId === product.id && item.preOrder === false,
      ),
    );
  }, [cart, product.id]);

  const date = product.offers.map(item => item.endDate)[0];

  const eventTime = moment.tz(date, 'Europe/Istanbul');

  const currentTime = moment.tz('Europe/Istanbul');

  const [duration, setDuration] = useState<any>(
    moment.duration(eventTime.diff(currentTime)),
  );

  useEffect(() => {
    if (duration) {
      const intervalId = setInterval(() => {
        // get updated duration
        setDuration(moment.duration(duration - 1000, 'milliseconds'));

        // if duration is >= 0
        if (duration.asSeconds() <= 0) {
          clearInterval(intervalId);
          setDuration(null);
        }
      }, 1000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [duration]);

  return (
    <>
      {duration ? (
        <div className='col'>
          <div className='productListCard h-100'>
            <div className='border:1 bg:white radius:12 p-3 shadow:4 w-100 h-100'>
              <div className='productListCard__box'>
                <div
                  className='d-flex justify-content-between align-items-center mb-3 heightElem'
                  style={{ '--heightElem': '2rem' } as React.CSSProperties}
                >
                  <div>
                    <div className='row row-cols-auto gy-0 gx-1'>
                      {product?.label?.includes('PreOrder') && (
                        <div className='col'>
                          <div
                            className='widthElem heightElem bg:orange:5 radius:full d-flex align-items-center justify-content-center iconSize:16'
                            style={
                              {
                                '--widthElem': '2rem',
                                '--heightElem': '2rem',
                              } as React.CSSProperties
                            }
                          >
                            <img src={iconHour} alt='' />
                          </div>
                        </div>
                      )}

                      {product?.label?.includes('Turuncu') && (
                        <div className='col'>
                          <div
                            className='widthElem heightElem bg:orange radius:full d-flex align-items-center justify-content-center iconSize:16'
                            style={
                              {
                                '--widthElem': '2rem',
                                '--heightElem': '2rem',
                              } as React.CSSProperties
                            }
                          >
                            <img src={iconStarFill} alt='' />
                          </div>
                        </div>
                      )}
                      <div className='col'>
                        <div
                          className='bg:yellow:4 c:white fs:13 ff:regular radius:5 paddingElem'
                          style={
                            {
                              '--paddingElem': '0.4rem 0.813rem',
                            } as React.CSSProperties
                          }
                        >
                          <p>
                            {duration.days() !== 0 && (
                              <>
                                <strong>{duration.asDays().toFixed(0)}</strong> gün{' '}
                              </>
                            )}
                            <strong>{duration.hours()}</strong> saat{' '}
                            <strong>{duration.minutes()}</strong> dakika{' '}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='ms-auto'>
                    <HeartButtonAllProducts product={product} />
                  </div>
                </div>
                <Link
                  className='productHover'
                  aria-label='link card'
                  to={`/urun/${product.id}`}
                >
                  <div className='productListCard__box-img'>
                    <img
                      src={
                        product?.thumbnail
                          ? `data:${product?.thumbnail?.contentType};base64,${product?.thumbnail?.fileContents}`
                          : '/brokenImage.svg'
                      }
                      alt={
                        product?.thumbnail?.contentType
                          ? product?.thumbnail?.contentType
                          : 'alttext'
                      }
                    />
                  </div>
                  <div className='heightElem' style={
                    {
                      '--heightElem': '4.375rem',
                    } as React.CSSProperties
                  }>
                    <p className='c:dark ff:bold fs:16 text-center mt-3 mb-2 lh:1'>{product.displayBrandName}</p>
                    <p className='c:dark ff:regular fs:14 text-center'>  {product.displayProductForm}</p>
                  </div>
                </Link>
                {error?.length && activeItem === product?.id ? (
                  <div
                    className='alert bg:red:2 p-3 radius:12 mt-3'
                    style={
                      {
                        '--alert-borderColor': 'rgba(243, 52, 52, 0.22)',
                      } as React.CSSProperties
                    }
                  >
                    <div className='d-flex align-items-center'>
                      <div className='flex-shrink-0'>
                        <img src={iconInfo} alt='' />
                      </div>
                      <div className='flex-grow-1 ms-3'>
                        <p className='c:red lt:2 ff:regular fs:14'>{error}</p>
                      </div>
                    </div>
                  </div>
                ) : null}
                {itemInCart?.campaignLimitMessage ? (
                  <div
                    className='alert bg:red:2 p-3 radius:12 mt-3'
                    style={
                      {
                        '--alert-borderColor': 'rgba(243, 52, 52, 0.22)',
                      } as React.CSSProperties
                    }
                  >
                    <div className='d-flex align-items-center'>
                      <div className='flex-shrink-0'>
                        <img src={iconInfo} alt='' />
                      </div>
                      <div className='flex-grow-1 ms-3'>
                        <p className='c:red lt:2 ff:regular fs:14'>
                          {itemInCart?.campaignLimitMessage}
                        </p>
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className='my-3'>
                  <SelectAnlik
                    product={product}
                    selectedOffer={selectedOffer}
                    setSelectedOffer={setSelectedOffer}
                  />
                </div>
                <AddButton
                  setActiveItem={setActiveItem}
                  product={product}
                  selectedOffer={selectedOffer}
                  preOrder={false}
                  cartItem={itemInCart}
                  color='c:orange'
                  backgroundColor='bg:orange:2'
                />
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default AnlikKampanyalarProduct;
