import HealtCheck from "views/HealtCheck";
import LandingPage from "views/LandingPage/LandingPage";
import NotFound from "views/NotFound";
import RouteGuard from "./routeGuard";
import MainLayout from "layouts/mainLayout";
import Home from "views/Home/Home";
import Products from "views/Products/Products";
import ProductsList from "views/Products/ProductsList";
import ProductDetails from "views/ProductDetails/ProductDetails";
import Cart from "views/Cart/Cart";
import MyAccount from "views/MyAccount/MyAccount";
import Segment from "views/MyAccount/Segment/Segment";
import MyFavorites from "views/MyAccount/MyFavorites";
import Orders from "views/MyAccount/Orders/Orders";
import { Navigate, createBrowserRouter } from "react-router-dom";
import KatildigimTdk from "views/Kampanyalarim/KatildigimTdk";
import SoruVeTalepler from "views/MyAccount/SoruVeTaleplerim/SoruVeTalepler";
import PersonalInfo from "views/MyAccount/PersonalInfo";
import PasswordChange from "views/MyAccount/PasswordChange";
import ContactPreferences from "views/MyAccount/ContactPreferences";
import YeniTalep from "views/MyAccount/SoruVeTaleplerim/YeniTalep";
import TuruncuDonemsel from "views/TuruncuHat/TuruncuDonemsel";
import AnlikKampanyalar from "views/AnlikKampanyalar/AnlikKampanyalar";
import Blog from "views/Blog";
import BlogCategories from "views/Blog/BlogCategories";
import BlogDetay from "views/Blog/BlogDetay";
import BlogSearch from "views/Blog/BlogSearch";
import SearchDetails from "views/Search/SearchDetails";
import Stores from "views/Stores/Stores";
import StoreDetails from "views/Stores/StoreDetails";
import FavoriteStores from "views/Stores/FavoriteStores";
import GuestGuard from "./guestGuard";
import PublicLayout from "layouts/publicLayout";
import Login from "views/Auth/Login";
import LoginExternal from "views/Auth/LoginExternal";
import Register from "views/Auth/Register/Register";
import ForgotPassword from "views/Auth/ForgotPassword/ForgotPassword";
import QuickOrders from "views/MyAccount/Orders/QuickOrders";
import PreOrders from "views/MyAccount/Orders/PreOrders";
import OrdersHistory from "views/MyAccount/Orders/OrdersHistory";
import OrdersLayout from "views/MyAccount/Orders/OrdersLayout";
import Step1 from "@components/Auth/Register/Step1";
import Step2 from "@components/Auth/Register/Step2";
import Step3 from "@components/Auth/Register/Step3";
import Step4 from "@components/Auth/Register/Step4";
import Step5 from "@components/Auth/Register/Step5";
import BlogByLabel from "views/Blog/BlogByLabel";
import SSS from "views/FooterPages/SSS";
import Contact from "views/FooterPages/Contact";
import PrivacyPolicy from "views/FooterPages/PrivacyPolicy";
import TermsOfUsage from "views/FooterPages/TermsOfUsage";
import Notification from "views/Notification";
import ResetPassword from "views/Auth/ResetPassword/ResetPassword";
import BlogProvider from "contexts/BlogContext/BlogProvider";
import ErrorBoundary from '../views/ErrorBoundary';
import LandingLayout from '../layouts/LandingLayout';
import AutoGuard from './autoGuard';
import Quizs from '../views/Quizs/Quizs';
import Loyality from '../views/MyAccount/Loyality/Loyality';
import PointHistory from '../views/MyAccount/Loyality/PointHistory/PointHistory';
import ActiveQuiz from '../views/Quizs/ActiveQuiz';

export const router = createBrowserRouter([
  {
    element: (
      <GuestGuard>
        <PublicLayout />
      </GuestGuard>
    ),
    children: [{ path: "/", element: <LandingPage /> }],
  },
  {
    element: <HealtCheck />,
    path: "/hc",
  },
  {
    element: <NotFound />,
    path: "*",
  },
  {
    element: (
      <AutoGuard>
        {({ isAuth }: { isAuth: boolean }) => {
          if (!isAuth) {
            return <LandingLayout />;
          }
          return (
            <RouteGuard>
              <MainLayout />
            </RouteGuard>
          );
        }}
      </AutoGuard>
    ),
    children: [
      {
        errorElement: <ErrorBoundary />,
        path: "/iletisim",
        element: <Contact />,
      },
      {
        errorElement: <ErrorBoundary />,
        path: "/gizlilik-politikasi",
        element: <PrivacyPolicy />,
      },
      {
        errorElement: <ErrorBoundary />,
        path: "/kullanim-sartlari",
        element: <TermsOfUsage />,
      },
    ]
  },
  {
    element: (
      <RouteGuard>
        <MainLayout />
      </RouteGuard>
    ),
    children: [
      {
        errorElement: <ErrorBoundary />,
        path: "/anasayfa",
        element: (
          <BlogProvider>
            <Home />
          </BlogProvider>
        ),
      },
      {
        errorElement: <ErrorBoundary />,
        path: "/urunler",
        element: <Products />,
      },
      {
        errorElement: <ErrorBoundary />,
        path: "/urunler/liste",
        element: <ProductsList />,
      },
      {
        errorElement: <ErrorBoundary />,
        path: "/urun/:id",
        element: <ProductDetails />,
      },
      {
        errorElement: <ErrorBoundary />,
        path: "/sepet",
        element: <Cart />,
      },
      {
        errorElement: <ErrorBoundary />,
        path: "/sepet/:id",
        element: <Cart />,
      },
      {
        errorElement: <ErrorBoundary />,
        path: "/hesabim",
        element: <MyAccount />,
      },
      {
        errorElement: <ErrorBoundary />,
        path: "/hesabim/segment",
        element: <Segment />,
      },
      {
        errorElement: <ErrorBoundary />,
        path: "/hesabim/favorilerim",
        element: <MyFavorites />,
      },
      // {
      //   errorElement: <ErrorBoundary />,
      //   path: "/hesabim/sadakat-programi",
      //   element: <Loyality />,
      // },
      // {
      //   errorElement: <ErrorBoundary />,
      //   path: "/hesabim/sadakat-programi/puanlarim",
      //   element: <PointHistory />,
      // },
      {
        errorElement: <ErrorBoundary />,
        element: <OrdersLayout />,
        children: [
          {
            errorElement: <ErrorBoundary />,
            path: "/hesabim/on-siparislerim",
            element: <PreOrders />,
          },
          {
            errorElement: <ErrorBoundary />,
            path: "/hesabim/gecmis-siparislerim",
            element: <OrdersHistory />,
          },
          {
            errorElement: <ErrorBoundary />,
            path: "/hesabim/hizli-siparislerim",
            element: <QuickOrders />,
          },
          {
            errorElement: <ErrorBoundary />,
            path: "/hesabim/siparislerim",
            element: <Orders />,
          },
        ],
      },
      {
        errorElement: <ErrorBoundary />,
        path: "/hesabim/kampanyalarim",
        element: <KatildigimTdk />,
      },
      // {
      //   path: "/hesabim/kampanyalarim/katildigim-anlik-kampanyalar",
      //   element: <KatildigimAnlik />,
      // },
      {
        errorElement: <ErrorBoundary />,
        path: "/hesabim/soru-ve-taleplerim",
        element: <SoruVeTalepler />,
      },
      {
        errorElement: <ErrorBoundary />,
        path: "/hesabim/bilgilerim",
        element: <PersonalInfo />,
      },
      {
        errorElement: <ErrorBoundary />,
        path: "/hesabim/sifre",
        element: <PasswordChange />,
      },
      {
        errorElement: <ErrorBoundary />,
        path: "/hesabim/iletisim-tercihleri",
        element: <ContactPreferences />,
      },
      {
        errorElement: <ErrorBoundary />,
        path: "/hesabim/soru-ve-taleplerim/yeni-talep",
        element: <YeniTalep />,
      },
      {
        errorElement: <ErrorBoundary />,
        path: "/ayarlar",
        element: <Orders />,
      },
      {
        errorElement: <ErrorBoundary />,
        path: "/turuncu-donemsel-kampanyalar",
        element: <TuruncuDonemsel />,
      },
      // {
      //   path: "/turuncu-urunler",
      //   element: <TuruncuUrunler />,
      // },
      {
        errorElement: <ErrorBoundary />,
        path: "/anlik-kampanyalar",
        element: <AnlikKampanyalar />,
      },
      {
        errorElement: <ErrorBoundary />,
        path: "/blog",
        element: (
          <BlogProvider>
            <Blog />
          </BlogProvider>
        ),
      },
      {
        errorElement: <ErrorBoundary />,
        path: "/blog/:id",
        element: (
          <BlogProvider>
            <BlogCategories />
          </BlogProvider>
        ),
      },
      {
        errorElement: <ErrorBoundary />,
        path: "/blog/label/:id",
        element: (
          <BlogProvider>
            <BlogByLabel />
          </BlogProvider>
        ),
      },
      {
        errorElement: <ErrorBoundary />,
        path: "/blogdetay/:id",
        element: (
          <BlogProvider>
            <BlogDetay />
          </BlogProvider>
        ),
      },
      {
        errorElement: <ErrorBoundary />,
        path: "/tag",
        element: (
          <BlogProvider>
            <BlogSearch />
          </BlogProvider>
        ),
      },
      {
        errorElement: <ErrorBoundary />,
        path: "/ara",
        element: <SearchDetails />,
      },
      {
        errorElement: <ErrorBoundary />,
        path: "/depolar",
        element: <Stores />,
      },
      // {
      //   errorElement: <ErrorBoundary />,
      //   path: "/quizler",
      //   element: <Quizs />,
      // },
      // {
      //   errorElement: <ErrorBoundary />,
      //   path: "/quizler/aktif-quiz",
      //   element: <ActiveQuiz />,
      // },
      {
        errorElement: <ErrorBoundary />,
        path: "/depolar/:id",
        element: <StoreDetails />,
      },
      {
        errorElement: <ErrorBoundary />,
        path: "/depolar/favori-depolarim",
        element: <FavoriteStores />,
      },
      {
        errorElement: <ErrorBoundary />,
        path: "/sikca-sorulan-sorular",
        element: <SSS />,
      },
      {
        errorElement: <ErrorBoundary />,
        path: "/notification/:id",
        element: <Notification />,
      },
    ],
  },
  {
    element: (
      <GuestGuard>
        <PublicLayout />
      </GuestGuard>
    ),
    children: [
      {
        errorElement: <ErrorBoundary />,
        path: "/giris",
        element: <Login />,
      },
      {
        errorElement: <ErrorBoundary />,
        path: "/gsk-giris",
        element: <LoginExternal />,
      },
      {
        errorElement: <ErrorBoundary />,
        path: "/kayit-ol",
        element: <Navigate to="/kayit-ol/adim-1" replace />,
      },
      {
        errorElement: <ErrorBoundary />,
        element: <Register />,
        children: [
          {
            errorElement: <ErrorBoundary />,
            path: "/kayit-ol/adim-1",
            element: <Step1 />,
          },
          {
            errorElement: <ErrorBoundary />,
            path: "/kayit-ol/adim-2",
            element: <Step2 />,
          },
          {
            errorElement: <ErrorBoundary />,
            path: "/kayit-ol/adim-3",
            element: <Step3 />,
          },
          {
            errorElement: <ErrorBoundary />,
            path: "/kayit-ol/adim-4",
            element: <Step4 />,
          },
          {
            errorElement: <ErrorBoundary />,
            path: "/kayit-ol/adim-5",
            element: <Step5 />,
          },
        ],
      },
      {
        errorElement: <ErrorBoundary />,
        path: "/sifremi-unuttum",
        element: <ForgotPassword />,
      },
      {
        errorElement: <ErrorBoundary />,
        path: "/sifremi-degistir",
        element: <ResetPassword />,
      },
    ],
  },
]);
