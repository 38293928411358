import React from "react";
import { Link } from "react-router-dom";

const BreadCrumb = ({
  productId,
  productName,
}: {
  productId: string;
  productName: string;
}) => {
  return (
    <section className="breadcrumb my-4 my-md-5">
      <div className="container">
        <ul className="c:dark fs:14 lt:3">
          <li>
            <Link to={`/urunler`}>Ürünler</Link>
          </li>
          <li>
            <Link to={`/urun/${productId}`}>{productName}</Link>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default BreadCrumb;
