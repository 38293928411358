import React, {useEffect, useState} from "react";
import {BodyContext} from "./BodyContext";
import {getCategories} from "services/category.service";
import {getProductsForCards} from "services/product.service";
import {IProductAll} from "types/productTypes";
import {ErrorHandler} from "@components/General/ErrorHandler";
import {getProductDataInterval} from "../../utility/getProductDataInterval";

export interface ICategories {
    children: ICategories[];
    code: string;
    id: string;
    name: string;
    parentId: null | string;
    thumbnail: {
        altText: string;
        height: 96;
        order: 0;
        size: string;
        url: string;
        width: 72;
    };
}

interface IFetchData {
    loading: boolean;
    categories?: any;
    products: IProductAll[];
    error?: string;
    errorCategories?: string;
}

/* eslint-disable react-hooks/exhaustive-deps */
const BodyProvider = ({children}: { children: React.ReactElement }) => {
    const [customClass, setCustomClass] = useState("");
    const [activePopup, setActivePopup] = useState("");
    const [data, setData] = useState();

    const [fetchData, setFetchData] = useState<IFetchData>({
        loading: true,
        categories: [],
        products: [],
        error: "",
        errorCategories: "",
    });

    const handleBody = (text: string) => {
        setCustomClass(text);
    };

    const handlePopup = (type: string, popupData?: any) => {
        setActivePopup(type);
        if (popupData) {
            setData(popupData);
        }
    };

    const editProducts = (prodId: string, val: boolean) => {
        const newData = fetchData.products.map((item) =>
            item.id === prodId ? {...item, isFavorite: val} : item
        );
        setFetchData({...fetchData, products: newData});
    };

    const loadData = async () => {
        // setFetchData({ ...fetchData, error: "" });
        try {
            const products = await getProductsForCards();
            setFetchData((prev) => {
                return {...prev, loading: false, products: products};
            });
        } catch (error) {
            const {errorMessage} = ErrorHandler(error);
            setFetchData((prev) => {
                return {...prev, loading: false, error: errorMessage};
            });
        }
        try {
            const categories = await getCategories();
            setFetchData((prev) => {
                return {...prev, loading: false, categories};
            });
        } catch (error) {
            const {errorMessage} = ErrorHandler(error);

            setFetchData((prev) => {
                return {...prev, loading: false, errorCategories: errorMessage};
            });
        }
    };

    useEffect(() => {
        loadData();
        const intervalId = setInterval(loadData, getProductDataInterval());
        return () => clearInterval(intervalId);
    }, []);

    const {categories, products, loading, error, errorCategories} = fetchData;

    return (
        <BodyContext.Provider
            value={{
                handleBody,
                customClass,
                handlePopup,
                activePopup,
                data,
                categories,
                products,
                loading,
                editProducts,
                error,
                errorCategories,
            }}
        >
            {children}
        </BodyContext.Provider>
    );
};

export default BodyProvider;
