import { useReducer } from "react";
import { appReducer } from "./AppReducer";
import { AppState } from "./AppState";
import { Actions } from "./AppActions";

// Define the initial state
const INITIAL_STATE = {
  pendingRequests: [],
  loading: false,
};

export const AppProvider = ({ children }: any) => {
  // Create the reducer
  const [state, dispatch] = useReducer(appReducer, INITIAL_STATE);
  const value = {
    ...state,
    sendRequest: (id: string) => {
      dispatch({ type: Actions.SEND_REQUEST, id });
    },
    completed: (id: string) => {
      dispatch({ type: Actions.COMPLETED, id });
    },
  };
  // Wrap the context provider around our component
  return <AppState.Provider value={value}>{children}</AppState.Provider>;
};
