import React, { useContext, useEffect, useRef, useState } from "react";
import iconMinusInput from "@images/icon-minus-input.svg";
import iconPlusInput from "@images/icon-plus-input.svg";
import { ICartItem } from "types/shoppingCartTypes";
import { CartContext } from "contexts/CartContext/CartContext";
import { BodyContext } from "contexts/BodyContext/BodyContext";
import { IProductAll } from "types/productTypes";
/* eslint-disable react-hooks/exhaustive-deps */
interface IProps {
  setActiveProduct?: (prod: IProductAll) => void;
  activeProduct?: IProductAll | undefined;
  cartItem: ICartItem;
  product: IProductAll | undefined;
}

const BasketSelect = ({
  cartItem,
  product,
  activeProduct,
  setActiveProduct,
}: IProps) => {
  const [cartItemQty, setCartItemQty] = useState({
    qty: cartItem.quantity.amount,
    prodId: cartItem.productId,
  });
  const { handlePopup, data } = useContext(BodyContext);

  useEffect(() => {
    if (
      data &&
      data.qty &&
      activeProduct &&
      activeProduct.id === data.item &&
      cartItemQty.prodId === data.item
    ) {
      setCartItemQty({ qty: data.qty, prodId: data.item });
      handlePopup("", null);
    }
    return () => {};
  }, [data, activeProduct]);

  const {
    handleAddMoreItemToCart,
    success: cartSuccess,
    loadingPlusMinus: cartLoading,
    handleMinusFromCart,
    updateCartItem,
  } = useContext(CartContext);

  const handlePlus = (product: IProductAll) => {
    setActiveProduct!(product);
    handleAddMoreItemToCart!(cartItem);
  };
  const handleMinus = (product: IProductAll) => {
    setActiveProduct!(product);
    if (cartItem.quantity.amount - 1 === 0) {
      handlePopup("deleteFromCart", {
        cartItem: cartItem,
        product: product,
        productId: product.id,
      });
    } else {
      handleMinusFromCart!(cartItem, "");
    }
  };

  const inputRef = useRef<HTMLInputElement>(null);

  const handleInput = (e: any, product: IProductAll) => {
    const cartItemLogic =
      cartItem.quantity.amount === cartItemQty.qty &&
      cartItem.productId === cartItemQty.prodId;
    setActiveProduct!(product);
    e.preventDefault();
    if (cartItemLogic) {
      return;
    }
    if (cartItemQty.qty === 0) {
      handlePopup("deleteFromCart", {
        cartItem: cartItem,
        product: product,
        productId: product.id,
      });
      return;
    }
    if (e.code === "Enter") {
      inputRef.current?.blur();
    } else {
      if (cartItemQty && Number(cartItemQty.qty) > 0) {
        updateCartItem!(cartItem, Number(cartItemQty.qty));
      } else {
        handlePopup("deleteFromCart", {
          cartItem: cartItem,
          product: product,
          productId: product.id,
        });
      }
    }
  };

  useEffect(() => {
    if (cartSuccess) {
      setCartItemQty({
        qty: cartItem.quantity.amount,
        prodId: cartItem.productId,
      });
    }
  }, [cartSuccess, cartItem.quantity.amount]);

  return (
    <div
      className="formElementItem__group d-flex align-items-center border:3 shadow:4 radius:12"
      style={{ "--form-height": "3rem" } as React.CSSProperties}
    >
      {cartLoading && activeProduct?.id === cartItem.productId && (
        <div className="disableLoader__spin">
          <svg
            version="1.1"
            id="loader-1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="40px"
            height="40px"
            viewBox="0 0 50 50"
            enableBackground="new 0 0 50 50"
            xmlSpace="preserve"
          >
            <path
              fill="#000"
              d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
            >
              <animateTransform
                attributeType="xml"
                attributeName="transform"
                type="rotate"
                from="0 25 25"
                to="360 25 25"
                dur="0.6s"
                repeatCount="indefinite"
              />
            </path>
          </svg>
        </div>
      )}

      <button
        onClick={() => handleMinus(product!)}
        className="formElementItem__group-btn formElementItem__group-btn--minus"
        type="button"
        disabled={cartLoading}
        aria-label="minus"
        style={{ "--form-height": "3rem" } as React.CSSProperties}
      >
        <img className="mx-auto" src={iconMinusInput} alt="" />
      </button>
      <input
        ref={inputRef}
        autoComplete="off"
        className="formElementItem__group-input w-100 text-center ff:bold c:white fs:16"
        type="number"
        step="1"
        max=""
        onKeyDown={(evt) =>
          ["e", "E", "+", "-", ".", ","].includes(evt.key)
            ? evt.preventDefault()
            : evt.key === "Enter" && handleInput(evt, product!)
        }
        onChange={(e) =>
          setCartItemQty({
            qty: Number(e.target.value),
            prodId: cartItem.productId,
          })
        }
        onBlur={(e) => handleInput(e, product!)}
        // disabled={cartLoading}
        value={cartItemQty.qty.toPrecision()}
        name="bag"
        style={{ "--form-height": "3rem" } as React.CSSProperties}
      />
      <button
        onClick={() => handlePlus(product!)}
        disabled={cartLoading}
        className="formElementItem__group-btn formElementItem__group-btn--plus"
        type="button"
        aria-label="plus"
        style={{ "--form-height": "3rem" } as React.CSSProperties}
      >
        <img className="mx-auto" src={iconPlusInput} alt="" />
      </button>
    </div>
  );
};

export default BasketSelect;
