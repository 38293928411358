import React, { useState, useRef } from "react";
import moment from "moment";
import iconClose from "@images/icon-close.svg";
import useOnClickOutside from "hooks/useOnClickOutside";
import { IDetails, IKatilTdk } from "views/Kampanyalarim/KatildigimTdk";
import { convertDate } from "utility/formatDate";

interface IProps {
  showDetails: boolean;
  setShowDetails: (val: boolean) => void;
  details: IDetails;
  tdkData: IKatilTdk;
}

const TdkDetailsPopup = ({
  showDetails,
  setShowDetails,
  details,
  tdkData,
}: IProps) => {
  const [isOpen, setIsOpen] = useState(true);
  const calculateEnd = (finalDate: string) => {
    const now = moment();
    const final = moment(finalDate);
    const diff = final.diff(now);

    return diff > 0;
  };

  const popupRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(popupRef, () => showDetails && setShowDetails(false));

  const productPlan = details?.product.plans.find(
    (item) => item.Id === details.activePlan.planId
  );

  return (
    <section className="popupWrapper animate__animated animate__fadeIn animate__faster">
      <div ref={popupRef} className="popupWrapper__content bg:white radius:20">
        <div className="popupWrapper__content-head d-flex align-items-center justify-content-between px-4 radius:20 bg:white">
          <div>
            <h1 className="ff:bold fs:18 c:dark lh:2">Kampanya Detayı</h1>
          </div>
          <div className="ps-4">
            <button
              onClick={() => setShowDetails(false)}
              aria-label="close popup"
              className="popupWrapper__close buttonStyle buttonStyle--light buttonStyle--paddingElem radius:full bg:white shadow:4"
              type="button"
              style={{ "--btnPaddingElem": "0" } as React.CSSProperties}
            >
              <img src={iconClose} alt="" />
            </button>
          </div>
        </div>
        <div className="px-4 pb-4">
          <section className="planPopup">
            <div className="row row-cols-1 gx-0 gy-4">
              <div className="col">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <div className="circleArea">
                      <svg
                        width="100%"
                        height="100%"
                        viewBox="0 0 40 40"
                        className="donut"
                      >
                        <circle
                          className="donut-hole"
                          cx="20"
                          cy="20"
                          r="15.91549430918954"
                          fill="#fff"
                        ></circle>
                        <circle
                          className="donut-ring"
                          cx="20"
                          cy="20"
                          r="15.91549430918954"
                          fill="transparent"
                          strokeWidth="3"
                        ></circle>
                        <defs>
                          <linearGradient
                            id="greenLinear"
                            x1="1.40606"
                            y1="0"
                            x2="58"
                            y2="0"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#39BD8D" />
                            <stop offset="1" stopColor="#63D98B" />
                          </linearGradient>
                        </defs>
                        <defs>
                          <linearGradient id="orangeLinear" x1="29" y1="0" x2="29" y2="58" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#FE6260" />
                            <stop offset="1" stopColor="#FF8E33" />
                          </linearGradient>
                        </defs>
                        <circle
                          className="donut-segment"
                          cx="20"
                          cy="20"
                          r="15.91549430918954"
                          fill="transparent"
                          strokeWidth="3"
                          strokeDasharray={`${details?.activePlan.percentage} ${
                            100 - details!.activePlan.percentage
                          }`}
                          strokeDashoffset="25"
                          stroke={`url(#${
                            details?.activePlan.percentage === 100
                              ? "greenLinear"
                              : "orangeLinear"
                          })`}
                        ></circle>
                        <g className="donut-text">
                          <text y="50%" transform="translate(0, 2)">
                            <tspan
                              x="50%"
                              y="53.5%"
                              dominantBaseline="auto"
                              textAnchor="middle"
                              className="donut-percent"
                              style={{
                                fill:
                                  details?.activePlan.percentage === 0
                                    ? "#aaa"
                                    : details?.activePlan.percentage === 100
                                    ? "#39BD8D"
                                    : "#F46700",
                              }}
                            >
                              &#xfe6a;{details?.activePlan.percentage}
                            </tspan>
                          </text>
                        </g>
                      </svg>
                    </div>
                  </div>
                  <div className="flex-grow-1 ms-2">
                    <p className="c:dark fs:14 ff:regular text-uppercase">
                      {details && details.product.displayName}
                    </p>
                    <p className="c:orange fs:14 ff:bold text-uppercase mt-1">
                      {details && details.activePlan.planName}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="row gx-0 gy-2 gy-sm-0 row-cols-1 row-cols-sm-auto justify-content-between">
                  <div className="col">
                    <div
                      className="bg:green:3 shadow:4 c:dark ff:regular fs:13 d-inline-block borderStyle radius:12 paddingElem"
                      style={
                        {
                          "--gsk-border-width": "0.063rem",
                          "--gsk-border-color": "#39BD8D",
                          "--paddingElem": "0.563rem 1.25rem",
                        } as React.CSSProperties
                      }
                    >
                      Aktif Plan
                    </div>
                  </div>
                  <div className="col">
                    <div className="d-flex align-items-center">
                      <div
                        className="bg:orange:9 shadow:4 c:orange ff:bold fs:13 d-inline-block borderStyle radius:12 paddingElem"
                        style={
                          {
                            "--gsk-border-width": "0.063rem",
                            "--gsk-border-color": "#FE9744",
                            "--paddingElem": "0.563rem 1.25rem",
                          } as React.CSSProperties
                        }
                      >
                        {details?.activePlan.performedQuantity}/
                        {details?.activePlan.targetQuantity}
                      </div>
                      <div
                        className="bg:orange:9 shadow:4 c:orange ff:bold fs:13 d-inline-block borderStyle radius:12 paddingElem ms-2"
                        style={
                          {
                            "--gsk-border-width": "0.063rem",
                            "--gsk-border-color": "#FE9744",
                            "--paddingElem": "0.563rem 1.25rem",
                          } as React.CSSProperties
                        }
                      >
                        <div className="d-flex">
                          <div className="me-1">
                            <p className="c:dark">KAZANIM</p>
                          </div>
                          <div> {details?.activePlan.mfQuantity} KUTU </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <p className="ff:bold c:dark fs:16 mb-2">Depo</p>
                <div
                  className="bg:orange:11 borderStyle radius:12 shadow:9 p-3"
                  style={
                    {
                      "--gsk-border-width": "0.5px",
                      "--gsk-border-color": "#FFD278",
                    } as React.CSSProperties
                  }
                >
                  <p className="c:dark fs:14 ff:regular">
                    {tdkData.warehouseName}
                  </p>
                </div>
              </div>
              <div className="col">
                <div className="row row-cols-1 text-center row-cols-sm-auto justify-content-between align-items-center gx-0 gy-1 gy-sm-0">
                  <div className="col">
                    <p className="c:dark:2 fs:16 ff:bold">
                      Kampanya bitiş tarihi:{" "}
                      {convertDate(tdkData.campaignEndDate)}
                    </p>
                  </div>
                  <div className="col">
                    <p className="fs:16 c:orange ff:bold">
                      {calculateEnd(tdkData.finalApplicationDate)
                        ? "Devam ediyor."
                        : "Kampanyaya katılım sonlandı."}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <section className="collapsePopup">
                  <ul className="collapseLayout">
                    <li
                      onClick={() => setIsOpen(!isOpen)}
                      className={`collapseLayout__item ${
                        isOpen ? "activePlan" : ""
                      } ${isOpen ? "collapseActiveItem" : ""}`}
                    >
                      <h1
                        className="collapseLayout__thumb d-flex align-items-center py-3 px-3 pe-5 ff:regular fs:14 c:dark bg:white radius:12 borderStyle shadow:4 text-uppercase"
                        style={
                          {
                            "--gsk-border-width": "0.031rem",
                            "--gsk-border-color": "#d7d7d7",
                          } as React.CSSProperties
                        }
                      >
                        <div className="d-block d-sm-flex align-items-center">
                          <span className="pe-3 d-block">
                            {details?.activePlan.planName}
                          </span>
                        </div>
                      </h1>
                      <div
                        className={`collapseLayout__panel ${
                          isOpen ? "" : "d-none"
                        }`}
                      >
                        <div className="my-3 p-3 radius:12 border:1">
                          <div className="mb-3">
                            <table>
                              <tbody>
                                <tr>
                                  <td>Plan</td>
                                  <td>{details?.activePlan.planName}</td>
                                </tr>
                                <tr>
                                  <td>MF Dahil Toplam Alım</td>
                                  <td>{productPlan?.target}</td>
                                </tr>
                                <tr>
                                  <td>Anamal Alımına Verilecek Ek MF Oranı</td>
                                  <td>%{productPlan?.percentageFreeGoods}</td>
                                </tr>
                                <tr>
                                  <td>
                                    Ek MF Hak Edişi İçin Azami Alım Limiti
                                  </td>
                                  <td>{productPlan?.limit}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </section>
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};

export default TdkDetailsPopup;
