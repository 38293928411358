import React, { useContext, useEffect, useReducer, useState } from "react";
import infoicon from "@images/icon-info.svg";
import { z } from "zod";
import phoneImage from "@images/icon-phone.svg";
import StepArrrows from "./StepArrrows";
import useApi from "hooks/useApi";
import { sendSmsValidation, mobileConfirmByEmail } from "services/user.service";
import {
  phoneValidation,
  smsCodeValidation,
} from "validations/AuthValidations";
import useSecondApi from "hooks/useSecondApi";
import {
  IRegisterState,
  RegisterContext,
} from "contexts/RegisterContext/RegisterContext";
import Skeleton from "react-loading-skeleton";
import { notificationTexts } from "utility/notificationTexts";
import { useTimer } from "hooks/useTimer";
import { formatInput } from "utility/FormatInput";
import Field from "@components/Form/Field";
import ButtonContinue from "./ButtonContinue";
import { PublicContext } from "contexts/PublicContext/PublicContext";
import { useNavigate } from "react-router-dom";
import { ErrorHandler } from "@components/General/ErrorHandler";

export interface IForm {
  mobile: string;
  smsCode: string;
}

const initialForm: IForm = {
  mobile: "",
  smsCode: "",
};
/* eslint-disable react-hooks/exhaustive-deps */
const Step4 = () => {
  const [disabled, setDisabled] = useState(true);

  const { user, token } = useContext(RegisterContext) as IRegisterState;

  const [state, dispatch] = useReducer(
    (state: IForm, updates: any) => ({ ...state, ...updates }),
    initialForm
  );
  const { error: apiError, loading, setError: setApiError } = useApi();

  const { secondError, secondSuccess, setSecondError, handleSecondApi } =
    useSecondApi();

  const navigate = useNavigate();

  const [error, setError] = useState("");
  const [smsSended, setSmsSended] = useState(false);
  const {
    settings,
    error: settingsError,
    success: successSettings,
    setSiteTitle,
  } = useContext(PublicContext);

  useEffect(() => {
    setSiteTitle("Telefonu Doğrula");
  }, []);

  const { time, setStatus, reStartTimer } = useTimer(
    !settingsError.length && successSettings
      ? Number(
          settings.find((item) => item.name === "REGISTER_VERIFY_PHONE_TIMER")
            ?.value
        )
      : notificationTexts.REGISTER_VERIFY_PHONE_TIMER
  );

  // const handleSendSmsCode = async (e: { preventDefault: () => void }) => {
  //   e.preventDefault();
  //   await handleApi(sendSmsValidation, {
  //     mobile: user?.mobile,
  //     email: user?.email,
  //     token: token,
  //   });
  //   reStartTimer(
  //     !settingsError.length && successSettings
  //       ? Number(
  //           settings.find((item) => item.name === "REGISTER_VERIFY_PHONE_TIMER")
  //             ?.value
  //         )
  //       : notificationTexts.REGISTER_VERIFY_PHONE_TIMER
  //   );
  // };

  const sendValidation = async () => {
    try {
      if (user?.mobile) {
        setSmsSended(true);
        await sendSmsValidation({
          mobile: user?.mobile,
          email: user?.email,
          token: token,
        });
        reStartTimer(
          !settingsError.length && successSettings
            ? Number(
                settings.find(
                  (item) => item.name === "REGISTER_VERIFY_PHONE_TIMER"
                )?.value
              )
            : notificationTexts.REGISTER_VERIFY_PHONE_TIMER
        );
      }
    } catch (error) {
      setSmsSended(false);
      const { errorMessage } = ErrorHandler(error);
      setApiError(errorMessage);
    }
  };

  useEffect(() => {
    sendValidation();
  }, []);

  const handleConfirmCode = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setError("");
    try {
      const isValidCode = smsCodeValidation.parse(state?.smsCode);

      if (isValidCode.length) {
        await handleSecondApi(mobileConfirmByEmail, {
          email: user?.email,
          mobile: user?.mobile,
          code: state?.smsCode,
        });
      }
    } catch (err) {
      if (err instanceof z.ZodError) {
        setError(err.issues[0].message);
      }
    }
  };

  useEffect(() => {
    if (smsSended) {
      setDisabled(true);
      setStatus(true);
    }
  }, [smsSended]);

  const handleReset = () => {
    setSmsSended(false);
    setError("");
    dispatch({ smsCode: "" });
    setApiError("");
    setSecondError("");
    sendValidation()
  };

  const handleChange = (event: any) => {
    setSecondError("");
    setApiError("");
    const { name, value } = event.target;
    dispatch({ [name]: value });
    const validations = name === "mobile" ? phoneValidation : smsCodeValidation;
    const result = validations.safeParse(value);
    setDisabled(!result.success);
    if (result.success) {
      setError("");
    } else {
      setError(result.error.issues[0].message);
    }
  };

  useEffect(() => {
    if (secondSuccess) {
      navigate(`/kayit-ol/adim-5`);
    }
  }, [secondSuccess]);

  return (
    <section className="w-100">
      <div className="container-fluid">
        <div className="row g-0 row-cols-1 row-cols-lg-2 row-cols-xl-3 justify-content-center">
          <div className="col">
            <div className="formElement">
              <div className="mb-4">
                <div className="row align-items-center row-cols-1 row-cols-sm-auto flex-column-reverse flex-sm-row justify-content-between g-0">
                  <div className="col">
                    <h1 className="ff:light c:dark fs:28 d-flex align-items-center justify-content-center">
                      Telefonu Doğrula
                    </h1>
                  </div>
                  <StepArrrows step={4} />
                </div>
              </div>

              <form onSubmit={(e) => e.preventDefault()}>
                <div className="formElement__block bg:white radius:12 shadow:3 p-3 p-sm-4">
                  <div className="p-2">
                    <div className="row row-cols-1 g-0 gy-3 gy-sm-4">
                      <div className="col">
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0">
                            <img src={phoneImage} alt="" />
                          </div>
                          <div className="flex-grow-1 ms-3">
                            <p className="fs:16 ff:light">
                              Telefonunuza sms ile gelen doğrulama kodunu
                              girerek, doğrulamayı tamamlayın.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        {/* <!-- sms doğrulama kodu ilk giriş görünümü --> */}
                        {loading ? (
                          <div className="formElementItem">
                            <Skeleton height={20} />
                            <Skeleton height={60} />
                            <p
                              className={`${
                                error.length ? "d-block" : "d-none"
                              } c:red text-uppercase ff:regular fs:11 mt-2 lt:1`}
                            >
                              {error}
                            </p>
                          </div>
                        ) : (
                          smsSended && (
                            <Field
                              type="text"
                              name="smsCode"
                              label="sms doğrulama kodu"
                              value={state?.smsCode}
                              placeholder="* * * * * *"
                              errors={error}
                              loading={loading}
                              onChange={handleChange}
                              minLength={6}
                              maxLength={6}
                              required={true}
                              pattern={formatInput}
                            />
                          )
                        )}
                      </div>
                      <div className="col">
                        {/* <!-- sms doğrulama adımındaki timer --> */}
                        {loading ? (
                          <Skeleton height={20} className="mb-3 mb-sm-4" />
                        ) : (
                          smsSended && (
                            <div className="timerText text-center mb-3 mb-sm-4">
                              {time.hours !== -1 ? (
                                time.minutes !== -1 ? (
                                  <p className="ff:bold c:dark:2 fs:15">
                                    Süre:{" "}
                                    <span
                                      className="d-inline-block"
                                      id="timerElem"
                                    >
                                      {time.minutes.toString().padStart(2, "0")}
                                      :
                                      {time.seconds.toString().padStart(2, "0")}
                                    </span>
                                  </p>
                                ) : (
                                  <p className="ff:bold c:orange fs:15">
                                    Süre doldu:{" "}
                                    <span
                                      className="d-inline-block"
                                      id="timerElem"
                                    >
                                      00:00
                                    </span>
                                  </p>
                                )
                              ) : null}
                            </div>
                          )
                        )}
                        {apiError.length && !error.length ? (
                          <div
                            className="alert  bg:red:2 p-3 radius:12 mb-3 mb-sm-4"
                            style={
                              {
                                "--alert-borderColor":
                                  "rgba(243, 52, 52, 0.22)",
                              } as React.CSSProperties
                            }
                          >
                            <div className="d-flex align-items-center">
                              <div className="flex-shrink-0">
                                <img src={infoicon} alt="" />
                              </div>
                              <div className="flex-grow-1 ms-3">
                                <p className="c:red lt:2 ff:regular fs:12">
                                  {apiError}
                                </p>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {secondError.length && !error.length ? (
                          <div
                            className="alert  bg:red:2 p-3 radius:12 mb-3 mb-sm-4"
                            style={
                              {
                                "--alert-borderColor":
                                  "rgba(243, 52, 52, 0.22)",
                              } as React.CSSProperties
                            }
                          >
                            <div className="d-flex align-items-center">
                              <div className="flex-shrink-0">
                                <img src={infoicon} alt="" />
                              </div>
                              <div className="flex-grow-1 ms-3">
                                <p className="c:red lt:2 ff:regular fs:12">
                                  {secondError}
                                </p>
                              </div>
                            </div>
                          </div>
                        ) : null}

                        {smsSended && time?.minutes !== -1 && (
                          <ButtonContinue
                            disabled={disabled}
                            loading={loading}
                            text="Doğrula"
                            onClick={handleConfirmCode}
                          />
                        )}
                        {/* <!-- süre dolduktan sonra butonun stili */}
                        {time?.minutes === -1 && smsSended && (
                          <ButtonContinue
                            disabled={time?.minutes !== -1}
                            loading={loading}
                            text="Kodu Tekrar Gönder"
                            onClick={handleReset}
                            ariaLabel="resendCode"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Step4;
