import useApi from "hooks/useApi";
import { IUser, useUser } from "hooks/useUser";
import React, { useState, useEffect } from "react";
import { getInterests, putUserInterests } from "services/user.service";
import iconInfo from "@images/icon-info.svg";
import iconInfoGreen from "@images/icon-info-green.svg";
import useSecondApi from "hooks/useSecondApi";
/* eslint-disable react-hooks/exhaustive-deps */
interface IProps {
  user: IUser;
  loading: boolean;
  setUser: (user: IUser) => void;
  setLoading: (bool: boolean) => void;
}

const PersonalInterests = ({ user }: IProps) => {
  const {
    success,
    error: apiError,
    loading: useApiLoading,
    data,
    handleApi,
  } = useApi();

  const { secondSuccess, secondData, handleSecondApi } = useSecondApi();

  const { addUser } = useUser();

  const [checkedInterests, setCheckedInterests] = useState<string[]>([]);
  const [error, setError] = useState("");
  const [interestsUpdated, setInterestsUpdated] = useState(false);

  useEffect(() => {
    handleSecondApi(getInterests, {});
  }, []);

  interface IInterest {
    value: string;
    text: string;
  }

  const interests: IInterest[] = secondData;

  const handleSelectInterests = (interest: string) => {
    if (!checkedInterests.includes(interest)) {
      setCheckedInterests((prevData) => [...prevData, interest]);
    } else {
      let filtered = checkedInterests.filter((elem) => elem !== interest);
      setCheckedInterests(filtered);
    }
  };

  const submitSelectedInterests = async () => {
    if (checkedInterests.length < 4) {
      setError("Lütfen en az 4 ilgi alanı seçiniz.");
    } else {
      setError("");
      await handleApi(putUserInterests, checkedInterests);
    }
  };

  useEffect(() => {
    if (success) {
      setInterestsUpdated(true);
      addUser(data);
    }
    return () => {};
  }, [success]);

  useEffect(() => {
    if (user && user.interests && !interestsUpdated) {
      setCheckedInterests(user.interests);
    }
  }, [user, interestsUpdated]);

  return (
    <div className="col">
      <section className="bg:white radius:12 shadow:4 p-4 p-md-5">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="mb-4">
            <div className="row row-cols-1 row-cols-sm-auto text-center gy-2 gy-sm-0 gx-0 justify-content-between align-items-center">
              <div className="col">
                <h1 className="c:dark ff:bold fs:20 lh:1">İlgi Alanlarım</h1>
              </div>
              <div className="col">
                <p className="c:dark fs:16 ff:light">
                  En az 4 ilgi alanı seçin.
                </p>
              </div>
            </div>
          </div>
          <div className="row row-cols-1 gx-0 gy-4">
            <div className="col">
              <div className="tagListItem tagListItem--checkbox">
                <ul className="c:dark ff:bold fs:14">
                  {secondSuccess &&
                    interests.map((interest) => (
                      <li key={interest.value}>
                        <label
                          aria-label="check button"
                          id=""
                          className="tagListItem--checkbox__check"
                        >
                          <input
                            onChange={(e) =>
                              handleSelectInterests(interest.value)
                            }
                            aria-label="check input"
                            type="checkbox"
                            checked={
                              checkedInterests &&
                              checkedInterests.includes(interest.value)
                            }
                            name=""
                          />
                          <p aria-label="check text">{interest.text}</p>
                        </label>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
            <div className="col">
              {success && !error && (
                <div
                  className="alert bg:green:2 p-3 radius:12 mb-4"
                  style={
                    {
                      "--alert-borderColor": "rgba(57, 189, 141, 0.22)",
                    } as React.CSSProperties
                  }
                >
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <img src={iconInfoGreen} alt="" />
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <p className="c:green lt:2 ff:regular fs:14">
                        İlgi Alanları Başarıyla Güncellendi.
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {apiError && (
                <div
                  className="alert bg:red:2 p-3 radius:12 mb-4"
                  style={
                    {
                      "--alert-borderColor": "rgba(243, 52, 52, 0.22)",
                    } as React.CSSProperties
                  }
                >
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <img src={iconInfo} alt="" />
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <p className="c:red lt:2 ff:regular fs:14">{apiError}</p>
                    </div>
                  </div>
                </div>
              )}
              {error && (
                <div
                  className="alert bg:red:2 p-3 radius:12 mb-4"
                  style={
                    {
                      "--alert-borderColor": "rgba(243, 52, 52, 0.22)",
                    } as React.CSSProperties
                  }
                >
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <img src={iconInfo} alt="" />
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <p className="c:red lt:2 ff:regular fs:14">{error}</p>
                    </div>
                  </div>
                </div>
              )}
              <div className="row justify-content-center g-0">
                <div className="col-12 col-md-9 col-lg-8">
                  <button
                    onClick={submitSelectedInterests}
                    disabled={useApiLoading}
                    type="button"
                    aria-label="Değişiklikleri Kaydet"
                    className="buttonStyle buttonStyle--icon buttonStyle--submit c:white ff:bold fs:16 bg:dark:5 d-block w-100 lh:1 text-center radius:12"
                    style={{ "--form-height": "3rem" } as React.CSSProperties}
                  >
                    Değişiklikleri Kaydet
                  </button>
                </div>
              </div>
            </div>
            {/* <div className="col">
              <div className="row justify-content-center g-0">
                <div className="col-12 col-md-9 col-lg-8">
                  <button
                    type="button"
                    aria-label="Vazgeç"
                    className="buttonStyle buttonStyle--border buttonStyle--submit c:dark ff:bold fs:16 bg:white d-block w-100 lh:1 text-center radius:12"
                    style={
                      {
                        "--btn-border": "#3F4148",
                        "--btn-hover": "#3F4148",
                        "--form-height": "3rem",
                      } as React.CSSProperties
                    }
                  >
                    Vazgeç
                  </button>
                </div>
              </div>
            </div> */}
          </div>
        </form>
      </section>
    </div>
  );
};

export default PersonalInterests;
