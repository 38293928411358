import { useEffect, useRef, useState } from "react";
import iconSearch from "@images/icon-search.svg";
import useOnClickOutside from "hooks/useOnClickOutside";
  /* eslint-disable react-hooks/exhaustive-deps */
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import useApi from "hooks/useApi";
import {
  deleteSearchKeyword,
  getSearchSuggests,
} from "services/account.service";
import Skeleton from "react-loading-skeleton";
import iconClose from "@images/icon-close.svg";
import useSecondApi from "hooks/useSecondApi";

interface ISuggest {
  value: string;
  text: string;
}

interface ISearchSuggests {
  userSearch: ISuggest[];
  popularSearch: ISuggest[];
}

const Search = () => {
  const [searchIsOpen, setSearchIsOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [customClass, setCustomClass] = useState("d-none");
  const { handleApi, data, loading } = useApi();
  const { handleSecondApi, secondSuccess, secondLoading } = useSecondApi();

  const formRef = useRef<HTMLFormElement>(null);

  const suggests: ISearchSuggests = data && data;

  useEffect(() => {
    if (searchIsOpen) {
      handleApi(getSearchSuggests, {});
    }
  }, [searchIsOpen]);

  const handleSearchOutside = () => {
    setSearchIsOpen(false);
    setTimeout(() => {
      setCustomClass("d-none");
    }, 400);
  };

  const handleSearchOpen = () => {
    setSearchIsOpen(true);
    setCustomClass("");
  };

  useOnClickOutside(formRef, () => searchIsOpen && handleSearchOutside());

  const navigate = useNavigate();
  const location = useLocation();

  const handleSearch = () => {
    navigate(`/ara?q=${searchText}`);
    handleSearchOutside();
  };

  let [searchParams] = useSearchParams();
  const [placeholderText, setPlaceholderText] = useState(
    "Ürün, kategori veya ilaç ara"
  );

  const query = searchParams.get("q");

  useEffect(() => {
    if (location.pathname !== "/ara") {
      setSearchText("");
    }
  }, [location]);

  useEffect(() => {
    if (query) {
      setSearchText(query);
    }
  }, [query]);

  const handleDeleteKeyword = (
    keyword: string,
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.stopPropagation();
    handleSecondApi(deleteSearchKeyword, keyword);
  };

  useEffect(() => {
    if (secondSuccess) {
      handleApi(getSearchSuggests, {});
    }
    return () => {};
  }, [secondSuccess]);

  return (
    <section className="header__search w-100">
      <form ref={formRef} onSubmit={(e) => e.preventDefault()}>
        <div className="header__search-item">
          <input
            autoComplete="off"
            className="bg:white radius:100 px-3 px-sm-4 pe-3 pe-sm-5 ff:regular c:dark fs:15"
            type="search"
            value={searchText}
            onMouseDown={handleSearchOpen}
            onChange={(e) => setSearchText(e.target.value)}
            name=""
            id="searchheader"
            onFocus={() => setPlaceholderText("En az 3 Karakter giriniz")}
            onBlur={() => setPlaceholderText("Ürün, kategori veya ilaç ara")}
            placeholder={placeholderText}
          />
          <button
            onClick={handleSearch}
            disabled={searchText.length < 3}
            aria-label="search button"
            className={`radius:full bg:orange`}
            type="submit"
          >
            <img src={iconSearch} alt="" />
          </button>
        </div>
        {/* <!-- kapandığında aşağıdaki class'tan "animate__fadeIn" silinir "animate__fadeOut" eklenir. --> */}
        <div
          className={`header__search-box bg:white radius:12 p-4 animate__animated ${
            searchIsOpen ? "animate__fadeIn" : `animate__fadeOut ${customClass}`
          } animate__faster `}
        >
          <div className="header__search-box__last">
            <p className="c:dark ff:bold fs:16 mb-3 lt:2">En Son Aramalar</p>
            <ul className="row row-cols-1 gx-0 gy-3 c:dark ff:regular fs:15">
              {loading || secondLoading ? (
                <>
                  <Skeleton height={10} width={100} />
                  <Skeleton height={10} width={100} />
                  <Skeleton height={10} width={100} />
                </>
              ) : (
                suggests?.userSearch?.map((suggest, index) => (
                  <li className="col" key={index}>
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1 me-3">
                        <Link
                          to={`/ara?q=${suggest.value}`}
                          onClick={() => handleSearchOutside()}
                        >
                          {suggest.text}
                        </Link>
                      </div>
                      <div className="flex-shrink-0">
                        <button
                          onClick={(e) => handleDeleteKeyword(suggest.value, e)}
                          aria-label="close search item"
                          className="hover:opacity:6"
                          type="button"
                        >
                          <img src={iconClose} alt="" />
                        </button>
                      </div>
                    </div>
                  </li>
                ))
              )}
            </ul>
          </div>
          <div className="header__search-box__tag mt-4">
            <p className="c:dark ff:bold fs:16 mb-3 lt:2">Popüler Aramalar</p>
            <ul className="c:dark ff:regular fs:14">
              {loading ? (
                <>
                  <Skeleton height={30} width={100} borderRadius={100} />
                  <Skeleton height={30} width={100} borderRadius={100} />
                  <Skeleton height={30} width={100} borderRadius={100} />
                </>
              ) : (
                suggests?.popularSearch?.map((suggest, index) => (
                  <li key={index}>
                    <Link
                      to={`/ara?q=${suggest.value}`}
                      onClick={() => handleSearchOutside()}
                    >
                      {suggest.text}
                    </Link>
                  </li>
                ))
              )}
            </ul>
          </div>
        </div>
      </form>
    </section>
  );
};

export default Search;
