import { useContext, useEffect } from "react";
import iconSms from "@images/icon-sms.svg";
import iconRing from "@images/icon-ring.svg";
import iconEnvlope from "@images/icon-envlope.svg";
import useApi from "hooks/useApi";
import { IPreference } from "views/MyAccount/ContactPreferences";
import { togglePreferences } from "services/account.service";
import { NotificationContext } from "contexts/NotificationContext/NotificationContext";
/* eslint-disable react-hooks/exhaustive-deps */
const Contact = ({ preferences }: { preferences: IPreference[] }) => {
  const { handleApi, loading, success } = useApi();
  const { setToggleState } = useContext(NotificationContext);

  const getImage = (prefName: string) => {
    if (prefName === "Sms") {
      return iconSms;
    }
    if (prefName === "Email") {
      return iconEnvlope;
    }
    if (prefName === "Push Notification") {
      return iconRing;
    }
  };

  const handleToggle = (prefId: string) => {
    handleApi(togglePreferences, prefId);
  };

  useEffect(() => {
    if (success) {
      setToggleState((prev: number) => prev + 1);
    }
  }, [success]);

  return (
    <div className="row row-cols-1 gx-0 gy-3">
      <div className="col">
        <section className="bg:white radius:12 shadow:4 p-4 p-md-5">
          <form action="">
            <ul className="row row-cols-1 gx-0 gy-4 listInfo listInfo--border">
              {preferences &&
                preferences.map((pref) => (
                  <li key={pref.notificationId} className="col">
                    <div className="listInfo__item">
                      <div className="row row-cols-auto g-0 justify-content-between align-items-center">
                        <div className="col">
                          <div className="d-flex align-items-center">
                            <div className="flex-shrink-0 iconSize:16">
                              <img src={getImage(pref.name)} alt="" />
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <p className="c:dark fs:16 ff:bold">
                                {pref.name}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col">
                          <label className="switchButton switchButton--single">
                            <input
                              aria-label="switch input"
                              type="checkbox"
                              disabled={loading}
                              onChange={() => handleToggle(pref.notificationId)}
                              checked={pref.enabled}
                            />
                            <span className="switchButton__slider switchButton__round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
            </ul>
          </form>
        </section>
      </div>
    </div>
  );
};

export default Contact;
