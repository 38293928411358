import React from "react";

interface IProps {
  onChange?: (e: any) => void;
  type: string;
  name: string;
  value: string;
  placeholder?: string;
  maxLength?: number;
  minLength?: number;
  invalid: boolean;
}

function FieldTextArea({
  name,
  value,
  placeholder,
  onChange,
  minLength,
  maxLength,
}: IProps) {
  return (
    <div
      className="formElementItem__textarea"
      style={{ "--form-height": "13rem" } as React.CSSProperties}
    >
      <div className="formElementItem__textarea-count bg:light:3 p-2 radius:6">
        <p className="c:dark:3 fs:12 ff:bold">
          {value.length}&nbsp;/&nbsp;{maxLength}
        </p>
      </div>
      <textarea
        className="formElementItem__input formElementItem__input--textarea bg:white radius:12 w-100 p-3 fs:16 ff:regular c:dark shadow:4 d-block"
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        minLength={minLength}
        maxLength={maxLength}
        placeholder={placeholder}
        style={
          {
            "--form-borderColor": "#D7D7D7",
            "--form-height": "13rem",
          } as React.CSSProperties
        }
      >
        {value}
      </textarea>
    </div>
  );
}

export default FieldTextArea;
