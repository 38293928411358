import { BodyContext } from "contexts/BodyContext/BodyContext";
import { useContext, useState } from "react";

interface IProps {
  isDisabled: boolean;
  currentRating: number;
  orderId?: string;
}

const StarsRating = ({ isDisabled, currentRating, orderId }: IProps) => {
  const [rating, setRating] = useState(currentRating);
  const { handlePopup } = useContext(BodyContext);

  const handleRating = () => {
    if (currentRating > 0) return;
    handlePopup("rating", { currentRating: currentRating, orderId: orderId });
  };

  return (
    <form onClick={handleRating} action="">
      {/* <!-- "rating__disabled" bu class sayesinde sadece oy sonucunu gösteriyoruz. oylamaya izin vermek için bu classın kaldırılması gerek --> */}
      <div className={`rating ${isDisabled ? "rating__disabled" : ""}`}>
        <input
          type="radio"
          name="rating"
          id="rating-5"
          value={5}
          checked={currentRating === 5}
          onChange={(e) => setRating(Number(e.target.value))}
        />
        <label htmlFor="rating-5"></label>
        <input
          type="radio"
          name="rating"
          id="rating-4"
          value={4}
          checked={currentRating === 4}
          onChange={(e) => setRating(Number(e.target.value))}
        />
        <label htmlFor="rating-4"></label>
        <input
          type="radio"
          name="rating"
          id="rating-3"
          value={3}
          checked={currentRating === 3}
          onChange={(e) => setRating(Number(e.target.value))}
        />
        <label htmlFor="rating-3"></label>
        <input
          type="radio"
          name="rating"
          id="rating-2"
          value={2}
          checked={currentRating === 2}
          onChange={(e) => setRating(Number(e.target.value))}
        />
        <label htmlFor="rating-2"></label>
        <input
          type="radio"
          name="rating"
          id="rating-1"
          value={1}
          checked={currentRating === 1}
          onChange={(e) => setRating(Number(e.target.value))}
        />
        <label htmlFor="rating-1"></label>
      </div>
    </form>
  );
};

export default StarsRating;
