import React from "react";
import arty from "@images/landing-banner.png";

const HeroSection = () => {
  return (
    <header id="home" className="scrollMargin">
      <section className="headerHero">
        <div className="headerHero__bg">
          <div className="headerHero__bg-text">
            <h1 className="c:white ff:bold fs:58 lh:2">
              TuruncuHat’a <bdi className="d-inline-block">Hoş Geldiniz!</bdi>
            </h1>
            <span className="c:white ff:light fs:20 d-block mt-2 mt-md-3">
              GSK’nın Eczacılara Özel Dijital Platformu
            </span>
          </div>
        </div>
        <div className="headerHero__cover d-none d-lg-block">
          <img alt='arty' src={arty} />
        </div>
      </section>
    </header>
  );
};

export default HeroSection;
