import { NavLink } from "react-router-dom";

const StoresHeadBreadCrumb = () => {
  return (
    <section className="singleMenu flex-nowrap">
      <nav>
        <ul className="fs:16 c:dark:2 ff:bold">
          <li>
            <NavLink
              end={true}
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "singleMenu__active" : ""
              }
              to="/depolar"
            >
              Depolar
            </NavLink>
          </li>
          <li>
            <NavLink
              end={true}
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "singleMenu__active" : ""
              }
              to="/depolar/favori-depolarim"
            >
              Favori Depolarım
            </NavLink>
          </li>
        </ul>
      </nav>
    </section>
  );
};

export default StoresHeadBreadCrumb;
