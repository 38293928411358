import QuickOrdersItem from "./QuickOrdersItem";
import { IQuickOrder } from "types/userTypes";

interface IProps {
  orders: IQuickOrder[];
}

const QuickOrdersAccordion = ({ orders }: IProps) => {
  return (
    <>
      {orders.length ? (
        <section className="tableLayout tableLayout--sm">
          <table className="tableLayout__table">
            <thead>
              <tr className="text-uppercase lt:3 c:dark ff:bold fs:13">
                <th>ÜRÜN</th>
                <th>DEPO</th>
                <th>ÜRÜN ADEDİ</th>
                <th>SİPARİŞ TOPLAMI</th>
                {/* <!-- <th>PLAN</th> --> */}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {orders.map((item, idx) => (
                <QuickOrdersItem key={idx} item={item} />
              ))}
            </tbody>
          </table>
        </section>
      ) : null}
    </>
  );
};

export default QuickOrdersAccordion;
