import { Link } from "react-router-dom";
import iconRating from "@images/icon-rating.svg";
import iconBlogStar from "@images/icon-blog-star.svg";
import { IBlogPosts } from "views/Blog/BlogCategories";
import { convertDate } from "utility/formatDate";

const BlogCard = ({ color, post }: { color: string; post?: IBlogPosts }) => {
  return (
    <div className="col">
      <div className="blogCard">
        <Link
          aria-label="title blog"
          to={`/blogdetay/${post?.id}`}
          className="blogCard__box blogCard__box--sm bg:white radius:12 shadow:7"
        >
          <div className="p-4">
            <div className="d-flex align-items-center mb-3">
              <div className="flex-grow-1 me-3">
                <p
                  className={`text-uppercase fs:12 lt:4  ff:bold`}
                  style={{ color: color }}
                >
                  {post?.blogName}
                </p>
              </div>
              <div className="flex-shrink-0">
                <div className="row row-cols-auto gy-0 gx-2">
                  {post?.label.includes("Popular") && (
                    <div className="col">
                      <div className="blogCard__box-icon">
                        <img src={iconRating} alt="" />
                      </div>
                    </div>
                  )}
                  {post?.label.includes("TopLiked") && (
                    <div className="col">
                      <div className="blogCard__box-icon">
                        <img src={iconBlogStar} alt="" />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <h1 className="c:dark fs:20 lt:3">{post?.title}</h1>

            <p className="c:dark ff:light fs:14 lt:2 mt-3">
              {post && convertDate(post.publishDate)}
            </p>
          </div>
          <img
            className="blogCard__box-img blogCard__box-img--sm radius:12"
            src={
              post?.banner?.fileContents
                ? `data:${post?.banner?.contentType};base64,${post?.banner?.fileContents}`
                : "/brokenImage.svg"
            }
            alt={
              post?.banner?.contentType ? post?.banner.contentType : "alttext"
            }
            loading="lazy"
            onError={(e) => {
              e.currentTarget.src = "/brokenImage.svg";
            }}
          />
        </Link>
      </div>
    </div>
  );
};

export default BlogCard;
