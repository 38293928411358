import { useContext, useEffect, useRef, useState } from "react";
import { BodyContext } from "contexts/BodyContext/BodyContext";
import iconBag from "@images/icon-bag.svg";
import { clearShoppingCart } from "services/shoppingCart.service";
import useApi from "hooks/useApi";
import { CartContext } from "contexts/CartContext/CartContext";
import useOnClickOutside from "hooks/useOnClickOutside";
import GlnPopup from "./PopUps/GlnPopup";
import KvkkPopup from "./PopUps/KvkkPopup";
import { deleteQuickOrder } from "services/user.service";
import FilePopup from "./PopUps/FilePopup";
import RatingPopup from "./PopUps/RatingPopup";
import PopupApprove from "@components/General/PopUps/PopupApprove";
import { QuickOrderContext } from "contexts/QuickOrderContext/QuickOrderContext";
import { IQuickOrder } from "types/userTypes";
import iconHp from "@images/icon-hp.svg";
import { IUser } from "hooks/useUser";
import { ErrorHandler } from "./ErrorHandler";
import { decryptData } from "utility/cryptoLocal";
/* eslint-disable react-hooks/exhaustive-deps */
const PopupWrapper = () => {
  const { activePopup, data, handlePopup } = useContext(BodyContext);

  const {
    handleRepeatQuickOrder,
    loading: loadingQuickOrder,
    reFetchQuickOrders,
  } = useContext(QuickOrderContext);

  const {
    addToCart,
    setCart,
    handleRemoveItemFromCart,
    loading: cartLoading,
    handleRepeatOrder,
    success: succesCart,
    setSuccess: setSuccessCart,
  } = useContext(CartContext);

  const [acceptedPolicies, setAcceptedPolicies] = useState(false);

  useEffect(() => {
    if (data && data.accepted) {
      setAcceptedPolicies(true);
    }
  }, [data]);

  const val = localStorage.getItem("user");
  const user: IUser = val ? JSON.parse(decryptData(val)) : null;

  const {
    success,
    error: apiError,
    loading,
    handleApi,
    data: apiData,
    setError,
    setSuccess,
  } = useApi();

  const [approveLoading, setApproveLoading] = useState(false);

  useEffect(() => {
    if (success) {
      if (data && data.product) {
        addToCart &&
          addToCart(
            data.product,
            data.quantity,
            data.selectedOfferId,
            data.isPreOrder
          );
      } else {
        setCart!(apiData);
      }
    }
  }, [success, apiData]);

  useEffect(() => {
    if (succesCart || success) {
      handlePopup("", null);

      setSuccess(false);
      setSuccessCart(false);
    }

    return () => {};
  }, [succesCart, success]);

  const handleClearBasket = () => {
    handleApi(clearShoppingCart, {});
  };

  const handleDeleteQuickOrder = async () => {
    const itemData: IQuickOrder = data;
    setApproveLoading(true);
    try {
      await deleteQuickOrder(itemData.id);
      reFetchQuickOrders();
      handlePopup("", null);
    } catch (error: any) {
      const { errorMessage } = ErrorHandler(error);
      setError(errorMessage);
    } finally {
      setApproveLoading(false);
    }
  };

  const handleDeleteFromCart = () => {
    handleRemoveItemFromCart!(data.cartItem);
  };

  const clearAllRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(clearAllRef, () => handlePopup(""));

  useEffect(() => {
    if (user !== null && !acceptedPolicies) {
      if (
        user?.roles?.some((role: { name: string }) => role.name.includes("Gsk"))
      ) {
        return;
      }
      if (user && !user.gln && user.provider === "Pharmacy") {
        handlePopup("gln");
      }
      if (user && user.forcePrivacyPolicy && user.forceTermOfUse) {
        handlePopup("both");
      } else {
        if (user && user.forcePrivacyPolicy) {
          handlePopup("kvkk");
        }
        if (user && user.forceTermOfUse) {
          handlePopup("terms");
        }
      }
    } else {
      handlePopup("", null);
    }
  }, [user, data]);

  const handleRepeat = async () => {
    if (handleRepeatOrder) {
      handleRepeatOrder(data.orderId);
    }
  };

  const applyQuickOrder = () => {
    if (data?.id) {
      handleRepeatQuickOrder!(data.id);
    }
  };
  const kvkkData = ["kvkk", "terms", "both"];

  const popupMessage: string = data?.popupMessage;

  return (
    <>
      {activePopup === "deleteFromCart" && (
        <PopupApprove
          popupOpen={activePopup === "deleteFromCart"}
          popupText="Sepetten silmek istediğinize emin misiniz?"
          productName={data && data?.product?.displayBrandName}
          approve={handleDeleteFromCart}
          icon={iconBag}
          loading={cartLoading}
          apiError={apiError}
        />
      )}
      {activePopup === "clear" && (
        <PopupApprove
          popupOpen={activePopup === "clear"}
          popupText={
            popupMessage
              ? popupMessage
              : `Sepeti boşaltmak istediğinizden <br />
            emin misiniz?`
          }
          approve={handleClearBasket}
          icon={iconBag}
          buttonText={"Sepeti Boşalt"}
          loading={loading}
          apiError={apiError}
        />
      )}

      {activePopup === "deleteQuickOrder" && (
        <PopupApprove
          productName={data && data?.productName}
          popupOpen={activePopup === "deleteQuickOrder"}
          popupText={`Ürününü hızlı siparişlerinizden kaldırmak istediğinize emin misiniz?`}
          approve={handleDeleteQuickOrder}
          icon={iconHp}
          loading={approveLoading}
          apiError={apiError}
        />
      )}
      {activePopup === "repeatOrder" && (
        <PopupApprove
          popupOpen={activePopup === "repeatOrder"}
          popupText="Siparişi tekrar etmek, sepetinizdeki ürünleri boşaltır. Sepeti boşaltmak istediğinize emin misiniz?"
          approve={handleRepeat}
          icon={iconBag}
          buttonText={"Onayla"}
          loading={approveLoading || cartLoading}
          apiError={apiError}
        />
      )}
      {activePopup === "applyQuickOrder" && (
        <PopupApprove
          popupOpen={activePopup === "applyQuickOrder"}
          popupText="Hızlı sipariş vermek sepetinizdeki ürünleri boşaltır. Sepeti boşaltmak istediğinize emin misiniz?"
          approve={applyQuickOrder}
          icon={iconBag}
          buttonText={"Onayla"}
          loading={loadingQuickOrder || cartLoading}
          apiError={apiError}
        />
      )}
      {activePopup === "gln" && <GlnPopup />}
      {activePopup === "file" && <FilePopup />}
      {activePopup === "rating" && <RatingPopup />}
      {kvkkData.includes(activePopup) && <KvkkPopup type={activePopup} />}
    </>
  );
};

export default PopupWrapper;
