import { Link } from "react-router-dom";

export default function LoginButton() {
   return (
    <Link
      className="buttonStyle buttonStyle--sm buttonStyle--icon bg:gradient:orange radius:12 c:white fs:13 ff:bold d-block d-xl-inline-block"
      to="/giris"
      data-testid="header-login"
      aria-label="giriş yap"
    >
      Giriş Yap
    </Link>
     );
}
