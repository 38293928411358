import React, { useEffect } from "react";
import badgeArrow from "@images/badge-arrow.svg";
import { Link } from "react-router-dom";
import { getParentCategories } from "services/category.service";
import useApi from "hooks/useApi";
import Skeleton from "react-loading-skeleton";

export interface IParents {
  code: string;
  name: string;
  children: [];
  thumbnail: {
    fileContents: string;
    contentType: string;
    fileDownloadName: string;
    lastModified: string;
    entityTag: string;
    enableRangeProcessing: boolean;
  };
  parentId: string;
  id: string;
}

const ProductsBadge = () => {
  const {
    data,
    error: apiError,
    success,
    loading,
    handleApi,
  } = useApi({ loading: true });
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    handleApi(getParentCategories, {});
    return () => {};
  }, []);

  const categories = success && data;

  const LoadingBadge = () => (
    <Skeleton
      className="col"
      width={"100%"}
      height={114}
      style={{ borderRadius: "0.75rem" }}
    />
  );
  return (
    <section className="productsBadge">
      <div className="container">
        <div className="row row-cols-1 row-cols-md-2 gx-0 gx-md-3 gy-3 gy-md-0">
          {loading ? (
            <>
              <LoadingBadge />
              <LoadingBadge />
            </>
          ) : (
            categories &&
            categories
              // .filter((category: ICategories) => category.children.length)
              .map((category: IParents) => (
                <div key={category.name} className="col">
                  <Link
                    aria-label="link product"
                    to={`liste?anakategori=${category.code}`}
                  >
                    <div
                      className="borderStyle position-relative bg:light:14 radius:12 px-4 shadow:4"
                      style={
                        {
                          "--gsk-border-width": "0.063rem",
                          "--gsk-border-color": "#F0DCB4",
                        } as React.CSSProperties
                      }
                    >
                      <img
                        className="productsBadge__img py-3 my-1 my-md-2"
                        src={
                          category.thumbnail
                            ? `data:${category.thumbnail.contentType};base64,${category.thumbnail.fileContents}`
                            : "/brokenImage.svg"
                        }
                        alt={
                          category.thumbnail?.contentType
                            ? category.thumbnail?.contentType
                            : "alttext"
                        }
                      />
                      <div className="d-flex align-items-center productsBadge__content">
                        <div className="flex-grow-1">
                          <p className="ff:regular c:dark fs:22">
                            {category.name}
                          </p>
                        </div>
                        <div className="flex-shrink-0 px-4">
                          <img src={badgeArrow} alt="" />
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))
          )}

          {apiError ? apiError : null}
        </div>
      </div>
    </section>
  );
};

export default ProductsBadge;
