import Skeleton from "react-loading-skeleton";

const CategoriesSkeleton = () => {
  return (
    <div className="d-flex">
      <Skeleton
        height={39.25}
        width={122}
        style={{
          borderRadius: "6.25rem",
          marginRight: "9px",
          marginLeft: "2.3rem",
        }}
      />
      <Skeleton
        height={39.25}
        width={122}
        style={{ borderRadius: "6.25rem", marginRight: "9px" }}
      />
      <Skeleton
        height={39.25}
        width={122}
        style={{ borderRadius: "6.25rem", marginRight: "9px" }}
      />
      <Skeleton
        height={39.25}
        width={122}
        style={{ borderRadius: "6.25rem", marginRight: "9px" }}
      />
      <Skeleton
        height={39.25}
        width={122}
        style={{ borderRadius: "6.25rem", marginRight: "9px" }}
      />
      <Skeleton
        height={39.25}
        width={122}
        style={{ borderRadius: "6.25rem", marginRight: "9px" }}
      />
      <Skeleton
        height={39.25}
        width={122}
        style={{ borderRadius: "6.25rem", marginRight: "9px" }}
      />
      <Skeleton
        height={39.25}
        width={122}
        style={{ borderRadius: "6.25rem", marginRight: "9px" }}
      />
      <Skeleton
        height={39.25}
        width={122}
        style={{ borderRadius: "6.25rem", marginRight: "9px" }}
      />
      <Skeleton
        height={39.25}
        width={122}
        style={{ borderRadius: "6.25rem", marginRight: "9px" }}
      />
    </div>
  );
};

export default CategoriesSkeleton;
