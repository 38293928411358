import {IUser} from "hooks/useUser";
import React from "react";

interface IProps {
    user: IUser;
    setUpdateProfile: (val: boolean) => void;
    setConfirmEmail: (val: boolean) => void;
    handleConfirmPhone: () => void;
}

const PersonalInfoSummary = ({
                                 user,
                                 setUpdateProfile,
                                 setConfirmEmail,
                                 handleConfirmPhone,
                             }: IProps) => {

    const gskProvider: boolean = user?.provider === "Gsk";
    const pharmacyProvider: boolean = user?.provider === "Pharmacy";

    return (
        <section>
            <ul className="row row-cols-1 gx-0 gy-4 listInfo">
                {pharmacyProvider && (
                    <li className="col">
                        <div className="listInfo__item">
                            <div
                                className="row row-cols-1 row-cols-md-auto gy-2 gy-md-0 gx-0 justify-content-between text-center align-items-center">
                                <div className="col">
                                    <p className="c:dark text-uppercase fs:12 ff:regular lt:1">
                                        gln kodu
                                    </p>
                                </div>
                                <div className="col">
                                    <p className="c:dark:4 fs:14 ff:regular">{user?.gln}</p>
                                </div>
                            </div>
                        </div>
                    </li>
                )}
                <li className="col">
                    <div className="listInfo__item">
                        <div
                            className="row row-cols-1 row-cols-md-auto gy-2 gy-md-0 gx-0 justify-content-between text-center align-items-center">
                            <div className="col">
                                <p className="c:dark text-uppercase fs:12 ff:regular lt:1">
                                    ad
                                </p>
                            </div>
                            <div className="col">
                                <p className="c:dark:4 fs:14 ff:regular">{user?.firstName}</p>
                            </div>
                        </div>
                    </div>
                </li>
                <li className="col">
                    <div className="listInfo__item">
                        <div
                            className="row row-cols-1 row-cols-md-auto gy-2 gy-md-0 gx-0 justify-content-between text-center align-items-center">
                            <div className="col">
                                <p className="c:dark text-uppercase fs:12 ff:regular lt:1">
                                    soyad
                                </p>
                            </div>
                            <div className="col">
                                <p className="c:dark:4 fs:14 ff:regular">{user?.lastName}</p>
                            </div>
                        </div>
                    </div>
                </li>
                <li className="col">
                    <div className="listInfo__item">
                        <div
                            className="row row-cols-1 row-cols-md-auto gy-2 gy-md-0 gx-0 justify-content-between text-center align-items-center">
                            <div className="col">
                                <p className="c:dark text-uppercase fs:12 ff:regular lt:1">
                                    E-POSTA
                                </p>
                            </div>
                            <div className="col">
                                <p className="c:dark:4 fs:14 ff:regular text-center text-md-end">
                                    {user?.email}
                                </p>
                                {pharmacyProvider && !user?.emailConfirmed && (
                                    <div className="d-flex justify-content-center justify-content-md-end mt-2 pt-1">
                                        <button
                                            onClick={() => setConfirmEmail(true)}
                                            type="button"
                                            aria-label="E-mail Doğrula"
                                            className="buttonStyle hover:opacity:8 py-2 px-3 c:white ff:bold fs:13 bg:orange text-center radius:5 d-inline-block"
                                        >
                                            E-mail Doğrula
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </li>
                {!gskProvider && (
                    <li className="col">
                        <div className="listInfo__item">
                            <div
                                className="row row-cols-1 row-cols-md-auto gy-2 gy-md-0 gx-0 justify-content-between text-center align-items-center">
                                <div className="col">
                                    <p className="c:dark text-uppercase fs:12 ff:regular lt:1">
                                        Cep Telefon Numarası
                                    </p>
                                </div>
                                <div className="col">
                                    <p className="c:dark:4 fs:14 ff:regular text-center text-md-end">
                                        {user?.mobile}
                                    </p>
                                    {pharmacyProvider && !user?.mobilePhoneConfirmed && user.mobile?.length && (
                                        <div className="d-flex justify-content-center justify-content-md-end mt-2 pt-1">
                                            <button
                                                onClick={() => handleConfirmPhone()}
                                                type="button"
                                                aria-label="E-mail Doğrula"
                                                className="buttonStyle hover:opacity:8 py-2 px-3 c:white ff:bold fs:13 bg:orange text-center radius:5 d-inline-block"
                                            >
                                                Telefon Doğrula
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </li>
                )}
                {pharmacyProvider && (
                    <>
                        <li className="col">
                            <div className="listInfo__item">
                                <div
                                    className="row row-cols-1 row-cols-md-auto gy-2 gy-md-0 gx-0 justify-content-between text-center align-items-center">
                                    <div className="col">
                                        <p className="c:dark text-uppercase fs:12 ff:regular lt:1">
                                            ECZANE ADI
                                        </p>
                                    </div>
                                    <div className="col">
                                        <p className="c:dark:4 fs:14 ff:regular">{user?.company}</p>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li className="col">
                            <div className="listInfo__item">
                                <div
                                    className="row row-cols-1 row-cols-md-auto gy-2 gy-md-0 gx-0 justify-content-between text-center align-items-center">
                                    <div className="col">
                                        <p className="c:dark text-uppercase fs:12 ff:regular lt:1">
                                            CİNSİYET
                                        </p>
                                    </div>
                                    <div className="col">
                                        <p className="c:dark:4 fs:14 ff:regular">
                                            {user?.gender === "Male" || user?.gender === "Erkek"
                                                ? "Erkek"
                                                : user?.gender === "Female" || user?.gender === "Kadın"
                                                    ? "Kadın"
                                                    : "Belirtmek istemiyorum."}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="col">
                            <div className="listInfo__item">
                                <div
                                    className="row row-cols-1 row-cols-md-auto gy-2 gy-md-0 gx-0 justify-content-between text-center align-items-center">
                                    <div className="col">
                                        <p className="c:dark text-uppercase fs:12 ff:regular lt:1">
                                            Şehir
                                        </p>
                                    </div>
                                    <div className="col">
                                        <p className="c:dark:4 fs:14 ff:regular">{user?.city}</p>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="col">
                            <div className="listInfo__item">
                                <div
                                    className="row row-cols-1 row-cols-md-auto gy-2 gy-md-0 gx-0 justify-content-between text-center align-items-center">
                                    <div className="col">
                                        <p className="c:dark text-uppercase fs:12 ff:regular lt:1">
                                            İlçe
                                        </p>
                                    </div>
                                    <div className="col">
                                        <p className="c:dark:4 fs:14 ff:regular">{user?.town}</p>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="col">
                            <div className="listInfo__item">
                                <div
                                    className="row row-cols-1 row-cols-md-auto gy-2 gy-md-0 gx-0 justify-content-between text-center align-items-center">
                                    <div className="col">
                                        <p className="c:dark text-uppercase fs:12 ff:regular lt:1">
                                            Adres
                                        </p>
                                    </div>
                                    <div className="col">
                                        <p className="c:dark:4 fs:14 ff:regular">{user?.address}</p>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </>
                )}
                <li className="col">
                    <div className="row g-0 justify-content-center">
                        <div className="col-12 col-md-9 col-lg-8">
                            <button
                                onClick={() => setUpdateProfile(true)}
                                type="button"
                                aria-label="Güncelle"
                                className="buttonStyle buttonStyle--border buttonStyle--submit c:dark ff:bold fs:16 bg:white d-block w-100 lh:1 text-center radius:12"
                                style={
                                    {
                                        "--btn-border": "#3F4148",
                                        "--btn-hover": "#3F4148",
                                        "--form-height": "3rem",
                                    } as React.CSSProperties
                                }
                            >
                                Güncelle
                            </button>
                        </div>
                    </div>
                </li>
            </ul>
        </section>
    );
};

export default PersonalInfoSummary;
