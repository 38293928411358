import React, { useContext, useEffect, useState } from "react";
import Field from "@components/Form/Field";
import useApi from "hooks/useApi";
import infoiconGreen from "@images/icon-info-green.svg";
import infoicon from "@images/icon-info.svg";
import { initialErrorState } from "@components/Auth/ForgotPassword/ResetPassword";
import { z } from "zod";
import {
  resetPasswordValidations,
  tokenValidation,
} from "validations/AuthValidations";
import Timer from "@components/Form/Timer";
import { notificationTexts } from "utility/notificationTexts";
import { formatInput } from "utility/FormatInput";
import iconEnvlopeOrange from "@images/icon-envlope-orange.svg";
import { useLocation, useNavigate } from "react-router-dom";
import {
  emailConfirm,
  forgotPassword,
  resetPassword,
} from "services/user.service";
import { ErrorHandler } from "@components/General/ErrorHandler";
import { PublicContext } from "contexts/PublicContext/PublicContext";
import { useTealium } from "hooks/useTealium";
import classNames from "classnames";
/* eslint-disable react-hooks/exhaustive-deps */
const ResetPassword = () => {
  const { error: apiError, success, loading, setError, setLoading } = useApi();
  const [errors, setErrors] = useState(initialErrorState);
  const [rePassword, setRePassword] = useState("");
  const [password, setPassword] = useState("");
  const [touched, setTouched] = useState(false); // Track whether the inputs have changed
  const [disabled, setDisabled] = useState(true);
  const [tokenCode, setTokenCode] = useState("");
  const [isTokenGet, setIsTokenGet] = useState(false);
  const [isTokenSet, setIsTokenSet] = useState(false);
  const tealium = useTealium();

  const { state } = useLocation();
  const {
    settings,
    error: settingsError,
    success: successSettings,
    setSiteTitle,
  } = useContext(PublicContext);

  const validations = z.object(resetPasswordValidations);
  const handleChange = (event: any) => {
    const { name, value } = event.target;
    if (touched === false) setTouched(true);
    if (name === "password") {
      setPassword(value);
    } else if (name === "passwordVerify") {
      setRePassword(value);
    }
    const validation = resetPasswordValidations[name];

    const result = validation.safeParse(value);

    if (result.success) {
      setErrors((prev) => {
        return { ...prev, [name]: "" };
      });
    } else {
      setErrors((prev) => {
        return { ...prev, [name]: result.error.issues[0].message };
      });
    }
  };

  const handleToken = async (event: any) => {
    try {
      setTokenCode(event.target.value);
      const result = await tokenValidation.safeParseAsync(event.target.value);

      if (result.success) {
        setErrors((prev) => {
          return { ...prev, [event.target.name]: "" };
        });
      } else {
        setErrors((prev) => {
          return {
            ...prev,
            [event.target.name]: result.error.issues[0].message,
          };
        });
      }
    } catch (error: any) {
      console.log("error", error);
    }
  };

  const [expired, setExpired] = useState(false);

  const handleGetToken = async () => {
    setError("");
    try {
      setLoading(true);
      if (state && state.email) {
        await forgotPassword(state.email);
        setIsTokenGet(true);
        setExpired(false);
      }
    } catch (error) {
      const { errorMessage } = ErrorHandler(error);
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleSetToken = async () => {
    setError("");
    try {
      setLoading(true);
      await emailConfirm({ email: state.email, code: tokenCode });
      setIsTokenSet(true);
    } catch (error) {
      const { errorMessage } = ErrorHandler(error);
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const navigate = useNavigate();

  const handlePasswordChange = async () => {
    setError("");
    try {
      setLoading(true);
      await resetPassword({
        email: state.email,
        password: password,
        code: tokenCode,
      });
      tealium.trackEvent("password_change_success", {
        user_email: state.email,
      });
      navigate("/giris");
    } catch (error) {
      const { errorMessage } = ErrorHandler(error);
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setSiteTitle("Şifremi Değiştir");
    if (touched) {
      const result = validations.safeParse({
        password: password,
        passwordVerify: rePassword,
        token: tokenCode,
      });
      if (result.success) {
        if (password !== rePassword) {
          setErrors((prev) => {
            return {
              ...prev,
              password: "Parolalar eşleşmiyor.",
              passwordVerify: "Parolalar eşleşmiyor.",
            };
          });
          setDisabled(true);
        } else {
          setErrors((prev) => {
            return { ...prev, password: "", passwordVerify: "" };
          });
          setDisabled(false);
        }
      } else {
        setDisabled(true);
      }
    }
  }, [touched, password, rePassword, tokenCode]);

  const handleExpiredAgain = (e: any) => {
    setIsTokenGet(false);
    setTokenCode("");
    handleGetToken();
  };



  return (
    <div className="layoutBody">
      <main className="layoutMain layoutMain--login">
        <section className="w-100">
          <div className="container-fluid">
            <div className="row g-0 row-cols-1 row-cols-lg-2 row-cols-xl-3 justify-content-center">
              <div className="col">
                <div className="formElement ">
                  <div className="mb-4">
                    <div className="row align-items-center row-cols-1 row-cols-sm-auto flex-column-reverse flex-sm-row justify-content-between g-0">
                      <div className="col">
                        <h1 className="ff:light c:dark fs:28 d-flex align-items-center justify-content-center">
                          Şifre Değiştir
                        </h1>
                      </div>
                    </div>
                  </div>
                  <form onSubmit={(e) => e.preventDefault()}>
                    <div className="formElement__block bg:white radius:12 shadow:3 p-3 p-sm-4">
                      <div className="p-2">
                        <div className="row row-cols-1 g-0 gy-3 gy-sm-4">
                          {!isTokenSet && (
                            <>
                              <div className="col">
                                <div className="d-flex align-items-center">
                                  <div className="flex-shrink-0">
                                    <img src={iconEnvlopeOrange} alt="" />
                                  </div>
                                  <div className="flex-grow-1 ms-3">
                                    <p className="fs:16 ff:light">
                                      TuruncuHat'a hoş geldiniz! Devam
                                      edebilmek için, yeni bir şifre
                                      belirlemeniz gerekiyor. Lütfen "Kod İste"
                                      buttonuna basarak, kayıtlı email
                                      adresinize gelecek emaili kontrol ediniz.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {isTokenGet && !expired && (
                                <div className="col">
                                  <Field
                                    type="text"
                                    name="token"
                                    label="email doğrulama kodu"
                                    value={tokenCode}
                                    errors={errors.token}
                                    maxLength={6}
                                    minLength={6}
                                    onChange={handleToken}
                                    pattern={formatInput}
                                  />
                                </div>
                              )}
                            </>
                          )}
                          {isTokenSet && (
                            <>
                              <div className="col">
                                <Field
                                  type="password"
                                  name="password"
                                  errors={errors.password}
                                  label="yeni şifre"
                                  onChange={handleChange}
                                  value={password}
                                />
                              </div>
                              <div className="col">
                                <Field
                                  type="password"
                                  name="passwordVerify"
                                  label="yeni şifreyi onayla"
                                  errors={errors.passwordVerify}
                                  value={rePassword}
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="col">
                                <div
                                  className="alert bg:green:2 p-3 radius:12"
                                  style={
                                    {
                                      "--alert-borderColor":
                                        "rgba(57, 189, 141, 0.22)",
                                    } as React.CSSProperties
                                  }
                                >
                                  <div className="d-flex align-items-center">
                                    <div className="flex-shrink-0">
                                      <img src={infoiconGreen} alt="" />
                                    </div>
                                    <div className="flex-grow-1 ms-3">
                                      <p className="c:green lt:2 ff:regular fs:12">
                                        Şifreniz değiştiğinde otomatik olarak
                                        giriş ekranına yönlendirileceksiniz.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col">
                                <ul className="bulletList ff:regular fs:12 c:dark">
                                  <li>{notificationTexts.passwordLength}</li>
                                  <li>{notificationTexts.passwordRules}</li>
                                </ul>
                              </div>
                            </>
                          )}
                          <div className="col">
                            {isTokenGet && !isTokenSet&& (
                              <Timer
                                onExpired={() => setExpired(true)}
                                success={success}
                                timeSeconds={
                                  !settingsError.length && successSettings
                                    ? Number(
                                        settings?.find(
                                          (item) =>
                                            item.name ===
                                            "FORGOT_PASSWORD_TIMER"
                                        )?.value
                                      )
                                    : notificationTexts.FORGOT_PASSWORD_TIMER
                                }
                              />
                            )}
                            {apiError.length ? (
                              <div
                                className="alert bg:red:2 p-3 radius:12 mb-3 mb-sm-4"
                                style={
                                  {
                                    "--alert-borderColor":
                                      "rgba(243, 52, 52, 0.22)",
                                  } as React.CSSProperties
                                }
                              >
                                <div className="d-flex align-items-center">
                                  <div className="flex-shrink-0">
                                    <img src={infoicon} alt="" />
                                  </div>
                                  <div className="flex-grow-1 ms-3">
                                    <p className="c:red lt:2 ff:regular fs:12">
                                      {apiError}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ) : null}

                            {isTokenGet && !isTokenSet && !expired ? (
                              <button
                                onClick={handleSetToken}
                                aria-label="continue"
                                disabled={loading || !tokenCode.length}
                                className={classNames(
                                  "buttonStyle buttonStyle--icon buttonStyle--submit c:white ff:bold fs:16 bg:gradient:orange d-block w-100 text-center radius:12",
                                  {
                                    spinnerElem: loading,
                                    disabledBtn:
                                      loading ||
                                      errors.token.length ||
                                      !tokenCode.length,
                                  }
                                )}
                                type="submit"
                                style={
                                  {
                                    "--form-height": "3rem",
                                  } as React.CSSProperties
                                }
                              >
                                Kodu Onayla
                              </button>
                            ) : !isTokenSet && !expired ? (
                              <button
                                onClick={handleGetToken}
                                aria-label="continue"
                                disabled={loading}
                                className={classNames(
                                  "buttonStyle buttonStyle--icon buttonStyle--submit c:white ff:bold fs:16 bg:gradient:orange d-block w-100 text-center radius:12",
                                  {
                                    spinnerElem: loading,
                                    disabledBtn: loading,
                                  }
                                )}
                                type="submit"
                                style={
                                  {
                                    "--form-height": "3rem",
                                  } as React.CSSProperties
                                }
                              >
                                Kod İste
                              </button>
                            ) : (
                              !expired && (
                                <button
                                  onClick={handlePasswordChange}
                                  aria-label="continue"
                                  disabled={loading || disabled}
                                  className={classNames(
                                    "buttonStyle buttonStyle--icon buttonStyle--submit c:white ff:bold fs:16 bg:gradient:orange d-block w-100 text-center radius:12",
                                    {
                                      spinnerElem: loading,
                                      disabledBtn:
                                        disabled || loading || !password.length,
                                    }
                                  )}
                                  type="submit"
                                  style={
                                    {
                                      "--form-height": "3rem",
                                    } as React.CSSProperties
                                  }
                                >
                                  Şifreyi değiştir
                                </button>
                              )
                            )}

                            {expired && (
                              <button
                                onClick={handleExpiredAgain}
                                aria-label="continue"
                                disabled={loading}
                                className={classNames(
                                  "buttonStyle buttonStyle--icon buttonStyle--submit c:white ff:bold fs:16 bg:gradient:orange d-block w-100 text-center radius:12",
                                  {
                                    spinnerElem: loading,
                                    disabledBtn: loading,
                                  }
                                )}
                                type="submit"
                                style={
                                  {
                                    "--form-height": "3rem",
                                  } as React.CSSProperties
                                }
                              >
                                Tekrar Kod Gönder
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default ResetPassword;
