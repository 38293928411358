import { endpoints } from "constants/endpoints";
import api from "../utility/request";

export const getPosts = async (): Promise<any> => {
  const response = (await api.get(`${endpoints.baseUrl}/post`)).data;
  return response;
};
export const getPostCards = async (): Promise<any> => {
  const response = (await api.get(`${endpoints.baseUrl}/post/card`)).data;
  return response;
};

export const getSinglePost = async (id: string): Promise<any> => {
  const response = (await api.get(`${endpoints.baseUrl}/post/${id}`)).data;
  return response;
};
export const getPostsBySlug = async (slug: string): Promise<any> => {
  const response = (
    await api.get(`${endpoints.baseUrl}/post/by/blog/slug/${slug}`)
  ).data;
  return response;
};
