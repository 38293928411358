import ReactPlayer from "react-player/lazy";
import { IPostItem } from "../BlogDetail";
import { useTealium } from "hooks/useTealium";
import { useState } from "react";

const Video = ({ item }: { item: IPostItem }) => {
  const [played, setPlayed] = useState<number>();
  const tealium = useTealium();

  const handlePlay = () => {
    tealium.trackEvent("videoPlay", {
      videoUrl: item.content,
      time: played?.toFixed(2),
    });
  };
  const handlePause = () => {
    tealium.trackEvent("videoPause", {
      videoUrl: item.content,
      time: played?.toFixed(2),
    });
  };
  const handleEndVideo = () => {
    tealium.trackEvent("videoEnd", {
      videoUrl: item.content,
      time: played?.toFixed(2),
    });
  };

  return (
    <section className="contentVideo my-4 my-sm-5">
      <ReactPlayer
        onProgress={(progress) => {
          setPlayed(progress.playedSeconds);
        }}
        onPlay={handlePlay}
        onPause={handlePause}
        onEnded={handleEndVideo}
        width="100%"
        height="100%"
        className="react-player"
        url={item.content}
        controls
        preload="auto"
      />
    </section>
  );
};

export default Video;
