import React, { useState, useEffect, useContext } from "react";
import BlogCard from "@components/Blog/BlogCard";
import { Link, useParams } from "react-router-dom";
import BlogBookmark from "./BlogBookmark";
import BlogMenu from "@components/Blog/BlogMenu";
import useApi from "hooks/useApi";
import { getBlogPosts } from "services/blog.service";
import NoData from "@components/General/NoData";
import Skeleton from "react-loading-skeleton";
import { PublicContext } from "contexts/PublicContext/PublicContext";
/* eslint-disable react-hooks/exhaustive-deps */
export interface IBlogPosts {
  blogName: string;
  blogColor: string;
  title: string;
  label: string[];
  hashtag: string[];
  publishDate: string;
  banner: {
    fileContents: string;
    contentType: string;
    fileDownloadName: string;
    lastModified: string;
    entityTag: string;
    enableRangeProcessing: boolean;
  };
  id: string;
}

const BlogCategories = () => {
  const { id } = useParams();
  const [limit, setLimit] = useState(3);
  const { handleApi, data, success, loading, error, setError } = useApi({
    loading: true,
  });
  const { setSiteTitle } = useContext(PublicContext);

  useEffect(() => {
    if (id && id !== "okuma-listem") {
      handleApi(getBlogPosts, { id: id });
    }
    setSiteTitle(id ?? "");
    return () => {
      setError("");
    };
  }, [id]);

  const posts: IBlogPosts[] = success && data;

  return (
    <>
      <section className="bg:white shadow:9 pt-4">
        <div className="container">
          <section className="breadcrumb pb-4">
            <ul className="c:dark fs:14 lt:3">
              <li>
                <Link to="/anasayfa">Anasayfa</Link>
              </li>
              <li>
                <Link to="/blog">Turuncu Blog</Link>
              </li>
            </ul>
          </section>
          <BlogMenu />
        </div>
      </section>
      {error && !posts.length ? (
        <NoData text={error} />
      ) : id && id !== "okuma-listem" && posts.length ? (
        <section className="mt-4 mt-sm-5 mb-5">
          <div className="container">
            <h1 className="c:dark ff:bold fs:24 text-center text-lg-start mb-4 mb-sm-5">
              <span
                className="d-block d-sm-inline-block  ff:regular"
                style={{ color: posts[0].blogColor }}
              >
                {posts[0].blogName}
              </span>
              &nbsp;hakkındaki tüm içerikler.
            </h1>
            <div className="row row-cols-1 row-cols-lg-3 gx-0 gx-lg-3 gy-3">
              {posts.length &&
                posts
                  .slice(0, limit)
                  .map((item) => (
                    <BlogCard
                      post={item}
                      key={item.id}
                      color={item.blogColor}
                    />
                  ))}
            </div>
            {posts.length > limit && (
              <div className="d-flex justify-content-center mt-4 pt-0 pt-sm-2">
                <button
                  onClick={() => setLimit((prev) => prev + 3)}
                  aria-label="daha fazla"
                  className="buttonStyle buttonStyle--light buttonStyle--paddingElem c:dark ff:bold fs:16 bg:white shadow:4 d-inline-block text-center radius:16"
                  style={
                    { "--btnPaddingElem": "1rem 4rem" } as React.CSSProperties
                  }
                >
                  Daha Fazla
                </button>
              </div>
            )}
          </div>
        </section>
      ) : loading && id !== "okuma-listem" ? (
        <section className="mt-4 mt-sm-5 mb-5">
          <div className="container">
            <h1 className="c:dark ff:bold fs:24 text-center text-lg-start mb-4 pb-0 pb-sm-2">
              <Skeleton height={30} count={1} width={500} />
            </h1>
            <div className="row row-cols-1 row-cols-lg-3 gx-0 gx-lg-3 gy-3 mt-3">
              <div className="col">
                <Skeleton
                  height={362.05}
                  width={"100%"}
                  count={1}
                  borderRadius={"1rem"}
                />
              </div>
              <div className="col">
                <Skeleton
                  height={362.05}
                  width={"100%"}
                  count={1}
                  borderRadius={"1rem"}
                />
              </div>
              <div className="col">
                <Skeleton
                  height={362.05}
                  width={"100%"}
                  count={1}
                  borderRadius={"1rem"}
                />
              </div>
            </div>
          </div>
        </section>
      ) : (
        id &&
        id !== "okuma-listem" && (
          <section className="mt-4 mt-sm-5 mb-5">
            <div className="container">
              <NoData text="Blog içeriği bulunamadı." />
            </div>
          </section>
        )
      )}

      {id && id === "okuma-listem" && <BlogBookmark />}
    </>
  );
};

export default BlogCategories;
