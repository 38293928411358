import React, { useContext, useEffect, useRef } from "react";
import iconMinusInput from "@images/icon-minus-input.svg";
import iconPlusInput from "@images/icon-plus-input.svg";
import { CartContext } from "contexts/CartContext/CartContext";
import { IOffer, IOffers, ResultDetail } from "types/productTypes";
import { getSettingsByName } from "services/user.service";
import { ICartItem } from "types/shoppingCartTypes";
import AddButton from "@components/Cart/AddButton";
import { BodyContext } from "contexts/BodyContext/BodyContext";
/* eslint-disable react-hooks/exhaustive-deps */
const InputGroupProductDetail = ({
  product,
  qty,
  setQty,
  selectedOffer,
  whichOffer,
  isPreOrder,
  cartItem,
  setSelectedOffer,
}: {
  product: ResultDetail;
  qty: number;
  setQty: (qt: number) => void;
  selectedOffer?: IOffer;
  whichOffer: IOffers | undefined;
  isPreOrder: boolean;
  cartItem: ICartItem | undefined;
  setSelectedOffer: (offer: IOffer | undefined) => void;
}) => {
  const {
    handleAddMoreItemToCart,
    updateCartItem,
    handleMinusFromCart,
    loading,
    loadingPlusMinus,
  } = useContext(CartContext);

  const { handlePopup, data } = useContext(BodyContext);

  useEffect(() => {
    if (data && data.qty) {
      setQty(data.qty);
      handlePopup("", null);
    }
    return () => {};
  }, [data]);

  const findOffer = () => {
    if (whichOffer) {
      const offer = whichOffer.items.findLast(
        (offer) => offer.quantity <= qty + 1
      );

      if (offer) {
        return offer.id;
      } else {
        return "";
      }
    } else {
      console.log("not whichofer");
    }
  };

  const inputRef = useRef<HTMLInputElement>(null);

  const handleInput = (event: any) => {
    event.preventDefault();
    const condition = whichOffer && whichOffer.items[0].quantity <= qty - 1;
    setSelectedOffer(condition ? selectedOffer : undefined);

    if (qty && Number(qty) > 0) {
      updateCartItem!(
        cartItem,
        Number(qty),
        condition ? selectedOffer?.id : "",
        isPreOrder
      );
    } else {
      handlePopup("deleteFromCart", {
        cartItem: cartItem,
        product: product,
      });
    }
  };

  const handlePrescription = async () => {
    try {
      const data = await getSettingsByName("PRESCRIPTION_URL");
      const url = data.value;
      window.open(url, "_blank", "noreferrer");
    } catch (error) {}
  };

  const handleMinus = () => {
    const condition =
      whichOffer && whichOffer.items.find((item) => item.quantity <= qty - 1);
    setSelectedOffer(condition ? selectedOffer : undefined);

    if (qty && Number(qty) > 1) {
      handleMinusFromCart!(
        cartItem,
        condition ? selectedOffer?.id : "",
        product,
        isPreOrder
      );
    } else {
      handlePopup("deleteFromCart", {
        cartItem: cartItem,
        product: product,
      });
    }
  };

  return (
    <>
      {cartItem && cartItem.quantity.amount! > 0 ? (
        <div
          className="widthElem mx-auto"
          style={{ "--widthElem": "7.5rem" } as React.CSSProperties}
        >
          <div className="formElementItem formElementItem--group">
            <div
              className="formElementItem__group d-flex align-items-center border:3 shadow:4 radius:12"
              style={{ "--form-height": "3rem" } as React.CSSProperties}
            >
              {loadingPlusMinus && (
                <div className="disableLoader__spin">
                  <svg
                    version="1.1"
                    id="loader-1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    width="40px"
                    height="40px"
                    viewBox="0 0 50 50"
                    enableBackground="new 0 0 50 50"
                    xmlSpace="preserve"
                  >
                    <path
                      fill="#000"
                      d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
                    >
                      <animateTransform
                        attributeType="xml"
                        attributeName="transform"
                        type="rotate"
                        from="0 25 25"
                        to="360 25 25"
                        dur="0.6s"
                        repeatCount="indefinite"
                      />
                    </path>
                  </svg>
                </div>
              )}

              <button
                disabled={loading || loadingPlusMinus}
                className="formElementItem__group-btn formElementItem__group-btn--minus"
                type="button"
                onClick={() => handleMinus()}
                aria-label="minus"
                style={{ "--form-height": "3rem" } as React.CSSProperties}
              >
                <img className="mx-auto" src={iconMinusInput} alt="" />
              </button>
              <input
                ref={inputRef}
                autoComplete="off"
                className={`formElementItem__group-input w-100 text-center ff:bold c:white fs:16 ${
                  loading ? "disabledInputOrange" : ""
                }`}
                type="number"
                step="1"
                max=""
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    inputRef.current?.blur();
                  }
                }}
                value={qty.toPrecision()}
                onChange={(e) => setQty(Number(e.target.value))}
                onBlur={handleInput}
                name="bag"
                style={
                  {
                    "--form-height": "3rem",
                  } as React.CSSProperties
                }
              />
              <button
                className="formElementItem__group-btn formElementItem__group-btn--plus"
                type="button"
                disabled={loading || loadingPlusMinus}
                onClick={() =>
                  handleAddMoreItemToCart!(cartItem, findOffer(), isPreOrder)
                }
                aria-label="plus"
                style={{ "--form-height": "3rem" } as React.CSSProperties}
              >
                <img className="mx-auto" src={iconPlusInput} alt="" />
              </button>
            </div>
          </div>
        </div>
      ) : product.isPrescription ? (
        <button
          onClick={handlePrescription}
          disabled={loading}
          aria-label="sepete ekle"
          className={`closeButtonElem buttonStyle buttonStyle--icon buttonStyle--submit c:white ff:bold fs:16 bg:orange d-block w-100 text-center radius:12 ${
            loading ? "disabledBtn" : ""
          }`}
          style={{ "--form-height": "3rem" } as React.CSSProperties}
        >
          Talep Oluştur
        </button>
      ) : (
        <AddButton
          product={product}
          selectedOffer={selectedOffer}
          preOrder={isPreOrder}
          cartItem={cartItem}
          color="c:white"
          backgroundColor="bg:orange"
        />
      )}
    </>
  );
};

export default InputGroupProductDetail;
