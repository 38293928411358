import StoreItem from "./StoreItem";
import { IWareHouses, IWareHousesResult } from "types/wareHouseTypes";
import InfiniteScroll from "react-infinite-scroll-component";
import { uniqueBy } from "utility/uniquBy";

interface IProps {
  wareHouses: IWareHouses;
  wareHouseResult: IWareHousesResult[];
  offset: number;
  setOffset: (offset: number) => void;
}

const StoreList = ({
  wareHouses,
  offset,
  setOffset,
  wareHouseResult,
}: IProps) => {
  const getData = () => {
    setOffset(offset + 20);
  };

  return (
    <InfiniteScroll
      className="row row-cols-1 row-cols-lg-2 row-cols-xl-3 g-3"
      style={{ overflow: "revert !important" }}
      dataLength={wareHouses && wareHouses.total - offset}
      next={getData}
      hasMore={wareHouses && wareHouses.total > offset}
      loader={
        <>
          <div> Loader</div>
          <div> Loader</div>
          <div> Loader</div>
        </>
      }
    >
      {uniqueBy(wareHouseResult, (el) => el.id).map((warehouse) => (
        <StoreItem item={warehouse} key={warehouse.id} />
      ))}
    </InfiniteScroll>
  );
};

export default StoreList;
