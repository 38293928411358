import React from "react";
import { Link } from "react-router-dom";

const LinkMore = ({ url }: { url: string }) => {
  return (
    <Link
      to={url}
      aria-label="daha fazla"
      className="buttonStyle buttonStyle--light buttonStyle--paddingElem c:dark ff:bold fs:16 bg:white shadow:4 d-inline-block text-center radius:16"
      style={{ "--btnPaddingElem": "0.813rem 4rem" } as React.CSSProperties}
    >
      Daha Fazla
    </Link>
  );
};

export default LinkMore;
