import iconQuestion from "@images/icon-question.svg";
import React, { useRef, useState } from "react";
import "animate.css";

const Section6 = () => {
  const [activeTab, setActiveTab] = useState("-");
  const [customClass, setCustomClass] = useState("");
  const faqs = [
    {
      title: "Kimler üye olabilir?",
      article: `TuruncuHat sadece eczacılara özel bir platformdur. Üyelik talebi oluşturulduktan sonra 24 saat içerisinde eczacı kimliği doğrulanır ve üyelik onaylanır.`,
    },
    {
      title: "Üyelik ücreti var mı?",
      article: `TuruncuHat eczacıların ücretsiz kullanabileceği bir dijital platformdur. Herhangi bir üyelik ücreti yoktur.`,
    },
    {
      title: "Ürünler nereden temin ediliyor?",
      article: `TuruncuHat üzerinden vermiş olduğunuz siparişler seçeceğiniz depolara iletir. Ürünler size depolar aracılığıyla ulaştırılır.`,
    },
  ];

  const collapseItemRef = useRef<HTMLLIElement | null>(null);

  const handleTab = (title: string) => {
    setActiveTab((prev) => (prev !== title ? title : "-"));
    setTimeout(() => {
      if (collapseItemRef.current) {
        let headerOffset = 80;
        const elementPosition = collapseItemRef.current.offsetTop;
        let offsetPosition = elementPosition! - headerOffset;
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    }, 500);
    setCustomClass("h-full animate__animated animate__fadeIn");
  };

  return (
    <section className="landingCollapse scrollMargin" id="section-5">
      <div className="py-5">
        <div className="py-0 py-sm-5">
          <div className="container">
            <section>
              <div className="bg:orange radius:25 p-4 p-sm-5">
                <div className="d-flex align-items-center mb-4 landingCollapse__title">
                  <div className="flex-shrink-0">
                    <img
                      className="landingCollapse__title-icon"
                      src={iconQuestion}
                      alt=""
                    />
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h1 className="c:white ff:bold fs:28 lh:2">
                      Sıkça Sorulan Sorular
                    </h1>
                  </div>
                </div>
                <ul className="collapseLayout">
                  {faqs.map((faq) => (
                    <li
                      key={faq.title}
                      className={`collapseLayout__item scrollMargin ${
                        faq.title === activeTab && "collapseActiveItem"
                      }`}
                      ref={faq.title === activeTab ? collapseItemRef : null}
                      onClick={() => handleTab(faq.title)}
                    >
                      <h1 className="collapseLayout__thumb d-flex align-items-center py-4 pe-5 ff:bold fs:18 c:white">
                        <span>{faq.title}</span>
                      </h1>
                      <div
                        style={
                          faq.title === activeTab ? { display: "block" } : {}
                        }
                        className={`${
                          faq.title === activeTab ? customClass : "h-0"
                        } collapseLayout__panel preline`}
                      >
                        <div className={"py-4"}>
                          <section className="contentArea ff:light fs:16 c:white">
                            <article>{faq.article}</article>
                          </section>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section6;
