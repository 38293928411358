import { useState } from "react";

interface IInitialState {
  loading: boolean;
}

const useApi = (initialState?: IInitialState) => {
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(
    initialState ? initialState.loading : false
  );
  const [success, setSuccess] = useState(false);

  const handleApi = async (service: any, payload: any) => {
    setSuccess(false);
    setLoading(true);
    setError("");

    try {
      const response = await service(payload);
      setData(response);
      setSuccess(true);
      setError("");
    } catch (error: any) {
      setSuccess(false);
      if (error?.code === "ERR_NETWORK") {
        setError("Şu anda işleminizi gerçekleştiremiyoruz, lütfen daha sonra tekrar deneyiniz.")
      }
      if (error?.response?.status && error.response.status === 500) {
        if (error.response.data.messages) {
          setError("Şu anda işleminizi gerçekleştiremiyoruz, lütfen daha sonra tekrar deneyiniz.");
        } else if (error.response.data.message) {
          setError("Şu anda işleminizi gerçekleştiremiyoruz, lütfen daha sonra tekrar deneyiniz.");
        }
      } else if (error?.response?.status && error?.response?.status === 404) {
        if (error?.response?.data?.message) {
          setError(error?.response?.data?.message);
        } else {
          setError("Şu anda işleminizi gerçekleştiremiyoruz, lütfen daha sonra tekrar deneyiniz.");
        }
      } else if (error?.response?.data?.errors) {
        const keys = Object.keys(error?.response?.data?.errors);
        let errors: any = [];
        keys.map((key) => errors.push(error?.response?.data?.errors[key]));
        setError(errors);
      } else {
        if (error?.response?.data?.message) {
          setError(error.response.data.message);
        } else {
          setError("Şu anda işleminizi gerçekleştiremiyoruz, lütfen daha sonra tekrar deneyiniz.");
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return {
    data,
    error,
    loading,
    handleApi,
    success,
    setError,
    setSuccess,
    setLoading,
  };
};

export default useApi;
