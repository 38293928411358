import React, { useState } from 'react';
import { ITdkProducts } from 'types/productTypes';
import Skeleton from 'react-loading-skeleton';
import tdkImage from '@images/tdk-nedir.webp';

interface IProps {
  data: ITdkProducts;
  loading: boolean;
}

const SecondSection = ({ data, loading }: IProps) => {
  const [accordionIsOpen, setAccordionIsOpen] = useState(false);
  const handleAccordion = (e: React.MouseEvent<HTMLHeadingElement, MouseEvent>) => {
    e.stopPropagation();
    setAccordionIsOpen((prev) => !prev);
  };
  return (
    <section className='mb-5'>
      <div className='container'>
        <section className='collapseContent'>
          {loading && !data ? (
            <Skeleton height={74} count={1} borderRadius={'1rem'} />
          ) : (
            <ul className='collapseLayout'>
              <li
                className={`collapseLayout__item ${
                  accordionIsOpen ? 'collapseActiveItem' : ''
                }`}
              >
                <h1
                  onClick={(e) => handleAccordion(e)}
                  className='collapseLayout__thumb d-flex align-items-center py-4 px-4 pe-5 ff:regular fs:16 c:dark bg:white radius:8 borderStyle shadow:11'
                  style={
                    {
                      '--gsk-border-width': '0.063rem',
                      '--gsk-border-color': '#E9E9E9',
                    } as React.CSSProperties
                  }
                >
                  <span>Turuncu Dönemsel Kampanyalar Nedir</span>
                </h1>
                <div
                  className={`collapseLayout__panel ${
                    accordionIsOpen ? '' : 'd-none'
                  } `}
                >
                  <div className='p-4 pb-4 pb-sm-5'>
                    <section className='contentArea ff:light fs:16 c:dark'>
                      <article>
                        {data?.whatIsTdk}
                        <br />
                        <br />
                        <br />
                        <img src={tdkImage} alt='tdk-nedir' />
                      </article>
                    </section>
                  </div>
                </div>
              </li>
            </ul>
          )}
        </section>
      </div>
    </section>
  );
};

export default SecondSection;
