import { useEffect, useState } from "react";
import BookMarkItem from "./BookMarkItem";
import PopupApprove from "@components/General/PopUps/PopupApprove";
import useApi from "hooks/useApi";
import { deleteBookmark, getAllBookmarks } from "services/account.service";
import { IBlogPosts } from "./BlogCategories";
import TagHead from "@components/Blog/tag/TagHead";
import NoData from "@components/General/NoData";
import iconSuccessTick from "@images/icon-success-tick.svg";
import BookMarkSkeletonItem from "./BookMarkSkeletonItem";
/* eslint-disable react-hooks/exhaustive-deps */
const BlogListBookmark = () => {
  const { handleApi, success, data, loading, error } = useApi({
    loading: true,
  });
  const [productName, setProductName] = useState("");
  const [popupOpen, setPopupOpen] = useState(false);
  const [bookmarkId, setBookmarkId] = useState("");

  const handlePopup = (productName: string, id?: string) => {
    setPopupOpen((prev) => !prev);
    setProductName(productName);
    setBookmarkId(id!);
  };

  const removeFavorites = async () => {
    try {
      await deleteBookmark(bookmarkId);
      handlePopup("");
      handleApi(getAllBookmarks, {});
    } catch (error) {}
  };

  useEffect(() => {
    handleApi(getAllBookmarks, {});
  }, []);
  const bookmarks: IBlogPosts[] = success && data;
  return (
    <>
      <section className="mt-4 mt-sm-5 pt-2 pt-sm-0">
        <div className="container">
          <TagHead
            loading={loading}
            tag="Okuma listem"
            bookmarksLength={bookmarks ? bookmarks.length : 0}
          />
        </div>
      </section>
      <section className="mt-4 mt-sm-5 mb-5 pt-2 pt-sm-0">
        <div className="container">
          <section className="blogList">
            {loading ? (
              <ul className="row row-cols-1 gx-0 gy-5 blogList__content">
                <BookMarkSkeletonItem />
                <BookMarkSkeletonItem />
              </ul>
            ) : bookmarks.length ? (
              <ul className="row row-cols-1 gx-0 gy-5 blogList__content">
                {bookmarks?.map((bookmark) => (
                  <BookMarkItem
                    key={bookmark.id}
                    bookmark={bookmark}
                    handlePopup={handlePopup}
                  />
                ))}
              </ul>
            ) : (
              <NoData text="Şu an okuma listesine eklenmiş blog bulunamadı." />
            )}
          </section>
          {popupOpen && (
            <PopupApprove
              productName={productName}
              popupOpen={popupOpen}
              approve={removeFavorites}
              popupText="Okuma listesinden kaldırmak istediğinize emin misiniz?"
              icon={iconSuccessTick}
              apiError={error}
            />
          )}

          {/* <div className="pt-4 pt-sm-0">
          <Pagination />
        </div> */}
        </div>
      </section>
    </>
  );
};

export default BlogListBookmark;
