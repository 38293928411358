import GoogleMapReact from "google-map-react";
import React, {useState} from "react";
import {IWareHousesResult} from "types/wareHouseTypes";

interface IProps {
    center: { lat: number; lng: number };
    wareHouse: IWareHousesResult;
}

const StoreMap = ({center, wareHouse}: IProps) => {
    const defaultProps = {
        zoom: 16,
    };
    const Marker = (props: any) => {
        const [showDetails, setshowDetails] = useState(false);
        const GetInfoWindowString = () => {
            return (
                <div
                    className="bg:white shadow:2 radius:5 widthElem p-3"
                    style={{ "--widthElem": "17.5rem" } as React.CSSProperties}
                >
                    <div className="d-flex align-items-start">
                        <div className="flex-grow-1 me-3">
                            <p className="ff:bold fs:12 c:dark text-uppercase">{wareHouse.name}</p>
                            <p className="ff:bold fs:12 c:light:20 mt-2">{wareHouse.address}</p>
                        </div>
                        <div className="flex-shrink-0">
                            <button  onClick={() => setshowDetails(false)} type="button"
                                    className="d-block hover:opacity:6 widthElem heightElem d-flex align-items-center justify-content-end"
                                    style={{ "--widthElem" :"1.5rem", "--heightElem":"1.5rem" } as React.CSSProperties}>
                                <svg width="15" height="15" viewBox="0 0 15 15" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14 1L1 14M1 1L14 14" stroke="#3F4148" strokeWidth="1.5"
                                          strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            );
        };
        return (
            <>
                {!showDetails ? (
                    <button
                        style={{
                            position: "absolute",
                            top: "100%",
                            left: "50%",
                            height: "48px",
                            width: "48px",
                            transform: "translate(-50%, -100%)",
                        }}
                        onClick={() => setshowDetails(true)}
                    >
                        <svg
                            viewBox="0 0 50 50"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M18.3154 39.2926C20.1138 42.1571 22.0587 45.6278 23.9792 49.0009C24.6913 50.246 25.3341 50.4331 26.1886 48.9561C28.044 45.7377 29.8668 42.4053 31.7873 39.2967C37.618 29.861 47.1595 20.6614 39.5711 7.96248C32.833 -3.3123 14.5069 -2.10792 9.02619 8.38971C2.74389 20.4213 12.5539 30.1214 18.3154 39.2926Z"
                                fill="#F36633"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M24.6828 10.4121C28.4709 10.4121 31.5429 13.4841 31.5429 17.2722C31.5429 21.0603 28.4709 24.1323 24.6828 24.1323C20.8947 24.1323 17.8228 21.0603 17.8228 17.2722C17.8228 13.4841 20.8947 10.4121 24.6828 10.4121Z"
                                fill="#A02B00"
                            />
                        </svg>
                    </button>
                ) : (
                    <GetInfoWindowString/>
                )}
            </>
        );
    };

    return (
        <div className="mapStyle" style={{height: "21.875rem", width: "100%"}}>
            <GoogleMapReact
                bootstrapURLKeys={{
                    key: process.env.REACT_APP_MAP_API_KEY as string,
                    language: "tr",
                    region: "tr",
                    libraries: ["places"],
                }}
                defaultCenter={center}
                defaultZoom={defaultProps.zoom}
            >
                <Marker lat={center.lat} lng={center.lng}/>
            </GoogleMapReact>
        </div>
    );
};

export default StoreMap;
