import React, { useEffect, useState } from "react";
import BlogCard from "@components/Blog/BlogCard";
import BlogMenu from "@components/Blog/BlogMenu";
import useApi from "hooks/useApi";
import { Link, useParams } from "react-router-dom";
import { getBlogHome } from "services/blog.service";
import { IHomeBlogs } from ".";
/* eslint-disable react-hooks/exhaustive-deps */
const BlogByLabel = () => {
  const { id } = useParams();
  const { handleApi, data, success } = useApi();
  const [limit, setLimit] = useState(3);

  useEffect(() => {
    handleApi(getBlogHome, {});
  }, []);

  const blogs: IHomeBlogs = success && data;

  const posts =
    blogs &&
    blogs.blogs.map((blog) =>
      blog.posts?.filter((post) => post.label.includes(id ?? ""))
    );

  const getNameByLabel = (label: string) => {
    if (label === "TopLiked") {
      return "En Beğenilen";
    }
    if (label === "Popular") {
      return "Popüler";
    }
  };

  return (
    <>
      <section className="bg:white shadow:9 pt-4">
        <div className="container">
          <section className="breadcrumb pb-4">
            <ul className="c:dark fs:14 lt:3">
              <li>
                <Link to="/anasayfa">Anasayfa</Link>
              </li>
              <li>
                <Link to="/blog">Turuncu Blog</Link>
              </li>
            </ul>
          </section>
          <BlogMenu />
        </div>
      </section>
      {id && posts.length ? (
        <section className="mt-4 mt-sm-5 mb-5">
          <div className="container">
            <h1 className="c:dark ff:bold fs:24 text-center text-lg-start mb-4 mb-sm-5">
              <span
                className="d-block d-sm-inline-block  ff:regular"
                // style={{ color: posts[0].blogColor }}
              >
                {getNameByLabel(id)}
              </span>
              &nbsp; içerikler.
            </h1>
            <div className="row row-cols-1 row-cols-lg-3 gx-0 gx-lg-3 gy-3">
              {posts.length &&
                posts
                  .flat()
                  .slice(0, limit)
                  .map(
                    (post) =>
                      post && (
                        <BlogCard
                          key={post.id}
                          color={post.blogColor}
                          post={post}
                        />
                      )
                  )}
            </div>
            {posts.length > limit && (
              <div className="d-flex justify-content-center mt-4 pt-0 pt-sm-2">
                <button
                  onClick={() => setLimit((prev) => prev + 3)}
                  aria-label="daha fazla"
                  className="buttonStyle buttonStyle--light buttonStyle--paddingElem c:dark ff:bold fs:16 bg:white shadow:4 d-inline-block text-center radius:16"
                  style={
                    { "--btnPaddingElem": "1rem 4rem" } as React.CSSProperties
                  }
                >
                  Daha Fazla
                </button>
              </div>
            )}
          </div>
        </section>
      ) : null}
    </>
  );
};

export default BlogByLabel;
