export const notificationTexts = {
  passwordNotValid: "Kullanıcı adı ya da şifre doğru değil.",
  registerPasswordNotValid: "Şifre en az bir büyük harf, küçük harf, rakam ve özel karakter içermelidir.",
  emptyInput: "Lütfen bu alanı boş bırakmayınız.",
  minimumPassword: "Şifre en az 6 karakter uzunluğunda olmalıdır.",
  maximumPassword: "Şifre en fazla 26 karakter uzunluğunda olmalıdır.",
  glnKodu: "Lütfen geçerli bir GLN kodu giriniz.",
  name: "Lütfen geçerli bir ad giriniz.",
  nameMin: "Ad en az 2 karakter uzunluğunda olmalıdır.",
  nameMax: "Ad en fazla 50 karakter uzunluğunda olmalıdır.",
  surnameMin: "Soyad en az 2 karakter uzunluğunda olmalıdır.",
  surnameMax: "Soyad fazla 50 karakter uzunluğunda olmalıdır.",
  gender: "Lütfen cinsiyetinizi belirtin.",
  phoneMin: "Cep Telefon numarası en az 10 karakter uzunluğunda olmalıdır.",
  company: "Lütfen geçerli bir eczane adı giriniz.",
  companyMin: "Lütfen geçerli bir eczane adı giriniz.",
  companyMax: "Eczane adı en fazla 100 karakter uzunluğunda olmalıdır.",
  addressMin: "Adres en az 5 karakter uzunluğunda olmalıdır.",
  addressMax: "Adres en fazla 500 karakter uzunluğunda olmalıdır.",
  successRegister: "Üyelik talebiniz alındı. Gün içerisinde üyeliğiniz ile ilgili sizinle iletişime geçilecektir.",
  minimumDemandText: "En az 20 karakter giriniz.",
  noOrderHistory: "Daha önce verilmiş bir siparişiniz bulunmamaktadır.",
  noPreOrder: "Daha önce verilmiş bir ön siparişiniz bulunmamaktadır.",
  noOldOrder: "Devam eden bir siparişiniz bulunmamaktadır.",
  noJoinedCompaign: "Katıldığınız bir kampanya bulunmamaktadır.",
  noBookmarkItem: "Şu an okuma listesine eklenmiş blog bulunamadı.",
  noBlogItem: "Blog içeriği bulunamadı.",
  noDemands: "Herhangi bir talebiniz bulunmamaktadır.",
  noFavoriteWareHouse: "Henüz Favoriye eklenmiş bir deponuz bulunmamaktadır.",
  noOrder: "Sipariş bulunamadı.",
  cartEmptyConfirmation: "Sepetinizi boşaltmak istediğinizden emin misiniz?",
  noQuickOrder: "Hızlı siparişlerime eklenen bir ürün bulunmamaktadır.",
  noProduct: "Ürün bulunamadı.",
  selectWareHouseTdk: "Plan ekleyebilmek için öncelikle depo seçimi yapmalısınız.",
  selectWareHouse: "Depo Seçin",
  noFavoriteProduct: "Favorilere eklenmiş bir ürün bulunamadı.",
  addedToFavorites: "Favorilere eklendi.",
  removedFromFavorites: "Favorilerden kaldırıldı.",
  noAdditionalProductInfo: "Henüz bir ek bilgi bulunmamaktadır.",
  redirectAfterPasswordChanged: "Şifre değişikliği yaptığınızda tekrar giriş yapmak için yönlendirileceksiniz.",
  passwordRules: "Büyük harf, küçük harf, bir özel karakter ve bir rakam.",
  passwordLength: "En az 6 karakter.",
  passwordChangedSuccess: "Şifreniz başarıyla değiştirildi.",
  noCity: "Lütfen bir şehir seçiniz.",
  noTown: "Lütfen bir ilçe seçiniz.",
  wrongFormat: "Lütfen geçerli bir e-posta adresi giriniz.",
  FORGOT_PASSWORD_TIMER: 100,
  PROFILE_VERIFY_PHONE_TIMER: 100,
  PROFILE_VERIFY_EMAIL_TIMER: 100,
  REGISTER_VERIFY_PHONE_TIMER: 100,
};
