import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import remarkGfm from "remark-gfm";

const FreePage = ({ title, content }: { title: string; content: any }) => {
  return (
    <>
      <section className="contentArea ff:light fs:16 c:dark mt-3 mt-sm-5 mb-5">
        <div className="container">
          <h1 className="c:dark ff:bold fs:24 mb-3 pb-1 text-center text-lg-start">
            {title}
          </h1>
          <article>
            <ReactMarkdown children={content && content} remarkPlugins={[remarkGfm]} />
          </article>
        </div>
      </section>
    </>
  );
};

export default FreePage;
