import { Link } from 'react-router-dom';
import artyFooter from '@images/arty-footer.webp';
import gifGsk from '@images/gif-gsk.gif';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="footer">
      <div className="py-5">
        <div className="py-0 py-sm-5">
          <div className="container">
            <div className="row row-cols-1 row-cols-md-5 gx-0 gx-md-4 gy-5 gy-md-0">
              <div className="col">
                <p className="ff:bold c:dark fs:15 mb-3">Kurumsal</p>
                <ul className="ff:regular c:dark fs:14 row row-cols-1 gx-0 gy-3">
                  <li>
                    <Link to="https://tr.gsk.com/tr-tr/hakkimizda/" target="_blank">
                      GSK Hakkında
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col">
                <p className="ff:bold c:dark fs:15 mb-3">TuruncuHat</p>
                <ul className="ff:regular c:dark fs:14 row row-cols-1 gx-0 gy-3">
                  <li className="col">
                    <Link to="/urunler">Ürünler</Link>
                  </li>
                  <li className="col">
                    <Link to="/turuncu-donemsel-kampanyalar">Turuncu Dönemsel Kampanyalar</Link>
                  </li>
                  <li className="col">
                    <Link to="/anlik-kampanyalar">Anlık Kampanyalar</Link>
                  </li>
                  <li className="col">
                    <Link to="/blog">Turuncu Blog</Link>
                  </li>
                  <li className="col">
                    <Link to="/depolar">Depolar</Link>
                  </li>
                </ul>
              </div>
              <div className="col">
                <p className="ff:bold c:dark fs:15 mb-3">Yardım</p>
                <ul className="ff:regular c:dark fs:14 row row-cols-1 gx-0 gy-3">
                  <li className="col">
                    <Link to="/sikca-sorulan-sorular">Sıkça Sorulan Sorular</Link>
                  </li>
                  <li className="col">
                    <Link to="/iletisim">İletişim</Link>
                  </li>
                </ul>
              </div>
              <div className="col">
                <p className="ff:bold c:dark fs:15 mb-3">Yardımcı Linkler</p>
                <ul className="ff:regular c:dark fs:14 row row-cols-1 gx-0 gy-3">
                  <li className="col">
                    <Link to="https://www.teb.org.tr/" target="_blank">
                      Türk Eczacıları Birliği
                    </Link>
                  </li>
                  <li className="col">
                    <Link to="https://www.titck.gov.tr/" target="_blank">
                      Türkiye İlaç ve Tıbbi Cihaz Kurumu
                    </Link>
                  </li>
                  <li className="col">
                    <Link to="https://www.istanbuleczaciodasi.org.tr/" target="_blank">
                      İstanbul Eczacı Odası
                    </Link>
                  </li>
                  <li className="col">
                    <Link to="https://kms.kaysis.gov.tr/Home/Kurum/22620739" target="_blank">
                      SGK Mevzuat Bilgileri
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col">
                <img className="w-100 px-5 px-md-0" src={artyFooter} alt="" />
              </div>
            </div>

            <section className="footer__bottom mt-5 pt-5 text-center text-md-start">
              <div className="row row-cols-1 row-cols-md-auto gx-0 gx-md-4 gy-4 gy-md-0 align-items-center justify-content-center justify-content-md-between">
                <div className="col">
                  <Link
                    aria-label="gsk logo"
                    className="footer__bottom-logo d-inline-block"
                    to="https://tr.gsk.com"
                  >
                    <img src={gifGsk} alt="gsk" />
                  </Link>
                </div>
                <div className="col">
                  <ul className="row row-cols-auto justify-content-center ff:regular c:dark fs:13">
                    <li className="col">
                      <Link to="/gizlilik-politikasi">Gizlilik Politikası</Link>
                    </li>
                    <li className="col">
                      <Link to="/kullanim-sartlari">Kullanım Şartları</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <p className="ff:light fs:12 c:dark text-center mt-4 mt-md-5">
                GSK ürünü kullanan bir hastanın advers olay yaşaması durumunda, Türkiye
                Farmakovijilans Merkezi’ne (TÜFAM) web sitesi (www.titck.gov.tr), e-posta
                (tufam@titck.gov.tr) veya telefon (0 800 314 00 08) aracılığı ile ve/veya GSK Ürün
                Güvenliği Departmanı’na e-posta (ist_tr_safety@gsk.com) veya telefon (444 5 475)
                aracılığı ile ulaşabilirsiniz.
                <br />
                <br />
                Tüm ürünlerimizin Kısa Ürün Bilgisi ve Kullanım Talimatı dokümanlarına ürünler
                sayfasından ulaşabilirsiniz.
                <br />
                <br />
                <Link
                  aria-label="gsk logo"
                  // className="footer__bottom-logo d-inline-block mt-4 mt-md-5"
                  to="https://gskpro.com/tr-tr/"
                >
                  https://gskpro.com/tr-tr/
                </Link>
                <br />
                <br />
                &copy; {currentYear} GlaxoSmithKline İlaçları San. Ve Tic. A.Ş. - Tüm hakları
                saklıdır.
              </p>
            </section>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
