import React, { useContext, useEffect, useState } from "react";
import useSecondApi from "hooks/useSecondApi";
import infoicon from "@images/icon-info.svg";
import iconEnvlope from "@images/icon-envlope.svg";
import { emailConfirm, getUser, sendEmailCode } from "services/user.service";
import useApi from "hooks/useApi";
import { smsCodeValidation } from "validations/AuthValidations";
import { z } from "zod";
import { IUser, useUser } from "hooks/useUser";
import { useTimer } from "hooks/useTimer";
import { notificationTexts } from "utility/notificationTexts";
import { formatInput } from "utility/FormatInput";
import Field from "@components/Form/Field";
import { PublicContext } from "contexts/PublicContext/PublicContext";
import classNames from "classnames";
import Skeleton from "react-loading-skeleton";

interface IProps {
  user: IUser | any;
  setEmailConfirmed: (bool: boolean) => void;
  isRegisterPage?: boolean;
}

const EmailPopup = ({
  user,
  setEmailConfirmed,
  isRegisterPage = false,
}: IProps) => {
  const [emailCode, setEmailCode] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [emailSended, setEmailSended] = useState(false);
  const [error, setError] = useState("");
  const {
    settings,
    error: settingsError,
    success: successSettings,
  } = useContext(PublicContext);
  const { time, setStatus, reStartTimer } = useTimer(
    !settingsError.length && successSettings
      ? Number(
          settings.find((item) => item.name === "PROFILE_VERIFY_EMAIL_TIMER")
            ?.value
        )
      : notificationTexts.PROFILE_VERIFY_EMAIL_TIMER
  );
  /* eslint-disable react-hooks/exhaustive-deps */
  const { error: apiError, loading, handleApi, success } = useApi();
  const {
    secondError,
    secondLoading,
    secondSuccess,
    setSecondError,
    handleSecondApi,
  } = useSecondApi();

  const { addUser } = useUser();

  useEffect(() => {
    setError("");
  }, [emailCode]);

  const handleSendSmsCode = async () => {
    setError("");

    try {
      if (user?.email) {
        await handleApi(sendEmailCode, user?.email);
      }
    } catch (err) {
      if (err instanceof z.ZodError) {
        setError(err.issues[0].message);
      }
    }
  };

  useEffect(() => {
    handleSendSmsCode();
  }, []);

  useEffect(() => {
    if (success) {
      setEmailSended(true);
    }
  }, [success]);

  const handleConfirmCode = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setError("");
    try {
      const isValidCode = smsCodeValidation.parse(emailCode);

      if (isValidCode.length) {
        await handleSecondApi(emailConfirm, {
          email: user?.email,
          code: emailCode,
        });
      }
    } catch (err) {
      if (err instanceof z.ZodError) {
        setError(err.issues[0].message);
      }
    }
  };

  const handleGetUser = async () => {
    try {
      const resp = await getUser();
      addUser(resp);
    } catch (error) {
      setError("Kullanıcı bilgileri getirilirken bir hata oluştu.");
    }
  };

  useEffect(() => {
    if (secondSuccess) {
      !isRegisterPage && handleGetUser();
      setEmailConfirmed(true);
    }
  }, [secondSuccess, isRegisterPage]);

  useEffect(() => {
    if (emailSended) {
      setStatus(true);
    }
  }, [emailSended]);

  const handleReset = () => {
    reStartTimer(
      !settingsError.length && successSettings
        ? Number(
            settings.find((item) => item.name === "PROFILE_VERIFY_EMAIL_TIMER")
              ?.value
          )
        : notificationTexts.PROFILE_VERIFY_EMAIL_TIMER
    );
    setStatus(false);
    setEmailSended(false);
    handleSendSmsCode();
    setError("");
    setSecondError("");
  };

  const handleChange = (eventValue: string) => {
    // if (touched === false) setTouched(true);
    setEmailCode(eventValue);
    const result = smsCodeValidation.safeParse(eventValue);

    if (result.success) {
      setDisabled(false);
      setError("");
    } else {
      setDisabled(true);
      setError(result.error.issues[0].message);
    }
  };

  return (
    <div className="row row-cols-1 gx-0 gy-3 gy-sm-4">
      <div className="col">
        <div className="d-flex align-items-center">
          <div className="flex-shrink-0">
            <img src={iconEnvlope} alt="" />
          </div>
          <div className="flex-grow-1 ms-3">
            <p className="fs:16 ff:light">
              E-Posta adresinize gelen doğrulama kodunu girerek, doğrulamayı
              tamamlayın.
            </p>
          </div>
        </div>
      </div>
      <div className="col">
        {loading ? (
          <Skeleton height={40} />
        ) : (
          emailSended && (
            <Field
              type="text"
              label="e-posta doğrulama kodu"
              name="verification-code"
              minLength={6}
              maxLength={6}
              placeholder="* * * * * *"
              value={emailCode}
              onChange={(e) => handleChange(e.target.value)}
              pattern={formatInput}
            />
          )
        )}
      </div>
      <div className="col">
        {/* <!-- sms doğrulama adımındaki timer --> */}
        {emailSended && (
          <div className="timerText text-center mb-3 mb-sm-4">
            {time && time.minutes !== -1 ? (
              <p className="ff:bold c:dark:2 fs:15">
                Süre:{" "}
                <span className="d-inline-block" id="timerElem">
                  {time.minutes.toString().padStart(2, "0")}:
                  {time.seconds.toString().padStart(2, "0")}
                </span>
              </p>
            ) : (
              <p className="ff:bold c:orange fs:15">
                Süre doldu:{" "}
                <span className="d-inline-block" id="timerElem">
                  00:00
                </span>
              </p>
            )}
          </div>
        )}
        {apiError.length && !error.length ? (
          <div
            className="alert  bg:red:2 p-3 radius:12 mb-3 mb-sm-4"
            style={
              {
                "--alert-borderColor": "rgba(243, 52, 52, 0.22)",
              } as React.CSSProperties
            }
          >
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                <img src={infoicon} alt="" />
              </div>
              <div className="flex-grow-1 ms-3">
                <p className="c:red lt:2 ff:regular fs:12">{apiError}</p>
              </div>
            </div>
          </div>
        ) : null}
        {secondError.length && !error.length ? (
          <div
            className="alert  bg:red:2 p-3 radius:12 mb-3 mb-sm-4"
            style={
              {
                "--alert-borderColor": "rgba(243, 52, 52, 0.22)",
              } as React.CSSProperties
            }
          >
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                <img src={infoicon} alt="" />
              </div>
              <div className="flex-grow-1 ms-3">
                <p className="c:red lt:2 ff:regular fs:12">{secondError}</p>
              </div>
            </div>
          </div>
        ) : null}

        {emailSended && time.minutes !== -1 && (
          <button
            disabled={secondLoading || disabled}
            aria-label="continue"
            className={classNames(
              "buttonStyle buttonStyle--icon buttonStyle--submit c:white ff:bold fs:16 bg:gradient:orange d-block w-100 text-center radius:12",
              { disabledBtn: disabled, spinnerElem: secondLoading }
            )}
            type="submit"
            onClick={handleConfirmCode}
            style={
              {
                "--form-height": "3rem",
              } as React.CSSProperties
            }
          >
            Doğrula
          </button>
        )}
        {/* <!-- süre dolduktan sonra butonun stili */}
        {time.minutes === -1 && emailSended && (
          <button
            aria-label="resendCode"
            onClick={handleReset}
            disabled={loading}
            className={`buttonStyle buttonStyle--icon buttonStyle--submit c:white ff:bold fs:16 bg:orange d-block w-100 text-center radius:12 ${
              loading ? "spinnerElem" : null
            }`}
            type="submit"
            style={
              {
                "--form-height": "3rem",
              } as React.CSSProperties
            }
          >
            Kodu Tekrar Gönder
          </button>
        )}
      </div>
    </div>
  );
};

export default EmailPopup;
