export const toElementDimentionStyle = (width: string, height:string = "") => {
    return {
      "--widthElem": `${width}rem`,
      "--heightElem": `${height || width}rem`,
    } as React.CSSProperties;
  };

export const toElementPaddingStyle = (x: string, y:string) => {
    return {
        "--btnPaddingElem": `${x}rem ${y}rem`,
      } as React.CSSProperties;
  };
