import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import { getBrands } from "services/brand.service";
import { IBrands } from "@components/ProductsList/ProductsFilter";
import useApi from "hooks/useApi";
/* eslint-disable react-hooks/exhaustive-deps */
const Company = () => {
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  const paginationRef = React.useRef(null);

  const { handleApi, data } = useApi();
  const [swiperSliderFirst, setSwiperSliderFirst] = useState<any>(null);

  useEffect(() => {
    handleApi(getBrands, {
      limit: 50,
    });

    return () => {};
  }, []);

  const brandsData: IBrands[] = data && data;

  return (
    <section className="companyContent">
      <div className="container">
        <h1 className="c:dark ff:bold fs:24 mb-3 pb-1 text-center text-lg-start">
          Markalar
        </h1>
        <div className="position-relative carouselType-2 carouselType-2--2">
          <div className="swiper carouselType-2__item carouselType-2__item--2">
            {brandsData && (
              <Swiper
                modules={[Navigation, Pagination]}
                spaceBetween={12}
                onSwiper={(swiper) => setSwiperSliderFirst(swiper)}
                slidesPerView={2}
                slidesPerGroup={2}
                navigation={{
                  prevEl: navigationPrevRef.current,
                  nextEl: navigationNextRef.current,
                }}
                className="swiper-wrapper"
                pagination={{
                  el: paginationRef.current,
                  clickable: true,
                  dynamicBullets: true,
                }}
                breakpoints={{
                  768: {
                    slidesPerView: 3,
                    slidesPerGroup: 1,
                  },
                  992: {
                    slidesPerView: 4,
                    slidesPerGroup: 1,
                  },
                  1200: {
                    slidesPerView: 6,
                    slidesPerGroup: 1,
                  },
                }}
              >
                {brandsData &&
                  brandsData.map((brand) => (
                    <SwiperSlide key={brand.id} className="swiper-slide">
                      <>
                        <div className="border:1 bg:white radius:12 py-4">
                          {brand.logo ? (
                            <>
                              <img
                                className="companyContent__img"
                                src={
                                  brand.logo
                                    ? `data:${brand.logo.contentType};base64,${brand.logo.fileContents}`
                                    : "/brokenImage.svg"
                                }
                                alt={
                                  brand.logo?.contentType
                                    ? brand.logo?.contentType
                                    : "alttext"
                                }
                                loading="lazy"
                                onError={(e) => {
                                  e.currentTarget.src = "/brokenImage.svg";
                                }}
                              />
                              <div className="swiper-lazy-preloader swiper-lazy-preloader-black"></div>
                            </>
                          ) : (
                            <div className="companyContent__text">
                              <p className="px-3 text-center smText c:dark fs:16 ff:regular text-capitalize">
                                {brand.name}
                              </p>
                            </div>
                          )}
                        </div>
                      </>
                    </SwiperSlide>
                  ))}
              </Swiper>
            )}

            <div
              ref={paginationRef}
              className="swiper-pagination mt-3 pt-2 d-block d-md-none position-relative"
            ></div>
          </div>
          <div
            ref={navigationNextRef}
            className="swiper-button-next d-none d-md-block"
          ></div>
          <div
            ref={navigationPrevRef}
            className="swiper-button-prev d-none d-md-block"
          ></div>
        </div>
      </div>
    </section>
  );
};

export default React.memo(Company);
