import iconRating from "@images/icon-rating.svg";
import iconBlogStar from "@images/icon-blog-star.svg";
import Skeleton from "react-loading-skeleton";

const BlogCardSkeleton = () => {
  return (
    <div className="col">
      <div className="blogCard">
        <div className="blogCard__box blogCard__box--sm bg:white radius:12 shadow:7">
          <div className="p-4">
            <div className="d-flex align-items-center mb-3">
              <div className="flex-grow-1 me-3">
                <p className={`text-uppercase fs:12 lt:4  ff:bold`}>
                  <Skeleton width={70} />
                </p>
              </div>
              <div className="flex-shrink-0">
                <div className="row row-cols-auto gy-0 gx-2">
                  <div className="col">
                    <div className="blogCard__box-icon">
                      <img src={iconRating} alt="" />
                    </div>
                  </div>
                  <div className="col">
                    <div className="blogCard__box-icon">
                      <img src={iconBlogStar} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div aria-label="title blog">
              <h1 className="c:dark fs:20 lt:3">
                <Skeleton width={90} />
              </h1>
            </div>
            <p className="c:dark ff:light fs:14 lt:2 mt-3">
              <Skeleton width={30} />
            </p>
          </div>
          <Skeleton width={"100%"} height={200} />
        </div>
      </div>
    </div>
  );
};

export default BlogCardSkeleton;
