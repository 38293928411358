import { useEffect, useState } from 'react';
import ContentSearch from '@components/General/ContentSearch';
import useApi from 'hooks/useApi';
import { getAllWareHouse } from 'services/warehouse.service';
import { ISelectedWareHouse, IWareHouses, IWareHousesResult } from 'types/wareHouseTypes';
import InfiniteScroll from 'react-infinite-scroll-component';
import GoPrevStep from './GoPrevStep';
import CartWareHouseItem from './CartWareHouseItem';
import CartWareHouseSkeletonItem from './CartWareHouseSkeletonItem';
import { uniqueBy } from 'utility/uniquBy';
import CartWareHouseSelectedItem from './CartWareHouseSelectedItem';
import NoData from '@components/General/NoData';

interface IProps {
  selectedWareHouse: ISelectedWareHouse;
  setSelectedWareHouse: (wareHouse: ISelectedWareHouse) => void;
}

/* eslint-disable react-hooks/exhaustive-deps */
const CartStore = ({ selectedWareHouse, setSelectedWareHouse }: IProps) => {
  const [searchQuery, setSearchQuery] = useState('');
  const { error: apiError, handleApi, data, loading, success } = useApi();
  const [offset, setOffset] = useState(10);
  const [wareHouseResult, setWareHouseResult] = useState<IWareHousesResult[]>([]);
  const [originalData, setOriginalData] = useState<IWareHousesResult[]>([]);

  useEffect(() => {
    handleApi(getAllWareHouse, {
      limit: 10,
      offset: searchQuery ? 0 : offset < 0 ? 0 : offset - 10,
      sort: 'name',
      query: searchQuery,
    });
    return () => {};
  }, [offset, searchQuery]);

  useEffect(() => {
    if (data && success) {
      setOriginalData((prev: any) => {
        const uniqueData = uniqueBy(data.result, (el: { id: number }) => el.id);
        return [...prev, ...uniqueData];
      });
      setWareHouseResult((prev) => {
        const isHaveSelectedWh: IWareHousesResult = data.result.find(
          (item: IWareHousesResult) => item.id === selectedWareHouse.wareHouseId,
        );

        if (isHaveSelectedWh && selectedWareHouse.isQuickOrder) {
          const filtered = data.result.filter(
            (item: IWareHousesResult) =>
              item.id !== selectedWareHouse.wareHouseId,
          );
          return [...filtered];
        } else if (!searchQuery.length) {
          return uniqueBy([...originalData, ...data.result], (el) => el.id);
        } else {
          return [...data.result];
        }
      });
    }
  }, [data, success, selectedWareHouse, searchQuery]);

  const wareHouses: IWareHouses = data;

  const getData = () => {
    if(searchQuery.length) return;
      setOffset((prev) => prev + 10);
  };

  const skeletonArr = [...Array(offset)];

  return (
    <>
      <section className='my-5'>
        <div className='container'>
          <section className='contentSearch mb-4'>
            <div className='row align-items-center gx-0 gy-3 gy-md-0'>
              <div className='col-12 col-md-8 col-lg-9'>
                <div className='contentSearch__title'>
                  <div className='d-flex align-items-center justify-content-center justify-content-md-start'>
                    <p className='c:dark ff:bold fs:24'>Depo Seçimi</p>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-4 col-lg-3'>
                <div className='mb-4'>
                  <GoPrevStep uri='/sepet' />
                </div>
                <ContentSearch
                  setSearchQuery={setSearchQuery}
                  searchQuery={searchQuery}
                  placeHolder='Depo Adı ile Ara'
                />
              </div>
            </div>
          </section>
          <section>
            {loading ? (
              <div className='row row-cols-1 gx-0 gy-3'>
                {skeletonArr.map((elem, index) => (
                  <CartWareHouseSkeletonItem key={index} />
                ))}
              </div>
            ) : apiError ? (
              apiError
            ) : (
              <InfiniteScroll
                className='row row-cols-1 gx-0 gy-3'
                dataLength={wareHouses && wareHouses.total - offset}
                next={getData}
                hasMore={wareHouses && wareHouses.total > offset}
                loader={
                  <>
                    <CartWareHouseSkeletonItem />
                    <CartWareHouseSkeletonItem />
                    <CartWareHouseSkeletonItem />
                  </>
                }
              >
                <>
                  {selectedWareHouse.wareHouseName &&
                  selectedWareHouse.isQuickOrder ? (
                    <CartWareHouseSelectedItem item={selectedWareHouse} />
                  ) : null}
                  {wareHouses && wareHouseResult?.length ? (
                    uniqueBy(wareHouseResult, (el) => el.id).map(
                      (warehouse) => (
                        <CartWareHouseItem
                          item={warehouse}
                          key={warehouse.id}
                          selectedWareHouse={selectedWareHouse}
                          setSelectedWareHouse={setSelectedWareHouse}
                        />
                      ),
                    )
                  ) : (
                    <NoData text='Depo Bulunamadı' />
                  )}
                </>
              </InfiniteScroll>
            )}
          </section>
        </div>
      </section>
    </>
  );
};

export default CartStore;
