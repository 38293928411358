import React from "react";
import iconStarts from "@images/icon-starts.svg";
import iconGradient1 from "@images/icon-gradient-1.svg";
import iconGradient2 from "@images/icon-gradient-2.svg";
import iconGradient3 from "@images/icon-gradient-3.svg";
import iconMore from "@images/icon-more.svg";
import iconTh from "@images/icon-th.svg";
import iconSign from "@images/icon-sign.svg";
import { Link } from "react-router-dom";

const GradientCard = () => {
  return (
    <section className="gradientCard">
      <div className="container">
        <div className="row row-cols-1 row-cols-xl-3 gx-0 gx-xl-3 gy-3 gy-xl-0">
          <div className="col">
            <div className="gradientCard__item gradientCard__item--1  p-4 pb-0">
              <Link to="/turuncu-donemsel-kampanyalar">
                <div className="d-flex align-items-center mb-3 mb-sm-4">
                  <div className="flex-grow-1 me-3">
                    <p className="c:white ff:bold fs:22 lh:3">
                      Turuncu Dönemsel <br />
                      Kampanyalar
                    </p>
                  </div>
                  <div className="flex-shrink-0">
                    <img
                      className="gradientCard__item-logo"
                      src={iconStarts}
                      alt=""
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center align-items-xl-start">
                  <div className="flex-shrink-0">
                    <img
                      className="gradientCard__item-icon"
                      src={iconGradient1}
                      alt=""
                    />
                  </div>
                  <div className="flex-grow-1 ms-3 ms-xl-4 pt-0 pt-xl-3">
                    <p className="c:white  fs:18">
                      <span className="ff:regular">
                        Turuncu Dönemsel <br />
                        Kampanyalarla{" "}
                      </span>
                      <span className="ff:bold">
                        Kazanmak <br />
                        Çok Kolay!
                      </span>
                    </p>
                  </div>
                </div>
                <div className="gradientCard__item-link">
                  <img src={iconMore} alt="" />
                </div>
              </Link>
            </div>
          </div>
          <div className="col">
            <div className="gradientCard__item gradientCard__item--2 p-4 pb-4 pb-xl-0">
              <Link
                to="/urunler/liste?anakategori=ilac"
                state={{ turuncuUrunler: true }}
              >
                <div className="d-flex align-items-center mb-3 mb-sm-4">
                  <div className="flex-grow-1 me-3">
                    <p className="c:white ff:bold fs:22 lh:3">
                      Turuncu Ürünler
                    </p>
                  </div>
                  <div className="flex-shrink-0">
                    <img
                      className="gradientCard__item-logo"
                      src={iconTh}
                      alt=""
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <img
                      className="gradientCard__item-icon"
                      src={iconGradient2}
                      alt=""
                    />
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <p className="c:white  fs:18">
                      <span className="ff:regular">Turuncu Ürünler </span>
                      <span className="ff:bold">Avantajlı Fırsatlarla </span>
                      Burada
                    </p>
                  </div>
                </div>
                <div className="gradientCard__item-link">
                  <img src={iconMore} alt="" />
                </div>
              </Link>
            </div>
          </div>
          <div className="col">
            <div className="gradientCard__item gradientCard__item--3 p-4 pb-4 pb-xl-0">
              <Link to="/anlik-kampanyalar">
                <div className="d-flex align-items-center mb-3 mb-sm-4">
                  <div className="flex-grow-1 me-3">
                    <p className="c:white ff:bold fs:22 lh:3">
                      Anlık Kampanyalar
                    </p>
                  </div>
                  <div className="flex-shrink-0">
                    <img
                      className="gradientCard__item-logo"
                      src={iconSign}
                      alt=""
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <img
                      className="gradientCard__item-icon"
                      src={iconGradient3}
                      alt=""
                    />
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <p className="c:white  fs:18 ff:regular">
                      Kampanyalara Katılın, Fırsatları Yakalayın.
                    </p>
                  </div>
                </div>
                <div className="gradientCard__item-link">
                  <img src={iconMore} alt="" />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default React.memo(GradientCard);
