import React from "react";
import { Link } from "react-router-dom";
import iconArrowLeft from "@images/icon-arrow-left.svg";

const GoPrevStep = ({ uri }: { uri: string }) => {
  return (
    <Link to={uri} className="iconLinkBtn" aria-label="geri dön">
      <div className="d-flex align-items-center justify-content-center justify-content-md-end">
        <div className="iconSize:14">
          <img src={iconArrowLeft} alt="" />
        </div>
        <div className="ms-2">
          <p className="ff:bold fs:16 c:orange lh:1">Önceki Adıma Dön</p>
        </div>
      </div>
    </Link>
  );
};

export default GoPrevStep;
