import React, { useContext, useEffect } from "react";
import ProductDetail from "@components/Products/ProductDetail";
import ProductContent from "@components/Products/ProductContent";
import ProductSidebar from "@components/Products/ProductSidebar";
import BreadCrumb from "@components/General/BreadCrumb";
import { useNavigate, useParams } from "react-router-dom";
import ProductsSlider from "@components/General/ProductsSlider";
import { getProductById } from "services/product.service";
import { ResultDetail } from "types/productTypes";
import useSecondApi from "hooks/useSecondApi";
import { ProductFilters } from "contexts/BodyContext/BodyContext";
import ProductDetailsSkeleton from "@components/Products/ProductDetailsSkeleton";
import { PublicContext } from "contexts/PublicContext/PublicContext";
/* eslint-disable react-hooks/exhaustive-deps */
const ProductDetails = () => {
  const { handleSecondApi, secondData, secondError, secondLoading } =
    useSecondApi();
  const { setSiteTitle } = useContext(PublicContext);
  const { id } = useParams();

  useEffect(() => {
    handleSecondApi(getProductById, id);
  }, [id]);

  const product: ResultDetail = secondData && secondData;
  const navigate = useNavigate();

  useEffect(() => {
    if (secondError.length) {
      return navigate("/urunler");
    }
  }, [navigate, secondError]);

  // console.log("ProductDetails.render");

  useEffect(() => {
    setSiteTitle(product?.displayBrandName);
  }, [product]);

  return (
    <>
      {!product || secondLoading ? (
        <ProductDetailsSkeleton />
      ) : (
        <>
          <BreadCrumb
            productId={product!.id}
            productName={product!.displayBrandName}
          />
          <section className="mb-5">
            <div className="container">
              <div className="row gx-0 gx-xl-4 gy-4 gy-xl-0">
                <div className="col-12 col-xl-8">
                  <ProductDetail product={product} />
                  <ProductContent product={product} />
                </div>
                <div className="col-12 col-xl-4">
                  <ProductSidebar product={product} />
                </div>
              </div>
              <div className="mt-5 d-none d-xl-block">
                <hr
                  style={
                    {
                      "--hr-color": "#E6E6E6",
                      "--hr-height": "0.063rem",
                    } as React.CSSProperties
                  }
                />
              </div>
            </div>
          </section>
        </>
      )}

      <section className="my-5">
        <ProductsSlider
          filter={ProductFilters.Suggested}
          title={"Önerilen Ürünler"}
        />
      </section>
    </>
  );
};

export default ProductDetails;
