import { endpoints } from 'constants/endpoints';
import { IEmailCode, IMobileCode, IQuickOrderCreate, Login, SmsCode, Warehouses } from '../types/userTypes';
import api from '../utility/request';
import { Order } from 'types/Order';
import { ICart } from 'types/shoppingCartTypes';

export const putUserInterests = async (data: any): Promise<any> => {
  const response = (
    await api.put(`${endpoints.user}/interests`, { interests: data })
  ).data;
  return response;
};

export const getInterests = async (): Promise<any> => {
  const response = (await api.get(`/interests`)).data;
  return response;
};

export const getSettings = async (): Promise<any> => {
  const response = (await api.get(`/settings`)).data;
  return response;
};
export const getSettingsByName = async (name: string): Promise<any> => {
  const response = (await api.get(`/settings/by/name/${name}`)).data;
  return response;
};

export const createUser = async (data: any): Promise<any> => {
  const response = (await api.post(`${endpoints.user}`, data)).data;
  return response;
};


export const createOwaUser = async (data: any): Promise<any> => {
  return (await api.post(`${endpoints.user}/create-owa-user`, data)).data;
};

export const changePassword = async (data: any): Promise<any> => {
  const response = (await api.post(`${endpoints.user}/change-password`, data))
    .data;
  return response;
};

export const loginUser = async (data: Login): Promise<any> => {
  const response = (await api.post(`${endpoints.user}/login`, data)).data;
  return response;
};


export const getUser = async (): Promise<any> => {
  const response = (await api.get(`${endpoints.user}`)).data;
  return response;
};

export const updateUser = async (data: any): Promise<any> => {
  const response = (await api.patch(`${endpoints.user}`, data)).data;
  return response;
};

export const checkUserEmail = async (email: string): Promise<any> => {
  const response = (
    await api.get(`${endpoints.user}/check?email=${encodeURIComponent(email)}`)
  ).data;
  return response;
};

export const checkUserEmailLogin = async (email: string): Promise<any> => {
  const response = (
    await api.get(
      `${endpoints.user}/check/login?email=${encodeURIComponent(email)}`
    )
  ).data;
  return response;
};

export const getPolicies = async (): Promise<any> => {
  const response = (await api.get(`${endpoints.baseUrl}/policies`)).data;
  return response;
};

export const setPolicies = async (data: any): Promise<any> => {
  const { terms_of_use_id, clarification_text_id } = data;
  const url =
    terms_of_use_id && clarification_text_id
      ? `${endpoints.user}/policy?ids=${encodeURIComponent(
          terms_of_use_id
        )}&ids=${encodeURIComponent(clarification_text_id)}`
      : terms_of_use_id
      ? `${endpoints.user}/policy?ids=${encodeURIComponent(terms_of_use_id)}`
      : clarification_text_id &&
        `${endpoints.user}/policy?ids=${encodeURIComponent(
          clarification_text_id
        )}`;

  const response = (await api.post(url)).data;
  return response;
};

export const forgotPassword = async (email: string): Promise<any> => {
  const response = (
    await api.post(`${endpoints.user}/forgot-password`, { email: email })
  ).data;
  return response;
};

export const resetPassword = async (data: any): Promise<any> => {
  const response = (await api.post(`${endpoints.user}/reset-password`, data))
    .data;
  return response;
};
export const userProfile = async (data: any): Promise<any> => {
  const response = (await api.put(`${endpoints.user}/profile`, data)).data;
  // console.log("userProfile", response);
  return response;
};

export const userGlnUpdate = async (data: any): Promise<any> => {
  return (await api.put(`${endpoints.user}/gln/${data.gln}`)).data;
};
export const userCompany = async (data: any): Promise<any> => {
  const response = (await api.put(`${endpoints.user}/company`, data)).data;
  return response;
};

export const sendSmsCode = async (phoneNumber: string): Promise<any> => {
  const response = (
    await api.get(
      `${endpoints.user}/send-sms-code?phone=${encodeURIComponent(phoneNumber)}`
    )
  ).data;
  return response;
};
export const sendSmsValidation = async (data: any): Promise<any> => {
  const response = (
    await api.post(`${endpoints.user}/send-sms-validation`, data)
  ).data;
  return response;
};
export const sendEmailCode = async (email: string): Promise<any> => {
  const response = (
    await api.get(
      `${endpoints.user}/send-email-verify-code?email=${encodeURIComponent(
        email
      )}`
    )
  ).data;
  return response;
};

export const emailConfirm = async (data: IEmailCode): Promise<any> => {
  const { email, code } = data;
  const response = (
    await api.get(
      `${endpoints.user}/confirm/email?email=${encodeURIComponent(
        email
      )}&code=${encodeURIComponent(code)}`
    )
  ).data;
  return response;
};
export const smsConfirm = async (data: SmsCode): Promise<any> => {
  const { phoneNumber, smsCode } = data;
  const response = (
    await api.get(
      `${endpoints.user}/confirm?phone=${encodeURIComponent(
        phoneNumber
      )}&code=${smsCode}`
    )
  ).data;
  return response;
};

export const mobileConfirmByEmail = async (data: IMobileCode): Promise<any> => {
  const { email, mobile, code } = data;
  const response = (
    await api.get(
      `${endpoints.user}/confirm-mobile?email=${encodeURIComponent(
        email
      )}&mobile=${encodeURIComponent(mobile)}&code=${code}`
    )
  ).data;
  return response;
};

export const getWarehouses = async (data: Warehouses): Promise<any> => {
  const { offset, limit } = data;
  const response = (
    await api.get(
      `${endpoints.account}/warehouses?offset=${offset ?? ""}&limit=${
        limit ?? ""
      }`
    )
  ).data;
  return response;
};

export const setWarehouses = async (warehouseId: string): Promise<any> => {
  const response = (
    await api.post(`${endpoints.account}/warehouses/${warehouseId}`)
  ).data;
  return response;
};

export const deleteWarehouses = async (warehouseId: string): Promise<any> => {
  const response = (
    await api.delete(`${endpoints.account}/warehouses/${warehouseId}`)
  ).data;
  return response;
};

export const getFavoriteProducts = async (): Promise<any> => {
  const response = (await api.get(`${endpoints.account}/products`)).data;
  return response;
};

export const setFavoriteProducts = async (data: {
  product_id: string;
}): Promise<any> => {
  const { product_id } = data;
  const response = (
    await api.post(`${endpoints.account}/products/${product_id}`)
  ).data;
  return response;
};

export const deleteFavoriteProducts = async (data: {
  product_id: string;
}): Promise<any> => {
  const { product_id } = data;
  const response = (
    await api.delete(`${endpoints.account}/products/${product_id}`)
  ).data;
  return response;
};

export const getAccountPlans = async (): Promise<any> => {
  const response = (await api.get(`${endpoints.account}/plans`)).data;
  return response;
};

export const addPlan = async (data: { plan_id: string }): Promise<any> => {
  const { plan_id } = data;
  const response = (await api.post(`${endpoints.account}/plans/${plan_id}`))
    .data;
  return response;
};

export const setWareHouse = async (data: {
  wareHouseId: string;
  termId: string;
}): Promise<any> => {
  const { wareHouseId, termId } = data;
  const response = (
    await api.post(
      `${endpoints.account}/term/${termId}/warehouse/${wareHouseId}`
    )
  ).data;
  return response;
};

export const deletePlan = async (data: {
  id: String;
  plan_id: string;
}): Promise<any> => {
  const { id, plan_id } = data;
  const response = (await api.delete(`${endpoints.user}/${id}/plan/${plan_id}`))
    .data;
  return response;
};

export const getBookmarks = async (id: string): Promise<any> => {
  const response = (await api.get(`${endpoints.user}/${id}/bookmark`)).data;
  return response;
};

export const addBookMark = async (data: {
  id: String;
  post_id: string;
}): Promise<any> => {
  const { id, post_id } = data;
  const response = (
    await api.post(`${endpoints.user}/${id}/bookmark/${post_id}`)
  ).data;
  return response;
};

export const deleteBookmark = async (data: {
  id: String;
  post_id: string;
}): Promise<any> => {
  const { id, post_id } = data;
  const response = (
    await api.delete(`${endpoints.user}/${id}/bookmark/${post_id}`)
  ).data;
  return response;
};

export const getMyOrders = async (): Promise<Order[]> => {
  const response = (await api.get(`${endpoints.account}/my/orders`)).data;
  return response;
};

export const getMyOrderHistory = async (): Promise<Order[]> => {
  const response = (await api.get(`${endpoints.account}/my/old/orders`)).data;
  return response;
};

export const getMyPreorders = async (): Promise<Order[]> => {
  const response = (await api.get(`${endpoints.account}/my/pre/orders`)).data;
  return response;
};

export const getMyWarehouseOrders = async (id: string): Promise<Order[]> => {
  const response = (
    await api.get(`${endpoints.account}/my/warehouse/${id}/orders`)
  ).data;
  return response;
};

//http://localhost:5021/user/order/3fa85f64-5717-4562-b3fc-2c963f66afa6/repeat
export const repeatOrder = async (id: string): Promise<ICart> => {
  const response = (
    await api.post(`${endpoints.account}/my/order/${id}/repeat`)
  ).data;
  return response;
};

export const getQuickOrders = async (): Promise<any> => {
  const response = (await api.get(`${endpoints.account}/quick-orders`)).data;
  return response;
};

export const addQuickOrder = async (data: IQuickOrderCreate): Promise<any> => {
  const response = (await api.post(`${endpoints.account}/quick-orders`, data))
    .data;
  return response;
};

export const applyQuickOrder = async (quickOrderId: string): Promise<any> => {
  const response = (
    await api.post(`${endpoints.account}/quick-orders/${quickOrderId}/apply`)
  ).data;
  return response;
};

export const deleteQuickOrder = async (quickOrderId: string): Promise<any> => {
  const response = (
    await api.delete(`${endpoints.account}/quick-orders/${quickOrderId}`)
  ).data;
  return response;
};

export const getNotificationPrefrences = async (id: string): Promise<any> => {
  const response = (
    await api.get(`${endpoints.user}/${id}/notification_preference`)
  ).data;
  return response;
};

export const toggleNotification = async (data: {
  id: String;
  notification_preference_id: string;
}): Promise<any> => {
  const { id, notification_preference_id } = data;
  const response = (
    await api.post(
      `${endpoints.user}/${id}/notification_preference/${notification_preference_id}`
    )
  ).data;
  return response;
};

export const getSegments = async (id: string): Promise<any> => {
  const response = (await api.get(`${endpoints.user}/${id}/segments`)).data;
  return response;
};

export const getGifts = async (id: string): Promise<any> => {
  const response = (await api.get(`${endpoints.user}/${id}/gifts`)).data;
  return response;
};

export const getDemands = async (): Promise<any> => {
  const response = (await api.get(`${endpoints.account}/demands`)).data;
  return response;
};

export const getDemandSubjects = async (): Promise<any> => {
  const response = (await api.get(`/demand/subjects/dd`)).data;
  return response;
};

export const setDemand = async (data: any): Promise<any> => {
  const response = (await api.post(`${endpoints.account}/demand`, data)).data;
  return response;
};

export const getCities = async (): Promise<any> => {
  const response = (await api.get(`/cities`)).data;
  return response;
};
