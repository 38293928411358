import React, { useContext } from "react";
import { CartContext } from "contexts/CartContext/CartContext";
import { BodyContext } from "contexts/BodyContext/BodyContext";

function RepeatOrder({ orderId }: any) {
  const { loading, handleRepeatOrder, cart } = useContext(CartContext);
  const { handlePopup } = useContext(BodyContext);

  const handleRepeat = async () => {
    if (cart && cart?.quantity >= 1) {
      handlePopup("repeatOrder", { orderId: orderId });
    } else {
      handleRepeatOrder(orderId);
    }
  };

  return (
    <button
      type="button"
      disabled={loading}
      onClick={handleRepeat}
      aria-label="Siparişi Tekrarla"
      className={`buttonStyle buttonStyle--paddingElem hover:opacity:8 c:white ff:bold fs:16 bg:orange text-center radius:12 d-inline-block ${
        loading ? "spinnerElem" : null
      }`}
      style={
        {
          "--btnPaddingElem": "1rem 2.8rem",
        } as React.CSSProperties
      }
    >
      Siparişi Tekrarla
    </button>
  );
}

export default RepeatOrder;
