import React from "react";
import Skeleton from "react-loading-skeleton";

const ProductHomeSkeleton = () => {
  return (
    <div className="productListCard h-100">
      <div className="border:1 bg:white radius:12 p-3 w-100 h-100">
        <div
          className="productListCard__box d-flex flex-column heightElem"
          style={{ "--heightElem": "15.5rem" } as React.CSSProperties}
        >
          <div>
            <div
              className="d-flex justify-content-between align-items-center mb-3 heightElem"
              style={{ "--heightElem": "2rem" } as React.CSSProperties}
            >
              <div>
                <div className="row row-cols-auto gy-0 gx-1"></div>
              </div>
              <div className="ms-auto">
                <Skeleton width={20} height={20} borderRadius={50} />
              </div>
            </div>
            <div className="productHover" aria-label="link card">
              <div className="productListCard__box-img d-flex justify-content-center">
                <Skeleton height={90} width={90} />
              </div>
              <p className="c:dark ff:bold fs:16 text-center mt-3 mb-2 lh:1">
                <Skeleton height={18} width={60} />
              </p>
              <p className="c:dark ff:regular fs:14 text-center">
                <Skeleton height={18} width={80} />
              </p>
            </div>
          </div>
          <div className="mt-auto">
            <p className="c:dark ff:bold fs:18 text-center c:orange lh:1">
              &#8378;&nbsp;
              <Skeleton height={18} width={60} />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductHomeSkeleton;
