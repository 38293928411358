import React, { useContext, useRef } from "react";
import { BodyContext } from "contexts/BodyContext/BodyContext";
import iconClose from "@images/icon-close.svg";
import useOnClickOutside from "hooks/useOnClickOutside";

const FilePopup = () => {
  const { handlePopup, data, handleBody } = useContext(BodyContext);
  const filePopupRef = useRef<HTMLDivElement>(null);

  const handleClose = () => {
    handleBody("");
    handlePopup("", null);
  };
  useOnClickOutside(filePopupRef, () => handleClose());

  return (
    <section className="popupWrapper animate__animated animate__fadeIn animate__faster">
      <div ref={filePopupRef} className="bg:white radius:20 popupPdfPosition">
        <div className="d-flex align-items-center justify-content-between px-4 radius:20 bg:white py-2">
          <div></div>
          <div className="ps-4">
            <button
              onClick={handleClose}
              aria-label="close popup"
              className="popupWrapper__close buttonStyle buttonStyle--light buttonStyle--paddingElem radius:full bg:white shadow:4"
              type="button"
              style={{ "--btnPaddingElem": "0" } as React.CSSProperties}
            >
              <img src={iconClose} alt="" />
            </button>
          </div>
        </div>
        <iframe
          className='popupPdfLayout'
          src={`data:${data.contentType};base64,${data.fileContents}`}
        ></iframe>
      </div>
    </section>
  );
};

export default FilePopup;
