import { useContext, useEffect, useMemo, useRef, useState } from "react";
import ProductsFilter, { IBrands } from "./ProductsFilter";
import Skeleton from "react-loading-skeleton";
import ContentSearch from "@components/General/ContentSearch";
import { groupBy } from "utility/groupBy";
import { uniqueBy } from "utility/uniquBy";
import { BodyContext } from "contexts/BodyContext/BodyContext";
import { ICategory } from "types/categoryTypes";
import { IProductAll } from "types/productTypes";
import DropdownFilter from "./DropdownFilter";
import ProductsCardSkeleton from "@components/General/ProductsCardSkeleton";
import ProductsCard from "@components/General/ProductsCard";
import { filterProducts } from "./filterProducts";
import { useLocation } from "react-router-dom";
import NoData from "@components/General/NoData";
/* eslint-disable react-hooks/exhaustive-deps */
interface IProps {
  parentCategory: ICategory | undefined;
  childCategoryCode: string;
}

const ProductsListFilter = ({ parentCategory, childCategoryCode }: IProps) => {
  const [searchQuery, setSearchQuery] = useState("");
  const { loading: bodyLoading, products, error } = useContext(BodyContext);

  const [label, setLabel] = useState("");
  const [sortBy, setSortBy] = useState("aZ");
  const [selectedForms, setSelectedForms] = useState<IBrands[]>([]);
  const [selectedBrands, setSelectedBrands] = useState<IBrands[]>([]);
  const [forms, setForms] = useState<IBrands[]>();
  const [newForms, setNewForms] = useState<IBrands[]>();

  const clearSelectedBrands = () => {
    setSelectedBrands([]);
  };
  const clearSelectedForms = () => {
    setSelectedForms([]);
  };

  useEffect(() => {
    if (childCategoryCode) {
      clearSelectedBrands();
    }
  }, [childCategoryCode]);

  const handleSelectBrands = (brnd: IBrands) => {
    const condition = selectedBrands.find((item) => item.name === brnd.name);
    if (condition) {
      const data = selectedBrands.filter((brand) => brand.name !== brnd.name);
      setSelectedBrands(data);
    } else {
      setSelectedBrands([...selectedBrands, brnd]);
    }
  };

  const handleSelectForms = (form: IBrands) => {
    const condition = selectedForms.find((item) => item.code === form.code);
    if (condition) {
      const data = selectedForms.filter((sForm) => sForm.code !== form.code);
      setSelectedForms(data);
    } else {
      setSelectedForms([...selectedForms, form]);
    }
  };

  const { sortedData, brandsData } = useMemo(
    () =>
      filterProducts({
        parentCategory: parentCategory,
        childCategoryCode: childCategoryCode,
        products: products,
        searchQuery: searchQuery,
        sortBy: sortBy,
      }),
    [childCategoryCode, parentCategory, searchQuery, sortBy, products]
  );

  const [filteredProducts, setFilteredProducts] = useState<IProductAll[]>();
  const [filteredByLabel, setFilteredByLabel] = useState<IProductAll[]>();
  const [filteredBrandsData, setFilteredBrandsData] = useState<IBrands[]>();
  const [isOrangeProducts, setIsOrangeProducts] = useState(false);
  const [isHasOfferProducts, setIsHasOfferProducts] = useState(false);

  useEffect(() => {
    if (selectedBrands.length && !selectedForms.length) {
      let filtered: IProductAll[] = [];
      sortedData.forEach(elem => {
        if (selectedBrands.some(brand => brand.name === (elem.brand.length ? elem.brand : "x"))) {
          filtered.push(elem);
        }
      });

      if (filtered.length) {
        setFilteredProducts(filtered);
        let newFormsData: IBrands[] = [];

        sortedData.forEach(prod => {
          selectedBrands.forEach(brand => {
            if (prod.brandCode === brand.code) {
              const item = {
                code: prod.formCode,
                name: prod.form,
                logo: null,
                id: prod.brandCode,
              };
              newFormsData.push(item);
            }
          });
        });

        if (newFormsData.length) {
          setNewForms(uniqueBy(newFormsData, el => el.code));
        } else {
          setNewForms(forms);
        }
      }
    } else if (selectedForms.length && !selectedBrands.length) {
      let filtered: IProductAll[] = [];
      sortedData.forEach(elem => {
        if (selectedForms.some(form => form.code === elem.formCode)) {
          filtered.push(elem);
        }
      });

      if (filtered.length) {
        setFilteredProducts(filtered);
        let newBrandsData: IBrands[] = [];

        sortedData.forEach(prod => {
          selectedForms.forEach(form => {
            if (prod.formCode === form.code) {
              const item = {
                code: prod.brandCode,
                name: prod.brand,
                logo: null,
                id: prod.formCode,
              };
              newBrandsData.push(item);
            }
          });
        });

        if (newBrandsData.length) {
          setFilteredBrandsData(uniqueBy(newBrandsData, el => el.code));
        } else {
          setFilteredBrandsData(brandsData);
        }
      }
    } else if (selectedForms.length && selectedBrands.length) {
      newFilter();
    } else {
      const sortedFormCodes = new Set(sortedData.map(item => item.formCode));
      const sortedBrandCodes = new Set(sortedData.map(item => item.brandCode));

      const filteredForms = forms?.filter(form => sortedFormCodes.has(form.code));
      const filteredBrands = brandsData.filter(brand => sortedBrandCodes.has(brand.code));

      setNewForms(filteredForms?.length ? filteredForms: []);
      setFilteredBrandsData(filteredBrands.length ? filteredBrands : brandsData);
      setFilteredProducts(sortedData);
    }
  }, [sortedData, selectedBrands, selectedForms, brandsData, forms]);


  let { state } = useLocation();

  useEffect(() => {
    if (state && state.turuncuUrunler) {
      setIsOrangeProducts(true);
    }
  }, [state]);

  const newFilter = () => {
    let data: IProductAll[] = [];
    sortedData?.map((item) => {
      selectedForms?.map((frm) => {
        if (frm.code === item.formCode && frm.id === item.brandCode) {
          data.push(item);
        }
        return frm;
      });
      selectedBrands.map((brnd) => {
        if (brnd.code === item.brandCode) {
          data.push(item);
        }
        return brnd;
      });
      let filterAgain: IProductAll[] = [];

      data.map((item) => {
        return selectedForms.filter((elem) => {
          if (item.formCode === elem.code) {
            filterAgain.push(item);
          }
          return elem;
        });
      });
      setFilteredProducts(uniqueBy(filterAgain, (el) => el.displayProductForm));
      return uniqueBy(data, (el) => el.brandCode);
    });
  };

  useEffect(() => {
    if (isOrangeProducts || isHasOfferProducts) {
      const data = filteredProducts?.filter((elem) => {
        if (isOrangeProducts && !isHasOfferProducts) {
          return elem.label.includes("Turuncu");
        } else if (isHasOfferProducts && !isOrangeProducts) {
          return elem.hasOffer;
        } else if (isHasOfferProducts && isOrangeProducts) {
          return elem.label.includes("Turuncu") && elem.hasOffer;
        } else {
          return elem;
        }
      });
      setFilteredByLabel(data);
    } else {
      setFilteredByLabel(filteredProducts);
    }
  }, [filteredProducts, isOrangeProducts, isHasOfferProducts]);

  const groupedProducts =
    filteredByLabel &&
    filteredByLabel?.length &&
    groupBy(filteredByLabel, (x) => x.brand);

  const filteredProductsAll: any = [];

  groupedProducts &&
    Object.entries(groupedProducts).map((item) => {
      if (!item[0].length) {
        const newItem = ["", item[1]];
        filteredProductsAll.push(newItem);
      } else {
        filteredProductsAll.push(item);
      }
      return item;
    });

  const titleRef = useRef<HTMLParagraphElement>(null);

  return (
    <section className="mb-5">
      <div className="container">
        <div className="row gx-0 gx-md-3 gy-3 gy-md-0">
          <div className="col-12 col-md-4 col-lg-3">
            {!bodyLoading ? (
              <ProductsFilter
                setLabel={setLabel}
                label={label}
                brands={filteredBrandsData}
                isOrangeProducts={isOrangeProducts}
                setIsOrangeProducts={setIsOrangeProducts}
                isHasOfferProducts={isHasOfferProducts}
                setIsHasOfferProducts={setIsHasOfferProducts}
                selectedBrands={selectedBrands}
                selectedForms={selectedForms}
                handleSelectBrands={handleSelectBrands}
                handleSelectForms={handleSelectForms}
                clearSelectedBrands={clearSelectedBrands}
                clearSelectedForms={clearSelectedForms}
                setForms={setForms}
                forms={newForms}
              />
            ) : (
              <Skeleton width={200} height={328.16} />
            )}
          </div>
          <div className="col-12 col-md-8 col-lg-9">
            <section className="contentSearch mb-4">
              <div className="row align-items-center gx-0 gy-3 gy-xl-0">
                <div className="col-12 col-xl-6">
                  <div className="contentSearch__title">
                    <div className="d-flex align-items-center justify-content-center justify-content-xl-start">
                      <p className="c:dark ff:bold fs:22" ref={titleRef}>
                        {filteredProductsAll?.length
                          ? filteredProductsAll[0][0]
                          : bodyLoading && <Skeleton width={100} height={18} />}
                      </p>
                      {products &&
                      filteredProductsAll?.length &&
                      filteredProductsAll[0][1].length ? (
                        <p className="ms-2 ff:regular c:dark fs:16 d-flex">
                          ({filteredProductsAll[0][1].length})
                        </p>
                      ) : bodyLoading ? (
                        <Skeleton width={312} height={18} />
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-xl-6">
                  <div className="row row-cols-1 row-cols-sm-2 gx-2 gy-3 gy-sm-0 align-items-center">
                    <div className="col">
                      <DropdownFilter sortBy={sortBy} setSortBy={setSortBy} />
                    </div>
                    <div className="col">
                      <ContentSearch
                        setSearchQuery={setSearchQuery}
                        searchQuery={searchQuery}
                        placeHolder="Adı, Kodu, Barkodu, Formu"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {!bodyLoading && error ? <NoData text={error}/> : filteredProductsAll.length ? (
              filteredProductsAll.map((item: any, index: any) => (
                <div key={item} className="mt-4">
                  <ul className="row row-cols-1 gx-0 gy-4">
                    <li className="col">
                      {index !== 0 && (
                        <div className="d-flex align-items-center justify-content-center justify-content-xl-start mb-4">
                          <p className="c:dark ff:bold fs:22">
                            {index !== 0 && item[0]}
                          </p>
                          {index !== 0 && item[1].length && (
                            <p className="ms-2 ff:regular c:dark fs:16">
                              ({item[1].length})
                            </p>
                          )}
                        </div>
                      )}

                      <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-4 g-3">
                        {item[1].map((product: IProductAll) => (
                          <ProductsCard key={product.id} product={product} />
                        ))}
                      </div>
                    </li>
                  </ul>
                </div>
              ))
            ) : bodyLoading ? (
              <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-4 g-3">
                <ProductsCardSkeleton />
                <ProductsCardSkeleton />
                <ProductsCardSkeleton />
              </div>
            ) : (
             <NoData text="Ürün bulunamadı."/>
            )}

            {/* {products && (
            <ListPagination
              path="/urunler/liste"
              searchParams={searchParams}
              items={products}
            />
          )} */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductsListFilter;
