import { endpoints } from "constants/endpoints";
import api from "../utility/request";

export const getCategories = async (): Promise<any> => {
  const response = (await api.get(`${endpoints.baseUrl}/category`)).data;
  return response;
};

export const getParentCategories = async (): Promise<any> => {
  const response = (await api.get(`${endpoints.baseUrl}/category/parents`))
    .data;
  return response;
};

export const getSingleCategory = async (id: string): Promise<any> => {
  const response = (await api.get(`${endpoints.baseUrl}/category/${id}`)).data;
  return response;
};
