import { useContext, useEffect } from "react";
import ProductsSlider from "@components/General/ProductsSlider";
import CategorySlider from "@components/Home/CategorySlider";
import Company from "@components/Home/Company";
import GradientCard from "@components/Home/GradientCard";
// import Loyality from "@components/Home/Loyality";
import MainSlider from "@components/Home/MainSlider";
import AppBanner from "@components/General/AppBanner";
import { ProductFilters } from "contexts/BodyContext/BodyContext";
import useApi from "hooks/useApi";
import { getHomeSlider } from "services/slider.service";
import { PublicContext } from "contexts/PublicContext/PublicContext";
import HomeBlog from "@components/Home/HomeBlog";

interface ISlideItem {
  fileContents: string;
  contentType: string;
  fileDownloadName: string;
  lastModified: string;
  entityTag: string;
  enableRangeProcessing: boolean;
}

export interface ISlides {
  thumbnail: ISlideItem;
  slide: ISlideItem;
}
/* eslint-disable react-hooks/exhaustive-deps */
function Home() {
  const { data, handleApi, success, loading } = useApi({ loading: true });
  const { setSiteTitle } = useContext(PublicContext);

  useEffect(() => {
    handleApi(getHomeSlider, {});
  }, []);

  useEffect(() => {
    setSiteTitle("Ana Sayfa");
  }, []);

  const slideData: ISlides[] = success && data;

  return (
    <main className="landingMain">
      <section className="mt-3 mt-sm-5 mb-4 mb-sm-5">
        <MainSlider slides={slideData} loading={loading} />
      </section>
      <section className="mb-5">
        <CategorySlider />
      </section>
      <section className="my-5">
        <GradientCard />
      </section>
      {/*<section className="my-5">*/}
      {/*  <Loyality />*/}
      {/*</section>*/}
      <section className="my-5">
        <Company />
      </section>
      <section className="my-5">
        <HomeBlog />
      </section>
      <section className="my-5">
        <ProductsSlider
          filter={ProductFilters.Popular}
          title={"Popüler Ürünler"}
        />
      </section>
      <section className="my-5">
        <ProductsSlider
          filter={ProductFilters.Turuncu}
          title={"Turuncu Ürünler"}
        />
      </section>
      <section className="my-5">
        <AppBanner />
        <p>NP-TR-NA-WCNT-240007</p>
      </section>
    </main>
  );
}

export default Home;
