import { useContext, useState } from "react";
import { CartContext } from "contexts/CartContext/CartContext";
import ListCartItem from "./ListCartItem";
import { IProductAll } from "types/productTypes";

const ListCarts = () => {
  const { cart } = useContext(CartContext);
  const [activeProduct, setActiveProduct] = useState<IProductAll | undefined>();
  return (
    <section className="tableLayout">
      <table className="tableLayout__table">
        <thead>
          {/* <tr className="text-uppercase lt:3 c:dark ff:bold fs:13">
        <th>TAMAMLANAN</th>
        <th>DURUM</th>
        <th>KAZANILAN</th>
        <th>ÜRÜN</th>
        <th>PLAN</th>
        <th></th>
      </tr> */}
        </thead>
        <tbody>
          {cart &&
            cart.items.map((cartItem) => (
              <ListCartItem
                cartItem={cartItem}
                key={cartItem.id}
                activeProduct={activeProduct}
                setActiveProduct={setActiveProduct}
              />
            ))}
        </tbody>
      </table>
    </section>
  );
};

export default ListCarts;
