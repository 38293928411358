import ListItem from "./ListItem";
import { IBlogPosts } from "views/Blog/BlogCategories";

const BlogList = ({ posts }: { posts: IBlogPosts[] }) => {
  return (
    <section className="blogList">
      <ul className="row row-cols-1 gx-0 gy-5 blogList__content">
        {posts.map((post) => (
          <ListItem key={post.id} post={post} />
        ))}
      </ul>
    </section>
  );
};

export default BlogList;
