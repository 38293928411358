import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import FreePage from "@components/General/FreePage";
import useApi from "hooks/useApi";
import { getPolicies } from "services/user.service";
import { PublicContext } from "contexts/PublicContext/PublicContext";
/* eslint-disable react-hooks/exhaustive-deps */
const PrivacyPolicy = () => {
  const { data, success, handleApi } = useApi();

  useEffect(() => {
    handleApi(getPolicies, {});
  }, []);

  const privacyPolicy = data?.find(
    (item: { type: string }) => item.type === "privacy_policy"
  );

  const { setSiteTitle } = useContext(PublicContext);
  useEffect(() => {
    setSiteTitle("Gizlilik Politikası");
  }, []);
  return (
    <>
      <section className="breadcrumb mt-4 mt-sm-5">
        <div className="container">
          <ul className="c:dark fs:14 lt:3">
            <li>
              <Link to="/anasayfa">Anasayfa</Link>
            </li>
            <li>
              <Link to="/gizlilik-politikasi">Gizlilik Politikası</Link>
            </li>
          </ul>
        </div>
      </section>
      {success && (
        <FreePage title="Gizlilik Politikası" content={privacyPolicy?.content} />
      )}
    </>
  );
};

export default PrivacyPolicy;
