import React, { useState, useRef } from "react";
import useOnClickOutside from "hooks/useOnClickOutside";

interface IProps {
  sortBy: string;
  setSortBy: (srt: string) => void;
}

const DropdownFilter = ({ sortBy, setSortBy }: IProps) => {
  const [dropdownIsVisible, setDropdownIsVisible] = useState(false);

  const handleDropdownFilter = () => {
    setDropdownIsVisible((prev) => !prev);
  };

  const dropdownRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(
    dropdownRef,
    () => dropdownIsVisible && handleDropdownFilter()
  );

  const handleDropDown = (param: string) => {
    setSortBy(param);
    handleDropdownFilter();
  };

  return (
    <div className="dropdownBtn dropdownBtn--secondary" ref={dropdownRef}>
      <div className="formElementItem formElementItem--customFilter">
        {/* <!-- selectDropdown'a tıklandığında formElementItem__input--select__active classı eklenecek. --> */}
        <div
          onClick={() => handleDropdownFilter()}
          className={`selectDropdown formElementItem__input formElementItem__input--padding formElementItem__input--select bg:white radius:12 w-100 fs:15 ff:regular c:dark shadow:4 d-flex align-items-center ${
            dropdownIsVisible ? "formElementItem__input--select__active" : ""
          }`}
          style={
            {
              "--inputPadding": "0 3rem 0 2.5rem",
              "--form-borderColor": "#D8D8D8",
              "--form-height": "2.5rem",
            } as React.CSSProperties
          }
        >
          <p className="c:dark ff:regular fs:15">{sortBy === "aZ" ? "A'dan Z'ye sırala" : "Z'den A'ya sırala"}</p>
        </div>
      </div>
      {/* <!-- selectDropdown'a tıklandığında d-none d-block --> */}
      <div
        className={`dropdownBtn__box bg:white radius:12 shadow:8 d-block border:1 ${
          dropdownIsVisible ? "d-block" : "d-none"
        }`}
      >
        <ul className="py-3">
          <li>
            <button
              type="button"
              onClick={() => handleDropDown("aZ")}
              aria-label="select campaigns"
              className={`px-3 w-100 ${
                sortBy === "aZ" ? "dropdownBtn__box-active" : ""
              }`}
            >
              <p className="c:dark ff:regular fs:15">A'dan Z'ye sırala</p>
            </button>
          </li>
          <li>
            <button
              onClick={() => handleDropDown("zA")}
              type="button"
              aria-label="select campaigns"
              className={`px-3 w-100 ${
                sortBy === "zA" ? "dropdownBtn__box-active" : ""
              }`}
            >
              <p className="c:dark ff:regular fs:15">Z'den A'ya sırala</p>
            </button>
          </li>
          {/* <li>
            <button
              type="button"
              aria-label="select campaigns"
              className="px-3 w-100"
            >
              <p className="c:dark ff:regular fs:15">Önerilen</p>
            </button>
          </li> */}
        </ul>
      </div>
    </div>
  );
};

export default DropdownFilter;
