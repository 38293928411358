import React from "react";
import turuncuHatLogoSecondary from "@images/turuncuhat-logo-secondary.svg";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <main className="layoutMain layoutMain--errorPage">
      <section className="w-100 errorPage">
        <div className="container-fluid">
          <div className="logo">
            <Link to="/anasayfa" aria-label="turuncuhat logo">
              <img className="mx-auto" src={turuncuHatLogoSecondary} alt="" />
            </Link>
          </div>
          <h1 className="errorPage__title c:dark:2 ff:light text-center my-4">
            404
          </h1>
          <p className="fs:18 ff:light c:dark:2 text-center mb-4">
            Aradığınız sayfaya şu anda ulaşılamıyor.
          </p>
          <div className="d-flex justify-content-center">
            <Link
              to="/anasayfa"
              aria-label="anasayfaya git"
              className="buttonStyle buttonStyle--icon buttonStyle--paddingElem c:white ff:bold fs:16 bg:orange text-center radius:12 d-inline-block"
              style={
                { "--btnPaddingElem": "0.813rem 4rem" } as React.CSSProperties
              }
            >
              Anasayfa
            </Link>
          </div>
        </div>
      </section>
    </main>
  );
};

export default NotFound;
