import React, { useContext, useEffect, useState } from "react";
import { CartContext } from "./CartContext";
import {
  deleteShoppingCart,
  getShoppingCart,
  setShoppingCart,
} from "services/shoppingCart.service";
import { AuthContextSite } from "contexts/AuthContext/AuthContext";
import { ResultDetail } from "types/productTypes";
import { ICart, ICartItem } from "types/shoppingCartTypes";
import { repeatOrder } from "services/user.service";
import { BodyContext } from "contexts/BodyContext/BodyContext";
import { useNavigate } from "react-router-dom";
import { ErrorHandler } from "@components/General/ErrorHandler";
import { useTealium } from "hooks/useTealium";

const CartProvider = ({ children }: { children: React.ReactElement }) => {
  const [cart, setCart] = useState<ICart>();
  const { handleNotification } = useContext(AuthContextSite);
  const { handlePopup } = useContext(BodyContext);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<any>();
  const [transactionId, setTransactionId] = useState<string | undefined>();

  const [loadingPlusMinus, setLoadingPlusMinus] = useState(false);

  const { products } = useContext(BodyContext);
  const tealium = useTealium();

  useEffect(() => {
    async function handleShopppingCart() {
      setLoading(true);
      try {
        const resp = await getShoppingCart();
        setCart(resp);
      } catch (error) {
        const { errorMessage } = ErrorHandler(error);
        setError(errorMessage);
      } finally {
        setLoading(false);
      }
    }
    handleShopppingCart();
  }, []);

  const addToCart = async (
    product: ResultDetail,
    qty?: number,
    selectedOfferId?: string,
    preOrder?: boolean,
    transactionId?: string
  ) => {
    if (!cart) return;

    setError(null);
    try {
      setLoading(true);
      setTransactionId(transactionId);
      const data = {
        items: [
          {
            action: "add",
            productId: product.id,
            quantity: {
              amount: qty ? qty : 1,
              // freeAmount: 0,
              units: "Adet",
            },
            selectedOffer: selectedOfferId ?? "",
            preOrder: preOrder,
          },
        ],
      };
      const resp: ICart = await setShoppingCart(data);
      setCart(resp);
      tealium.linkEvent({
        tealium_event: "card_add_item",
        product_id: product.id,
        product_price: product.salePrice,
        product_quantity: qty ? qty : 1,
      });
      handleNotification!("addToCart");
    } catch (error: any) {
      const { errorMessage, errorCode } = ErrorHandler(error);

      if (error?.response?.data) {
        if (
          errorCode === "HasPreOrderInShoppingCart" ||
          errorCode === "HasNonPreOrderInShoppingCart"
        ) {
          handlePopup("clear", {
            popupMessage: errorMessage,
            product: product,
            productId: product.id,
            quantity: qty ? qty : 1,
            isPreOrder: preOrder,
            selectedOfferId: selectedOfferId ?? "",
          });
        } else {
          setError(errorMessage);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const updateCartItem = async (
    cartItem: ICartItem,
    qty?: number,
    selectedOfferId?: string,
    preOrder?: boolean,
    transactionId?: string
  ) => {
    setError(null);
    const data = {
      items: [
        {
          action: "modify",
          id: cartItem.id,
          productId: cartItem.productId,
          quantity: {
            amount: qty,
            // freeAmount: cartItem.quantity.freeAmount,
            units: "Adet",
          },
          selectedOffer: selectedOfferId ?? "",
          preOrder: preOrder,
        },
      ],
    };
    if (qty !== 0) {
      try {
        setLoadingPlusMinus(true);
        setTransactionId(transactionId);
        const resp = await setShoppingCart(data);
        tealium.linkEvent({
          tealium_event: "card_modify",
          product_id: cartItem.productId,
          product_price: cartItem.salePrice,
          product_quantity: qty ? qty : 1,
        });
        setCart(resp);
        handleNotification!("updateCartItem");
      } catch (error: any) {
        setError(error?.response?.data?.message);
      } finally {
        setLoadingPlusMinus(false);
      }
    } else {
      handleRemoveItemFromCart(cartItem);
    }
  };

  const handleAddMoreItemToCart = async (
    cartItem: ICartItem,
    selectedOfferId?: string,
    preOrder?: boolean
  ) => {
    setError(null);
    setSuccess(false);
    const data = {
      items: [
        {
          action: "modify",
          id: cartItem.id,
          productId: cartItem.productId,
          quantity: {
            amount: cartItem.quantity.amount + 1,
            // freeAmount: 0,
            units: "Adet",
          },
          selectedOffer: selectedOfferId ?? cartItem.selectedOffer,
          preOrder: preOrder,
        },
      ],
    };

    try {
      setLoadingPlusMinus(true);
      const resp = await setShoppingCart(data);
      tealium.linkEvent({
        tealium_event: "card_item_plus",
        product_id: cartItem.productId,
        product_price: cartItem.salePrice,
        product_quantity: cartItem.quantity.amount + 1,
      });
      setCart(resp);
      setSuccess(true);
    } catch (error: any) {
      const { errorMessage } = ErrorHandler(error);
      setError(errorMessage);
    } finally {
      setLoadingPlusMinus(false);
    }
  };

  const handleRemoveItemFromCart = async (cartItem: ICartItem) => {
    const data = {
      items: [
        {
          id: cartItem.id,
          action: "Delete",
        },
      ],
    };
    setError(null);
    try {
      setLoading(true);
      const resp = await deleteShoppingCart(data);
      tealium.linkEvent({
        tealium_event: "card_delete_item",
        product_id: cartItem.productId,
        product_price: cartItem.salePrice,
        product_quantity: cartItem.quantity.amount,
      });
      handleNotification!("removeFromCart");
      setCart(resp);
      setSuccess(true);
      handlePopup("", null);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleMinusFromCart = async (
    cartItem: ICartItem,
    selectedOfferId?: string,
    product?: any,
    preOrder?: boolean
  ) => {
    setSuccess(false);
    setError(null);
    const data = {
      items: [
        {
          action: "modify",
          id: cartItem.id,
          productId: cartItem.productId,
          quantity: {
            amount: cartItem.quantity.amount - 1,
            // freeAmount: 0,
            units: "Adet",
          },
          selectedOffer: selectedOfferId ?? cartItem.selectedOffer,
          preOrder: preOrder,
        },
      ],
    };
    if (cartItem.quantity.amount - 1 !== 0) {
      try {
        setLoadingPlusMinus(true);
        const resp = await setShoppingCart(data);
        tealium.linkEvent({
          tealium_event: "card_item_minus",
          product_id: cartItem.productId,
          product_price: cartItem.salePrice,
          product_quantity: cartItem.quantity.amount - 1,
        });
        setCart(resp);
        setSuccess(true);
      } catch (error: any) {
        const { errorMessage } = ErrorHandler(error);
        setError(errorMessage);
      } finally {
        setLoadingPlusMinus(false);
      }
    } else {
      handleRemoveItemFromCart(cartItem);
    }

    // handleNotification!("removeFromCart");
  };

  const updateWareHouseId = async (warehouseId: string, type?: string) => {
    setError(null);

    if (type?.length && type === "send") {
      try {
        setLoading(true);
        const resp = await setShoppingCart({ warehouseId: warehouseId, });
        tealium.linkEvent({
          tealium_event: "update_warehouseıd",
          warehouseId: warehouseId,
        });
        setCart(resp);
        setSuccess(true);
      } catch (error: any) {
        const { errorMessage } = ErrorHandler(error);
        setError(errorMessage);
      } finally {
        setLoading(false);
      }
    }
  };

  const navigate = useNavigate();

  const handleRepeatOrder = async (orderId: string) => {
    setError(null);
    try {
      setLoading(true);
      const resp = await repeatOrder(orderId);
      tealium.linkEvent({
        tealium_event: "repeat_order",
        orderId: orderId,
      });
      setCart(resp);
      setSuccess(true);
      navigate("/sepet");
    } catch (error: any) {
      const { errorMessage } = ErrorHandler(error);
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const getProductInCart = (prodId: string) => {
    let product = products.find((product) => product.id === prodId);

    return product;
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        setCart,
        addToCart,
        handleAddMoreItemToCart,
        handleRemoveItemFromCart,
        updateCartItem,
        handleMinusFromCart,
        updateWareHouseId,
        handleRepeatOrder,
        loading,
        success,
        error,
        setError,
        transactionId,
        getProductInCart,
        loadingPlusMinus,
        setSuccess,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartProvider;
