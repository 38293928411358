import { useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import iconHome from "@images/icon-home.svg";
import useApi from "hooks/useApi";
import { getBlog } from "services/blog.service";
import { BlogContext } from "contexts/BlogContext/BlogContext";

export interface IBlogNav {
  slug: string;
  name: string;
  color: string;
  id: string;
}
/* eslint-disable react-hooks/exhaustive-deps */
function BlogMenu() {
  const { handleApi, data, success } = useApi();
  const { blogMenu, setBlogMenu } = useContext(BlogContext);

  useEffect(() => {
    if (!blogMenu?.length) {
      handleApi(getBlog, {});
    }
  }, [blogMenu]);

  useEffect(() => {
    if (success && data) {
      setBlogMenu(data);
    }
  }, [success, data]);

  return (
    <section className="singleMenu flex-nowrap">
      <nav>
        <ul className="fs:16 c:dark:2 ff:bold">
          <li>
            <NavLink
              to={"/blog"}
              end={true}
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "singleMenu__active" : ""
              }
            >
              <img src={iconHome} alt="" />
            </NavLink>
          </li>

          {blogMenu &&
            blogMenu.map((item) => (
              <li key={item.id}>
                <NavLink
                  to={`/blog/${item.slug}`}
                  end={true}
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "singleMenu__active" : ""
                  }
                >
                  {item.name}
                </NavLink>
              </li>
            ))}
          <li>
            <NavLink
              to={"/blog/okuma-listem"}
              end={true}
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "singleMenu__active" : ""
              }
            >
              Okuma Listem
            </NavLink>
          </li>
        </ul>
      </nav>
    </section>
  );
}

export default BlogMenu;
