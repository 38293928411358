import { IWareHouses, IWareHousesResult } from "types/wareHouseTypes";
import InfiniteScroll from "react-infinite-scroll-component";
import StoreSkeletonItem from "./StoreSkeletonItem";
import NoData from "@components/General/NoData";
import StoreFavoriteItem from "./StoreFavoriteItem";

interface IProps {
  wareHouses: IWareHouses | undefined;
  filteredWareHouses: IWareHousesResult[] | undefined;
  limit: number;
  setLimit: (limit: number) => void;
  setRemovedWareHouseId: (whId: string) => void;
  loading: boolean;
}

const FavoriteStoreList = ({
  wareHouses,
  filteredWareHouses,
  limit,
  setLimit,
  setRemovedWareHouseId,
  loading,
}: IProps) => {
  const getData = () => {
    setLimit(limit + 6);
  };

  return loading ? (
    <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-3 g-3">
      <StoreSkeletonItem />
      <StoreSkeletonItem />
      <StoreSkeletonItem />
    </div>
  ) : filteredWareHouses && !filteredWareHouses.length ? (
    <div>
      <NoData text="Favori Deponuz bulunmamaktadır." />
    </div>
  ) : wareHouses ? (
    <InfiniteScroll
      className="row row-cols-1 row-cols-lg-2 row-cols-xl-3 g-3"
      dataLength={wareHouses && wareHouses.total - limit}
      next={getData}
      hasMore={wareHouses && wareHouses.total > limit}
      loader={
        <>
          <StoreSkeletonItem />
          <StoreSkeletonItem />
          <StoreSkeletonItem />
          <StoreSkeletonItem />
          <StoreSkeletonItem />
          <StoreSkeletonItem />
        </>
      }
    >
      {filteredWareHouses &&
        filteredWareHouses.map((warehouse) => (
          <StoreFavoriteItem
            item={warehouse}
            key={warehouse.id}
            setRemovedWareHouseId={setRemovedWareHouseId}
          />
        ))}
    </InfiniteScroll>
  ) : null;
};

export default FavoriteStoreList;
