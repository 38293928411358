import { useEffect } from "react";
import { Link, Outlet } from "react-router-dom";
import logo from "@images/turuncuhat-logo.svg";
import { RegisterProvider } from "contexts/RegisterContext/RegisterProvider";
import RegisterProgress from "@components/Auth/Register/RegisterProgress";
// import { AuthContextSite } from "contexts/AuthContext/AuthContext";

const Register = () => {
  // const { user } = useContext(AuthContextSite);

  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    window.addEventListener("resize", () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
  }, []);

  // const navigate = useNavigate();

  // useEffect(() => {
  //   if (user) {
  //     if (user!.userStatus < 5) {
  //       // navigate(`/kayit-ol/adim-${user.userStatus + 2}`);
  //     }
  //   } else {
  //     navigate(`/kayit-ol/adim-1`);
  //   }
  // }, [user]);

  return (
    <RegisterProvider>
      <div className="layoutBody">
        <header className="layoutHeader">
          <div className="layoutHeader__content">
            <RegisterProgress />
            <div className="logo d-none d-xl-block">
              <Link to="/" aria-label="turuncuhat logo">
                <img src={logo} alt="" />
              </Link>
            </div>
          </div>
        </header>
        <main className="layoutMain">
          <Outlet />
        </main>
      </div>
    </RegisterProvider>
  );
};

export default Register;
