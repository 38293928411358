import { useContext, useEffect } from "react";
import Footer from "@components/Footer";
import HeadMenu from "@components/HeadMenu";
import HeroSection from "@components/LandingPage/HeroSection";
import Section5 from "@components/LandingPage/Section5";
import Section6 from "@components/LandingPage/Section6";
import Section7 from "@components/LandingPage/Section7";
import Section1 from "@components/LandingPage/Section1";
import Section2 from "@components/LandingPage/Section2";
import Section3 from "@components/LandingPage/Section3";
import Gumshoe from "gumshoejs";
import { PublicContext } from "contexts/PublicContext/PublicContext";
/* eslint-disable react-hooks/exhaustive-deps */
const LandingPage = () => {
  const { setSiteTitle } = useContext(PublicContext);
  useEffect(() => {
    setSiteTitle("Ana Sayfa")
    var header = document.querySelector(".landingHeaderBar");
    var spy = new Gumshoe(".spyNav a", {
      offset: function () {
        return header!.getBoundingClientRect().height;
      },
      reflow: true,
    });
    spy.setup();
  }, []);

  return (
    <>
      <HeadMenu />
      <HeroSection />
      <Section1 />
      <Section2 />
      <Section3 />
      {/*<Section4 />*/}
      <Section5 />
      <Section6 />
      <Section7 />
      <Footer />
    </>
  );
};

export default LandingPage;
