import React, { useContext } from "react";
import iconStores from "@images/icon-stores.svg";
import { IQuickOrder } from "types/userTypes";
import { CartContext } from "contexts/CartContext/CartContext";
import { BodyContext } from "contexts/BodyContext/BodyContext";
import { formatCurrency } from "utility/formatCurrency";
import { convertDatetimeStrMonth } from "utility/formatDate";
import iconTrashList from "@images/icon-trash-list.svg";
import { QuickOrderContext } from "contexts/QuickOrderContext/QuickOrderContext";

interface IProps {
  item: IQuickOrder;
}

const QuickOrdersItem = ({ item }: IProps) => {
  const { cart } = useContext(CartContext);
  const { handleRepeatQuickOrder } = useContext(QuickOrderContext);
  const { handlePopup } = useContext(BodyContext);

  const handleApply = (item: any) => {
    if (cart && cart.quantity > 0) {
      handlePopup("applyQuickOrder", {
        id: item.id,
      });
    } else handleRepeatQuickOrder!(item.id);
  };

  return (
    <tr>
      <td>
        <div className="d-flex align-items-start">
          <div className="flex-shrink-0">
            <div
              className="widthElem heightElem iconSize:16 bg:purple radius:full d-flex align-items-center justify-content-center"
              style={
                {
                  "--widthElem": "2.125rem",
                  "--heightElem": "2.125rem",
                } as React.CSSProperties
              }
            >
              <img src={iconStores} alt="" />
            </div>
          </div>
          <div className="flex-grow-1 ms-3">
            <p className="c:dark ff:regular fs:16 smText" title="VOLIBRIS 5 mg">
              {item.productName}
            </p>
            <p className="text-uppercase lt:2 ff:regular fs:13 c:dark:2 mt-1">
              {convertDatetimeStrMonth(new Date(item.createdAt))}
            </p>
          </div>
        </div>
      </td>
      <td>
        <p className="c:dark fs:14 ff:regular smText text-uppercase">
          {item.warehouseName}
        </p>
      </td>
      <td>
        <p className="c:dark fs:16 ff:bold">{item.quantity}</p>
      </td>
      <td>
        <p className="c:dark fs:16 ff:bold">
          &#8378;&nbsp;{formatCurrency(item.totalPrice.value)}
        </p>
      </td>
      <td>
        <div className="d-flex align-items-center justify-content-end">
          <div>
            <button
              type="button"
              onClick={() => handleApply(item)}
              aria-label="Hızlı Sipariş Ver"
              className="buttonStyle buttonStyle--paddingElem buttonStyle--border c:orange ff:bold fs:16 bg:white d-inline-block text-center radius:12 shadow:10 lh:1"
              style={
                {
                  "--btnPaddingElem": "0.8rem 2rem",
                  "--btn-border": "#FE9744",
                  "--btn-hover": "#FE9744",
                } as React.CSSProperties
              }
            >
              Hızlı Sipariş Ver
            </button>
          </div>
          <div className="ms-3">
            <button
              onClick={() => handlePopup("deleteQuickOrder", item)}
              className="trashButton"
              aria-label="sil butonu"
              type="button"
            >
              <img src={iconTrashList} alt="" />
            </button>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default QuickOrdersItem;
