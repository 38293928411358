import React, { useContext, useState } from 'react';
import ProductHome from './ProductHome';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import { IProductAll } from 'types/productTypes';
import { BodyContext, ProductFilters } from 'contexts/BodyContext/BodyContext';
import ProductHomeSkeleton from './ProductHomeSkeleton';

const ProductsSlider = ({
                          title,
                          filter,
                        }: {
  title: string;
  filter: ProductFilters;
}) => {
  let filtered: IProductAll[] | [];
  const { loading, products, error } = useContext(BodyContext);
  const [swiperSlide, setSwiperSlide] = useState<any>(null);
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  const paginationRef = React.useRef(null);

  //if (loading) return <p>Loading...</p>;
  switch (filter) {
    case ProductFilters.Popular:
      filtered = products
        .filter((product) => product?.label?.includes('Popular'))
        .slice(0, 10);
      break;
    case ProductFilters.BestSeller:
      filtered = products
        .filter((product) => product?.label?.includes('BestSaller'))
        .slice(0, 10);
      break;
    case ProductFilters.Turuncu:
      filtered = products
        .filter((product) => product?.label?.includes('Turuncu'))
        .slice(0, 10);
      break;
    case ProductFilters.Suggested:
      filtered = products
        .filter((product) => product?.label?.includes('Suggested'))
        .slice(0, 10);
      break;
    default:
      filtered = products.slice(0, 10);
      break;
  }

  return (
    <section className='productListCard'>
      <div className='container'>
        <h1 className='c:dark ff:bold fs:24 mb-3 pb-1 text-center text-lg-start'>
          {title}
        </h1>
        {!loading && error ? error : null}
        <div className='position-relative carouselType-2 carouselType-2--3'>
          <>
            <div className='swiper carouselType-2__item carouselType-2__item--3'>
              <Swiper
                modules={[Navigation, Pagination]}
                spaceBetween={12}
                onSwiper={(swiper) => setSwiperSlide(swiper)}
                watchSlidesProgress={true}
                slidesPerView={1}
                slidesPerGroup={1}
                autoHeight={true}
                // observer={true}
                // observeParents={true}
                navigation={{
                  prevEl: navigationPrevRef.current,
                  nextEl: navigationNextRef.current,
                }}
                className='swiper-wrapper'
                pagination={{
                  el: paginationRef.current,
                  clickable: true,
                  dynamicBullets: true,
                }}
                breakpoints={{
                  768: {
                    slidesPerView: 2,
                    slidesPerGroup: 1,
                  },
                  992: {
                    slidesPerView: 3,
                    slidesPerGroup: 1,
                  },
                  1200: {
                    slidesPerView: 5,
                    slidesPerGroup: 1,
                  },
                }}
              >
                {loading ? (
                  <>
                    <SwiperSlide className='swiper-slide'>
                      <ProductHomeSkeleton />
                    </SwiperSlide>

                    <SwiperSlide className='swiper-slide'>
                      <ProductHomeSkeleton />
                    </SwiperSlide>

                    <SwiperSlide className='swiper-slide'>
                      <ProductHomeSkeleton />
                    </SwiperSlide>

                    <SwiperSlide className='swiper-slide'>
                      <ProductHomeSkeleton />
                    </SwiperSlide>

                    <SwiperSlide className='swiper-slide'>
                      <ProductHomeSkeleton />
                    </SwiperSlide>
                  </>
                ) : (
                  filtered.map((product: IProductAll) => (
                    <SwiperSlide key={product.id} className='swiper-slide'>
                      <ProductHome product={product} />
                    </SwiperSlide>
                  ))
                )}
              </Swiper>

              <div
                ref={paginationRef}
                className='swiper-pagination mt-3 pt-2 d-block d-md-none position-relative'
              ></div>
            </div>
            <div
              ref={navigationNextRef}
              className='swiper-button-next d-none d-md-block'
            ></div>
            <div
              ref={navigationPrevRef}
              className='swiper-button-prev d-none d-md-block'
            ></div>
          </>
        </div>
      </div>
    </section>
  );
};

export default React.memo(ProductsSlider);
