import React, { useContext, useEffect, useReducer, useState } from 'react';
import { z } from 'zod';
import StepArrrows from './StepArrrows';
import infoicon from '@images/icon-info.svg';
import { companyInformationValidations } from 'validations/AuthValidations';
import useApi from 'hooks/useApi';
import { getCities, userProfile } from 'services/user.service';
import {
  IRegisterState,
  RegisterContext,
} from 'contexts/RegisterContext/RegisterContext';
import { ICity } from '@components/Hesabim/MyAccount/PersonalInfoEditable';
import Field from '@components/Form/Field';
import ButtonContinue from './ButtonContinue';
import { formatInput } from 'utility/FormatInput';
import { useNavigate } from 'react-router-dom';
import { PublicContext } from 'contexts/PublicContext/PublicContext';

/* eslint-disable react-hooks/exhaustive-deps */
export interface IForm {
  company: string;
  address: string;
  city: string;
  town: string;
  gln: string;
}

const initialForm: IForm = {
  company: '',
  address: '',
  city: '',
  town: '',
  gln: '',
};

const initialErrorState = {
  company: '',
  address: '',
  city: '',
  town: '',
  gln: '',
};

const validations = z.object(companyInformationValidations);
/* eslint-disable react-hooks/exhaustive-deps */
const Step2 = () => {
  const { user, setUser, token } = useContext(
    RegisterContext,
  ) as IRegisterState;
  const [disabled, setDisabled] = useState(true);
  const [touched, setTouched] = useState(false); // Track whether the inputs have changed
  const { step } = useContext(RegisterContext) as IRegisterState;
  const [cities, setCities] = useState<ICity[]>([]);
  const [state, dispatch] = useReducer(
    (state: IForm, updates: any) => ({ ...state, ...updates }),
    initialForm,
  );

  const { setSiteTitle } = useContext(PublicContext);
  useEffect(() => {
    setSiteTitle('Kayıt Ol - Adım 2');
  }, []);

  const [errors, setErrors] = useState(initialErrorState);
  const {
    error: apiError,
    loading,
    handleApi,
    success,
    data,
    setSuccess,
  } = useApi();

  const navigate = useNavigate();

  const handleClick = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    await handleApi(userProfile, {
      email: user?.email,
      gln: state?.gln,
      company: state?.company,
      address: state?.address,
      city: state?.city,
      town: state?.town,
      token: token,
    });
  };

  useEffect(() => {
    if (success) {
      if (data) {
        setUser(data);

        navigate(`/kayit-ol/adim-3`);
      }
      return () => {
      };
    }
  }, [success, data]);

  useEffect(() => {
    if (user) {
      dispatch({
        company: user?.company,
        address: user?.address,
        city: user?.city,
        town: user?.town,
        gln: user?.gln,
      });
    }
    return () => {
    };
  }, [user]);

  useEffect(() => {
    const handleGetCities = async () => {
      const data = await getCities();
      setSuccess(false);
      setCities(data);
    };
    handleGetCities();
  }, []);

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    if (!touched) setTouched(true);
    if (name === 'city') {
      dispatch({ [name]: value, town: '' });
    } else {
      dispatch({ [name]: value });
    }
    const validation = companyInformationValidations[name];
    const result = validation.safeParse(value);

    // @ts-ignore
    setUser((prev: any) => {
      return { ...prev, [name]: value };
    });

    if (result.success) {
      setErrors((prev) => {
        return { ...prev, [name]: '' };
      });
    } else {
      setErrors((prev) => {
        return { ...prev, [name]: result.error.issues[0].message };
      });
    }
  };

  useEffect(() => {
    const result = validations.safeParse({
      company: user?.company,
      address: user?.address,
      city: user?.city,
      town: user?.town,
      gln: user?.gln,
    });

    if (result.success) {
      setErrors(initialErrorState);
    } else {
      setErrors((prev) => {
        const newErrors: any = result.error.issues.reduce((acc, error) => {
          // @ts-ignore
          acc[error.path[0]] = error.message;
          return acc;
        }, {});

        const filteredErrors = Object.keys(newErrors).reduce((acc, name) => {
          // @ts-ignore
          if (user?.[name]) {
            // @ts-ignore
            acc[name] = newErrors[name];
          }
          return acc;
        }, {});

        return {
          ...prev,
          ...filteredErrors,
        };
      });
    }
  }, [user?.gln, user?.company, user?.address, user?.city, user?.town]);


  useEffect(() => {
    const result = validations.safeParse({
      company: state?.company,
      address: state?.address,
      city: state?.city,
      town: state?.town,
      gln: state.gln,
    });
    setDisabled(!result.success);
  }, [touched, state, step]);

  return (
    <section className='w-100'>
      <div className='container-fluid'>
        <div className='row g-0 row-cols-1 row-cols-lg-2 row-cols-xl-3 justify-content-center'>
          <div className='col'>
            <div className='formElement '>
              <div className='mb-4'>
                <div
                  className='row align-items-center row-cols-1 row-cols-sm-auto flex-column-reverse flex-sm-row justify-content-between g-0'>
                  <div className='col'>
                    <h1 className='ff:light c:dark fs:28 d-flex align-items-center justify-content-center'>
                      Eczane Bilgileri
                    </h1>
                  </div>
                  <StepArrrows step={2} />
                </div>
              </div>
              <form onSubmit={(e) => e.preventDefault()}>
                <div className='formElement__block bg:white radius:12 shadow:3 p-3 p-sm-4'>
                  <div className='p-2'>
                    <div className='row row-cols-1 g-0 gy-3 gy-sm-4'>
                      <div className='col'>
                        <Field
                          type='text'
                          name='gln'
                          label='GLN kodu'
                          value={state.gln}
                          errors={errors.gln}
                          loading={loading}
                          onChange={handleChange}
                          maxLength={13}
                          pattern={formatInput}
                        />
                      </div>
                      <div className='col'>
                        <Field
                          type='text'
                          name='company'
                          label='eczane adı'
                          value={state?.company}
                          errors={errors.company}
                          loading={loading}
                          onChange={handleChange}
                        />
                      </div>

                      <div className='col'>
                        <Field
                          type='select'
                          name='city'
                          label='Şehir'
                          value={state?.city}
                          options={cities?.map((city) => {
                            return { value: city.name, label: city.name };
                          })}
                          errors={errors.city}
                          loading={loading}
                          onChange={handleChange}
                          searchable={true}
                        />
                      </div>
                      {state.city ? (
                        <div className='col'>
                          <Field
                            type='select'
                            name='town'
                            label='İlçe'
                            value={state?.town}
                            options={cities
                              ?.find((cty) => cty.name === state.city)
                              ?.towns.map((town) => {
                                return { value: town, label: town };
                              })}
                            errors={errors.town}
                            loading={loading}
                            searchable={true}
                            onChange={handleChange}
                          />
                        </div>
                      ) : (
                        <></>
                      )}

                      <div className='col'>
                        <Field
                          type='text'
                          name='address'
                          label='adres'
                          value={state?.address}
                          errors={errors.address}
                          loading={loading}
                          onChange={handleChange}
                          minLength={20}
                          maxLength={500}
                        />
                      </div>

                      <div className='col'>
                        {apiError.length ? (
                          <div
                            className='alert bg:red:2 p-3 radius:12 mb-3 mb-sm-4'
                            style={
                              {
                                '--alert-borderColor':
                                  'rgba(243, 52, 52, 0.22)',
                              } as React.CSSProperties
                            }
                          >
                            <div className='d-flex align-items-center'>
                              <div className='flex-shrink-0'>
                                <img src={infoicon} alt='' />
                              </div>
                              <div className='flex-grow-1 ms-3'>
                                <p className='c:red lt:2 ff:regular fs:12'>
                                  {apiError}
                                </p>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <ButtonContinue
                          disabled={disabled}
                          loading={loading}
                          text='Devam Et'
                          onClick={handleClick}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Step2;
