import React, { useContext } from "react";
import { CartContext } from "contexts/CartContext/CartContext";
import { formatCurrency } from "utility/formatCurrency";
import { useUser } from "hooks/useUser";
import { ISelectedWareHouse } from "types/wareHouseTypes";
import classNames from "classnames";

interface IProps {
  handleStep: () => void;
  step: string;
  selectedWareHouse: ISelectedWareHouse;
  apiLoading: boolean;
}

const CartFooter = ({
  handleStep,
  step,
  selectedWareHouse,
  apiLoading,
}: IProps) => {
  const { cart, loading } = useContext(CartContext);
  const { checkUserRole } = useUser();

  return (
    <div className="bg:white py-4 cartsBanner">
      <div className="container">
        <div className="row row-cols-1 row-cols-md-auto flex-column-reverse flex-md-row gx-0 gy-3 gy-md-0 align-items-center justify-content-between">
          <div className="col">
            <div className="d-flex align-items-center justify-content-center">
              <div className="me-2 pe-1">
                <p className="ff:regular c:dark fs:16 lh:1">Seçilen ürünler</p>
              </div>
              <div>
                <div className="d-inline-block bg:orange radius:8 px-1 py-1 c:white fs:14 ff:bold">
                  <p className="px-2 py-1 lh:1"> {cart && cart.quantity} </p>
                </div>
              </div>
            </div>
          </div>
          {cart && cart.quantity ? (
            <div className="col">
              <div className="row row-cols-auto align-items-center justify-content-center gx-3 gx-sm-4 gy-3 gy-sm-0 text-center text-sm-start">
                <div className="col">
                  <p className="ff:regular fs:16 c:dark lh:1">
                    Toplam sipariş tutarı
                  </p>
                  <p className="ff:bold fs:22 c:dark lh:1 mt-2 text-end">
                    &#8378;&nbsp;{cart && formatCurrency(cart.totalPrice.value)}
                  </p>
                </div>
                <div className="col">
                  {step !== "ozet" ? (
                    <button
                      onClick={() => handleStep()}
                      aria-label="Devam Et"
                      disabled={
                        !cart || loading || step !== "depo"
                          ? false
                          : !selectedWareHouse.wareHouseId.length
                          ? true
                          : false
                      }
                      type="button"
                      className={classNames(
                        "buttonStyle buttonStyle--hoverBorder buttonStyle--submit px-4 px-sm-5 py-0 c:white ff:bold fs:16 bg:orange d-block text-center radius:12 lh:1 mx-auto",
                        {
                          disabledBtn:
                            !cart ||
                            loading ||
                            (step === "depo" &&
                              !selectedWareHouse.wareHouseId.length),
                        }
                      )}
                      style={
                        {
                          "--form-height": "3rem",
                          "--btn-border": "#F36633",
                          "--btn-hover": "#fff",
                          "--btn-hover-color": "#F36633",
                        } as React.CSSProperties
                      }
                    >
                      Devam Et
                    </button>
                  ) : null}
                  {step === "ozet" && checkUserRole("OnlyPharmacy") ? (
                    <button
                      onClick={() => handleStep()}
                      aria-label="Devam Et"
                      disabled={
                        cart && selectedWareHouse && !loading && !apiLoading
                          ? false
                          : true
                      }
                      type="button"
                      className={`buttonStyle buttonStyle--hoverBorder buttonStyle--submit px-4 px-sm-5 py-0 c:white ff:bold fs:16 bg:orange d-block text-center radius:12 lh:1 mx-auto ${
                        loading || apiLoading
                          ? "spinnerElem spinnerElem--orange"
                          : ""
                      } ${
                        cart && selectedWareHouse && !loading && !apiLoading
                          ? ""
                          : "disabledBtn"
                      }`}
                      style={
                        {
                          "--form-height": "3rem",
                          "--btn-border": "#F36633",
                          "--btn-hover": "#fff",
                          "--btn-hover-color": "#F36633",
                        } as React.CSSProperties
                      }
                    >
                      Siparişi Tamamla
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default CartFooter;
