import { useContext, useEffect } from "react";
import useApi from "hooks/useApi";
import iconCircleCheck from "@images/icon-circle-check.svg";
import iconTrash from "@images/icon-trash.svg";
import { NotificationContext } from "contexts/NotificationContext/NotificationContext";
import {
  readAllNotifications,
  removeAllNotifications,
} from "services/notification.service";

const HandleNotificationsButton = ({
  setAllHandleIsOpen,
}: {
  setAllHandleIsOpen: (val: boolean) => void;
}) => {
  const { handleApi, success } = useApi();
  const { reFetchNotifications, notifications, unreadedNotifications } =
    useContext(NotificationContext);

  const handleDeleteAllNotification = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    handleApi(removeAllNotifications, {});
  };
  const handleMarkAllRead = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    handleApi(readAllNotifications, {});
  };
/* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (success) {
      setAllHandleIsOpen(false);
      reFetchNotifications();
    }
  }, [success]);

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className="notificationBar__detail bg:white shadow:4 radius:12 p-4 me-3 me-sm-4 mt-2"
    >
      <div className="row row-cols-1 gx-0 gy-3">
        <div className="col">
          <button
            onClick={handleDeleteAllNotification}
            disabled={!notifications.length}
            aria-label="notification delete"
            type="button"
            className={`${
              !notifications.length ? "disableText" : null
            }`}
          >
            <div className="d-flex align-items-center text-start">
              <div className="flex-shrink-0">
                <img src={iconTrash} alt="" />
              </div>
              <div className="flex-grow-1 ms-2">
                <p className="ff:regular c:dark fs:15">Tüm bildirimleri sil</p>
              </div>
            </div>
          </button>
        </div>
        <div className="col">
          <button
            onClick={handleMarkAllRead}
            disabled={unreadedNotifications < 1}
            aria-label="notification delete"
            type="button"
            className={`${
              unreadedNotifications < 1 ? "disableText" : null
            }`}
          >
            <div className="d-flex align-items-center text-start">
              <div className="flex-shrink-0">
                <img src={iconCircleCheck} alt="" />
              </div>
              <div className="flex-grow-1 ms-2">
                <p className="ff:regular c:dark fs:15">
                  Tümünü okundu olarak işaretle
                </p>
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default HandleNotificationsButton;
