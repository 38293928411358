import React, { useState, useEffect } from "react";
import { IUser, useUser } from "hooks/useUser";
import { formatInput } from "utility/FormatInput";
import iconInfoGreen from "@images/icon-info-green.svg";
import iconInfo from "@images/icon-info.svg";
import {
  externalProfileValidations,
  gskProfileValidations,
  updateProfileValidations,
} from "validations/AuthValidations";
import { z } from "zod";
import { getCities, updateUser } from "services/user.service";
import useApi from "hooks/useApi";
import Field from "@components/Form/Field";
import { genderOptions } from "utility/genderOptions";
import classNames from "classnames";

export interface ICity {
  name: string;
  towns: string[];
}

interface IProps {
  user: IUser;
  mobileApproved: boolean;
  setMobileApproved: (val: boolean) => void;
  setIsMobilePhoneChanged: (val: boolean) => void;
  setUpdateProfile: (val: boolean) => void;
  state: any;
  dispatch: any;
  success: boolean;
  setSuccessFirst: (val: boolean) => void;
}

const initialErrorState = {
  name: "",
  surname: "",
  phone: "",
  mobile: "",
  gln: "",
  gender: "",
  company: "",
  address: "",
  city: "",
  town: "",
};

const PersonalInfoEditable = ({
  user,
  mobileApproved,
  setMobileApproved,
  setIsMobilePhoneChanged,
  setUpdateProfile,
  state,
  dispatch,
  success,
  setSuccessFirst,
}: IProps) => {
  const {
    error: apiError,
    loading,
    handleApi,
    setSuccess,
    data,
    success: apiSuccess,
  } = useApi();

  const handleUpdateProfile = () => {
    setUpdateProfile(false);
    setSuccess(false);
  };

  const gskProvider: boolean = user?.provider === "Gsk";
  const externalProvider: boolean = user?.provider === "External";
  const pharmacyProvider: boolean = user?.provider === "Pharmacy";

  const { addUser } = useUser();
  const [cities, setCities] = useState<ICity[]>([]);
  const [errors, setErrors] = useState<{ [key: string]: any }>(
    initialErrorState
  );

  let initialValidations;

  if (externalProvider) {
    initialValidations = externalProfileValidations;
  } else if (pharmacyProvider) {
    initialValidations = updateProfileValidations;
  } else if (gskProvider) {
    initialValidations = gskProfileValidations;
  } else {
    initialValidations = {}; // Default value if none of the conditions are met
  }

  const validations = z.object(initialValidations);

  const [touched, setTouched] = useState(false); // Track whether the inputs have changed
  const [disabled, setDisabled] = useState(true);

  const handleUpdateUser = async (e: any) => {
    e.preventDefault();
    setSuccess(false);

    const data = {
      firstName: state?.name ?? "",
      lastName: state?.surname ?? "",
    };

    const pharmacy = {
      ...data,
      gln: state.gln ?? "",
      mobile: state.mobile ?? "",
      gender: state.gender ?? "",
      company: state.company ?? "",
      address: state.address ?? "",
      city: state.city !== "Bir seçim yapın" ? state?.city : "",
      town: state.town !== "Bir seçim yapın" ? state?.town : "",
    };

    const external = {
      ...data,
      mobile: state.mobile ?? "",
    };

    const gsk = {
      ...data,
    };

    if (user?.mobile === state?.mobile) {
      const formData = pharmacyProvider
        ? pharmacy
        : externalProvider
        ? external
        : gsk;
      await handleApi(updateUser, formData);
    } else if (mobileApproved) {
      setSuccess(false);
      const formData = pharmacyProvider
        ? pharmacy
        : externalProvider
        ? external
        : gsk;
      await handleApi(updateUser, formData);
      setMobileApproved(false);
    } else {
      setIsMobilePhoneChanged(true);
    }
  };

  useEffect(() => {
    if (success) {
      addUser(user);
    }
    if (apiSuccess) {
      addUser(data);
    }
    return () => {};
  }, [data, user, success, apiSuccess]);

  useEffect(() => {
    setSuccessFirst(false);
  }, [setSuccessFirst]);

  useEffect(() => {
    const handleGetCities = async () => {
      const data = await getCities();
      setCities(data);
    };
    handleGetCities();
  }, []);

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    if (touched === false) setTouched(true);
    if (name === "city") {
      dispatch({ [name]: value, town: "" });
    } else {
      dispatch({ [name]: value });
    }

    const validation = updateProfileValidations[name];
    const result = validation.safeParse(value);
    if (result.success) {
      setErrors((prev) => {
        return { ...prev, [name]: "" };
      });
    } else {
      setErrors((prev) => {
        return { ...prev, [name]: result.error.issues[0].message };
      });
    }
  };
  useEffect(() => {
    if (touched) {
      const result = validations.safeParse(state);
      if (result.success) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    }

    return () => {};
  }, [touched, state, validations]);

  return (
    <div className="row g-0 justify-content-center">
      <div className="col-12 col-md-9 col-lg-8">
        <div className="formElement">
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="row row-cols-1 gx-0 gy-3 gy-sm-4">
              <div className="col">
                <Field
                  type="text"
                  name="name"
                  label="Ad"
                  value={state?.name}
                  errors={errors.name}
                  loading={loading}
                  onChange={handleChange}
                  required={true}
                />
              </div>
              <div className="col">
                <Field
                  type="text"
                  name="surname"
                  label="Soyad"
                  value={state?.surname}
                  errors={errors.surname}
                  loading={loading}
                  onChange={handleChange}
                  required={true}
                />
              </div>
              {pharmacyProvider && (
                <>
                  <div className="col">
                    <Field
                      type="text"
                      name="gln"
                      label="gln kodu"
                      value={state.gln}
                      errors={errors.gln}
                      loading={loading}
                      onChange={handleChange}
                      maxLength={13}
                      required={true}
                      pattern={formatInput}
                    />
                  </div>
                  <div className="col">
                    <Field
                      type="select"
                      name="gender"
                      label="Cinsiyet"
                      value={state?.gender}
                      options={genderOptions}
                      errors={errors.gender}
                      loading={loading}
                      onChange={handleChange}
                      required={true}
                    />
                  </div>
                </>
              )}
              {!gskProvider && (
                <div className="col">
                  <Field
                    type="text"
                    name="mobile"
                    label="cep telefon numarası"
                    value={state.mobile}
                    errors={errors.mobile}
                    loading={loading}
                    onChange={handleChange}
                    minLength={10}
                    maxLength={10}
                    placeholder={"5XX XXX XX XX"}
                    required={true}
                    pattern={formatInput}
                  />
                </div>
              )}
              {pharmacyProvider && (
                <>
                  <div className="col">
                    <Field
                      type="text"
                      name="company"
                      label="eczane adı"
                      value={state?.company}
                      errors={errors.company}
                      loading={loading}
                      onChange={handleChange}
                      required={true}
                    />
                  </div>
                  <div className="col">
                    <Field
                      type="select"
                      name="city"
                      label="Şehir"
                      value={state?.city}
                      options={cities?.map((city) => {
                        return { value: city.name, label: city.name };
                      })}
                      errors={errors.city}
                      loading={loading}
                      onChange={handleChange}
                      required={true}
                      searchable={true}
                    />
                  </div>
                  {state.city ? (
                    <div className="col">
                      <Field
                        type="select"
                        name="town"
                        label="İlçe"
                        value={state?.town}
                        options={cities
                          ?.find((cty) => cty.name === state.city)
                          ?.towns.map((town) => {
                            return { value: town, label: town };
                          })}
                        errors={errors.town}
                        loading={loading}
                        onChange={handleChange}
                        required={true}
                        searchable={true}
                      />
                    </div>
                  ) : null}

                  <div className="col">
                    <Field
                      type="text"
                      name="address"
                      label="adres"
                      value={state?.address}
                      placeholder={"eczane adresi"}
                      errors={errors.address}
                      loading={loading}
                      onChange={handleChange}
                      minLength={20}
                      maxLength={500}
                      required={true}
                    />
                  </div>
                </>
              )}
              <div className="col">
                {(success || apiSuccess) && (
                  <div
                    className="alert bg:green:2 p-3 radius:12 mb-3 mb-sm-4"
                    style={
                      {
                        "--alert-borderColor": "rgba(57, 189, 141, 0.22)",
                      } as React.CSSProperties
                    }
                  >
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0">
                        <img src={iconInfoGreen} alt="" />
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <p className="c:green lt:2 ff:regular fs:14">
                          Profil Başarıyla Güncellendi.
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                {apiError && (
                  <div
                    className="alert bg:red:2 p-3 radius:12 mb-3 mb-sm-4"
                    style={
                      {
                        "--alert-borderColor": "rgba(243, 52, 52, 0.22)",
                      } as React.CSSProperties
                    }
                  >
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0">
                        <img src={iconInfo} alt="" />
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <p className="c:red lt:2 ff:regular fs:14">
                          {apiError}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                <button
                  onClick={handleUpdateUser}
                  disabled={loading || disabled}
                  type="button"
                  aria-label="Değişiklikleri Kaydet"
                  className={classNames(
                    "buttonStyle buttonStyle--icon buttonStyle--submit c:white ff:bold fs:16 bg:dark:5 d-block w-100 lh:1 text-center radius:12",
                    {
                      spinnerElem: loading,
                      disabledBtn: disabled,
                    }
                  )}
                  style={
                    {
                      "--form-height": "3rem",
                    } as React.CSSProperties
                  }
                >
                  Değişiklikleri Kaydet
                </button>
              </div>
              <div className="col">
                <button
                  onClick={handleUpdateProfile}
                  type="button"
                  aria-label="Vazgeç"
                  className="buttonStyle buttonStyle--border buttonStyle--submit c:dark ff:bold fs:16 bg:white d-block w-100 lh:1 text-center radius:12"
                  style={
                    {
                      "--btn-border": "#3F4148",
                      "--btn-hover": "#3F4148",
                      "--form-height": "3rem",
                    } as React.CSSProperties
                  }
                >
                  {success || apiSuccess ? "Geri Dön" : "Vazgeç"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PersonalInfoEditable;
