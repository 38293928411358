import { INotification } from "types/notificationTypes";
import { convertDatetimeStrMonth, untilHours } from "utility/formatDate";
import iconTrash from "@images/icon-trash.svg";
import iconCircleCheck from "@images/icon-circle-check.svg";
import { useContext, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import {
  deleteSingleNotification,
  readSingleNotification,
} from "services/notification.service";
import useApi from "hooks/useApi";
import { NotificationContext } from "contexts/NotificationContext/NotificationContext";
import Skeleton from "react-loading-skeleton";

const NotificationItem = ({
  notification,
  iconDot,
  iconNotification,
  setNotificationDrawerIsOpen,
  setActiveNotification,
  activeNotification,
  handleActiveNotification,
}: {
  notification: INotification;
  setNotificationDrawerIsOpen: (val: boolean) => void;
  setActiveNotification: (val: string) => void;
  handleActiveNotification: (val: string, e: any) => void;
  activeNotification: string;
  iconDot: string;
  iconNotification: string;
}) => {
  const { reFetchNotifications } = useContext(NotificationContext);
  const { handleApi, success, loading: apiLoading } = useApi();

  const navigate = useNavigate();

  const handleNavigate = (buttonId: string, data: any) => {
    if (buttonId === "open-order") {
      navigate(`/hesabim/gecmis-siparislerim`, { state: { orderId: data.orderId } });
    }
    if (buttonId === "open-blog") {
      navigate(`/blogdetay/${data.id}`);
    }
    if (buttonId === "open-product") {
      navigate(`/urun/${data.id}`);
    }
    setNotificationDrawerIsOpen(false);
    handleApi(readSingleNotification, notification.id);
  };

  const markAsRead = (e: any) => {
    e.stopPropagation();
    handleApi(readSingleNotification, notification.id);
  };
  const deleteNotification = (e: any) => {
    e.stopPropagation();
    handleApi(deleteSingleNotification, notification.id);
  };
  useEffect(() => {
    if (success) {
      reFetchNotifications();
      setActiveNotification("");
    }

    return () => {};
  }, [success]);

  return (
    <li className={notification.isRead ? "bg:light:15" : ""}>
      <div className="row align-items-center justify-content-between gx-0 gy-3 gy-sm-0">
        <p className="fs:16 c:dark ff:bold mb-3">
          {convertDatetimeStrMonth(new Date(notification.createdAt))}
        </p>
        <div className="col-12 col-sm-7">
          <div className="d-flex align-items-center">
            {notification.imageUrl ? (
              <div className="flex-shrink-0">
                <div
                  className="widthElem heightElem me-3 bg:orange d-flex align-items-center justify-content-center radius:full"
                  style={
                    {
                      "--widthElem": "2.375rem",
                      "--heightElem": "2.375rem",
                    } as React.CSSProperties
                  }
                >
                  <div className="iconSize:16">
                    <img src={notification.imageUrl} alt="" />
                  </div>
                </div>
              </div>
            ) : null}

            <div className="flex-grow-1">
              <p className="ff:light c:dark fs:12 mb-1">
                {untilHours(new Date(notification.createdAt))}
              </p>
              <p className="ff:regular c:dark fs:15">{notification.message}</p>
              {/* {notification.isRead ? (
                <div
                  className="bg:green:3 d-inline-block mt-2 px-3 py-2 ff:bold lt:4 radius:100 c:green shadow:4 text-uppercase lh:1 fs:10 borderStyle"
                  style={
                    {
                      "--gsk-border-width": " 0.063rem",
                      "--gsk-border-color": " #39BD8D",
                    } as React.CSSProperties
                  }
                >
                  <p>Okundu</p>
                </div>
              ) : null} */}
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-5">
          <div className="row row-cols-auto justify-content-end align-items-center gx-3 gy-0">
            {notification?.actionButtons?.length ? (
              <div className="col">
                <button
                  onClick={() =>
                    handleNavigate(
                      notification.actionButtons[0].id,
                      notification.data,
                    )
                  }
                  aria-label="plana git"
                  className="buttonStyle buttonStyle--light c:dark ff:bold fs:14 bg:white shadow:4 d-inline-block text-center radius:12"
                  type="submit"
                >
                  {notification.actionButtons[0].label}
                </button>
              </div>
            ) : null}

            <div className="col">
              <button
                onClick={(e) => handleActiveNotification(notification.id, e)}
                style={
                  {
                    "--btn-width-size": "2.506rem",
                    "--btn-height-size": "2.506rem",
                  } as React.CSSProperties
                }
                aria-label="bildirim ayarı"
                className={`buttonStyle buttonStyle--size buttonStyle--light c:dark ff:bold fs:14 bg:white shadow:4 d-flex align-items-center justify-content-center text-center radius:full ${
                  activeNotification === notification.id
                    ? "notificationBtnActive"
                    : null
                }`}
                type="submit"
              >
                <img src={iconDot} alt="" />
              </button>
              {/* <!-- aşağıdaki butona tıklandığında butona "notificationBtnActive" class'ı eklenecek --> */}

              {notification.id === activeNotification ? (
                <div
                  onClick={(e) => e.stopPropagation()}
                  className="notificationBar__detail bg:white shadow:4 radius:12 p-4 me-3 me-sm-4 mt-2"
                >
                  <div className="row row-cols-1 gx-0 gy-3">
                    <div className="col">
                      <button
                        onClick={deleteNotification}
                        aria-label="notification delete"
                        type="button"
                      >
                        <div className="d-flex align-items-center text-start">
                          <div className="flex-shrink-0">
                            <img src={iconTrash} alt="" />
                          </div>
                          <div className="flex-grow-1 ms-2">
                            {apiLoading ? (
                              <Skeleton height={17} width={73} />
                            ) : (
                              <p className="ff:regular c:dark fs:15">
                                Bildirimi Sil
                              </p>
                            )}
                          </div>
                        </div>
                      </button>
                    </div>
                    <div className="col">
                      <button
                        disabled={notification.isRead}
                        onClick={(e) => markAsRead(e)}
                        aria-label="notification delete"
                        type="button"
                        className={`${
                          notification.isRead ? "disableText" : null
                        }`}
                      >
                        <div className="d-flex align-items-center text-start">
                          <div className="flex-shrink-0">
                            <img src={iconCircleCheck} alt="" />
                          </div>
                          <div className="flex-grow-1 ms-2">
                            {apiLoading ? (
                              <Skeleton height={17} width={151} />
                            ) : (
                              <p className="ff:regular c:dark fs:15">
                                Okundu olarak işaretle
                              </p>
                            )}
                          </div>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default NotificationItem;
