import { endpoints } from "constants/endpoints";
import api from "../utility/request";

export const getBlog = async (): Promise<any> => {
  const response = (await api.get(`${endpoints.baseUrl}/blog`)).data;
  return response;
};
export const getBlogHome = async (): Promise<any> => {
  const response = (await api.get(`${endpoints.baseUrl}/blog/home`)).data;
  return response;
};

export const getBlogPosts = async (data: any): Promise<any> => {
  const { id } = data;
  const response = (
    await api.get(`${endpoints.baseUrl}/post/by/blog/slug/${id}`)
  ).data;
  return response;
};
export const getBlogPostsByHashTag = async (tag: string): Promise<any> => {
  const response = (
    await api.get(`${endpoints.baseUrl}/post/by/hashtag/${tag}`)
  ).data;
  return response;
};
