import iconInfoFill from "@images/icon-info-fill.svg";
import BlogZoomImage from "../BlogZoomImage";
import { IPostItem } from "../BlogDetail";

const Brosur = ({ item }: { item: IPostItem }) => {
  return (
    <section className="my-4 my-lg-5">
      <div className="d-flex align-items-center mb-4">
        <div className="flex-shrink-0 iconSize:20">
          <img src={iconInfoFill} alt="" />
        </div>
        <div className="flex-grow-1 ms-2 ps-1">
          <p className="c:red fs:16 ff:bold">
            Aşağıdaki broşüre iki kez tıklayıp büyütebilirsiniz.
          </p>
        </div>
      </div>
      <div className="row row-cols-1 gx-0 gy-3">
        <BlogZoomImage item={item} />
      </div>
    </section>
  );
};

export default Brosur;
