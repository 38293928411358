import React from "react";
import { Link } from "react-router-dom";
import iconShoppingCart from "@images/icon-shopping-cart.svg";

const CartEmpty = () => {
  return (
    <>
      <section className="mt-4 mt-sm-5 mb-4">
        <div className="container">
          <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
            <p className="c:dark ff:bold fs:24">Sepetim</p>
            <p className="ms-2 ff:regular c:dark fs:16">(0 ürün)</p>
          </div>
        </div>
      </section>
      <section className="mb-5">
        <div className="container">
          <div className="row justify-content-center g-0">
            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
              <div className="row row-cols-1 gx-0 gy-3">
                <div className="col">
                  <div
                    className="widthElem heightElem iconSize:28 mx-auto bg:orange:2 radius:full d-flex align-items-center justify-content-center"
                    style={
                      {
                        "--widthElem": "5rem",
                        "--heightElem": "5rem",
                      } as React.CSSProperties
                    }
                  >
                    <img src={iconShoppingCart} alt="" />
                  </div>
                </div>
                <div className="col">
                  <p className="c:dark ff:light fs:16 text-center lt:2">
                    Alışveriş sepetiniz boş.
                  </p>
                </div>
                <div className="col">
                  {/* <!-- <button aria-label="Ana Sayfa" type="button" class="buttonStyle buttonStyle--hoverBorder buttonStyle--submit px-4 px-sm-5 py-0 c:white ff:bold fs:16 bg:orange d-flex align-items-center justify-content-center w-100 text-center radius:12 lh:1 mx-auto" style={{"--form-height":"3rem", "--btn-border":"#F36633", "--btn-hover":"#fff", "--btn-hover-color":"#F36633"} as React.CSSProperties}>Ana Sayfa</button> --> */}
                  <Link
                    aria-label="Ana Sayfa"
                    to="/urunler"
                    className="buttonStyle buttonStyle--hoverBorder buttonStyle--submit px-4 px-sm-5 py-0 c:white ff:bold fs:16 bg:orange d-flex align-items-center justify-content-center w-100 text-center radius:12 lh:1 mx-auto"
                    style={
                      {
                        "--form-height": "3rem",
                        "--btn-border": "#F36633",
                        "--btn-hover": "#fff",
                        "--btn-hover-color": "#F36633",
                      } as React.CSSProperties
                    }
                  >
                    Ürünlere Git
                  </Link>
                </div>
                <div className="col">
                  <Link
                    aria-label="Hızlı Sipariş Ver"
                    to="/hesabim/hizli-siparislerim"
                    className="buttonStyle buttonStyle--hoverBorder buttonStyle--submit px-4 px-sm-5 py-0 c:white ff:bold fs:16 bg:purple:2 d-flex align-items-center justify-content-center w-100 text-center radius:12 lh:1 mx-auto"
                    style={
                      {
                        "--form-height": "3rem",
                        "--btn-border": "#AB95C7",
                        "--btn-hover": "#fff",
                        "--btn-hover-color": "#AB95C7",
                      } as React.CSSProperties
                    }
                  >
                    Hızlı Sipariş Ver
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CartEmpty;
